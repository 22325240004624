import { Component, Inject } from '@angular/core';
import { PlmDialogToken, PlmDialogInject } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  selector: 'plm-speed-legend',
  templateUrl: './speed-legend.component.html',
  styleUrls: ['./speed-legend.component.scss']
})
export class SpeedLegendComponent {
  constructor(@Inject(PlmDialogToken) public dialog: PlmDialogInject) {}

  speedLevels = ['blazingFast', 'speedy', 'decent', 'sluggish'];

  get transPth(): string {
    return 'insights.speed.legend.';
  }
}
