import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../../lib/directives/directives.module';
import { IconModule } from '../../../lib/directives/icon/icon.module';
import { BoxModule } from '../box/box.component.module';

import { CarouselComponent } from './carousel.component';

@NgModule({
  imports: [BoxModule, CommonModule, IconModule, DirectivesModule],
  exports: [CarouselComponent],
  declarations: [CarouselComponent],
  providers: []
})
export class CarouselModule {}
