import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { IPlumeState } from './store';
import { setCurrentLocationId } from './store/actions/plume.actions';
import { currentLocation } from './store/selectors/plume.selectors';
import defaultLanguage from '../../assets/i18n/en.json';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService, private store$: Store<IPlumeState>) {}

  ngOnInit(): void {
    this.translate.setTranslation('en', defaultLanguage);
    this.translate.setDefaultLang('en');
    this.translate.use(this.translate.getBrowserCultureLang());
    moment.locale(this.translate.getBrowserCultureLang());
    this.store$
      .select(currentLocation)
      .pipe(take(1))
      .subscribe(location => {
        if (location) {
          this.store$.dispatch(setCurrentLocationId({ currentLocationId: location.id }));
        }
      });
  }
}
