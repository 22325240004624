<ng-container [ngSwitch]="field.type">
	<ng-container *ngSwitchCase="inputType.SEARCH">
    <div class="icon" icon="search"></div>
		<input type="search"
          [id]="id"
          class="form-input search-input"
          [attr.placeholder]="field.placeholder"
          [attr.aria-label]="field.label"
          [name]="field.name"
          [formControl]="field.control">
	</ng-container>

		<ng-container *ngSwitchCase="inputType.PASSWORD">
			<input [type]="showPassword ? 'text' : 'password'"
          [id]="id"
          class="form-input password-input"
          [name]="field.name"
          [formControl]="field.control">
			<label [for]="id">{{field.label}}</label>
			<button class="show-button"
      *ngIf="!!field.control.value" (click)="toggleShowPassword()">{{showPassword ? 'HIDE' : 'SHOW'}}</button>
		</ng-container>

		<ng-container *ngSwitchDefault>
			<input type="text"
      [id]="id"
          class="form-input"
          [attr.aria-label]="field.label"
          [name]="field.name"
          [formControl]="field.control">
			<label [for]="id">{{field.label}}</label>
		</ng-container>
	</ng-container>