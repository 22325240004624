import { Component, Inject } from '@angular/core';
import { PlmDialogToken, PlmDialogInject } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  templateUrl: './delete-sec-events.component.html',
  styleUrls: ['./delete-sec-events.component.scss']
})
export class DeleteSecEventsComponent {
  constructor(@Inject(PlmDialogToken) public data: PlmDialogInject<void, boolean>) {}

  get transPth(): string {
    return 'aisecurity.settings.deleteEventDialog.';
  }
}
