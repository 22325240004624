import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PersonService } from '../../services/people/person.service';
import { PersonActions } from '../actions/person.actions';

@Injectable()
export class PersonEffects {
  constructor(
    private actions$: Actions,
    private personService: PersonService) {}

  person$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonActions.getPersonById),
      switchMap((person) =>
        this.personService.getPersonById$(person.id).pipe(
          map(response => PersonActions.getPersonByIdSuccess({ person: response })),
          catchError(error => of(PersonActions.getPersonByIdFail(error)))
        )
      )
    )
  );
}
