<div class="podSignal-conatiner">
  <div class="title">{{ 'podHealth.title' | translate }}</div>
  <div class="description">
    {{ 'podHealth.description' | translate }}
  </div>
  <div class="signal-title">
    {{ 'podHealth.excellent' | translate }}
    <span class="excellent-signal"> {{ 'podHealth.excellentSignal' | translate }}</span>
    <div class="description">
      {{ 'podHealth.excellentSignalDesc' | translate }}
    </div>
  </div>

  <div class="signal-title">
    {{ 'podHealth.good' | translate }} <span class="excellent-signal"> {{ 'podHealth.goodSignal' | translate }}</span>
    <div class="description">
      {{ 'podHealth.goodSignalDesc' | translate }}
    </div>
  </div>

  <div class="signal-title">
    {{ 'podHealth.fair' | translate }} <span class="fair-signal"> {{ 'podHealth.fairSignal' | translate }}</span>
    <div class="description">
      {{ 'podHealth.fairSignalDesc' | translate }}
    </div>
  </div>
  <div class="signal-title">
    {{ 'podHealth.poor' | translate }} <span class="fair-signal"> {{ 'podHealth.poorSignal' | translate }}</span>
    <div class="description">
      {{ 'podHealth.poorSignalDesc' | translate }}
    </div>
  </div>
  <button (click)="dialog.close(false)" class="close-button">{{ 'buttons.close' | translate }}</button>
</div>
