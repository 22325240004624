import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
  transform(dateTime: string | number): string {
    return moment(dateTime)
      .format('LT')
      ?.toLowerCase();
  }
}
