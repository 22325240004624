import { NgModule } from '@angular/core';
import { DaysAgoPipe } from './days-ago/days-ago.pipe';
import { LastSeenPipe } from './last-seen/last-seen.pipe';
import { SortPipe } from './sort/sort.pipe';
import { TimePipe } from './time/time.pipe';
import { MomentFormatPipe } from './moment-format.pipe';

@NgModule({
  declarations: [TimePipe, DaysAgoPipe, LastSeenPipe, SortPipe, MomentFormatPipe],
  exports: [TimePipe, DaysAgoPipe, LastSeenPipe, SortPipe, MomentFormatPipe]
})
export class PipesModule {}
