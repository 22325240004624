<div class="header">{{ transPth + 'title' | translate }}</div>
<div class="content-text">{{ transPth + 'description' | translate }}</div>
<plm-input-field class="inputField">
  <label>{{ transPth + 'location' | translate }}</label>
  <input autofocus [(ngModel)]="dialog.data" />
</plm-input-field>
<div class="bottom-buttons">
  <button (click)="dialog.close(undefined)">{{ 'buttons.cancel' | translate }}</button>
  <button class="highlight" (click)="dialog.close(dialog.data)">{{ 'buttons.save' | translate }}</button>
</div>
