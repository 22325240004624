<div class="header">
  <span class="highlighted">{{ 'settings.access.passwordList.title' | translate }}</span>
  <span> {{ 'settings.access.passwordList.' + type + 'Description' | translate }}</span>
</div>
<plm-access-password
  *ngFor="let passwordData of passwords; let first = first"
  [data]="passwordData"
  [class.border-top]="!first"
  [dropDownType]="dropDownType(passwordData)"
  [devices]="devices"
  (dropDownSelected)="dropDownSelected.emit({ key: passwordData, type: $event })"
></plm-access-password>
