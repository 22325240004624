import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { Dhcp } from 'src/app/lib/store/models/dhcp.model';
import { LanSubnetEditConfirmDialogComponent } from '../lan-subnet-edit-confirm-dialog/lan-subnet-edit-confirm-dialog.component';

@Component({
  selector: 'plm-lan-subnet-edit',
  templateUrl: 'lan-subnet-edit.component.html',
  styleUrls: ['lan-subnet-edit.component.scss']
})
export class LanSubnetEditComponent {
  public octet2: number;
  public octet3: number;
  private valDhcp: Dhcp;
  public invalid = false;

  @Input() set dhcp(dhcp: Dhcp) {
    this.valDhcp = dhcp;
    if (this.valDhcp && this.valDhcp.subnet) {
      this.octet3 = parseInt(this.valDhcp.subnet.split('.')[2], 10);
      this.octet2 = parseInt(this.valDhcp.subnet.split('.')[1], 10);
    }
  }

  get dhcp(): Dhcp {
    return this.valDhcp;
  }

  @Input() networkClass: string;

  @Output() save = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();

  constructor(private dialog: DialogService) {}

  onSave(): void {
    switch (this.networkClass) {
      case 'A':
        if (this.octet3 >= 0 && this.octet3 <= 255 && this.octet2 >= 0 && this.octet2 <= 255) {
          this.emitSave(`10.${this.octet2}.${this.octet3}.0`);
          this.invalid = false;
        } else {
          this.invalid = true;
        }
        break;
      case 'B':
        if (this.octet3 >= 0 && this.octet3 <= 255 && this.octet2 >= 16 && this.octet2 <= 32) {
          this.emitSave(`172.${this.octet2}.${this.octet3}.0`);
          this.invalid = false;
        } else {
          this.invalid = true;
        }
        break;
      case 'C':
        if (this.octet3 >= 0 && this.octet3 <= 255) {
          this.emitSave(`192.168.${this.octet3}.0`);
          this.invalid = false;
        } else {
          this.invalid = true;
        }
        break;
    }
  }

  emitSave(value: string): void {
    this.dialog
      .openDialog$(LanSubnetEditConfirmDialogComponent)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.save.emit(value);
        }
      });
  }

  onCancel(): void {
    this.cancel.emit();
  }
}
