<div class="mobile-banner" *ngIf="showBanner">
  <p class="header">{{ 'home.header' | translate }}</p>
  <p>
    {{ 'home.appDescription' | translate }}
  </p>
  <div class="controls">
    <div class="button pill" (click)="banner('openApp')">{{ 'home.openApp' | translate }}</div>
    <div class="button link" (click)="banner('continue')">{{ 'home.continueWeb' | translate }}</div>
  </div>
</div>
