import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Configuration } from '../../../../../app/lib/rest-types';
import { IPlumeState } from '../../../../../app/lib/store';
import { configuration } from '../../../../../app/lib/store/selectors/plume.selectors';

@Component({
  selector: 'plm-assistance',
  templateUrl: 'assistance.component.html',
  styleUrls: ['assistance.component.scss']
})
export class AssistanceComponent {
  config$ = this.store$.pipe(select(configuration));
  constructor(private store$: Store<IPlumeState>) {}

  navigate(property: string, conf: Configuration): void {
    window.open(conf[property]);
  }
}
