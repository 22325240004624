<div class="header">{{ 'aisecurity.guard' | translate }}</div>
<div class="content-text subheader">{{ 'aisecurity.settings.subTitle' | translate }}</div>
<div *ngIf="!loading; else loader">
  <div [class.disabled]="privacyMode">
    <div class="row" *ngFor="let item of securityRules">
      <div class="iconColumn">
        <div class="icon" [icon]="item.icon"></div>
      </div>
      <div class="fieldText">
        <div class="name titleM">
          <span>{{ 'aisecurity.settings.rules.' + item.type + '.title' | translate }}</span>
          <span class="chip" *ngFor="let chip of item.chips">{{
            'aisecurity.settings.chips.' + chip | translate
          }}</span>
        </div>
        <div class="description content-text">
          {{ 'aisecurity.settings.rules.' + item.type + '.description' | translate }}
        </div>
      </div>
      <div class="control subtitle1">
        <plm-dropdown-menu position="topRight">
          <plm-list dropdown>
            <li class="drop-down-item" (click)="ruleMenuSelected(item, 'enabled')">
              <div>{{ 'aisecurity.settings.ruleState.enabled' | translate }}</div>
              <div class="icon" [icon]="item.state === 'enabled' ? 'selected-iem' : ''"></div>
            </li>
            <li class="drop-down-item" (click)="ruleMenuSelected(item, 'disabled')">
              <div>{{ 'aisecurity.settings.ruleState.disabled' | translate }}</div>
              <div class="icon" [icon]="item.state === 'disabled' ? 'selected-iem' : ''"></div>
            </li>
          </plm-list>
          <div [ngSwitch]="item.state">
            <div *ngSwitchCase="'enabled'">{{ 'aisecurity.settings.ruleState.enabled' | translate }}</div>
            <div *ngSwitchCase="'disabled'">{{ 'aisecurity.settings.ruleState.disabled' | translate }}</div>
            <div *ngSwitchDefault>{{ 'aisecurity.settings.ruleState.custom' | translate }}</div>
          </div>
        </plm-dropdown-menu>
      </div>
    </div>
  </div>

  <div class="privacy">
    <div class="privacyHeader">{{ 'aisecurity.settings.privacyTitle' | translate }}</div>
    <div class="content-text subheader">
      <span>{{ 'aisecurity.settings.privacySubTitle' | translate }}</span>
      <span [plmToolTipBox]="templatePortalContent" class="info" icon="info"></span>
    </div>
    <div>
      <div class="row">
        <div class="iconColumn">
          <div class="icon" icon="privacy-mode-off"></div>
        </div>
        <div class="fieldText itemTitle" *ngIf="!privacyMode">
          {{ 'aisecurity.settings.privacyModeOff' | translate }}
        </div>
        <div class="fieldText itemTitle" *ngIf="privacyMode">
          {{ 'aisecurity.settings.privacyModeOn' | translate }}
        </div>
        <div class="control subtitle1">
          <plm-toggle [(ngModel)]="privacyMode"></plm-toggle>
        </div>
      </div>
      <div class="row deleteGuard">
        <div class="iconColumn">
          <div class="icon" icon="delete-guard"></div>
        </div>
        <div class="fieldText itemTitle">
          <span class="clickable" (click)="deleteSecurityEvent()">{{
            'aisecurity.settings.DeleteGuardEvents' | translate
          }}</span>
        </div>
        <div class="control subtitle1"></div>
      </div>
    </div>
  </div>

  <div class="bottom-buttons">
    <button (click)="save()" [disabled]="saveLoading" class="highlight">{{ 'buttons.save' | translate }}</button>
  </div>
</div>

<ng-template #templatePortalContent>
  <div class="tool-tip-header">{{ 'aisecurity.settings.privacyModeToolTipHeader' | translate }}</div>
  <div class="tool-tip-paragraph" [innerHTML]="'aisecurity.settings.privacyModeToolTipText' | translate"></div>
</ng-template>

<ng-template #loader>
  <div class="loading" icon="loading"></div>
</ng-template>
