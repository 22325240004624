import { KeyValue } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'plm-hardware-info',
  templateUrl: 'hardware-info.component.html',
  styleUrls: ['hardware-info.component.scss']
})
export class HardwareInfoComponent {
  @Input() hardware: { [key: string]: string };

  // Preserve original property order
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => 0;
}
