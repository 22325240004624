<div
  #borderBox
  class="borderBox"
  [class.left]="overflowOnLeft"
  [class.right]="overflowOnRight"
  [style.left.px]="calculations.columnXCenter(this.index)"
  [style.bottom.px]="6 - calculations.margins.top"
>
  <div class="drop"></div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
