<div class="date">
  <div class="monthSelectRow">
    <div class="pill-icon-box focus monthText">{{ monthText }}</div>
    <div class="arrows">
      <div icon="arrow" class="icon rotate" (click)="previousMonth()"></div>
      <div icon="arrow" class="icon" (click)="nextMonth()"></div>
    </div>
  </div>

  <div class="dayTable">
    <div class="dayName" *ngFor="let weekDay of weekDays">{{ weekDay }}</div>
    <div
      *ngFor="let day of days"
      class="day"
      [class.currentMonth]="day.currentMonth"
      [class.selectedDay]="day.selectedDay"
      (click)="setDay(day.day)"
    >
      {{ day.date }}
    </div>
  </div>
</div>
<div class="time">
  <div class="timeSelectRow">
    <div class="pill-icon-box focus">{{ time.hours | number: '2.0' }}</div>
    <div class="pill-icon-box focus">{{ time.minutes | number: '2.0' }}</div>
    <div class="pill-icon-box focus" *ngIf="amPm">{{ time.amPm }}</div>
  </div>
  <div class="selectors">
    <div class="hours">
      <div icon="arrow" class="icon rotate" (click)="hourScroller.scrollBy({ behavior: 'smooth', top: -31 })"></div>
      <div class="scroller" #hourScroller>
        <div
          class="singleItem"
          *ngFor="let hour of [].constructor(amPm ? 12 : 24); let index = index"
          (click)="setHours(index + (amPm ? 1 : 0))"
        >
          {{ index + (amPm ? 1 : 0) | number: '2.0' }}
        </div>
      </div>
      <div icon="arrow" class="icon" (click)="hourScroller.scrollBy({ behavior: 'smooth', top: 31 })"></div>
    </div>
    <div class="minutes">
      <div icon="arrow" class="icon rotate" (click)="minutesScroller.scrollBy({ behavior: 'smooth', top: -31 })"></div>
      <div class="scroller" #minutesScroller>
        <div
          class="singleItem"
          *ngFor="let minutes of [].constructor(60); let index = index"
          (click)="setMinutes(index)"
        >
          {{ index | number: '2.0' }}
        </div>
      </div>
      <div icon="arrow" class="icon" (click)="minutesScroller.scrollBy({ behavior: 'smooth', top: 31 })"></div>
    </div>
    <div class="ampm" *ngIf="amPm && time.amPm === 'PM'" (click)="setAmPm('AM')">AM</div>
    <div class="ampm" *ngIf="amPm && time.amPm === 'AM'" (click)="setAmPm('PM')">PM</div>
  </div>
</div>
