import { Component, Input } from '@angular/core';
import { PlumeNode } from 'src/app/lib/rest-types';

@Component({
  selector: 'plm-pods-overview',
  templateUrl: './pods-overview.component.html',
  styleUrls: ['./pods-overview.component.scss']
})
export class PodsOverviewComponent {
  @Input() set nodes(nodes: PlumeNode[]) {
    this.nodeList = nodes
      .filter(node => node.leafToRoot?.length > 0 || node.connectionState === 'disconnected')
      .map(node => ({ ...node, name: node.nickname ?? node.defaultName }));
    this.gatewayList = nodes
      .filter(node => (!node.leafToRoot || node.leafToRoot.length === 0) && node.connectionState === 'connected')
      .map(node => ({ ...node, name: node.nickname ?? node.defaultName }));
    this.viewAll = this.nodeList.length <= 5;
  }
  @Input() connectedDevices = 0;
  @Input() allDevices = 0;

  gatewayList: (PlumeNode & { name: string })[] = [];
  nodeList: (PlumeNode & { name: string })[] = [];
  viewAll = false;

  toggleAll(): void {
    this.viewAll = !this.viewAll;
  }

  healthStatus(node: PlumeNode): 'excellent' | 'good' | 'fair' | 'poor' | 'offline' | 'calculating' {
    if (node.connectionState === 'disconnected') {
      return 'offline';
    }
    return node.health?.status;
  }
}
