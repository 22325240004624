<div class="accordion-item">
  <div
    class="accordion-item__heading"
    (click)="toggle.emit()"
    [ngClass]="{
      'no-border': noBorder || opened,
      'border-radius': borderRadius,
      alert: alert,
      'alert-open': opened && alert,
      'no-border-radius': borderRadius && opened
    }"
  >
    <ng-content select="[slot-heading]"></ng-content>
    <div [@rotatedState]="!opened ? 'default' : 'rotated'"><span class="icon" icon="arrow-right">x</span></div>
  </div>
  <div [@smoothCollapse]="!opened ? 'initial' : 'final'">
    <div class="accordion-item__body">
      <ng-content select="[slot-body]"></ng-content>
    </div>
  </div>
</div>
