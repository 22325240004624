import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AppFacade } from 'src/app/lib/rest-types';
import { IPlumeState } from 'src/app/lib/store';
import { offline } from 'src/app/lib/store/selectors/home.selectors';
@Component({
  selector: 'plm-speed-test-box',
  templateUrl: './speed-test-box.component.html',
  styleUrls: ['./speed-test-box.component.scss']
})
export class SpeedTestBoxComponent {
  @Input() public set speedTestRunning$(speedTestObs$: Observable<boolean>) {
    this.valSpeedTestRunning$ = speedTestObs$;
    this.speedTestFinished$ = speedTestObs$.pipe(
      filter((running, index) => !running && index > 0),
      switchMap(() => of<void>(undefined))
    );
  }
  public get speedTestRunning$(): Observable<boolean> {
    return this.valSpeedTestRunning$;
  }

  @Input() facade: AppFacade;
  @Output() startSpeedTest = new EventEmitter<void>();
  private valSpeedTestRunning$: Observable<boolean>;
  speedTestFinished$: Observable<void>;
  granularity: 'day' | 'week' | 'month' = 'day';

  offline$ = this.store$.pipe(select(offline));

  constructor(private store$: Store<IPlumeState>) {}

  changeGranularity(granularity: 'day' | 'week' | 'month'): void {
    this.granularity = granularity;
  }
}
