import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CalculationsService } from '../calculations/calculations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[plm-x-value-line]',
  templateUrl: './x-value-line.component.html',
  styleUrls: ['./x-value-line.component.scss']
})
export class XValueLineComponent {
  @ViewChild('textElm') textElm: ElementRef<SVGTextElement>;
  @Input() value: number;
  @Input() position: 'under' | 'over' = 'under';
  @Input()
  public set text(val: string) {
    this.textValue = val;
    this.textWidth = 6.5 * val.length;
  }
  textWidth = 0;
  textValue = '';

  constructor(public calculations: CalculationsService) {}
}
