<h3>{{ 'podsOffline.title' | translate }}</h3>
<div class="subHeader">{{ 'podsOffline.subTitle' | translate }}</div>

<div class="contentBox">
  <div class="imgBox">
    <img src="assets/podsOffline.svg" [alt]="'podsOffline.imgAlt' | translate" />
    <span class="text cable">{{ 'podsOffline.cable' | translate }}</span>
    <span class="text pod">{{ 'podsOffline.pod' | translate }}</span>
    <span class="text modem">{{ 'podsOffline.modem' | translate }}</span>
  </div>
  <plm-box class="textBox">
    <div>
      {{ 'podsOffline.ledNotPulsing' | translate }}
    </div>
    <div class="separator"></div>
    <div>
      {{ 'podsOffline.brokenPod' | translate }}
    </div>
  </plm-box>
</div>
