import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IPlumeState } from '..';
import { SecurityPolicyModel, WhitelistedOrBlacklisted } from '../models/security-policy.model';

export const selectSecurityPolicy = (state: IPlumeState): SecurityPolicyModel => state.insightsState.securityPolicy;

export const securityPolicyWhiteList = (state: IPlumeState): WhitelistedOrBlacklisted[] =>
  state.insightsState.securityPolicy ? state.insightsState.securityPolicy?.websites?.whitelisted : [];

export const securityPolicyBlackList = (state: IPlumeState): WhitelistedOrBlacklisted[] =>
  state.insightsState.securityPolicy ? state.insightsState.securityPolicy?.websites?.blacklisted : [];

export const securityPolicyPersonWhiteList = (state: IPlumeState): WhitelistedOrBlacklisted[] =>
  state.insightsState.selectedPerson ? state.insightsState.selectedPerson.securityPolicy.websites.whitelisted : [];

export const securityPolicyPersonBlackList = (state: IPlumeState): WhitelistedOrBlacklisted[] =>
  state.insightsState.selectedPerson ? state.insightsState.selectedPerson.securityPolicy.websites.blacklisted : [];

export const securityPolicyPersonLoading = (state: IPlumeState): boolean => state.insightsState.selectedPersonLoad;
export const securityPolicyLoading = (state: IPlumeState): boolean => state.insightsState.securityPolicyLoad;

export const selectWebsitesLoading = (
  type: string
): MemoizedSelector<IPlumeState, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(securityPolicyPersonLoading, securityPolicyLoading, (personLoading, securityLoading) => {
    switch (type) {
      case 'PolicyWhiteList':
        return securityLoading;

      case 'PolicyBlackList':
        return securityLoading;

      case 'PersonWhiteList':
        return personLoading;

      case 'PersonBlackList':
        return personLoading;

      default:
        break;
    }
  });
export const selectWebsites = (
  type: string
): MemoizedSelector<IPlumeState, WhitelistedOrBlacklisted[], DefaultProjectorFn<WhitelistedOrBlacklisted[]>> =>
  createSelector(
    securityPolicyWhiteList,
    securityPolicyBlackList,
    securityPolicyPersonWhiteList,
    securityPolicyPersonBlackList,
    (spWhiteList, spBlackList, spPersonWhiteList, spPersonBlackList) => {
      switch (type) {
        case 'PolicyWhiteList':
          return spWhiteList;

        case 'PolicyBlackList':
          return spBlackList;

        case 'PersonWhiteList':
          return spPersonWhiteList;

        case 'PersonBlackList':
          return spPersonBlackList;

        default:
          break;
      }
    }
  );
