import { createAction, props } from '@ngrx/store';
import { PlumeHome, PlumeNode, Topology } from '../../rest-types';

export const loadHome = createAction('[home] load home');
export const loadHomeSilent = createAction('[home] load home silent');
export const loadHomeDone = createAction('[home] load home done', props<{ data: PlumeHome }>());
export const setPubnubForceGraph = createAction('[home] set pubnub forceGraph', props<{ forceGraph: Topology }>());
export const deleteNode = createAction('[home] delete node', props<{ nodeId: string }>());
export const deleteNodeDone = createAction('[home] delete node done', props<{ nodeId: string; success: boolean }>());
export const setNodeLedMode = createAction(
  '[home] set node led mode',
  props<{ nodeId: string; mode: 'locate' | 'normal'; locationId?: string }>()
);
export const renameNode = createAction('[home] rename node', props<{ nodeId: string; newName: string }>());
export const renameNodeDone = createAction('[home] rename node done', props<{ node: Partial<PlumeNode> }>());
export const renameNodeError = createAction(
  '[home] rename node error',
  props<{
    nodeId: string;
    errorMessage: string;
  }>()
);
