import { Device } from './store/models/device.model';
import { Person } from './store/models/person.model';

export interface CustomerLocation {
  createdAt: string;
  id: string;
  name: string;
  onboardedAt: Date;
  ssid: string;
  networkMode: 'auto' | 'bridge' | 'router';
  networkModeRealized: 'bridge' | 'router';
  geoIp: {
    country: string;
    city: string;
    latitude: number;
    longitude: number;
    timezone: string;
    ISP: string;
    _version: string;
    updatedAt: string;
    wifiIsoCode: string;
    zip: string;
  };
}

export interface Configuration {
  newCustomerVideoUrl: string;
  supportPhone?: string;
}

export interface Customer {
  id: string;
  name?: string;
  partnerId?: string;
}

export interface SpeedForChart {
  timestamp: number;
  manual?: boolean;
  download: number;
  upload: number;
}

export interface Consumption {
  download: number;
  upload: number;
  downloadUnits: string;
  uploadUnits: string;
}

export interface AppFacadeDashboardGranularity {
  chart: SpeedForChart[];
  hasData: boolean;
  average: { download: number; upload: number; resultCount: number };
  devices: {
    totalConsumption: Consumption;
    mostActive: Device[];
  };
}

export interface AppFacadeDashboard {
  autoRun: {
    _version: string;
    enable: boolean;
    enableAllNodes: boolean;
    createdAt: string;
  };
  isp: string;
  mostRecent: {
    download: number;
    upload: number;
    timestamp: number;
    speedLevel: string;
  };
  hasBeenOptimized: boolean;
  optimization: {
    id: string;
    state: string;
    priority: number;
    triggers: string[];
    stateChangedAt: number;
    createdAt: string;
    uuid: string;
  };
}

export interface AppFacade {
  dashboard: AppFacadeDashboard;
  day: AppFacadeDashboardGranularity;
  week: AppFacadeDashboardGranularity;
  month: AppFacadeDashboardGranularity;
}

export type HourlyBlockedCounts = Partial<
  {
    [key in SecurityPolicyEventTypes]: number[][];
  }
>;

export enum SecurityPolicyEventTypes {
  adBlocking = 'adBlocking',
  secureAndProtect = 'secureAndProtect',
  iotProtect = 'iotProtect',
  adultAndSensitive = 'adultAndSensitive',
  kids = 'kids',
  teenagers = 'teenagers',
  workAppropriate = 'workAppropriate',
  dnsBlocked = 'dnsBlocked',
  ipThreat = 'ipThreat',
  anomaly = 'anomaly',
  blacklist = 'blacklist',
  'IoTProtect.blacklistedAnomalyRollback' = 'IoTProtect.blacklistedAnomalyRollback',
  'IoTProtect.whitelistedAnomaly' = 'IoTProtect.whitelistedAnomaly',
  'IoTProtect.blacklistedAnomaly' = 'IoTProtect.blacklistedAnomaly',
  'IoTProtect.rolledbackAnomaly' = 'IoTProtect.rolledbackAnomaly',
  'SecureAndProtect.outboundIpBlocked' = 'SecureAndProtect.outboundIpBlocked',
  'SecureAndProtect.inboundIpBlocked' = 'SecureAndProtect.inboundIpBlocked'
}
export type SecurityPolicyContent = 'adBlocking' | 'teenagers';
export interface SecurityPolicy {
  secureAndProtect: boolean;
  iotProtect: boolean;
  iotProtectUpdatedAt: string;
  content: SecurityPolicyContent[];
  createdAt: string;
  appliesToAllDevices: Partial<
    {
      [key in SecurityPolicyEventTypes]: boolean;
    }
  >;
}

export type AccessZoneWifiType = 'guests' | 'home' | 'internetAccessOnly';

export interface AccessZoneWifi {
  id: number;
  type: AccessZoneWifiType;
  description: string;
  accessibleDevices: string[];
  createdAt: string;
  _version: string;
}

export interface WifiNetworkKeys {
  id: number;
  encryptionKey: string;
  accessZone: AccessZoneWifiType;
  accessZoneId: number;
  format: string;
  enable: boolean;
  createdAt: string;
  updatedAt?: string;
  expiresAt?: string;
  _version?: string;
  accessibleDevices?: string[];
}
export interface WifiNetwork {
  ssid: string;
  wpaMode: string;
  privateMode: boolean;
  locationId: string;
  keys: WifiNetworkKeys[];
  persons: Person[];
  devices: Device[];
  accessZones: AccessZoneWifi[];
}

export const securityTypeConversion: Partial<{ [key in SecurityPolicyEventTypes]: string }> = {
  teenagers: 'contentAccess',
  kids: 'contentAccess',
  adultAndSensitive: 'contentAccess',
  workAppropriate: 'contentAccess',
  ipThreat: 'onlineProtection',
  secureAndProtect: 'onlineProtection',
  'SecureAndProtect.outboundIpBlocked': 'onlineProtection',
  'SecureAndProtect.inboundIpBlocked': 'onlineProtection',
  anomaly: 'anomaly',
  'IoTProtect.blacklistedAnomalyRollback': 'anomaly',
  'IoTProtect.whitelistedAnomaly': 'anomaly',
  'IoTProtect.blacklistedAnomaly': 'anomaly',
  'IoTProtect.rolledbackAnomaly': 'anomaly'
};

export interface SecurityPolicyEvents {
  createdAt: string;
  eventType: SecurityPolicyEventTypes;
  eventData: {
    categoryId: number;
    fqdn?: string;
    mac: string;
    queryType?: 'DNS';
    riskScore: 'safe' | 'unsafe';
    source: 'webpulse';
    sourceCategoryId: number;
    topLevelDomain: string;
    ipaddress?: string;
    latitude?: string;
    longitude?: string;
    port?: number;
    protocol?: number;
    city?: string;
    country?: string;
  };
}

export interface Optimization {
  id: string;
  state: string;
  priority: number;
  triggers: string[];
  deviatedNodes: string[];
  stateChangedAt: number;
  createdAt: string;
  uuid: string;
}

export type SignalStrength = 'excellent' | 'good' | 'fair' | 'poor';
export type ConnectionState = 'connected' | 'disconnected';

export interface PlumeNode {
  id: string;
  connectionState: ConnectionState;
  model: string;
  mac: string;
  ip: string;
  wanIp: string;
  backhaulType: 'ethernet' | 'wifi';
  backhaulChannel: number;
  defaultName: string;
  nickname: string;
  radioMac24: string;
  radioMac50: string;
  radioMac50L: string;
  radioMac50U: string;
  ethernet1Mac: string;
  serialNumber: string;
  ipv6: [];
  firmwareVersion: string;
  platformVersion: string;
  connectedDeviceCount: number;
  connectionStateChangeAt: string;
  networkMode: 'router' | 'auto' | 'bridge';
  leafToRoot: { id: string; nickname: string }[];
  packId: string;
  subscriptionRequired: boolean;
  claimedAt: string;
  residentialGateway: boolean;
  speedTest: {
    id: string;
    startedAt: string;
    endedAt: string;
    gateway: boolean;
    status: 'succeeded';
    trigger: 'scheduled';
    serverName: string;
    isp: string;
    download: number;
    upload: number;
    rtt: number;
    serverId: number;
    downloadDuration: number;
    uploadDuration: number;
    duration: number;
  };
  manufacturerSerialNumber: string;
  vendor: {
    name: string;
    partNumber: string;
    manufacturer: string;
    factory: string;
    mfgDate: string;
  };
  certificates: {
    clientAfter: string;
    clientBefore: string;
    rootAfter: string;
    rootBefore: string;
  };
  ethernetLan: { default: { mode: 'auto' } };
  publicIp: string;
  alerts: [];
  '2gChannel': number;
  '5guChannel': number;
  '5glChannel': number;
  health?: { status: SignalStrength; score: number };
}

export interface PlumeSummary {
  gatewayConnectionState: ConnectionState;
  nodeConnectedCount: number;
  nodeClaimedCount: number;
  connectedDeviceCount: number;
  _version: string;
  gatewayConnectionStateChangeAt: string;
  wifiNetworkProvisioned: boolean;
  onboardingStatus: 'OnboardingComplete';
  monitorMode: boolean;
  onboardedAt: string;
  poorHealthNodeCount: number;
  poorHealthDeviceCount: number;
  hasResidentialGateway: boolean;
  networkModeProvisioned: 'router' | 'auto' | 'bridge';
  networkModeRealized: 'router' | 'auto' | 'bridge';
  upstreamDnsServers: {
    primaryDns: string;
    secondaryDns: string;
    enabled: boolean;
  };
  geoIp: {
    _version: string;
    updatedAt: string;
    city: string;
    wifiIsoCode: string;
    country: string;
    latitude: number;
    longitude: number;
    timezone: string;
    ISP: string;
    zip: string;
    ip: string;
  };
}

export interface PlumeHome {
  customer: Customer;
  optimization: Optimization;
  devices: Device[];
  nodes: PlumeNode[];
  summary: PlumeSummary;
  persons: Person[];
  forceGraph: Topology;
}

export interface Topology {
  vertices: [
    {
      connectionState: ConnectionState;
      health: { status: SignalStrength };
      alerts: [
        {
          type: string;
          state: string;
          snoozeUntil: string;
          snoozed: boolean;
          alertChangedAt: string;
        }
      ];
      type: 'pod' | 'device';
      label: string;
      metadata:
        | {
            networkMode: 'router' | 'auto' | 'bridge';
            backhaulType: 'ethernet' | 'wifi';
            connectedDeviceCount: number;
            firmwareVersion: string;
            platformVersion: string;
            model: string;
            serialNumber: string;
            mac: string;
            radio50L: { channel: number; vaps: string[] };
            radio50U: { channel: number; vaps: string[] };
            radio24: { channel: number; vaps: string[] };
          }
        | {
            icon: string;
            iconV2: string;
            mac: string;
            vapType: string;
            networkId: string;
          };
      id: string;
      isPartnerComponent: boolean;
    }
  ];
  edges: [
    {
      source: string;
      target: string;
      direction: 'sourceToTarget';
      medium: 'ethernet' | 'wifi';
      metadata: {
        freqBand: string;
        channel: number;
        channelWidth: number;
      };
    }
  ];
}
