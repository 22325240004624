import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Device } from 'src/app/lib/store/models/device.model';
import { Person } from 'src/app/lib/store/models/person.model';

type GroupedDevices = {
  [key: string]: { personName: string; devices: (Device & { selected: boolean; deviceName: string })[] };
};

@Component({
  selector: 'plm-device-list-chooser',
  templateUrl: './device-list-chooser.component.html',
  styleUrls: ['./device-list-chooser.component.scss']
})
export class DeviceListChooserComponent implements OnChanges {
  @Input() devices: Device[];
  @Input() selectedMacs: string[] = [];
  @Input() people: Person[];
  @Output() selectedMacsChange = new EventEmitter<string[]>();
  groupedDevices: GroupedDevices = {};
  filterText = '';

  mapDevices(): void {
    this.groupedDevices = this.devices.reduce((acc, device) => {
      if (!acc[device.personId]) {
        acc[device.personId] = {
          personName: this.people.find(person => person.id === device.personId)?.nickname || '',
          devices: []
        };
      }
      const deviceName = device.nickname || device.name;
      if (deviceName.toLowerCase().includes(this.filterText.toLowerCase())) {
        acc[device.personId].devices.push({
          ...device,
          deviceName,
          selected: this.selectedMacs.includes(device.mac)
        });
      }

      return acc;
    }, {} as GroupedDevices);
  }

  ngOnChanges(): void {
    this.mapDevices();
  }

  toggleDeviceFlag(mac: string): void {
    this.selectedMacs = [...this.selectedMacs];
    if (this.selectedMacs.includes(mac)) {
      this.selectedMacs = this.selectedMacs.filter(loopMac => loopMac !== mac);
    } else {
      this.selectedMacs.push(mac);
    }
    this.mapDevices();
    this.selectedMacsChange.emit(this.selectedMacs);
  }

  filter(): void {
    this.mapDevices();
  }

  deviceTrackBy(index: number, device: Device & { selected: boolean; deviceName: string }): string {
    return device.mac;
  }

  groupTrackBy(
    index: number,
    pair: KeyValue<string, { personName: string; devices: (Device & { selected: boolean; deviceName: string })[] }>
  ): string {
    return pair.key;
  }
}
