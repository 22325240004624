import { createReducer, on, Action } from '@ngrx/store';
import {
  deleteIpReservationSuccess,
  editIpReservationSuccess,
  getDhcpReservationsSuccess,
  newIpReservationSuccess,
  getDnsSuccess,
  getUpnpSuccess,
  setDnsSuccess,
  setSsidName,
  setUpnpSuccess,
  ssidLoaded,
  ssidLoadedError,
  addPortForwardingSuccess,
  deletePortForwardingSuccess,
  editPortForwardingSuccess,
  getDhcpSuccess,
  setDhcpSuccess
} from '../actions/settings.actions';
import { ISettings, initialSettingsState } from '../interfaces/settings.interface';

export const reducer = createReducer(
  initialSettingsState,
  on(setSsidName, (state, { locationId }) => ({
    ...state,
    locationIdSsidSaving: [...state.locationIdSsidSaving, locationId]
  })),
  on(ssidLoaded, (state, { wifiNetwork }) => ({
    ...state,
    locationIdSsidSaving: state.locationIdSsidSaving.filter(locationId => wifiNetwork.locationId !== locationId)
  })),
  on(ssidLoadedError, (state, { locationId }) => ({
    ...state,
    locationIdSsidSaving: state.locationIdSsidSaving.filter(loopLocationId => loopLocationId !== locationId)
  })),
  on(getUpnpSuccess, (state, { upnp }) => ({
    ...state,
    upnp: upnp
  })),
  on(getDhcpReservationsSuccess, (state, { dhcpReservations }) => ({
    ...state,
    dhcpReservations: dhcpReservations
  })),
  on(newIpReservationSuccess, (state, { ip, mac, name, hostname }) => {
    if (state.dhcpReservations.some(reservation => reservation.ip === ip || reservation.mac === mac)) {
      return state;
    }
    return { ...state, dhcpReservations: [...state.dhcpReservations, { ip, mac, name, hostname }] };
  }),
  on(editIpReservationSuccess, (state, { ip, mac }) => ({
    ...state,
    dhcpReservations: state.dhcpReservations.map(reservation =>
      reservation.mac === mac ? { ...reservation, ip: ip } : reservation
    )
  })),
  on(deleteIpReservationSuccess, (state, { mac }) => ({
    ...state,
    dhcpReservations: state.dhcpReservations.filter(reservation => reservation.mac !== mac)
  })),
  on(setUpnpSuccess, (state, { upnp }) => ({
    ...state,
    upnp: upnp
  })),
  on(getDnsSuccess, (state, { dnsServers }) => ({
    ...state,
    dnsServers: {
      primaryDns: dnsServers.primaryDns,
      secondaryDns: dnsServers.secondaryDns || '',
      enabled: dnsServers.enabled
    }
  })),
  on(setDnsSuccess, (state, { dns }) => ({
    ...state,
    dnsServers: {
      primaryDns: dns.primaryDns,
      secondaryDns: dns.secondaryDns || '',
      enabled: dns.enabled
    }
  })),
  on(addPortForwardingSuccess, (state, { mac, port }) => ({
    ...state,
    dhcpReservations: state.dhcpReservations.map(reservation =>
      reservation.mac === mac ? { ...reservation, portForwards: [...reservation.portForwards, port] } : reservation
    )
  })),
  on(editPortForwardingSuccess, (state, { mac, port }) => ({
    ...state,
    dhcpReservations: state.dhcpReservations.map(reservation =>
      reservation.mac === mac
        ? {
            ...reservation,
            portForwards: reservation.portForwards.map(portItem =>
              portItem.externalPort === port.externalPort ? port : portItem
            )
          }
        : reservation
    )
  })),
  on(deletePortForwardingSuccess, (state, { mac, ports }) => ({
    ...state,
    dhcpReservations: state.dhcpReservations.map(reservation =>
      reservation.mac === mac ? { ...reservation, portForwards: ports } : reservation
    )
  })),
  on(getDhcpSuccess, (state, { dhcp }) => ({
    ...state,
    dhcp
  })),
  on(setDhcpSuccess, (state, { dhcp }) => ({
    ...state,
    dhcp
  }))
);

export const SettingsReducer = (state: ISettings, action: Action): ISettings => reducer(state, action);
