import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  ipAddress(val: string): boolean {
    const ipValidatePattern =
      '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$';
    return !!new RegExp(ipValidatePattern).exec(val) || !val;
  }

  macAddress(val: string): boolean {
    const macValidatePattern = '^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$';
    return !!new RegExp(macValidatePattern).exec(val);
  }

  portRange(val: string): boolean {
    const portPattern = '^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$';
    return !!new RegExp(portPattern).exec(val) && +val > 0;
  }

  noWhiteSpace(val: string): boolean {
    return !/\s/g.test(val);
  }
}
