import { ActionReducer, ActionReducerMap, combineReducers, MetaReducer } from '@ngrx/store';
import { setCurrentLocationId } from './actions/plume.actions';
import { HomeEffects } from './effects/home.effects';
import { InsightsEffects } from './effects/insights.effects';
import { PersonEffects } from './effects/people.effects';
import { PlumeEffects } from './effects/plume.effects';
import { SecurityPolicyEffects } from './effects/security-policy.effects';
import { SettingsEffects } from './effects/settings.effects';
import { IHome, initialHomeState } from './interfaces/home.interface';
import { IInsights, initialInsightsState } from './interfaces/insights.interface';
import { IAiSecurity, initialAiSecurityState } from './interfaces/local-state/ai-security.interface';
import { IPlume } from './interfaces/plume.interface';
import { initialSettingsState, ISettings } from './interfaces/settings.interface';
import { HomeReducer } from './reducers/home.reducer';
import { sessionStorageSyncReducer } from './reducers/hydrate.reducer';
import { InsightReducer } from './reducers/insights.reducer';
import { AiSecurityReducer } from './reducers/local-state/ai-security.reducer';
import { PlumeReducer } from './reducers/plume.reducer';
import { SettingsReducer } from './reducers/settings.reducer';

export interface IPlumeState {
  plumeState: IPlume;
  insightsState: IInsights;
  homeState: IHome;
  localState: {
    aiSecurity: IAiSecurity;
  };
  settingsState: ISettings;
}

export const reducers: ActionReducerMap<IPlumeState> = {
  plumeState: PlumeReducer,
  insightsState: InsightReducer,
  homeState: HomeReducer,
  localState: combineReducers({
    aiSecurity: AiSecurityReducer
  }),
  settingsState: SettingsReducer
};

const locationChangeReducer = (reducer: ActionReducer<IPlumeState>): ActionReducer<IPlumeState> => (state, action) => {
  if (action.type === setCurrentLocationId.type) {
    state = {
      plumeState: state.plumeState,
      insightsState: initialInsightsState,
      localState: { aiSecurity: initialAiSecurityState },
      homeState: initialHomeState,
      settingsState: initialSettingsState
    };
  }
  return reducer(state, action);
};

export const metaReducers: MetaReducer<IPlumeState>[] = [sessionStorageSyncReducer, locationChangeReducer];
export const effects = [
  PlumeEffects,
  InsightsEffects,
  PersonEffects,
  SecurityPolicyEffects,
  HomeEffects,
  SettingsEffects
];
