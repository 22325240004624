<div class="passwordBox">
  <div icon="key" class="icon"></div>
  <div class="borderedBox">
    <div class="fullWidth">
      <plm-view-password [password]="data?.encryptionKey"></plm-view-password>
      <div class="devices" *ngIf="!data?.enable">{{ 'settings.accessPassword.disabled' | translate }}</div>
      <ng-container *ngIf="data?.enable">
        <div class="devices" *ngIf="deviceCount > 0">
          {{ 'settings.accessPassword.inUseByDevices' | translate: { count: deviceCount } }}
        </div>
        <div class="devices" *ngIf="deviceCount === 0">
          {{ 'settings.accessPassword.notInUse' | translate }}
        </div>
        <div class="expires" *ngIf="data.accessZone !== 'home'">
          {{ 'settings.accessPassword.expires' | translate }}
          <ng-container *ngIf="!data.expiresAt">{{ 'settings.accessPassword.never' | translate }}</ng-container>
          <ng-container *ngIf="data.expiresAt">{{ data.expiresAt | momentFormat: 'llll' }}</ng-container>
        </div>
      </ng-container>
    </div>

    <plm-dropdown-menu [position]="'topRight'" [disabled]="dropDownType === AccessPasswordDropDownType.none">
      <plm-list dropdown>
        <li
          class="drop-down-item"
          (click)="copyPasswordToClipboard()"
          *ngIf="
            dropDownType === AccessPasswordDropDownType.copyEdit || dropDownType === AccessPasswordDropDownType.all
          "
        >
          <div>{{ 'settings.accessPassword.copy' | translate }}</div>
        </li>
        <li
          class="drop-down-item"
          (click)="dropDownSelected.emit(AccessPasswordDropDownClicked.edit)"
          *ngIf="
            dropDownType === AccessPasswordDropDownType.copyEdit || dropDownType === AccessPasswordDropDownType.all
          "
        >
          <div>{{ 'settings.accessPassword.edit' | translate }}</div>
        </li>
        <li
          class="drop-down-item"
          (click)="dropDownSelected.emit(AccessPasswordDropDownClicked.toggleEnable)"
          *ngIf="
            dropDownType === AccessPasswordDropDownType.enableDelete || dropDownType === AccessPasswordDropDownType.all
          "
        >
          <div *ngIf="data?.enable">{{ 'settings.accessPassword.disable' | translate }}</div>
          <div *ngIf="!data?.enable">{{ 'settings.accessPassword.enable' | translate }}</div>
        </li>
        <li
          class="drop-down-item"
          (click)="dropDownSelected.emit(AccessPasswordDropDownClicked.delete)"
          *ngIf="
            dropDownType === AccessPasswordDropDownType.enableDelete || dropDownType === AccessPasswordDropDownType.all
          "
        >
          <div>{{ 'settings.accessPassword.delete' | translate }}</div>
        </li>
      </plm-list>
      <div class="icon" icon="more" [class.disabled]="dropDownType === AccessPasswordDropDownType.none"></div>
    </plm-dropdown-menu>
  </div>
</div>
<plm-alert-pop-bar [alert$]="alert$" [offset]="'30px'"></plm-alert-pop-bar>
