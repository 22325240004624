import { Component, Inject } from '@angular/core';
import { PlmDialogInject, PlmDialogToken } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  selector: 'plm-delete-pod-dialog',
  templateUrl: './delete-pod-dialog.component.html',
  styleUrls: ['./delete-pod-dialog.component.scss']
})
export class DeletePodDialogComponent {
  constructor(@Inject(PlmDialogToken) public dialog: PlmDialogInject<void, boolean>) {}
  get transPth(): string {
    return 'pod.deleteDialog.';
  }
}
