import { Component, ElementRef, HostBinding, Input } from '@angular/core';

export type TileTypes = 'all' | 'onlineProtection' | 'adBlocking' | 'contentAccess' | 'anomaly';

@Component({
  selector: 'plm-guard-tile',
  templateUrl: './guard-tile.component.html',
  styleUrls: ['./guard-tile.component.scss']
})
export class GuardTileComponent {
  @HostBinding('class') @Input() type: TileTypes = 'all';
  @Input() count!: number | undefined;
  // @Input() selected - it's a class

  iconByType: { [key in TileTypes]: string } = {
    all: 'shield',
    onlineProtection: 'online-protection',
    adBlocking: 'stop',
    contentAccess: 'checklist',
    anomaly: 'protection'
  };

  @HostBinding('class.disabled') get disabled(): boolean {
    return this.count === undefined;
  }

  // needed for the parent, don't delete
  constructor(public elmRef: ElementRef<HTMLElement>) {}
}
