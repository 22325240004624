<plm-column-graph-tool-tip
  *ngIf="toolTipIndex >= 0 && valChartData[toolTipIndex][mode]"
  class="tooltip"
  [index]="toolTipIndex"
>
  <div class="time" *ngIf="xAxis === 'day'">{{ valChartData[toolTipIndex].timestamp | time }}</div>
  <div class="time" *ngIf="xAxis === 'week'">{{ weekTimeFormat(valChartData[toolTipIndex].timestamp) }}</div>
  <div class="time" *ngIf="xAxis === 'month'">{{ dayTimeFormat(valChartData[toolTipIndex].timestamp) }}</div>
  <div class="value">{{ round(valChartData[toolTipIndex][mode]) }} Mbps</div>
</plm-column-graph-tool-tip>

<div class="resizeContainer" (plmResized)="render()">
  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
    <svg:defs>
      <linearGradient id="maxSpeed" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="minSpeed" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="regularSpeed" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="background" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" />
        <stop offset="100%" />
      </linearGradient>
      <filter id="hoverCircleShadow" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.121569 0 0 0 0 0.141176 0 0 0 0 0.172549 0 0 0 0.22 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </svg:defs>
    <g class="columnArea" [class.hover]="toolTipIndex >= 0 && valChartData[toolTipIndex][mode]">
      <svg:g
        class="columnGroup"
        (mouseenter)="setToolTipIndex(index)"
        (mouseleave)="setToolTipIndex(-1)"
        *ngFor="let _ of [].constructor(calculations.axisSizeInUnits.maxX); let index = index; let last = last"
      >
        <svg:g plm-column class="column background" [index]="index" [value]="calculations.axisSizeInUnits.maxY"></svg:g>
        <svg:g
          *ngIf="valChartData[index]"
          plm-column
          class="column value"
          [index]="index"
          [value]="testInProgress && last ? calculations.axisSizeInUnits.maxY : valChartData[index][mode]"
          [class.maxSpeed]="calculations.maxY === valChartData[index][mode]"
          [class.minSpeed]="calculations.minY === valChartData[index][mode]"
          [class.inProgress]="testInProgress && last"
        ></svg:g>
        <svg:text
          *ngIf="valChartData[index]?.manual"
          [attr.x]="calculations.columnXCenter(index)"
          [attr.y]="calculations.columnValueY(index) + 10"
          text-anchor="middle"
          class="manualText"
        >
          M
        </svg:text>

        <svg:circle
          *ngIf="valChartData[index][mode]"
          class="hoverCircle"
          [attr.cx]="calculations.columnXCenter(index)"
          [attr.cy]="calculations.columnY(calculations.axisSizeInUnits.maxY * 0.2) - calculations.hoverCircleRadius"
          [attr.r]="calculations.hoverCircleRadius"
        />
      </svg:g>
    </g>
    <svg:g
      class="valueLine"
      *ngIf="calculations.minY > -1"
      plm-x-value-line
      [text]="'speedTest.chartMinAxisText' | translate: { value: round(calculations.minY) }"
      [value]="calculations.minY"
    ></svg:g>
    <svg:g
      class="valueLine"
      *ngIf="calculations.maxY > -1"
      plm-x-value-line
      [text]="'speedTest.chartMaxAxisText' | translate: { value: round(calculations.maxY) }"
      [value]="calculations.maxY"
      position="over"
    ></svg:g>
    <ng-container [ngSwitch]="xAxis">
      <svg:g plm-x-axis-hours *ngSwitchCase="'day'" [chartData]="valChartData"></svg:g>
      <svg:g plm-x-axis-days *ngSwitchCase="'week'" [chartData]="valChartData"></svg:g>
      <svg:g plm-x-axis-weeks *ngSwitchCase="'month'" [chartData]="valChartData"></svg:g>
    </ng-container>
  </svg>
</div>
