import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  icons: { [key: string]: string } = {};
  iconsV2: string[] = [];
  iconsV2Root = 'iconsV2';
  iconListLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.loadIconV2List();
  }

  get(icon: string = null): string {
    if (icon) {
      return this.icons[icon];
    } else {
      return '';
    }
  }

  set(icon: string, content: string): void {
    this.icons[icon] = content;
  }

  loadIconV2List(): void {
    this.http
      .get<{
        path: string;
        name: string;
        size: string;
        type: string;
        children: { path: string; name: string; size: number; extension: string; type: string }[];
      }>('/assets/parsedIcons.json')
      .pipe(take(1))
      .subscribe(data => {
        data.children.forEach(icon => {
          this.iconsV2.push(icon.name.split(icon.extension)[0] || icon.name);
        });
        this.iconListLoaded$.next(true);
      });
  }

  loadIcon$(icon: string): Observable<string> {
    return this.http.get(`/assets/icons/${icon}.svg`, { responseType: 'text' }).pipe(
      tap(response => {
        this.set(icon, response);
      })
    );
  }

  loadIconV2$(icon: string): Observable<string> {
    return this.iconListLoaded$.pipe(
      filter(loaded => loaded),
      switchMap(() =>
        this.http.get(this.getPath(icon), { responseType: 'text' }).pipe(
          tap(response => {
            this.set(icon, response);
          })
        )
      )
    );
  }

  getPath(name: string): string {
    return 'assets/' + this.iconsV2Root + '/' + this.identify(name) + '.svg';
  }

  identify(name: string): string {
    let found = -1;
    let depth = 0;

    if (!name) {
      return 'unknown';
    }

    while (name && found < 0 && depth < 10) {
      depth++; // depth counter in case of wacky name
      found = this.iconsV2.indexOf(name);
      name = name.substring(0, name.lastIndexOf('-'));
    }

    return this.iconsV2[found] || 'unknown';
  }
}
