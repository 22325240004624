import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { ValidationService } from 'src/app/lib/services/validation/validation.service';
import { DnsServers } from 'src/app/lib/store/models/dns.model';
import { NetworkModeRouterDialogComponent } from '../network-mode-router-dialog/network-mode-router-dialog.component';

@Component({
  selector: 'plm-dns',
  templateUrl: 'dns.component.html',
  styleUrls: ['dns.component.scss']
})
export class DnsComponent {
  public customPrimaryDns: string;
  public customSecondaryDns: string;
  public isCustomPrimaryDnsValid = true;
  public isCustomSecondaryDnsValid = true;
  private valDns: DnsServers;

  @Input() set dns(dns: DnsServers) {
    if (dns) {
      this.valDns = JSON.parse(JSON.stringify(dns)) as DnsServers;
      this.customPrimaryDns = dns && dns.enabled ? (JSON.parse(JSON.stringify(dns.primaryDns)) as string) : '';
      this.customSecondaryDns = dns && dns.enabled ? (JSON.parse(JSON.stringify(dns.secondaryDns)) as string) : '';
    }
  }

  get dns(): DnsServers {
    return this.valDns;
  }

  @Input() @HostBinding('class.disabled') disabled = false;

  @Output() save = new EventEmitter<DnsServers>();
  @Output() resetDns = new EventEmitter<void>();

  constructor(private validationService: ValidationService, private dialog: DialogService) {}

  onSave(): void {
    // Validate IP's

    if (!this.validationService.ipAddress(this.customPrimaryDns)) {
      this.isCustomPrimaryDnsValid = false;
      return;
    } else {
      this.isCustomPrimaryDnsValid = true;
    }
    if (!this.validationService.ipAddress(this.customSecondaryDns) && this.customSecondaryDns !== '') {
      this.isCustomSecondaryDnsValid = false;
      return;
    } else {
      this.isCustomSecondaryDnsValid = true;
    }

    const newDns = {
      primaryDns: this.customPrimaryDns,
      secondaryDns: this.customSecondaryDns
    };

    this.save.emit(newDns);
  }

  onReset(): void {
    this.resetDns.emit();
  }

  openNetworkingModeDialog(): void {
    this.dialog.openDialog$<boolean>(NetworkModeRouterDialogComponent);
  }
}
