<div class="bubble">
  <h4>{{ 'supportMenu.title' | translate }}</h4>
  <div class="navigation" gaEvent="FAQ" *ngIf="config$ | async as config" (click)="navigate('supportFaqUrl', config)">
    <div class="icon" icon="faq"></div>
    <div class="title">{{ 'supportMenu.faq' | translate }}</div>
  </div>
  <div
    class="navigation"
    gaEvent="contactUs"
    *ngIf="config$ | async as config"
    (click)="navigate('supportUrl', config)"
  >
    <div class="icon" icon="hands"></div>
    <div class="title">{{ 'supportMenu.contactUs' | translate }}</div>
  </div>
  <div class="navigation nolink">
    <div class="icon" icon="call"></div>
    <div class="number">{{ (config$ | async)?.supportPhone }}</div>
  </div>
  <div class="navigation" (click)="openChat()">
    <div class="icon" icon="chat"></div>
    <div class="title">{{ 'supportMenu.chatWithUs' | translate }}</div>
  </div>
  <div class="divider"></div>
  <div
    class="navigation last"
    gaEvent="requestYourData"
    *ngIf="config$ | async as config"
    (click)="navigate('requestYourDataUrl', config)"
  >
    <div class="icon" icon="form-send"></div>
    <div class="title">{{ 'supportMenu.requestYourData' | translate }}</div>
  </div>
</div>
