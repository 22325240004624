import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  pure: false
})
/**
 * check unit tests
 * usage:
 *  array | sort: 'name' for sorting by name
 *  array | sort: 'name':'-num' for sorting by name then by num reversed
 * @export
 * @class SortPipe
 * @implements {PipeTransform}
 */
export class SortPipe implements PipeTransform {
  transform<T>(val: ReadonlyArray<T>, ...args: string[]): Array<T> {
    const value: { [key: string]: unknown }[] = val as never;
    if (!value || !value.length || !args || !args.length) {
      return value as never;
    }
    value.sort((a, b) => {
      for (let key of args) {
        let orientation = 1;
        if (key.startsWith('-')) {
          orientation = -1;
          key = key.slice(1);
        }
        const res = this.compare(this.getDeepNestedValue(a, key), this.getDeepNestedValue(b, key), orientation);
        if (res !== 0) {
          return res;
        }
      }
      return 0;
    });
    return value as never;
  }

  private compare(val1: unknown, val2: unknown, orientation: number): number {
    if (typeof val1 === 'string' && typeof val2 === 'string') {
      return val1.localeCompare(val2) * orientation;
    }
    if (typeof val1 === 'number' && typeof val2 === 'number') {
      return (val1 - val2) * orientation;
    }
    return 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getDeepNestedValue(object: any, keyString: string): any {
    const keys = keyString.split('.');
    keys.forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      object = object[key];
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return object;
  }
}
