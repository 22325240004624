<div class="network-mode">
  <div class="network-mode__item line" (click)="networkMode !== 'auto' && changeMode('auto')">
    {{
      networkModeRealized === 'router'
        ? ('networkingMode.autoRouter' | translate)
        : ('networkingMode.autoBridge' | translate)
    }}
    <span class="network-mode__icon" icon="check" *ngIf="networkMode === 'auto'"></span>
  </div>
  <div class="network-mode__item" (click)="networkMode !== 'router' && changeMode('router')">
    {{ 'networkingMode.routerOnly' | translate }}
    <span class="network-mode__icon" icon="check" *ngIf="networkMode === 'router'"></span>
  </div>
</div>
