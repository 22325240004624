import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WifiNetworkKeys } from 'src/app/lib/rest-types';
import { Device } from 'src/app/lib/store/models/device.model';
import {
  AccessPasswordDropDownClicked,
  AccessPasswordDropDownType
} from '../access-password/access-password.interface';

@Component({
  selector: 'plm-access-password-list',
  templateUrl: './access-password-list.component.html',
  styleUrls: ['./access-password-list.component.scss']
})
export class AccessPasswordListComponent {
  AccessPasswordDropDownType = AccessPasswordDropDownType;
  @Input() passwords: WifiNetworkKeys[] = [];
  @Input() type: 'home' | 'internetOnly';
  @Input() devices: Device[];
  @Input() keysInProgress: number[] = [];
  @Output() dropDownSelected = new EventEmitter<{ key: WifiNetworkKeys; type: AccessPasswordDropDownClicked }>();

  dropDownType(key: WifiNetworkKeys): AccessPasswordDropDownType {
    if (this.keysInProgress.includes(key.id)) {
      return AccessPasswordDropDownType.none;
    }
    if (this.type === 'home' && key.enable && this.passwords.filter(loopKey => loopKey.enable).length <= 1) {
      return AccessPasswordDropDownType.copyEdit;
    }
    if (!key.enable) {
      return AccessPasswordDropDownType.enableDelete;
    }
    return AccessPasswordDropDownType.all;
  }
}
