<plm-box class="settings-advance">
  <h3>{{ 'settings.advanceView.title' | translate }}</h3>
  <plm-box-sub-title class="settings-advance__sub-title"
    >{{ 'settings.advanceView.portForwarding' | translate }}
    <plm-info-overlay [height]="200" [width]="350">
      <div class="settings-advance__container">
        <div class="settings-advance__header">
          <div class="settings-advance__title">{{ 'settings.advanceView.portForwarding' | translate }}</div>
          <span class="settings-advance__close" [icon]="'close'"></span>
        </div>
        <plm-space size="m"></plm-space>
        <div class="settings-advance__content">{{ 'portForwarding.ipReservationTip' | translate }}</div>
        <plm-space size="m"></plm-space>
        <div class="settings-advance__content">{{ 'portForwarding.throughFirewallTip' | translate }}</div>
      </div>
    </plm-info-overlay>
  </plm-box-sub-title>
  <plm-ip-reservation-list
    [disabled]="(currentLocation$ | async).networkModeRealized === 'bridge' || (offline$ | async) === true"
    [dhcpReservations]="dhcpReservations$ | async"
  ></plm-ip-reservation-list>
  <plm-box-sub-title class="settings-advance__sub-title"
    >{{ 'settings.advanceView.networkingMode' | translate }}
    <plm-info-overlay [height]="314" [width]="350">
      <div class="settings-advance__container">
        <div class="settings-advance__header">
          <div class="settings-advance__title">{{ 'networkingMode.infoTitleAuto' | translate }}</div>
          <span class="settings-advance__close" [icon]="'close'"></span>
        </div>
        <plm-space size="m"></plm-space>
        <div class="settings-advance__content">{{ 'networkingMode.infoContentAuto' | translate }}</div>
        <plm-space size="m"></plm-space>
        <div class="settings-advance__title">{{ 'networkingMode.infoTitleRouterOnly' | translate }}</div>
        <plm-space size="m"></plm-space>
        <div class="settings-advance__content">{{ 'networkingMode.infoContentRouterOnly' | translate }}</div>
      </div>
    </plm-info-overlay>
  </plm-box-sub-title>
  <plm-networking-mode
    [networkMode]="(currentLocation$ | async).networkMode"
    [networkModeRealized]="(currentLocation$ | async).networkModeRealized"
  >
  </plm-networking-mode>

  <plm-box-sub-title class="settings-advance__sub-title">
    {{ 'settings.advanceView.lanIpSubnet' | translate }}
  </plm-box-sub-title>

  <plm-lan-subnet
    [dhcp]="dhcp$ | async"
    (save)="onSaveLanSubnet($event)"
    [disabled]="(currentLocation$ | async).networkModeRealized === 'bridge' || (offline$ | async) === true"
  ></plm-lan-subnet>

  <plm-box-sub-title class="settings-advance__sub-title">
    {{ 'dns.title' | translate }}
    <plm-info-overlay [height]="142" [width]="350">
      <div class="settings-advance__container">
        <div class="settings-advance__header">
          <div class="settings-advance__title">{{ 'dns.title' | translate }}</div>
          <span class="settings-advance__close" [icon]="'close'"></span>
        </div>
        <plm-space size="m"></plm-space>
        <div class="settings-advance__content">{{ 'dns.info' | translate }}</div>
      </div>
    </plm-info-overlay>
  </plm-box-sub-title>

  <plm-dns
    [dns]="dnsServers$ | async"
    (resetDns)="onDnsReset()"
    (save)="onCustomDns($event)"
    [disabled]="(currentLocation$ | async).networkModeRealized === 'bridge' || (offline$ | async) === true"
  >
  </plm-dns>

  <plm-box-sub-title class="settings-advance__sub-title">
    {{ 'settings.advanceView.upnpTitle' | translate }}
    <plm-info-overlay [height]="152" [width]="350">
      <div class="settings-advance__container">
        <div class="settings-advance__header">
          <div class="settings-advance__title">{{ 'settings.advanceView.upnpTitle' | translate }}</div>
          <span class="settings-advance__close" [icon]="'close'"></span>
        </div>
        <plm-space size="m"></plm-space>
        <div class="settings-advance__content">{{ 'upnp.info' | translate }}</div>
      </div>
    </plm-info-overlay>
  </plm-box-sub-title>

  <plm-upnp
    [upnp]="upnp$ | async"
    [networkModeRealized]="(currentLocation$ | async).networkModeRealized"
    (upnpEnabled)="onUpnEnabled($event)"
  ></plm-upnp>
</plm-box>
