import { Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'plm-list',
    templateUrl: 'list.component.html',
    styleUrls: ['list.component.scss']
})

export class ListComponent{
    @Input() ruled = false;

    @HostBinding('class.list--ruled')
    public get isRuled(): boolean {
        return this.ruled;
    }
}
