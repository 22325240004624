<div class="welcome">
  <div class="title">
    <plm-mood></plm-mood>,
    <span class="firstName">{{ firstName$ | async }}</span>
  </div>
  <plm-speed-description
    [dashboard]="(appFacade$ | async)?.dashboard"
    [devices]="devices$ | async"
    [ssid]="(currentLocation$ | async)?.ssid"
    [loading]="homeLoading$ | async"
  ></plm-speed-description>
</div>
<plm-speed-test-box
  [speedTestRunning$]="speedTestRunning$"
  [facade]="appFacade$ | async"
  (startSpeedTest)="startSpeedTest()"
></plm-speed-test-box>
<plm-space size="s"></plm-space>
<plm-box
  *ngIf="((nodes$ | async).length > 0 && (offline$ | async) === false) || (homeLoading$ | async) === true"
  class="podsOverview"
  [class.online]="(homeLoading$ | async) === false"
>
  <ng-container *ngIf="(homeLoading$ | async) === false">
    <plm-pods-overview
      [connectedDevices]="(summary$ | async)?.connectedDeviceCount"
      [allDevices]="(devices$ | async)?.length"
      [nodes]="nodes$ | async"
    >
    </plm-pods-overview>
    <plm-box-footer
      [itsLink]="false"
      [text]="'podsOverview.networkOptimizedAt' | translate: { at: (optimization$ | async)?.createdAt | time }"
      linkIcon="pod_hierarchy"
    ></plm-box-footer>
  </ng-container>
  <ng-container *ngIf="(homeLoading$ | async) === true">
    <div class="loading" icon="loading"></div>
  </ng-container>
</plm-box>
<plm-box *ngIf="offline$ | async">
  <plm-pods-offline></plm-pods-offline>
</plm-box>
<plm-space size="s"></plm-space>
<plm-box>
  <plm-ai-security
    [securityDailyCounts]="aiSecurityDailyCounts$ | async"
    [devices]="devices$ | async"
    (device)="onDeviceSelected($event)"
    (reloadSecurity)="reloadSecurity()"
  ></plm-ai-security>
  <plm-box-footer
    [routerLink]="['events']"
    [text]="'aisecurity.viewAllEvents' | translate"
    linkIcon="shield"
  ></plm-box-footer>
</plm-box>
<plm-space size="s"></plm-space>

<plm-active-devices [devices]="devices$ | async" [summary]="appFacade$ | async"></plm-active-devices>
