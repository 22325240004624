<div class="carousel" (plmResized)="resized()">
  <!-- set `contentWrapper` width to `widthOfSlide` to reduce shaking when resizing -->
  <div
    class="container"
    #contentWrapper
    [style.transition-duration]="transitionPaused ? 'unset' : ''"
    [style.transform]="'translateX(' + offset + 'px)'"
    [style.width.px]="widthOfSlide"
  >
    <ng-content></ng-content>
  </div>
  <div class="button-container">
    <span class="arrow-left" icon="arrow-right" (click)="prev()"></span>
    <span
      *ngFor="let item of [].constructor(numberOfSlides); let i = index"
      icon="dot"
      class="icon"
      [ngClass]="{ selected: currentSlide === i + 1 }"
      (click)="goToSlide(i)"
    ></span>
    <span class="arrow-right" icon="arrow-right" (click)="next()"></span>
  </div>
</div>
