import { Component } from '@angular/core';

/**
 * Use `Section` to compose content into vertical page layouts.
 */
@Component({
  selector: 'plm-section',
  styleUrls: ['section.component.scss'],
  template: '<ng-content></ng-content>'
})
export class SectionComponent {}
