<div class="footer" *ngIf="config$ | async as config">
  <div class="section">
    <div class="language" *ngIf="false">
      {{ 'footer.language' | translate }}
      <div class="selector">
        <div class="current">{{ 'footer.english' | translate }}</div>
        <div class="icon" icon="arrow"></div>
      </div>
    </div>
  </div>
  <div class="divider" *ngIf="false"></div>
  <div class="section">
    <div class="title">{{ 'footer.followUs' | translate }}</div>
    <div class="social">
      <div class="icon" icon="facebook" gaEvent="facebook" (click)="navigate('socialUrlFacebook', config)"></div>
      <div class="icon" icon="twitter" gaEvent="twitter" (click)="navigate('socialUrlTwitter', config)"></div>
      <div class="icon" icon="linkedin" gaEvent="linkedIn" (click)="navigate('socialUrlLinkedIn', config)"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="section">
    <div class="legal" gaEvent="termsAndConditions" (click)="navigate('termsAndConditionsUrl', config)">
      {{ 'footer.termsAndConditions' | translate }}
    </div>
    <div class="legal" gaEvent="privacyPolicy" (click)="navigate('privacyPolicyUrl', config)">
      {{ 'footer.privacyPolicy' | translate }}
    </div>
  </div>
</div>
