import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'plm-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit {
  @Input() disabled = false;
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Output() tabSelected = new EventEmitter<{ tab: TabComponent; index: number; initial: boolean }>();
  ngAfterContentInit(): void {
    const activeTabs = this.tabs.find(tab => tab.active);

    if (!activeTabs) {
      this.selectTab(this.tabs.first, true);
    }
  }

  selectTab(tabForSelection: TabComponent, initial = false): void {
    this.tabs.forEach(tab => (tab.active = false));
    if (!tabForSelection) {
      return;
    }
    tabForSelection.active = true;
    this.tabSelected.emit({
      tab: tabForSelection,
      index: this.tabs.toArray().findIndex(tab => tab === tabForSelection),
      initial
    });
  }

  selectTabIndex(index: number): void {
    this.tabs.forEach(tab => (tab.active = false));
    this.tabs.get(index).active = true;
  }
}
