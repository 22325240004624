import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Configuration } from 'src/app/lib/rest-types';
import { IPlumeState } from 'src/app/lib/store';
import { configuration } from 'src/app/lib/store/selectors/plume.selectors';

@Component({
  selector: 'plm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent{
  config$ = this.store$.pipe(select(configuration));
  constructor(private store$: Store<IPlumeState>) {}

  navigate(property: string, conf: Configuration): void {
    window.open(conf[property]);
  }
}
