import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpBackend, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { TranslateModule, TranslateLoader, TranslateService, TranslateCompiler } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { RoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LoginComponent, HandlerComponent } from 'src/app/views/login/login.component';
import { MainComponent } from 'src/app/views/home/main.component';
import { InsightsComponent } from 'src/app/views/home/insights/insights.component';
import { PodsComponent } from 'src/app/views/home/pods/pods.component';
import { NewsComponent } from 'src/app/views/home/news/news.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { MainMenuComponent } from 'src/app/components/main-menu/main-menu.component';
import { AccountMenuComponent } from 'src/app/components/account-menu/account-menu.component';
import { SettingsMenuComponent } from 'src/app/components/settings-menu/settings-menu.component';
import { SupportMenuComponent } from 'src/app/components/support-menu/support-menu.component';
import { IosDownloadComponent } from 'src/app/components/ios-download/ios-download.component';
import { AndroidDownloadComponent } from 'src/app/components/android-download/android-download.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { MapModule } from 'src/app/components/map/map.module';
import { ActiveDevicesComponent } from 'src/app/components/active-devices/active-devices.component';
import { AiSecurityComponent } from 'src/app/components/ai-security/ai-security.component';
import { PersonalizeNetworkComponent } from 'src/app/components/personalize-network/personalize-network.component';
import { environment } from '../../environments/environment';
import { CustomLoaderFactory } from './app.loaders';
import { ButtonModule } from '../components/ui/button/button.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayButtonModule } from '../components/ui/overlay-button/overlay-button.module';
import { BoxModule } from '../components/ui/box/box.component.module';
import { D3Service } from 'src/app/lib/d3/service';
import { SecurityChartVisualComponent } from 'src/app/lib/d3/visuals/charts/securitychart/securitychart.component';
import { IconModule } from './directives/icon/icon.module';
import { SpaceModule } from '../components/ui/space/space.module';
import { ListModule } from '../components/ui/list/list.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers, effects } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { TokenInterceptor } from './services/token-interceptor.service';
import { MoodComponent } from '../components/mood/mood.component';
import { SpeedDescriptionComponent } from '../components/speed-description/speed-description.component';
import { SecuritySettingsComponent } from '../components/security-settings/security-settings.component';
import { FormInputModule } from '../components/ui/form-input/form-input.module';
import { IconService } from 'src/app/lib/services/icon-service/icon.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from './services/auth.service';
import { BrazeService } from './services/braze.service';
import { PlumeService } from './services/plume.service';
import { DeviceFilterPipe } from '../components/ai-security/device.pipe';
import { EmptyComponent } from '../components/empty/empty.component';
import { ChipModule } from '../components/ui/chip/chip.module';
import { ToggleComponent } from '../components/ui/toggle/toggle.component';
import { ToolTipBoxDirective } from './directives/tool-tip-box/tool-tip-box.directive';
import { UpdateLocationComponent } from '../components/security-settings/update-location/update-location.component';
import { DeleteSecEventsComponent } from '../components/security-settings/delete-sec-events/delete-sec-events.component';
import { EnableOnlineProtectionComponent } from '../components/security-settings/enable-online-protection/enable-online-protection.component';
import { NetworkErrorComponent } from '../components/network-error/network-error.component';
import { GuardTileComponent } from '../components/guard-tile/guard-tile.component';
import { GuardTilesBoxComponent } from '../components/guard-tiles-box/guard-tiles-box.component';
import { EventsComponent } from '../views/home/insights/events/events.component';
import { GuardEventListComponent } from '../components/guard-event-list/guard-event-list.component';
import { ApproveBlockComponent } from '../components/approve-block/approve-block.component';
import { RuleModule } from '../components/ui/rule/rule.module';
import { EllipsisPipeModule } from './pipes/ellipsis.module';
import { PipesModule } from './pipes/pipes.module';
import { ConsumptionCircleComponent } from '../components/consumption-circle/consumption-circle.component';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { ApproveBlockErrorModule } from '../components/approve-block/approve-block-error-modal/approve-block-error-modal.module';
import { PodsOverviewComponent } from '../components/pods-overview/pods-overview.component';
import { PodGraphicsComponent } from '../components/pods-overview/pod-graphics/pod-graphics.component';
import { PodsOfflineComponent } from '../components/pods-overview/pods-offline/pods-offline.component';
import { OverflowToolTipDirective } from './directives/overflow-tool-tip/overflow-tool-tip.directive';
import { InvalidPartnerComponent } from '../views/invalid-partner/invalid-partner.component';
import { TopologyComponent } from '../views/topology/topology.component';
import { OverflowElementHiderComponent } from '../components/overflow-element-hider/overflow-element-hider.component';
import { ZendChatModule } from '../components/zend-chat/zend-chat.module';
import { AlertPopBarModule } from '../components/alert-pop-bar/alert-pop-bar.module';
import { FocusOnClickDirective } from './directives/focus-on-click/focus-on-click.directive';
import { DropdownMenuComponent } from '../components/ui/dropdown-menu/dropdown-menu.component';
import { DeviceItemComponent } from '../components/ui/device-item/device-item.component';
import { TopBarComponent } from '../components/topology/top-bar/top-bar.component';
import { BottomBarComponent } from '../components/topology/bottom-bar/bottom-bar.component';
import { ToolTipTextDirective } from './directives/tool-tip-text/tool-tip-text.directive';
import { PillWithCounterComponent } from '../components/pills/pill-with-counter/pill-with-counter.component';
import { ThemeContainerComponent } from '../theme-container/theme-container.component';
import { RenameLocationComponent } from '../components/account-menu/rename-location/rename-location.component';
import { InputFieldComponent } from '../components/ui/input-field/input-field.component';
import { PeopleDropDownComponent } from '../components/ui/people-drop-down/people-drop-down.component';
import { TabsComponent } from '../components/ui/tab/tabs/tabs.component';
import { TabComponent } from '../components/ui/tab/tab/tab.component';
import { TabLabelDirective } from '../components/ui/tab//tab-label/tab-label.directive';
import { MenuModule } from '../components/ui/menu/menu.module';
import { TroubleshootingCarouselModule } from '../components/troublshooting-carousel/troubleshooting-carousel.module';
import { SpeedTestBoxComponent } from '../components/speed-test-box/speed-test-box.component';
import { BoxSpeedTestProgressComponent } from '../components/ui/box/box-speed-test-progress/box-speed-test-progress.component';
import { SpeedLegendComponent } from '../components/speed-description/speed-legend/speed-legend.component';
import { SettingsAdaptComponent } from '../views/settings/settings-adapt/settings-adapt.component';
import { SaveSsidDialogComponent } from '../components/adapt/save-ssid-dialog/save-ssid-dialog.component';
import { ColumnGraphModule } from '../components/column-graph/column-graph.module';
import { SettingsGuardComponent } from '../views/settings/settings-guard/settings-guard.component';
import { DirectivesModule } from './directives/directives.module';
import { DeletePodDialogComponent } from '../components/delete-pod-dialog/delete-pod-dialog.component';
import { AccordionModule } from '../components/ui/accordion/accordion.module';
import { BlockModule } from '../components/ui/block/block.module';
import { RenamePodComponent } from '../components/rename-pod/rename-pod.component';
import { NoDeviceComponent } from '../components/ui/no-device/no-device.component';
import { SignalPillComponent } from '../components/ui/signal-pill/signal-pill.component';
import { ChannelConnectionComponent } from '../components/ui/channel-connection/channel-connection.component';
import { ParentNodeComponent } from '../components/ui/parent-node/parent-node.component';
import { PodHealthDescComponent } from '../components/pod-health-desc/pod-health-desc.component';
import { HardwareInfoModule } from '../components/hardware-info/hardware-info.module';
import { CalendarComponent } from '../components/ui/date-time/calendar/calendar.component';
import { DateTimeInputComponent } from '../components/ui/date-time/date-time-input/date-time-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { DateTimeComponent } from '../components/ui/date-time/date-time/date-time.component';
import { SettingsAdvancedComponent } from '../views/settings/settings-advanced/settings.advanced.component';
import { GridModule } from '../components/ui/grid/grid.module';
import { SectionModule } from '../components/ui/section/section.module';
import { AccessPasswordComponent } from '../components/access-settings/access-password/access-password.component';
import { SettingsAccessComponent } from '../views/settings/settings-access/settings-access.component';
import { ViewPasswordComponent } from '../components/ui/view-password/view-password.component';
import { AccessPasswordListComponent } from '../components/access-settings/access-password-list/access-password-list.component';
import { AccessEditZoneAndKeyComponent } from '../views/settings/settings-access/access-edit-zone-and-key/access-edit-zone-and-key.component';
import { DeviceListChooserComponent } from '../components/ui/device-list-chooser/device-list-chooser.component';
import { MobileBannerModule } from '../components/mobile-banner/mobile-banner.module';
import { BoxSubTitleComponent } from '../components/ui/box/box-sub-title/box-sub-title.component';
import { NetworkingModeComponent } from '../components/networking-mode/networking-mode.component';
import { NetworkModeDialogComponent } from '../components/networking-mode/networking-mode-dialog/networking-mode-dialog.component';
import { NetworkRestartComponent } from '../components/networking-mode/network-restart-dialog/network-restart.component';
import { AccessGuestPasswordListComponent } from '../components/access-settings/access-guest-password-list/access-guest-password-list.component';
import { InfoOverlayModule } from '../components/ui/info-overlay/info-overlay.module';
import { EditWifiPasswordComponent } from '../components/access-settings/dialogs/edit-wifi-password/edit-wifi-password.component';
import { DisableWifiPasswordComponent } from '../components/access-settings/dialogs/disable-wifi-password/disable-wifi-password.component';
import { DeleteWifiPasswordComponent } from '../components/access-settings/dialogs/delete-wifi-password/delete-wifi-password.component';
import { UpnpComponent } from '../components/upnp/upnp.component';
import { DnsComponent } from '../components/dns/dns.component';
import { NetworkModeRouterDialogComponent } from '../components/network-mode-router-dialog/network-mode-router-dialog.component';
import { IpReservationListComponent } from '../components/ip-reservation-list/ip-reservation-list.component';
import { IpReservationEditComponent } from '../components/ip-reservation-edit/ip-reservation-edit.component';
import { DevicesDropDownComponent } from '../components/ui/devices-drop-down/devices-drop-down.component';
import { IpReservationPortComponent } from '../components/ip-reservation-port/ip-reservation-port.component';
import { IpReservationPortListComponent } from '../components/ip-reservation-port-list/ip-reservation-port-list.component';
import { LanSubnetComponent } from '../components/lan-subnet/lan-subnet.component';
import { LanSubnetEditComponent } from '../components/lan-subnet-edit/lan-subnet-edit.component';
import { PodWhyOfflineComponent } from '../components/pod-why-offline/pod-why-offline.component';
import { AccountMenuMobileComponent } from '../components/account-menu-mobile/account-menu-mobile.component';
import { SideMenuMobileComponent } from '../components/side-menu-mobile/side-menu-mobile.component';
import { LanSubnetEditConfirmDialogComponent } from '../components/lan-subnet-edit-confirm-dialog/lan-subnet-edit-confirm-dialog.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    LeafletModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxGoogleAnalyticsModule.forRoot(environment.server.ga),
    NgxGoogleAnalyticsRouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CustomLoaderFactory,
        deps: [HttpBackend]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    PerfectScrollbarModule,
    FormInputModule,
    ChipModule,
    EllipsisPipeModule,
    ListModule,
    MapModule,
    SpaceModule,
    IconModule,
    ButtonModule,
    OverlayModule,
    OverlayButtonModule,
    BoxModule,
    ApproveBlockErrorModule,
    RuleModule,
    ZendChatModule,
    MenuModule,
    BrowserAnimationsModule,
    AlertPopBarModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument(),
    TroubleshootingCarouselModule,
    ColumnGraphModule,
    PipesModule,
    DirectivesModule,
    AccordionModule,
    BlockModule,
    HardwareInfoModule,
    GridModule,
    SectionModule,
    MobileBannerModule,
    InfoOverlayModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HandlerComponent,
    MainComponent,
    InsightsComponent,
    PodsComponent,
    AiSecurityComponent,
    ActiveDevicesComponent,
    PersonalizeNetworkComponent,
    NewsComponent,
    HeaderComponent,
    FooterComponent,
    MainMenuComponent,
    AccountMenuComponent,
    SettingsMenuComponent,
    SupportMenuComponent,
    IosDownloadComponent,
    AndroidDownloadComponent,
    SecurityChartVisualComponent,
    MoodComponent,
    SpeedDescriptionComponent,
    SecuritySettingsComponent,
    DeviceFilterPipe,
    EmptyComponent,
    ToggleComponent,
    ToolTipBoxDirective,
    UpdateLocationComponent,
    DeleteSecEventsComponent,
    EnableOnlineProtectionComponent,
    NetworkErrorComponent,
    GuardTileComponent,
    GuardTilesBoxComponent,
    EventsComponent,
    DeviceItemComponent,
    GuardEventListComponent,
    ConsumptionCircleComponent,
    PodsOverviewComponent,
    PodGraphicsComponent,
    PodsOfflineComponent,
    OverflowToolTipDirective,
    InvalidPartnerComponent,
    TopologyComponent,
    NoDeviceComponent,
    OverflowElementHiderComponent,
    FocusOnClickDirective,
    DropdownMenuComponent,
    TopBarComponent,
    BottomBarComponent,
    ToolTipTextDirective,
    PillWithCounterComponent,
    ThemeContainerComponent,
    RenameLocationComponent,
    InputFieldComponent,
    ApproveBlockComponent,
    SignalPillComponent,
    ChannelConnectionComponent,
    PeopleDropDownComponent,
    TabsComponent,
    TabComponent,
    TabLabelDirective,
    SpeedTestBoxComponent,
    BoxSpeedTestProgressComponent,
    SpeedLegendComponent,
    SettingsAdaptComponent,
    SaveSsidDialogComponent,
    DeletePodDialogComponent,
    SettingsGuardComponent,
    RenamePodComponent,
    ParentNodeComponent,
    PodHealthDescComponent,
    CalendarComponent,
    DateTimeInputComponent,
    DateTimeComponent,
    SettingsAdvancedComponent,
    SettingsAccessComponent,
    AccessPasswordComponent,
    ViewPasswordComponent,
    AccessPasswordListComponent,
    BoxSubTitleComponent,
    NetworkingModeComponent,
    NetworkModeDialogComponent,
    NetworkRestartComponent,
    AccessEditZoneAndKeyComponent,
    DeviceListChooserComponent,
    AccessGuestPasswordListComponent,
    EditWifiPasswordComponent,
    DisableWifiPasswordComponent,
    DeleteWifiPasswordComponent,
    UpnpComponent,
    NetworkModeRouterDialogComponent,
    IpReservationListComponent,
    IpReservationEditComponent,
    DevicesDropDownComponent,
    DnsComponent,
    IpReservationPortComponent,
    IpReservationPortListComponent,
    LanSubnetComponent,
    LanSubnetEditComponent,
    PodWhyOfflineComponent,
    AccountMenuMobileComponent,
    SideMenuMobileComponent,
    LanSubnetEditConfirmDialogComponent
  ],
  providers: [
    AuthService,
    BrazeService,
    PlumeService,
    IconService,
    TranslateService,
    D3Service,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [DeviceFilterPipe]
})
export class AppModule {}
