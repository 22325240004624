import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { BrazeService } from 'src/app/lib/services/braze.service';
import { IPlumeState } from 'src/app/lib/store';
import { configuration, customerFirstName, onboarded } from 'src/app/lib/store/selectors/plume.selectors';

@UntilDestroy()
@Component({
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('newsfeed') feed: ElementRef;
  isOnboarded$ = this.store$.pipe(select(onboarded));
  firstName$ = this.store$.pipe(select(customerFirstName));
  video$ = this.store$.pipe(
    select(configuration),
    map(conf => this.sanitizer.bypassSecurityTrustResourceUrl(conf.newCustomerVideoUrl))
  );

  constructor(
    private route: ActivatedRoute,
    public braze: BrazeService,
    private sanitizer: DomSanitizer,
    private store$: Store<IPlumeState>
  ) {}

  ngAfterViewInit(): void {
    const filter = (this.route.snapshot.data.name as string) || null;
    this.showNewsfeed(filter);
  }

  showNewsfeed(filter: string): void {
    switch (filter) {
      case 'tips':
        this.braze.showFeed(this.feed.nativeElement, ['Advertising']);
        break;
      case 'blog':
        this.braze.showFeed(this.feed.nativeElement, ['Social']);
        break;
      case 'releasenotes':
        this.braze.showFeed(this.feed.nativeElement, ['Announcements']);
        break;
      case 'notifications':
        // NOT USED
        this.braze.showFeed(this.feed.nativeElement, ['News']);
        break;
      default:
        this.braze.showFeed(this.feed.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this.braze.destroyFeed();
  }
}
