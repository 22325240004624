import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'plm-pill-with-counter',
  templateUrl: './pill-with-counter.component.html',
  styleUrls: ['./pill-with-counter.component.scss']
})
export class PillWithCounterComponent {
  @Input() text = '';
  @Input() count = 0;
  @Input() selected = false;

  @HostBinding('class.pill-icon-box') get pillClass(): boolean {
    return true;
  }

  @HostBinding('class.selected') get selectedClass(): boolean {
    return this.selected;
  }

  @HostBinding('attr.aria-pressed') get ariaPressed(): string {
    return this.selected ? 'true' : 'false';
  }

  @HostBinding('attr.role') get ariaRole(): string {
    return 'button';
  }

  @HostBinding('attr.tabindex') get tabindex(): number {
    return 0;
  }
}
