import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AccessZoneWifi, AccessZoneWifiType, WifiNetwork, WifiNetworkKeys } from 'src/app/lib/rest-types';
import { DhcpReservation, PortForwards } from '../models/dhcp-reservation.model';
import { Dhcp } from '../models/dhcp.model';
import { DnsServers } from '../models/dns.model';
import { Upnp } from '../models/upnp.model';

export const setSsidName = createAction('[wifi] set ssid name', props<{ ssid: string; locationId?: string }>());
export const ssidLoaded = createAction('[wifi] set ssid loaded', props<{ wifiNetwork: WifiNetwork }>());
export const ssidLoadedError = createAction(
  '[wifi] set ssid loaded error',
  props<{ errorMessage: string; locationId?: string }>()
);
export const setWifiNetwork = createAction(
  '[wifi] set wifi network',
  props<{ wifiNetwork: Partial<WifiNetwork>; locationId?: string }>()
);
export const setWifiNetworkLoaded = createAction(
  '[wifi]  set wifi network loaded',
  props<{ wifiNetwork: WifiNetwork }>()
);
export const addNewWifiPassword = createAction(
  '[wifi] add wifi password',
  props<{
    accessZone: AccessZoneWifiType;
    password: string;
    expiresAt: string | undefined;
    locationId?: string;
  }>()
);

export const addNewWifiPasswordWithAccessZone = createAction(
  '[wifi] add wifi password with access zone',
  props<{
    accessZone: AccessZoneWifiType;
    password: string;
    expiresAt: string | undefined;
    guestName: string;
    accessibleDevices: string[];
    locationId?: string;
  }>()
);

export const addNewWifiPasswordSuccess = createAction(
  '[wifi] add wifi password success',
  props<{ keys: WifiNetworkKeys[] }>()
);

export const addNewWifiPasswordError = createAction(
  '[wifi] add wifi password error',
  props<{ error: HttpErrorResponse }>()
);

export const editWifiPassword = createAction(
  '[wifi] edit wifi password',
  props<{ key: Partial<WifiNetworkKeys>; locationId?: string }>()
);

export const editWifiPasswordSuccess = createAction(
  '[wifi] edit wifi password success',
  props<{ keyId: number; keys: WifiNetworkKeys[] }>()
);

export const editWifiPasswordError = createAction(
  '[wifi] edit wifi password error',
  props<{ keyId: number; error: HttpErrorResponse }>()
);

export const editWifiAccessZone = createAction(
  '[wifi] edit wifi access zone',
  props<{ accessZone: Partial<AccessZoneWifi>; locationId?: string }>()
);

export const editWifiAccessZoneSuccess = createAction(
  '[wifi] edit wifi access zone success',
  props<{ accessZone: AccessZoneWifi }>()
);

export const editWifiAccessZoneError = createAction(
  '[wifi] edit wifi access zone error',
  props<{ error: HttpErrorResponse }>()
);

export const changeNetworkMode = createAction('[Settings] change network mode', props<{ mode: 'auto' | 'router' }>());

export const changeNetworkModeFail = createAction(
  '[Settings] change network mode fail',
  props<{ error: HttpErrorResponse }>()
);

export const changeNetworkModeSuccess = createAction('[Settings] change network mode success');

export const deleteWifiPassword = createAction(
  '[wifi] delete wifi password',
  props<{ key: WifiNetworkKeys; locationId?: string }>()
);

export const deleteWifiPasswordSuccess = createAction(
  '[wifi] delete wifi password success',
  props<{ keyId: number; keys: WifiNetworkKeys[] }>()
);

export const deleteWifiPasswordError = createAction(
  '[wifi] delete wifi password error',
  props<{ keyId: number; error: HttpErrorResponse }>()
);

export const deleteWifiAccessZone = createAction(
  '[wifi] delete wifi access zone',
  props<{ key: WifiNetworkKeys; locationId?: string }>()
);

export const deleteWifiAccessZoneSuccess = createAction(
  '[wifi] delete wifi access zone success',
  props<{ accessZones: AccessZoneWifi[]; keyId: number }>()
);

export const deleteWifiAccessZoneError = createAction(
  '[wifi] delete wifi access zone error',
  props<{ keyId: number; error: HttpErrorResponse }>()
);

export const getUpnp = createAction('[settings] getUpnp');

export const getUpnpSuccess = createAction('[settings] getUpnp Success', props<{ upnp: Upnp }>());

export const getUpnpFail = createAction('[settings] getUpnp fail', props<{ error: HttpErrorResponse }>());

export const setUpnp = createAction('[settings] set Upnp', props<{ mode: string }>());

export const setUpnpSuccess = createAction('[settings] set Upnp Success', props<{ upnp: Upnp }>());

export const setUpnpFail = createAction('[settings] set Upnp fail', props<{ error: HttpErrorResponse }>());

export const getDhcpReservations = createAction('[settings] get dhcp reservation');

export const getDhcpReservationsSuccess = createAction(
  '[settings] get dhcp reservation Success',
  props<{ dhcpReservations: DhcpReservation[] }>()
);

export const getDhcpReservationsFail = createAction(
  '[settings] get dhcp reservation fail',
  props<{ error: HttpErrorResponse }>()
);

export const newIpReservation = createAction(
  '[settings] new ip reservation',
  props<{ ip: string; mac: string; name?: string; hostName?: string }>()
);
export const newIpReservationSuccess = createAction(
  '[settings] new ip reservation success',
  props<{ ip: string; mac: string; name?: string; hostname?: string }>()
);
export const newIpReservationFailed = createAction(
  '[settings] new ip reservation failed',
  props<{ error: HttpErrorResponse }>()
);

export const editIpReservation = createAction('[settings] edit ip reservation', props<{ ip: string; mac: string }>());
export const editIpReservationSuccess = createAction(
  '[settings] edit ip reservation success',
  props<{ ip: string; mac: string }>()
);
export const editIpReservationFailed = createAction(
  '[settings] edit ip reservation failed',
  props<{ mac: string; error: HttpErrorResponse }>()
);

export const deleteIpReservation = createAction('[settings] delete ip reservation', props<{ mac: string }>());
export const deleteIpReservationSuccess = createAction(
  '[settings] delete ip reservation success',
  props<{ mac: string }>()
);
export const deleteIpReservationFailed = createAction(
  '[settings] delete ip reservation failed',
  props<{ mac: string; error: HttpErrorResponse }>()
);
export const getDns = createAction('[settings] get dns servers');

export const getDnsSuccess = createAction('[settings] get dns server Success', props<{ dnsServers: DnsServers }>());

export const getDnsFail = createAction('[settings] get dns servers fail', props<{ error: HttpErrorResponse }>());

export const setDns = createAction('[settings] set Dns', props<{ dns: DnsServers }>());

export const setDnsSuccess = createAction('[settings] set Dns Success', props<{ dns: DnsServers }>());

export const setDnsFail = createAction('[settings] set Dns fail', props<{ error: HttpErrorResponse }>());

export const addPortForwarding = createAction(
  '[settings] add port forwarding',
  props<{ mac: string; port: PortForwards }>()
);
export const addPortForwardingSuccess = createAction(
  '[settings] add port forwarding success',
  props<{ mac: string; port: PortForwards }>()
);
export const addPortForwardingError = createAction(
  '[settings] add port forwarding error',
  props<{ mac: string; error: HttpErrorResponse }>()
);
export const editPortForwarding = createAction(
  '[settings] edit port forwarding',
  props<{ mac: string; port: PortForwards }>()
);
export const editPortForwardingSuccess = createAction(
  '[settings] edit port forwarding success',
  props<{ mac: string; port: PortForwards }>()
);
export const editPortForwardingError = createAction(
  '[settings] edit port forwarding error',
  props<{ mac: string; externalPort: number; error: HttpErrorResponse }>()
);
export const deletePortForwarding = createAction(
  '[settings] delete port forwarding',
  props<{ mac: string; externalPort: number }>()
);
export const deletePortForwardingSuccess = createAction(
  '[settings] delete port forwarding success',
  props<{ mac: string; externalPort: number; ports: PortForwards[] }>()
);
export const deletePortForwardingError = createAction(
  '[settings] delete port forwarding error',
  props<{ mac: string; externalPort: number; error: HttpErrorResponse }>()
);
export const getDhcp = createAction('[settings] get dhcp');

export const getDhcpSuccess = createAction('[settings] get dhcp Success', props<{ dhcp: Dhcp }>());

export const getDhcpFail = createAction('[settings] getdhcp fail', props<{ error: HttpErrorResponse }>());

export const setDhcp = createAction('[settings] set DHCP', props<{ dhcp: Dhcp }>());

export const setDhcpSuccess = createAction('[settings] set DHCP Success', props<{ dhcp: Dhcp }>());

export const setDhcpFail = createAction('[settings] set FHCP fail', props<{ error: HttpErrorResponse }>());
