<div class="bubble">
  <div class="header">
    <h3>{{ 'activeDevices.mostactiveDevices' | translate }}</h3>
    <div class="icon" icon="more" *ngIf="false"></div>
  </div>
  <div class="granulation">
    <button class="pill-icon-box" [class.focus]="granularity === 'day'" (click)="changeGranularity('day')">
      {{ 'activeDevices.last24hrs' | translate }}
    </button>
    <button class="pill-icon-box" [class.focus]="granularity === 'week'" (click)="changeGranularity('week')">
      {{ 'activeDevices.last7days' | translate }}
    </button>
    <button class="pill-icon-box" [class.focus]="granularity === 'month'" (click)="changeGranularity('month')">
      {{ 'activeDevices.last30days' | translate }}
    </button>
  </div>
  <plm-tabs>
    <plm-tab>
      <ng-template plmTabLabel>
        <span class="icon" icon="download"></span>
        <span>{{ 'activeDevices.download' | translate }}</span>
      </ng-template>
      <ng-content *ngTemplateOutlet="tabContent; context: { mode: 'download' }"></ng-content>
    </plm-tab>
    <plm-tab>
      <ng-template plmTabLabel>
        <span class="icon" icon="upload"></span>
        <span>{{ 'activeDevices.upload' | translate }}</span>
      </ng-template>
      <ng-content *ngTemplateOutlet="tabContent; context: { mode: 'upload' }"></ng-content>
    </plm-tab>
  </plm-tabs>
</div>

<ng-template #tabContent let-mode="mode">
  <div class="loading" *ngIf="!summary">
    <div class="icon" icon="loading"></div>
  </div>
  <div class="info" *ngIf="summary">
    <div class="description">
      {{ 'activeDevices.totalDataConsumedDescription1' | translate }}
      {{ summary[granularity].devices.mostActive.length }}
      {{ 'activeDevices.totalDataConsumedDescription2' | translate }}
    </div>
    <div class="total">
      <div class="value">
        {{ stats[mode].total.consumption }}
      </div>
      <div class="unit">
        {{ stats[mode].total.unit }}
      </div>
    </div>
  </div>
  <div class="list" *ngIf="summary">
    <div class="device" *ngFor="let device of stats[mode].mappedDevices; let index = index">
      <div class="icon" [device]="true" height="128" width="128" [icon]="device.iconV2"></div>
      <div class="data">
        <div class="name">{{ device.name }}</div>
        <div class="lineBox">
          <div class="line" *ngIf="index === 0" [style.width.%]="100"></div>
          <div
            class="line"
            *ngIf="index !== 0"
            [style.width.%]="(device.percent / stats[mode].mappedDevices[0].percent) * 100"
          ></div>
        </div>
        <div class="value">{{ device.consumption }} {{ device.unit }}</div>
      </div>
      <div class="chart">
        <plm-consumption-circle
          [start]="consumptionTo(index, stats[mode].mappedDevices)"
          [value]="device.percent"
        ></plm-consumption-circle>
      </div>
    </div>
  </div>
</ng-template>
