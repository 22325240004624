<svg:line
  class="valueLine"
  [attr.x1]="0"
  [attr.y1]="calculations.columnY(this.value)"
  [attr.x2]="calculations.width"
  [attr.y2]="calculations.columnY(this.value)"
/>

<svg:rect
  class="textBg"
  [attr.x]="calculations.margins.left"
  [attr.y]="calculations.columnY(this.value) + (position === 'under' ? 0 : 0 - 16)"
  [attr.width]="textWidth + 20"
  height="16"
/>

<svg:text
  class="text"
  #textElm
  [attr.x]="calculations.margins.left + 10"
  [attr.y]="calculations.columnY(this.value) + (position === 'under' ? 12 : 12 - 16)"
>
  {{ textValue }}
</svg:text>
