import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PlumeNode } from '../../rest-types';
import { SharedUrlsService } from '../shared-urls';

@Injectable({
  providedIn: 'root'
})
export class NodesService {
  constructor(private httpClient: HttpClient, private sharedUrls: SharedUrlsService) {}

  setLedMode$(
    nodeId: string,
    mode: 'locate' | 'normal',
    locationId?: string
  ): Observable<{ mode: 'locate' | 'normal' }> {
    return this.sharedUrls.locationUrl$(locationId).pipe(
      switchMap(locationUrl =>
        this.httpClient.put<{ mode: 'locate' | 'normal' }>(`${locationUrl}/nodes/${nodeId}/ledMode`, { mode })
      )
    );
  }

  delete$(nodeId: string): Observable<void> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(switchMap(locationUrl => this.httpClient.delete<void>(`${locationUrl}/nodes/${nodeId}`)));
  }

  // emitMessage will disable the blinking LED as well
  rename$(nodeId: string, newName: string, emitMessage: 'on' | 'off' = 'on'): Observable<Partial<PlumeNode>> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.httpClient.put<Partial<PlumeNode>>(
          `${locationUrl}/nodes/${nodeId}`,
          { nickname: newName, emitMessage },
          {
            headers: { 'errors-white-list': ['400', '422'] }
          }
        )
      )
    );
  }
}
