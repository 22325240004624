import { Component, Inject } from '@angular/core';
import { PlmDialogInject, PlmDialogToken } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  selector: 'plm-lan-subnet-edit-confirm-dialog',
  templateUrl: './lan-subnet-edit-confirm-dialog.component.html',
  styleUrls: ['./lan-subnet-edit-confirm-dialog.component.scss']
})
export class LanSubnetEditConfirmDialogComponent {
  constructor(@Inject(PlmDialogToken) public dialog: PlmDialogInject<string, boolean>) {}

  get transPth(): string {
    return 'dhcp.updateDialog.';
  }
}
