<plm-header (toggleMenu)="showMobileSideMenu = !showMobileSideMenu"></plm-header>
<plm-space class="headerSpace" size="xxl"></plm-space>
<plm-side-menu-mobile *ngIf="showMobileSideMenu" [config]="menuItems"></plm-side-menu-mobile>
<plm-account-menu-mobile class="mobileLocation"></plm-account-menu-mobile>
<plm-section>
  <plm-grid>
    <plm-grid-item colSpan="3" class="locationMenu">
      <plm-account-menu></plm-account-menu>
      <plm-menu [config]="menuItems"></plm-menu>
      <plm-space size="s"></plm-space>
    </plm-grid-item>

    <plm-grid-item [colSpan]="mainColSpan">
      <router-outlet></router-outlet>
    </plm-grid-item>
    <plm-grid-item [colSpan]="rightColSpan" [colStart]="rightColStart" *ngIf="!panelRightHide">
      <plm-support-menu></plm-support-menu>
      <plm-footer></plm-footer>
    </plm-grid-item>
  </plm-grid>
</plm-section>
<plm-mobile-banner></plm-mobile-banner>
<plm-zend-chat></plm-zend-chat>
