<plm-dropdown-menu
  class="legendButtonBox"
  #legendDropdown
  [position]="'bottomLeft'"
  [closeOnClick]="false"
  [closeOnClickOutside]="false"
  [menuOpened]="true"
>
  <button class="legendButton pill-icon-box up small" [class.opened]="legendDropdown.menuOpened">
    <div icon="arrow" class="arrow big"></div>
  </button>
  <plm-list dropdown class="read-only legend floatBottom">
    <li class="drop-down-item ellipsis-overflow excellent">
      <svg viewBox="0 0 32 4">
        <line x1="0" y1="2" x2="32" y2="2" stroke-width="2" stroke-linecap="round" />
      </svg>
      {{ 'topology.signal.excellent' | translate }}
    </li>
    <li class="drop-down-item ellipsis-overflow good">
      <svg viewBox="0 0 32 4">
        <line x1="0" y1="2" x2="32" y2="2" stroke-width="2" stroke-linecap="round" stroke-dasharray="2 4" />
      </svg>
      {{ 'topology.signal.good' | translate }}
    </li>
    <li class="drop-down-item ellipsis-overflow fair">
      <svg viewBox="0 0 32 4">
        <line x1="0" y1="2" x2="32" y2="2" stroke-width="2" stroke-linecap="round" />
      </svg>
      {{ 'topology.signal.fair' | translate }}
    </li>
    <li class="drop-down-item ellipsis-overflow poor">
      <svg viewBox="0 0 32 4">
        <line x1="0" y1="2" x2="32" y2="2" stroke-width="2" stroke-linecap="round" stroke-dasharray="2 4" />
      </svg>
      {{ 'topology.signal.poor' | translate }}
    </li>
    <li class="drop-down-item ellipsis-overflow offline">
      <svg viewBox="0 0 32 4">
        <line x1="0" y1="2" x2="32" y2="2" stroke-width="2" stroke-linecap="round" />
      </svg>
      {{ 'topology.signal.offline' | translate }}
    </li>
  </plm-list>
</plm-dropdown-menu>

<div class="capsuledButtonList pill-icon-box-group">
  <button class="pill-icon-box medium" (click)="zoomOut.emit()"><div icon="minus" class="big"></div></button>
  <button class="pill-icon-box medium" (click)="zoomIn.emit()"><div icon="plus" class="big"></div></button>
</div>

<plm-dropdown-menu
  class="offlineDevices"
  [disabled]="devicesWithPerson.length === 0"
  width="350px"
  #offlineDevicesDropdown
  [position]="[{ originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'bottom' }]"
>
  <button
    class="pill-icon-box up"
    [class.opened]="offlineDevicesDropdown.menuOpened"
    *ngIf="devicesWithPerson.length !== 0"
  >
    <div>{{ 'topology.offlineDeviceCount' | translate: { count: devicesWithPerson.length } }}</div>
    <div icon="arrow" class="arrow big"></div>
  </button>
  <button class="pill-icon-box up" disabled *ngIf="devicesWithPerson.length === 0">
    <div>{{ 'topology.noOfflineDevices' | translate }}</div>
  </button>

  <div dropdown class="offlineDevicesDropDown">
    <div class="header">
      <div>{{ 'topology.offlineDeviceCount' | translate: { count: devicesWithPerson.length } }}</div>
      <div icon="arrow" class="arrow"></div>
    </div>
    <div>
      <perfect-scrollbar class="scrollbar">
        <plm-list>
          <li class="drop-down-item" *ngFor="let device of devicesWithPerson" (click)="selectDevice(device)">
            <div
              [icon]="device.person?.imageId ? 'user' : ''"
              class="icon user"
              [plmToolTipText]="device.person?.nickname"
            ></div>
            <div class="deviceIcon" [icon]="device.iconV2" [device]="true" height="64" width="64"></div>
            <div class="text">
              <div plmOverflowToolTip class="ellipsis-overflow">{{ device.nickname || device.name || '' }}</div>
              <div plmOverflowToolTip class="ellipsis-overflow lastOnline">
                {{ device.connectionStateChangeAt | lastSeen }}
              </div>
            </div>
          </li>
        </plm-list>
      </perfect-scrollbar>
    </div>
  </div>
</plm-dropdown-menu>
