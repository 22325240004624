export const braze = {
  apiKey: '8408ed52-17c5-4eb1-9ca7-0a1b0b66a28b',
  baseUrl: 'https://sdk.iad-03.appboy.com',
  cloud: {
    beta: 'd43f482a-45a2-4095-89ed-9dfafe03e2c0',
    dogfood: 'ff91f21f-34dc-4199-9a97-308e9baf000f',
    gamma: '4236bb88-f719-403a-bc23-586c1483d794',
    kappa: '019cc6d4-7306-453d-8808-5211af9d0e02'
  }
};
