<div class="dhcp">
  <ng-container *ngIf="networkClass === 'A'; then classA"> </ng-container>
  <ng-container *ngIf="networkClass === 'B'; then classB"> </ng-container>
  <ng-container *ngIf="networkClass === 'C'; then classC"> </ng-container>
  <div class="dhcp__bottom-buttons">
    <button (click)="onCancel()">{{ 'buttons.cancel' | translate }}</button>
    <button class="highlight" (click)="onSave()">{{ 'buttons.update' | translate }}</button>
  </div>
</div>

<ng-template #classA>
  <div class="dhcp__ip-address">
    <span class="dhcp__ip-text">10</span>
    <span class="dhcp__ip-text">.</span>
    <input class="dhcp__input" [(ngModel)]="octet2" />
    <span class="dhcp__ip-text">.</span>
    <input class="dhcp__input" [(ngModel)]="octet3" />
    <span class="dhcp__ip-text">.</span>
    <span class="dhcp__ip-text">x</span>
  </div>
  <div class="dhcp__pick-number">
    <plm-space size="xs"></plm-space>
    <div>{{ 'dhcp.pickNumber' | translate }}</div>
  </div>
</ng-template>

<ng-template #classB>
  <div class="dhcp__ip-address">
    <span class="dhcp__ip-text">172</span>
    <span class="dhcp__ip-text">.</span>
    <input class="dhcp__input" [(ngModel)]="octet2" />
    <span class="dhcp__ip-text">.</span>
    <input class="dhcp__input" [(ngModel)]="octet3" />
    <span class="dhcp__ip-text">.</span>
    <span class="dhcp__ip-text">x</span>
  </div>
  <div class="dhcp__pick-number">
    <plm-space size="xs"></plm-space>
    <div>{{ 'dhcp.pickNumberFor172Range' | translate }}</div>
  </div>
</ng-template>

<ng-template #classC>
  <div class="dhcp__ip-address">
    <span class="dhcp__ip-text">192</span>
    <span class="dhcp__ip-text">.</span>
    <span class="dhcp__ip-text">168</span>
    <span class="dhcp__ip-text">.</span>
    <input class="dhcp__input" [(ngModel)]="octet3" [ngClass]="{ invalid: invalid }" />
    <span class="dhcp__ip-text">.</span>
    <span class="dhcp__ip-text">x</span>
  </div>
  <div class="dhcp__pick-number">
    <plm-space size="xs"></plm-space>
    <div>{{ 'dhcp.pickNumber' | translate }}</div>
  </div>
</ng-template>
