import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'plm-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls: ['carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit {
  widthOfSlide: number;
  offset = 0;
  transitionPaused = false;

  @ViewChild('contentWrapper') contentWrapper: ElementRef<HTMLElement>;
  public numberOfSlides: number;
  public currentSlide = 1;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.numberOfSlides = this.contentWrapper.nativeElement.childNodes.length || 0;
      this.widthOfSlide = this.contentWrapper.nativeElement.parentElement.clientWidth;
    });
  }

  next(): void {
    if (this.currentSlide < this.numberOfSlides) {
      this.currentSlide++;
      this.offset = this.offset + -this.widthOfSlide - 10;
    }
  }

  prev(): void {
    if (this.currentSlide <= this.numberOfSlides && this.currentSlide !== 1) {
      this.currentSlide--;
      this.offset = this.offset + this.widthOfSlide + 10;
    }
  }

  goToSlide(index: number): void {
    if (index + 1 === this.currentSlide) {
      return;
    }
    this.currentSlide = index + 1;
    this.offset = -index * this.widthOfSlide - 10;
  }

  resized(): void {
    this.transitionPaused = true;
    this.widthOfSlide = this.contentWrapper.nativeElement.parentElement.clientWidth;
    this.offset = -(this.currentSlide - 1) * this.widthOfSlide;
    setTimeout(() => {
      this.transitionPaused = false;
    }, 500);
  }
}
