import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Person } from '../../store/models/person.model';
import { SharedUrlsService } from '../shared-urls';

@Injectable({ providedIn: 'root' })
export class PersonService {
  constructor(
    private httpClient: HttpClient,
    private sharedUrls: SharedUrlsService) {}

  getPeopleByLocationId$(): Observable<Person[]> {
    return this.sharedUrls
    .locationUrl$()
    .pipe(switchMap(locationUrl => this.httpClient.get<Person[]>(`${locationUrl}/Persons`)));
  }

  getPersonById$(personId: string): Observable<Person> {
    return this.sharedUrls
    .locationUrl$()
    .pipe(switchMap(locationUrl => this.httpClient.get<Person>(`${locationUrl}/Persons/${personId}`)));
  }
}
