<div class="bubble">
  <h4>{{ 'personalizeNetwork.title' | translate }}</h4>
  <div class="content">
    <div class="sign">
      <div class="inner">
        <div class="icon" icon="wifi"></div>
      </div>
    </div>
    <div class="setup">
      <p>{{ 'personalizeNetwork.setup' | translate }}</p>
      <button>{{ 'personalizeNetwork.setupButton' | translate }}</button>
    </div>
  </div>
</div>
