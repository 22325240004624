import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'plm-pod-why-offline',
  templateUrl: './pod-why-offline.component.html',
  styleUrls: ['./pod-why-offline.component.scss']
})
export class PodWhyOfflineComponent {
  urls = {
    support: 'https://www.plume.com/homepass/contact',
    tooFar: 'https://support.plume.com/hc/en-us/articles/360005084254',
    learnMore: 'https://support.plume.com/hc/en-us/articles/218323157-Why-are-my-pods-offline-'
  };

  constructor(translate: TranslateService) {
    if (translate.currentLang?.startsWith('de')) {
      this.urls = {
        learnMore: 'https://support.plume.com/hc/de/articles/218323157',
        support: 'https://www.plume.com/gb/homepass/contact',
        tooFar: 'https://support.plume.com/hc/de/articles/360005084254'
      };
    } else if (translate.currentLang?.startsWith('fr')) {
      this.urls = {
        learnMore: 'https://support.plume.com/hc/fr/articles/218323157',
        support: 'https://www.plume.com/be/homepass/contact',
        tooFar: 'https://support.plume.com/hc/fr/articles/360005084254'
      };
    } else if (translate.currentLang?.toLowerCase().startsWith('en-gb')) {
      this.urls = {
        learnMore: 'https://support.plume.com/hc/en-gb/articles/218323157',
        support: 'https://www.plume.com/gb/homepass/contact',
        tooFar: 'https://support.plume.com/hc/en-gb/articles/360005084254'
      };
    }
  }
}
