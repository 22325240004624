import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ValidationService } from 'src/app/lib/services/validation/validation.service';
import { DhcpReservation, PortForwards } from 'src/app/lib/store/models/dhcp-reservation.model';
import { AlertMsg, AlertType } from '../alert-pop-bar/alert-pop.interface';

@Component({
  selector: 'plm-ip-reservation-edit',
  templateUrl: './ip-reservation-edit.component.html',
  styleUrls: ['./ip-reservation-edit.component.scss']
})
export class IpReservationEditComponent {
  @Input()
  public set data(value: DhcpReservation) {
    this.valData = { ...value };
    this.pureValData = value;
  }

  @Input() saving = false;
  @Input() manualMode = false;
  @Input() editMode = false;
  @Input() closeNewPort$: Observable<string>;
  @Input() ports: PortForwards[] = [];
  @Input() portSaving: { [key: string]: true } = {}; // [external port list]: true
  @Input() newPortSaving = false;
  @Output() newPortOpenedChanged = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<DhcpReservation>();
  @Output() delete = new EventEmitter<void>();
  @Output() saveNewPort = new EventEmitter<PortForwards>();
  @Output() saveEditPort = new EventEmitter<PortForwards>();
  @Output() deletePort = new EventEmitter<number>();
  cancelObservable$ = this.cancel.asObservable();
  alert$ = new Subject<AlertMsg>();
  valData: DhcpReservation = { ip: '', mac: '', name: '', portForwards: [] };
  pureValData: DhcpReservation = { ip: '', mac: '', name: '', portForwards: [] };
  constructor(private validationService: ValidationService) {}

  doSave(): void {
    if (!this.validationService.ipAddress(this.valData.ip)) {
      this.alert$.next({ type: AlertType.fail, message: 'portForwarding.ipAddressInvalid', needsTranslation: true });
      return;
    }
    if (!this.manualMode) {
      this.save.emit({ ip: this.valData.ip, mac: this.valData.mac, name: this.valData.name });
      return;
    }
    if (!this.validationService.macAddress(this.valData.mac)) {
      this.alert$.next({ type: AlertType.fail, message: 'portForwarding.macAddressInvalid', needsTranslation: true });
      return;
    }
    if (!this.validationService.noWhiteSpace(this.valData.name) || this.valData.name.length < 2) {
      this.alert$.next({
        type: AlertType.fail,
        message: 'portForwarding.hostnameNoWhiteSpace',
        needsTranslation: true
      });
      return;
    }
    this.save.emit({ ip: this.valData.ip, mac: this.valData.mac, hostname: this.valData.name });
  }

  doCancel(): void {
    this.valData = { ...this.pureValData };
    this.cancel.emit();
  }
}
