import { ChangeDetectionStrategy, EventEmitter, ViewChild } from '@angular/core';
import { Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Device } from 'src/app/lib/store/models/device.model';
import { FormInputBaseField, FormInputType } from '../ui/form-input/form-input-field.interface';
import { AiSecurityHourlyEventCount } from 'src/app/lib/services/ai-security/ai-security.service';
import { DayVisualElement } from 'src/app/lib/d3/models/charts/security.chart';
import { HttpState, LoadingState } from 'src/app/lib/store/interfaces/http-state.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TileTypes } from '../guard-tile/guard-tile.component';
import { Store } from '@ngrx/store';
import { IPlumeState } from 'src/app/lib/store';
import { setDay, setDevice, setEventType } from 'src/app/lib/store/actions/local-state/ai-security.actions';
import {
  EventType,
  SelectedDay,
  SelectedDeviceMac
} from 'src/app/lib/store/selectors/local-state/ai-security.selectors';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { macQueryDataSame } from 'src/app/lib/store/selectors/insights.selectors';
import { ApproveBlockComponent } from '../approve-block/approve-block.component';
import { SecurityAccess } from 'src/app/lib/enums/plume.enums';
import { OverlayButtonComponent } from '../ui/overlay-button/overlay-button.component';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'plm-ai-security',
  templateUrl: './ai-security.component.html',
  styleUrls: ['./ai-security.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiSecurityComponent {
  @Input() devices: Device[];
  securityAccess: typeof SecurityAccess;
  @Input() set securityDailyCounts(data: HttpState<AiSecurityHourlyEventCount[]>) {
    this.securityDailyCounts$.next(data);
  }
  @Input() backButton = false;
  @Output() device = new EventEmitter<Device>();
  @Output() reloadSecurity = new EventEmitter<void>();
  @Output() approveDialogOpened = new EventEmitter<boolean>();

  @ViewChild(OverlayButtonComponent) overlayButton: OverlayButtonComponent;

  securityDailyCounts$ = new BehaviorSubject<HttpState<AiSecurityHourlyEventCount[]>>(null);
  selectedDay$ = this.store$.select(SelectedDay);
  activeTile$ = this.store$.select(EventType);
  selectedDevice$ = this.store$
    .select(SelectedDeviceMac)
    .pipe(map(mac => this.devices?.find(device => device.mac === mac)));
  tileCounts$ = combineLatest([this.selectedDay$, this.securityDailyCounts$]).pipe(map(x => this.filterDailyEvents(x)));
  chartData$ = combineLatest([this.activeTile$, this.securityDailyCounts$]).pipe(map(x => this.filterChartData(x)));
  error$ = this.securityDailyCounts$.pipe(map(data => !!(data.state as { error: unknown })?.error));
  loading$ = this.securityDailyCounts$.pipe(
    withLatestFrom(this.store$.select(macQueryDataSame)),
    map(([data, sameMac]) => (data.state === LoadingState.loading && !data.data) || !sameMac)
  );

  searchTerm = '';
  scrollBarConfig = {
    maxScrollbarLength: 125
  };
  lookUpOptions: FormInputBaseField = {
    name: '',
    placeholder: '',
    type: FormInputType.SEARCH,
    control: new FormControl()
  };

  constructor(
    translate: TranslateService,
    private dialog: DialogService,
    private store$: Store<IPlumeState>,
    private router: Router
  ) {
    translate
      .get('aisecurity.searchDevice')
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => {
        this.lookUpOptions.name = res;
        this.lookUpOptions.placeholder = res;
      });

    this.securityAccess = SecurityAccess;
  }

  public removeSelectedDevice(): void {
    this.store$.dispatch(setDevice(null));
    this.device.emit(null);
  }

  public filterDevices(term: string): void {
    this.searchTerm = term;
  }

  public onDeviceSelected(device: Device): void {
    this.store$.dispatch(setDevice({ deviceMac: device.mac }));
    this.device.emit(device);
  }

  public onDaySelected(day: DayVisualElement): void {
    this.store$.dispatch(setDay({ day }));
  }

  public removeSelectedDay(): void {
    this.store$.dispatch(setDay(null));
  }

  public openSettings(): void {
    void this.router.navigate(['/settings/guard']);
  }

  openApproveBlock(mode: SecurityAccess): void {
    this.overlayButton.close();
    this.approveDialogOpened.emit(true);
    this.dialog
      .openDialog$(ApproveBlockComponent, { mode: mode })
      .pipe(take(1))
      .subscribe(() => {
        this.reloadSecurity.emit();
        this.approveDialogOpened.emit(false);
      });
  }

  tileClicked(tile: TileTypes): void {
    this.store$.dispatch(setEventType({ eventType: tile }));
  }

  private filterChartData(data: [TileTypes, HttpState<AiSecurityHourlyEventCount[]>]): AiSecurityHourlyEventCount[] {
    const [eventType, counts] = data;
    if (eventType === 'all') {
      return counts.data;
    } else {
      return counts.data.filter(event => event.type === eventType);
    }
  }

  private filterDailyEvents(
    data: [DayVisualElement, HttpState<AiSecurityHourlyEventCount[]>]
  ): HttpState<AiSecurityHourlyEventCount[]> {
    const [day, hourlyCounts] = data;
    if (day) {
      return {
        ...hourlyCounts,
        data: hourlyCounts.data.filter(dailyCount => dailyCount.day === day.dayIndex)
      };
    } else {
      return { ...hourlyCounts };
    }
  }
}
