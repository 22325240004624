import { Component, Input } from '@angular/core';

@Component({
  selector: 'plm-consumption-circle',
  templateUrl: './consumption-circle.component.svg',
  styleUrls: ['./consumption-circle.component.scss']
})
export class ConsumptionCircleComponent {
  @Input() start = 0;
  @Input() value = 0;

  polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ): {
    x: number;
    y: number;
  } {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

    return d;
  }
}
