import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertType } from 'src/app/components/alert-pop-bar/alert-pop.interface';
import { AccessZoneWifi, AccessZoneWifiType, WifiNetwork, WifiNetworkKeys } from 'src/app/lib/rest-types';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { IPlumeState } from 'src/app/lib/store';
import { SecurityPolicyActions } from 'src/app/lib/store/actions/security-policy.actions';
import {
  addNewWifiPassword,
  addNewWifiPasswordError,
  addNewWifiPasswordSuccess,
  addNewWifiPasswordWithAccessZone,
  editWifiAccessZone,
  editWifiAccessZoneError,
  editWifiAccessZoneSuccess,
  editWifiPassword,
  editWifiPasswordError,
  editWifiPasswordSuccess
} from 'src/app/lib/store/actions/settings.actions';
import { selectDevices, selectPeople } from 'src/app/lib/store/selectors/home.selectors';
import { wifiNetwork } from 'src/app/lib/store/selectors/insights.selectors';

type ExpiresAtValue = 'never' | '1hour' | '3hour' | 'dayEnd' | 'custom';

@UntilDestroy()
@Component({
  selector: 'plm-access-edit-zone-and-key',
  templateUrl: './access-edit-zone-and-key.component.html',
  styleUrls: ['./access-edit-zone-and-key.component.scss']
})
export class AccessEditZoneAndKeyComponent {
  saving = false;
  editMode: 'NEW' | 'PASSWORD' | 'ACCESS_ZONE' = 'NEW';
  editKey: WifiNetworkKeys;
  editAccessZone: AccessZoneWifi;
  alert$ = new Subject<{ type: AlertType; message: string }>();
  routeDataWithWifi$ = combineLatest([
    this.route.queryParams,
    this.route.data,
    this.store$.pipe(
      select(wifiNetwork),
      map(wifi => wifi?.data)
    )
  ]);
  homeKeysIds$ = this.store$.pipe(
    select(wifiNetwork),
    map(wifi => wifi?.data?.keys || []),
    map(keys => keys.filter(key => key.accessZone === 'home')),
    map(keys => keys.map(key => key.id))
  );

  homeDevices$ = combineLatest([this.homeKeysIds$, this.store$.select(selectDevices)]).pipe(
    map(([homeKeysIds, devices]) => devices.filter(device => homeKeysIds.includes(device.keyId)))
  );
  people$ = this.store$.select(selectPeople);
  type: AccessZoneWifiType;
  expiresAt = {
    visible: false,
    values: ['never', '1hour', '3hour', 'dayEnd', 'custom'] as ExpiresAtValue[],
    selectedValue: 'never' as ExpiresAtValue,
    customDate: moment(),
    customDateValid: true
  };
  sharedHomeDevices = {
    visible: false,
    opened: false,
    selectedMacs: [] as string[]
  };
  guestName = {
    visible: false,
    value: ''
  };
  password = {
    visible: true,
    value: ''
  };

  constructor(
    private store$: Store<IPlumeState>,
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private plumeService: PlumeService
  ) {
    store$.dispatch(SecurityPolicyActions.loadWifiNetwork());
    this.routeDataWithWifi$.pipe(untilDestroyed(this)).subscribe(([params, routeData, wifi]) => {
      this.type = routeData.type as AccessZoneWifiType;
      this.editMode = routeData.accessZoneOnly ? 'ACCESS_ZONE' : routeData.edit ? 'PASSWORD' : 'NEW';
      this.showPasswordFieldsByType();
      if (this.editMode === 'PASSWORD') {
        this.initEditPasswordValues(wifi, params.id);
      }
      if (this.editMode === 'ACCESS_ZONE') {
        this.initEditAccessZone(wifi, params.id);
      }
    });
    this.startComponentEffects();
  }

  save(): void {
    if (this.editMode !== 'ACCESS_ZONE' && !this.validate()) {
      return;
    }
    this.saving = true;
    if (this.editMode === 'PASSWORD') {
      this.saveEditPassword();
    } else if (this.editMode === 'ACCESS_ZONE') {
      this.saveAccessZone();
    } else {
      this.saveNewPassword();
    }
  }

  selectExpiredValue(value: ExpiresAtValue): void {
    if (value === 'custom') {
      this.expiresAt.customDate = moment();
      this.expiresAt.customDateValid = true;
    }
    this.expiresAt.selectedValue = value;
  }

  private saveNewPassword(): void {
    if (this.type === 'guests') {
      this.store$.dispatch(
        addNewWifiPasswordWithAccessZone({
          accessZone: this.type,
          password: this.password.value,
          expiresAt: this.getExpiredDateTime(),
          guestName: this.guestName.value,
          accessibleDevices: this.sharedHomeDevices.selectedMacs
        })
      );
    } else {
      this.store$.dispatch(
        addNewWifiPassword({
          accessZone: this.type,
          password: this.password.value,
          expiresAt: this.getExpiredDateTime()
        })
      );
    }
  }

  private saveEditPassword(): void {
    this.store$.dispatch(
      editWifiPassword({
        key: {
          ...this.editKey,
          encryptionKey: this.password.value,
          expiresAt: this.getExpiredDateTime()
        }
      })
    );
  }

  private saveAccessZone(): void {
    this.store$.dispatch(
      editWifiAccessZone({
        accessZone: {
          ...this.editAccessZone,
          description: this.guestName.value,
          accessibleDevices: this.sharedHomeDevices.selectedMacs
        }
      })
    );
  }

  private initEditPasswordValues(wifi: WifiNetwork, id: string): void {
    this.editKey = wifi?.keys?.find(loopKey => loopKey.id === +id);
    if (!this.editKey) {
      return;
    }
    this.password.value = this.editKey.encryptionKey;
    if (this.editKey.expiresAt) {
      this.expiresAt.selectedValue = 'custom';
      this.expiresAt.customDate = moment(this.editKey.expiresAt);
    }
  }

  private initEditAccessZone(wifi: WifiNetwork, id: string): void {
    this.editAccessZone = wifi?.accessZones?.find(loopZone => loopZone.id === +id);
    if (!this.editAccessZone) {
      return;
    }
    this.guestName.value = this.editAccessZone.description;
    this.sharedHomeDevices.selectedMacs = this.editAccessZone.accessibleDevices;
    this.sharedHomeDevices.opened = true;
  }

  private showPasswordFieldsByType(): void {
    if (this.type !== 'home' && this.editMode !== 'ACCESS_ZONE') {
      this.expiresAt.visible = true;
    }
    if (this.type === 'guests' && this.editMode === 'NEW') {
      this.sharedHomeDevices.visible = true;
      this.guestName.visible = true;
    }
    if (this.editMode === 'ACCESS_ZONE') {
      this.password.visible = false;
      this.sharedHomeDevices.visible = true;
      this.guestName.visible = true;
    }
  }

  private getExpiredDateTime(): string {
    if (!this.expiresAt.visible) {
      return undefined;
    }
    switch (this.expiresAt.selectedValue) {
      case 'never':
        return undefined;
      case '1hour':
        return moment()
          .add(1, 'hours')
          .toISOString();
      case '3hour':
        return moment()
          .add(3, 'hours')
          .toISOString();
      case 'dayEnd':
        return moment()
          .endOf('day')
          .toISOString();
      case 'custom':
        return this.expiresAt.customDate.toISOString();
    }
  }

  private validate(): boolean {
    if (this.expiresAt.visible && this.expiresAt.selectedValue === 'custom' && !this.expiresAt.customDateValid) {
      this.alert$.next({
        type: AlertType.fail,
        message: this.translate.instant('settings.access.editPassword.customDateNotValid') as string
      });
      return false;
    }
    if (!this.plumeService.isASCII(this.password.value)) {
      this.alert$.next({
        type: AlertType.fail,
        message: this.translate.instant('settings.access.editPassword.asciiOnly') as string
      });
      return;
    }
    if (this.password.value.length < 8) {
      this.alert$.next({
        type: AlertType.fail,
        message: this.translate.instant('settings.access.editPassword.min8chars') as string
      });
      return;
    }
    return true;
  }

  private startComponentEffects() {
    this.actions$
      .pipe(ofType(addNewWifiPasswordSuccess, editWifiPasswordSuccess, editWifiAccessZoneSuccess), untilDestroyed(this))
      .subscribe(() => {
        void this.router.navigate(['..'], { relativeTo: this.route, queryParams: { tab: this.type } });
      });

    this.actions$
      .pipe(ofType(addNewWifiPasswordError, editWifiPasswordError, editWifiAccessZoneError), untilDestroyed(this))
      .subscribe(error => {
        this.alert$.next({
          type: AlertType.fail,
          message: (error?.error?.error as { error: { message: string } })?.error?.message
        });
        this.saving = false;
      });
  }
}
