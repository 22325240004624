import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BoxComponent } from './box.component';
import { BoxFooterComponent } from './box-footer/box-footer.component';
import { IconModule } from '../../../../app/lib/directives/icon/icon.module';

@NgModule({
  imports: [CommonModule, IconModule],
  exports: [BoxComponent, BoxFooterComponent],
  declarations: [BoxComponent, BoxFooterComponent],
  providers: []
})
export class BoxModule {}
