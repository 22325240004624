import { IPlumeState } from '..';
import { DhcpReservation } from '../models/dhcp-reservation.model';
import { Dhcp } from '../models/dhcp.model';
import { DnsServers } from '../models/dns.model';
import { Upnp } from '../models/upnp.model';

export const ssidSaving = (state: IPlumeState): boolean =>
  !!state.settingsState.locationIdSsidSaving.find(locationId => locationId === state.plumeState.currentLocationId);

export const upnp = (state: IPlumeState): Upnp => state.settingsState.upnp;
export const dhcpReservations = (state: IPlumeState): DhcpReservation[] => state.settingsState.dhcpReservations;

export const dnsServers = (state: IPlumeState): DnsServers => state.settingsState.dnsServers;

export const dhcp = (state: IPlumeState): Dhcp => state.settingsState.dhcp;
