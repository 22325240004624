import { Component, Inject } from '@angular/core';
import { PlmDialogInject, PlmDialogToken } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  selector: 'plm-save-dialog',
  templateUrl: './save-ssid-dialog.component.html',
  styleUrls: ['./save-ssid-dialog.component.scss']
})
export class SaveSsidDialogComponent {
  constructor(@Inject(PlmDialogToken) public dialog: PlmDialogInject<void, boolean>) {}
  get transPth(): string {
    return 'settings.adaptView.saveDialog.';
  }
}
