import { Component, ContentChildren, QueryList } from '@angular/core';

import { GridItemComponent } from './grid-item/grid-item.component';

/**
 * Use `Grid` to layout content in a responsive grid.
 */
@Component({
  selector: 'plm-grid',
  styleUrls: ['./grid.component.scss'],
  template: '<ng-content></ng-content>'
})
export class GridComponent {
  /**
   * GridItems to display in the Grid.
   */
  @ContentChildren(GridItemComponent) items: QueryList<GridItemComponent>;
}
