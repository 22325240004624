<div class="search">
  <div class="icon" icon="search"></div>
  <input
    [placeholder]="'settings.access.placeholder' | translate"
    [(ngModel)]="filterText"
    (ngModelChange)="filter()"
  />
</div>
<ng-container *ngFor="let group of groupedDevices | keyvalue | sort: 'value.personName'; trackBy: groupTrackBy">
  <div *ngIf="group.value.devices.length > 0" class="deviceGroup">
    <div class="groupName">{{ group.value.personName || 'settings.access.deviceListNoPersonIn' | translate }}</div>
    <div class="devices">
      <div class="device" *ngFor="let device of group.value.devices | sort: 'deviceName'; trackBy: deviceTrackBy">
        <div [icon]="device.personId ? 'person' : 'home-circle'" class="icon"></div>
        <div class="deviceIcon" [icon]="device.iconV2" [device]="true" height="64" width="64"></div>
        <div class="deviceName">{{ device.deviceName }}</div>
        <div
          class="icon check"
          [class.selected]="device.selected"
          icon="check-circle"
          (click)="toggleDeviceFlag(device.mac)"
        ></div>
      </div>
    </div>
  </div>
</ng-container>
