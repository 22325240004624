import { Injectable } from '@angular/core';
import { SpeedForChart } from 'src/app/lib/rest-types';
import { AxisSize, Margins, UnitSize } from '../interface';

interface ChartData {
  x: number;
  y: number;
}

@Injectable()
export class CalculationsService {
  private privateWidth = 0;
  private privateHeight = 0;
  private privateChartData: ChartData[];
  private privateMinY = 0;
  private privateMaxY = 1;
  private privateAxisSizeInUnits: AxisSize = { maxX: 0, maxY: 0 };
  private privateMargins: Margins = { top: 0, right: 0, bottom: 0, left: 0 };
  private privateUnitSize: UnitSize = { height: 0, width: 0 };

  public get width(): number {
    return this.privateWidth;
  }

  public get height(): number {
    return this.privateHeight;
  }

  public get chartData(): ChartData[] {
    return this.privateChartData;
  }

  public get minY(): number {
    return this.privateMinY;
  }

  public get maxY(): number {
    return this.privateMaxY;
  }

  public get axisSizeInUnits(): AxisSize {
    return this.privateAxisSizeInUnits;
  }

  public get margins(): Margins {
    return this.privateMargins;
  }

  public get unitSize(): UnitSize {
    return this.privateUnitSize;
  }

  public get drawWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  public get drawHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  public get gap(): number {
    return this.unitSize.width / 2;
  }

  public get hoverCircleRadius(): number {
    return (5 / 8) * this.unitSize.width;
  }

  public get columnDrawingWith(): number {
    return this.unitSize.width - this.gap;
  }

  recalculate(width: number, height: number, chartData: ChartData[]): void {
    width = width || this.width;
    height = height || this.height;
    this.privateWidth = width;
    this.privateHeight = height;
    this.privateChartData = chartData;
    this.calcMinMaxY();
    this.calcAxisSizeInUnits();

    if (width < 1 || height < 1) {
      return;
    }
    this.calcMargins();
    this.calcUnitSize();
  }

  public columnXCenter(index: number): number {
    return this.columnXStart(index) + this.unitSize.width / 2;
  }

  public columnXStart(index: number): number {
    return this.margins.left + index * this.unitSize.width;
  }

  public columnXDrawStart(index: number): number {
    return this.columnXStart(index) + this.gap / 2;
  }

  public columnValueY(index: number): number {
    return this.columnY(this.chartData[index].y);
  }

  public columnY(value: number): number {
    return this.margins.top + this.unitSize.height * (this.axisSizeInUnits.maxY - value);
  }

  public columnHeight(value: number): number {
    return this.unitSize.height * value;
  }

  public emptyDayChart(): SpeedForChart[] {
    return Array.from({ length: 24 }, (v, i) => i).map(index => ({
      timestamp: (index - 1) * 60 * 60 * 1000,
      download: undefined,
      upload: undefined
    }));
  }

  public emptyWeekChart(): SpeedForChart[] {
    return Array.from({ length: 21 }, (v, i) => i).map(index => ({
      timestamp: index * 8 * 60 * 60 * 1000,
      download: undefined,
      upload: undefined
    }));
  }

  public emptyMonthChart(): SpeedForChart[] {
    return Array.from({ length: 30 }, (v, i) => i).map(index => ({
      timestamp: index * 24 * 60 * 60 * 1000,
      download: undefined,
      upload: undefined
    }));
  }

  private calcMinMaxY(): void {
    this.privateMaxY = this.chartData
      .filter(sample => sample.y !== undefined && sample.y !== null)
      .reduce((a, b) => Math.max(a, b.y), 0);

    this.privateMinY = this.chartData
      .filter(sample => sample.y !== undefined && sample.y !== null)
      .reduce((a, b) => Math.min(a, b.y), Number.MAX_SAFE_INTEGER);
    if (this.maxY === 0) {
      this.privateMaxY = -1;
    }
    if (this.minY === Number.MAX_SAFE_INTEGER) {
      this.privateMinY = -1;
    }
  }

  private calcAxisSizeInUnits(): void {
    this.privateAxisSizeInUnits = { maxX: this.chartData.length, maxY: this.maxY };
  }

  private calcMargins(): void {
    this.privateMargins = {
      top: 50,
      right: this.width / (this.axisSizeInUnits.maxX + 1) / 2,
      bottom: 28,
      left: this.width / (this.axisSizeInUnits.maxX + 1) / 2
    };
  }

  private calcUnitSize(): void {
    this.privateUnitSize = {
      width: (this.width - this.margins.left - this.margins.right) / this.axisSizeInUnits.maxX,
      height: (this.height - this.margins.top - this.margins.bottom) / this.axisSizeInUnits.maxY
    };
  }
}
