/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, from, of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { BrazeService } from '../../services/braze.service';
import {
  changeCurrentLocationId,
  customerLoaded,
  getLocations,
  getLocationsFail,
  getLocationsSuccess,
  initBraze,
  locationNameLoaded,
  loginUser,
  setCurrentLocationId,
  setLocationName
} from '../actions/plume.actions';
import { IPlumeState } from '..';
import { cloudId, currentLocation, currentLocationId, customer } from '../selectors/plume.selectors';
import * as momentTz from 'moment-timezone';
import { PubnubService } from '../../services/pubnub/pubnub.service';
import { loadHome } from '../actions/home.actions';
import { AppFacadeService } from '../../services/app-facade/app-facade.service';

@Injectable()
export class PlumeEffects {
  locationSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentLocationId),
      switchMapTo(this.store$.select(currentLocation).pipe(take(1))),
      tap((location) => {
        this.braze.setUser(location.id);
        momentTz.tz.setDefault(location.geoIp?.timezone);
        this.pubnub.init();
      }),
      switchMap(() => [loadHome(), getLocations()])
    )
  );

  locationChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeCurrentLocationId),
      switchMap((action) => {
        const currentUrl = this.router.url;
        return forkJoin([
          of(action.newLocationId),
          from(
            this.router
              .navigateByUrl('/empty', { skipLocationChange: true })
              .then(() => this.router.navigateByUrl(currentUrl, { skipLocationChange: true }))
          )
        ]);
      }),
      map(([currentLocationId]) => setCurrentLocationId({ currentLocationId }))
    )
  );

  setCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginUser),
      switchMap(({ customerId, cloudId }) =>
        this.auth
          .getCustomer$(customerId)
          .pipe(
            switchMap((customer) =>
              forkJoin([
                of(customer),
                this.auth.getLocation$(customerId),
                this.auth.configuration$(cloudId, customer.partnerId)
              ])
            )
          )
      ),
      map(([customer, locations, configuration]) => customerLoaded({ customer, locations, configuration }))
    )
  );

  loginDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerLoaded),
      tap(() => void this.router.navigate([''])),
      map((data) => setCurrentLocationId({ currentLocationId: data.locations.length > 0 ? data.locations[0].id : '' }))
    )
  );

  setLocationName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setLocationName),
      switchMap(({ locationId, newName }) => this.facade.setLocationName$(locationId, newName)),
      map((location) => locationNameLoaded({ location }))
    )
  );

  getLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLocations),
      withLatestFrom(this.store$.select(customer)),
      switchMap(([, customer]) =>
        this.auth.getLocation$(customer.id).pipe(
          map((locations) => getLocationsSuccess({ locations })),
          catchError((error) => of(getLocationsFail(error)))
        )
      )
    )
  );

  initBraze$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initBraze),
        withLatestFrom(this.store$.select(cloudId)),
        withLatestFrom(this.store$.select(currentLocationId)),
        map(([[, cloudId], currentLocationId]) => ({ cloudId: cloudId, currentLocationId: currentLocationId })),
        tap(({ cloudId, currentLocationId }) => {
          this.braze.init(cloudId, currentLocationId);
        })
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private braze: BrazeService,
    private actions$: Actions,
    private auth: AuthService,
    private router: Router,
    private store$: Store<IPlumeState>,
    private pubnub: PubnubService,
    private facade: AppFacadeService
  ) {}
}
