<div class="inputBox" [plmFocusOnClick]="renameInput" [class.error]="inputModel.length === 0">
  <input [(ngModel)]="inputModel" #renameInput maxlength="32" />
  <div class="icon" icon="close-small-ligher-color" (click)="clearModel()"></div>
</div>
<div class="errorMsg" *ngIf="inputModel.length === 0">{{ 'renamePod.errorEmpty' | translate }}</div>
<div class="tip">{{ 'renamePod.tip' | translate }}</div>
<div class="buttons">
  <div class="icon close" icon="close-medium" (click)="close()"></div>
  <div class="separator"></div>
  <div class="icon" icon="check-green" (click)="save()"></div>
</div>
