import { Input, HostBinding } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'plm-box',
  templateUrl: 'box.component.html',
  styleUrls: ['box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxComponent {
  @Input() minHeight: number;
  @Input() rounded = true;
  @Input() size = 's';
  @Input() padding = true;

  @HostBinding('class.no-padding')
  public get noPadding(): boolean {
    return !this.padding;
  }

  @HostBinding('style.padding.px')
  get paddingSize(): number {
    return this.size === 's' ? 20 : 24;
  }

  @HostBinding('style.height.px')
  get height(): number {
    return this.minHeight;
  }
}
