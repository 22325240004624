<div class="row">
  <div>{{ 'portForwarding.nickName' | translate }}</div>
  <plm-input-field>
    <input [disabled]="!manualMode || editMode" [(ngModel)]="valData.name" maxlength="63"
  /></plm-input-field>
</div>
<div class="row">
  <div>{{ 'portForwarding.ipAddress' | translate }}</div>
  <plm-input-field><input [(ngModel)]="valData.ip" /></plm-input-field>
</div>
<div class="row">
  <div>{{ 'portForwarding.macAddress' | translate }}</div>
  <plm-input-field><input [disabled]="!manualMode || editMode" [(ngModel)]="valData.mac" /></plm-input-field>
</div>

<plm-ip-reservation-port-list
  *ngIf="editMode"
  [closeNewPort$]="closeNewPort$"
  [portSaving]="portSaving"
  [newPortSaving]="newPortSaving"
  [ports]="valData.portForwards"
  [mac]="valData.mac"
  [cancelEdit$]="cancelObservable$"
  (saveNewPort)="saveNewPort.emit($event)"
  (saveEditPort)="saveEditPort.emit($event)"
  (deletePort)="deletePort.emit($event)"
></plm-ip-reservation-port-list>
<div class="buttonList">
  <button [disabled]="saving" class="flat alert delete" (click)="this.delete.emit()" *ngIf="editMode">
    {{ 'portForwarding.deleteReservation' | translate }}
  </button>
  <button [disabled]="saving" class="flat" (click)="doCancel()">{{ 'buttons.cancel' | translate }}</button>
  <button [disabled]="saving" class="flat highlight" (click)="doSave()">{{ 'buttons.save' | translate }}</button>
</div>
<plm-alert-pop-bar [alert$]="alert$"></plm-alert-pop-bar>
