<plm-carousel>
  <div class="carousel-item">
    <plm-box [minHeight]="252">
      <plm-centralize-pods></plm-centralize-pods>
    </plm-box>
  </div>
  <div class="carousel-item">
    <plm-box [minHeight]="252">
      <plm-obstructions></plm-obstructions>
    </plm-box>
  </div>
  <div class="carousel-item">
    <plm-box [minHeight]="252">
      <plm-metals></plm-metals>
    </plm-box>
  </div>
  <div class="carousel-item">
    <plm-box [minHeight]="252">
      <plm-plugs></plm-plugs>
    </plm-box>
  </div>
  <div class="carousel-item">
    <plm-box [minHeight]="252">
      <plm-assistance></plm-assistance>
    </plm-box>
  </div>
</plm-carousel>
