<div class="networking-mode">
  <div class="networking-mode__title">
    {{ mode === 'auto' ? ('networkingMode.titleAuto' | translate) : ('networkingMode.titleRouter' | translate) }}
  </div>
  <div class="networking-mode__content">
    <p>
      {{ mode === 'auto' ? ('networkingMode.auto' | translate) : ('networkingMode.router' | translate) }}
    </p>
    <p>
      {{ 'networkingMode.warningMessageLine1' | translate }} <br />
      {{ 'networkingMode.warningMessageLine2' | translate }}
    </p>
  </div>
  <div class="bottom-buttons">
    <button (click)="data.close(false)">{{ 'buttons.cancel' | translate }}</button>
    <button class="networking-mode__button-ok" (click)="data.close(true)">{{ 'buttons.ok' | translate }}</button>
  </div>
</div>
