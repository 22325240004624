import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { SpeedForChart } from 'src/app/lib/rest-types';
import { CalculationsService } from '../calculations/calculations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[plm-x-axis-days]',
  templateUrl: './x-axis-days.component.html',
  styleUrls: ['./x-axis-days.component.scss']
})
export class XAxisDaysComponent {
  @Input()
  public set chartData(value: SpeedForChart[]) {
    this.privateChartData = value;
    this.calcNewDayIndexes();
  }
  public get chartData(): SpeedForChart[] {
    return this.privateChartData;
  }

  newDayIndexes: number[] = [];

  private privateChartData: SpeedForChart[] = [];

  constructor(public calculations: CalculationsService) {}

  textCenter(newDayArrPosition: number): number {
    const dayEndPosition = this.calculations.columnXStart(this.newDayIndexes[newDayArrPosition]);
    const previousDayPosition =
      this.calculations.columnXStart(this.newDayIndexes[newDayArrPosition - 1]) || this.calculations.margins.left;
    return (dayEndPosition + previousDayPosition) / 2;
  }

  textCenterToday(): number {
    const todayStartPosition = this.calculations.columnXStart(this.newDayIndexes[this.newDayIndexes.length - 1]) || 0;
    const todayEndPosition = this.calculations.width - this.calculations.margins.right;
    return (todayStartPosition + todayEndPosition) / 2;
  }

  dayText(newDayArrPosition: number): string {
    return moment(this.chartData[newDayArrPosition].timestamp).format('ddd');
  }

  private calcNewDayIndexes(): void {
    const daysOfYear = this.chartData.map(sample => moment(sample.timestamp).dayOfYear());
    this.newDayIndexes = daysOfYear.reduce(
      (acc, currValue, index, array) => (array[index - 1] && array[index - 1] !== currValue ? [...acc, index] : acc),
      [] as number[]
    );
  }
}
