import { Component, Input } from '@angular/core';
import { AppFacadeDashboard } from 'src/app/lib/rest-types';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { Device } from 'src/app/lib/store/models/device.model';
import { SpeedLegendComponent } from './speed-legend/speed-legend.component';

@Component({
  selector: 'plm-speed-description',
  templateUrl: './speed-description.component.html',
  styleUrls: ['./speed-description.component.scss']
})
export class SpeedDescriptionComponent {
  @Input() dashboard: AppFacadeDashboard;
  @Input() devices: Device[] = [];
  @Input() ssid = '';
  @Input() loading = true;

  constructor(private dialog: DialogService) {}

  openSpeedLegend(): void {
    this.dialog.openDialog$(SpeedLegendComponent);
  }
}
