import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnComponent } from './column/column.component';
import { SpeedTestGraphComponent } from './speed-test-graph/speed-test-graph.component';
import { ToolTipComponent } from './tool-tip/tool-tip.component';
import { XAxisDaysComponent } from './x-axis-days/x-axis-days.component';
import { XAxisHoursComponent } from './x-axis-hours/x-axis-hours.component';
import { XAxisWeeksComponent } from './x-axis-weeks/x-axis-weeks.component';
import { XValueLineComponent } from './x-value-line/x-value-line.component';
import { PipesModule } from '../../../app/lib/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../../app/lib/directives/directives.module';

@NgModule({
  declarations: [
    ColumnComponent,
    SpeedTestGraphComponent,
    ToolTipComponent,
    XAxisDaysComponent,
    XAxisHoursComponent,
    XAxisWeeksComponent,
    XValueLineComponent
  ],
  exports: [
    ColumnComponent,
    SpeedTestGraphComponent,
    ToolTipComponent,
    XAxisDaysComponent,
    XAxisHoursComponent,
    XAxisWeeksComponent,
    XValueLineComponent
  ],
  imports: [CommonModule, PipesModule, TranslateModule, DirectivesModule]
})
export class ColumnGraphModule {}
