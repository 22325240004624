import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { TabLabelDirective } from '../tab-label/tab-label.directive';

@Component({
  selector: 'plm-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent {
  @Input() active = false;
  @ContentChild(TabLabelDirective, { read: TemplateRef }) label: TemplateRef<HTMLElement>;
}
