/* eslint-disable max-lines-per-function */
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AlertType } from 'src/app/components/alert-pop-bar/alert-pop.interface';
import { DeletePodDialogComponent } from 'src/app/components/delete-pod-dialog/delete-pod-dialog.component';
import { ConnectionState, PlumeNode } from 'src/app/lib/rest-types';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { IPlumeState } from 'src/app/lib/store';
import {
  deleteNode,
  deleteNodeDone,
  renameNode,
  renameNodeDone,
  renameNodeError,
  setNodeLedMode
} from 'src/app/lib/store/actions/home.actions';
import {
  deletingNodesId,
  homeLoading,
  nodes as selectNodes,
  selectDevices
} from 'src/app/lib/store/selectors/home.selectors';
import { currentLocationId } from 'src/app/lib/store/selectors/plume.selectors';
import { PodHealthDescComponent } from 'src/app/components/pod-health-desc/pod-health-desc.component';
import { AccordionItemComponent } from 'src/app/components/ui/accordion/accordion-item.component';
import { AccordionComponent } from 'src/app/components/ui/accordion/accordion.component';

@UntilDestroy()
@Component({
  templateUrl: './pods.component.html',
  styleUrls: ['./pods.component.scss']
})
export class PodsComponent implements OnDestroy, AfterViewInit {
  @ViewChild('accordion') accordion: AccordionComponent;
  @ViewChild('accordionItemHwInfo') accordionItemHwInfo: AccordionItemComponent;
  @ViewChild('accordionItemDevices') accordionItemDevices: AccordionItemComponent;
  alert$ = new Subject<{ type: AlertType; message: string }>();
  node$ = combineLatest([
    this.activatedRoute.params,
    this.store$.pipe(select(selectNodes)),
    this.store$.pipe(select(homeLoading))
  ]).pipe(
    map(([params, nodes, loading]) => ({ loading, node: nodes.find(node => node.id === params.id) })),
    tap(({ node, loading }) => {
      if (!loading && !node) {
        void this.router.navigate(['/insights']);
      }
    }),
    filter(({ loading }) => !loading),
    tap(({ node }) => {
      this.podHardwareInfo(node);
    }),
    map(({ node }) => node)
  );
  renameInProgress = false;
  deleteInProgress$ = this.store$.pipe(
    select(deletingNodesId),
    map(deletingNodes => deletingNodes.includes(this.activatedRoute.snapshot.params.id))
  );
  renamingPod = false;
  locationId = '';
  podBandwidth: string;
  channelNumber: number;
  parentNode: { id: string; nickname: string; connectionState?: ConnectionState; isGateway: boolean };
  isGateway: boolean;
  hardwareInfo: { [key: string]: string } = {};

  connectedDevices$ = combineLatest([this.activatedRoute.params, this.store$.pipe(select(selectDevices))]).pipe(
    map(([params, devices]) =>
      devices.filter(device => device.connectionState === 'connected' && device.leafToRoot[0].id === params.id)
    )
  );
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store$: Store<IPlumeState>,
    private dialog: DialogService,
    private actions$: Actions,
    private cd: ChangeDetectorRef
  ) {
    this.node$.pipe(untilDestroyed(this)).subscribe(node => {
      if (node) {
        this.setChannelData(node);
        this.getParentNode(node);
      }
    });

    this.startComponentEffects();
    this.store$.pipe(select(currentLocationId), take(1)).subscribe(locationId => {
      this.locationId = locationId;
    });
  }

  ngAfterViewInit(): void {
    this.activatedRoute.params
      .pipe(switchMap(() => this.node$.pipe(take(1))))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        // wait until ngIf is creates accordion item
        setTimeout(() => {
          if (this.accordionItemDevices && this.activatedRoute.snapshot.queryParams?.action !== 'viewInfo') {
            this.accordion.openItem(this.accordionItemDevices);
          }
        }, 0);
      });

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      switch (params?.action) {
        case 'delete':
          this.deletePod();
          break;
        case 'rename':
          this.renamePod();
          break;
        case 'viewInfo':
          this.accordion.openItem(this.accordionItemHwInfo);
          break;
      }
      this.cd.detectChanges();
    });
  }

  healthStatus(node: PlumeNode): 'excellent' | 'good' | 'fair' | 'poor' | 'offline' | 'calculating' {
    if (node?.connectionState === 'disconnected') {
      return 'offline';
    }
    return node?.health?.status;
  }

  setChannelData(node: PlumeNode): void {
    this.podBandwidth = '';
    this.channelNumber = null;
    this.isGateway = false;
    if (node?.backhaulType === 'wifi') {
      if (node?.backhaulChannel > 13) {
        this.podBandwidth = '5';
        this.channelNumber = node.backhaulChannel;
      } else {
        this.podBandwidth = '2.4';
        this.channelNumber = node.backhaulChannel;
      }
    } else {
      if (node?.connectionState === 'connected') {
        this.isGateway = true;
      }
    }
  }

  getParentNode(node: PlumeNode): void {
    this.parentNode = undefined;
    if (node?.leafToRoot) {
      this.parentNode = { ...node?.leafToRoot?.[0], isGateway: !node?.leafToRoot?.[1] };

      if (this.parentNode) {
        this.store$
          .select(selectNodes)
          .pipe(untilDestroyed(this))
          .subscribe(pods => {
            if (pods) {
              pods.forEach(pod => {
                if (pod?.id === this.parentNode.id) {
                  this.parentNode.connectionState = pod?.connectionState;
                }
              });
            }
          });
      }
    }
  }

  openSignalHealthDesc(): void {
    this.dialog.openDialog$(PodHealthDescComponent);
  }
  deletePod(): void {
    this.dialog
      .openDialog$(DeletePodDialogComponent)
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.store$.dispatch(deleteNode({ nodeId: this.activatedRoute.snapshot.params.id as string }));
        }
      });
  }

  renamePod(): void {
    this.node$
      .pipe(
        untilDestroyed(this),
        filter(node => !!node),
        take(1)
      )
      .subscribe(() => {
        this.renamingPod = true;
        this.store$.dispatch(
          setNodeLedMode({ mode: 'locate', nodeId: this.activatedRoute.snapshot.params.id as string })
        );
      });
  }

  cancelRenamePod(): void {
    this.renamingPod = false;
    this.store$.dispatch(setNodeLedMode({ mode: 'normal', nodeId: this.activatedRoute.snapshot.params.id as string }));
  }

  applyRenamePod(newName: string): void {
    this.renameInProgress = true;
    this.store$.dispatch(renameNode({ newName, nodeId: this.activatedRoute.snapshot.params.id as string }));
  }

  ngOnDestroy(): void {
    if (this.renamingPod) {
      this.store$.dispatch(
        setNodeLedMode({
          mode: 'normal',
          nodeId: this.activatedRoute.snapshot.params.id as string,
          locationId: this.locationId
        })
      );
    }
  }

  // eslint-disable-next-line max-lines-per-function
  private startComponentEffects(): void {
    this.actions$
      .pipe(
        ofType(deleteNodeDone),
        filter(({ nodeId, success }) => success && nodeId === this.activatedRoute.snapshot.params.id),
        untilDestroyed(this)
      )
      .subscribe(() => {
        void this.router.navigate(['/insights']);
      });

    this.actions$
      .pipe(
        ofType(renameNodeDone),
        filter(({ node }) => node.id === this.activatedRoute.snapshot.params.id),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.renameInProgress = false;
        this.renamingPod = false;
      });

    this.actions$
      .pipe(
        ofType(renameNodeError),
        filter(({ nodeId }) => nodeId === this.activatedRoute.snapshot.params.id),
        untilDestroyed(this)
      )
      .subscribe(error => {
        this.renameInProgress = false;
        this.alert$.next({ type: AlertType.fail, message: error.errorMessage });
      });
  }

  public podHardwareInfo(node: PlumeNode): void {
    this.hardwareInfo = {};
    if (node) {
      Object.assign(
        this.hardwareInfo,
        node.model ? { 'hardwareInfo.model': this.modelNameLookUp(node.model) } : null,
        node.id ? { 'hardwareInfo.serialNumber': node.id } : null,
        node.firmwareVersion ? { 'hardwareInfo.fwVersion': node.firmwareVersion } : null,
        node.mac ? { 'hardwareInfo.ethernetMac': node.mac } : null,
        node.ethernet1Mac ? { 'hardwareInfo.ethernet2Mac': node.ethernet1Mac } : null,
        node.radioMac24 ? { 'hardwareInfo.24ghzMac': node.radioMac24 } : null,
        node.radioMac50 ? { 'hardwareInfo.5ghzMac': node.radioMac50 } : null,
        node.radioMac50L ? { 'hardwareInfo.5ghzLowerMac': node.radioMac50L } : null,
        node.radioMac50U ? { 'hardwareInfo.5ghzUpperMac': node.radioMac50U } : null,
        node.ip ? { 'hardwareInfo.lanIpAddress': node.ip } : null,
        node.publicIp ? { 'hardwareInfo.publicIpAddress': node.publicIp } : null,
        node.id ? { 'hardwareInfo.serialNumber': node.id } : null
        // REMOVED UNTIL Mobile shows IPv6 Info
        // node.ipv6 && node.ipv6.length > 0 ? { 'hardwareInfo.iPv6Addresses': node.ipv6.join(', ') } : null
      );
    }
  }

  private modelNameLookUp(model: string): string {
    switch (model) {
      case 'PP302Z':
        return 'PowerPod';
      case 'PP203X' || 'PP213X':
        return 'SuperPod';
      case 'PP403Z':
        return 'SuperPod AX';
      case 'Plume Pod v1.0':
        return 'Pod';
      default:
        return undefined;
    }
  }
}
