import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GridItemComponent } from './grid-item/grid-item.component';
import { GridComponent } from './grid.component';

@NgModule({
  declarations: [GridComponent, GridItemComponent],
  exports: [GridComponent, GridItemComponent],
  imports: [CommonModule]
})
export class GridModule {}
