import { Pipe, PipeTransform } from '@angular/core';
import { Device } from 'src/app/lib/store/models/device.model';

@Pipe({
    name: 'deviceFilter',
    pure: false
})
export class DeviceFilterPipe implements PipeTransform {
    transform(items: Device[], filter: string): Device[] | [] {
        if (!items || !filter) {
            return items;
        }
        return items.filter(item => item.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1);
    }
}
