import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'lastSeen'
})
export class LastSeenPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  // eslint-disable-next-line max-lines-per-function
  transform(dateTime: string): string {
    const inputDay = moment(dateTime).startOf('day');
    const todayDay = moment().startOf('day');
    const diffInDays = moment(todayDay).diff(inputDay, 'days');
    return this.translate.instant(this.translationKey(diffInDays), this.params(dateTime, diffInDays)) as string;
  }

  private params(
    dateTime: string,
    diffInDays: number
  ): {
    time: string;
    days: number;
    weeks: number;
    months: number;
  } {
    return {
      time: moment(dateTime)
        .format('LT')
        ?.toLowerCase(),
      days: diffInDays,
      weeks: Math.floor(diffInDays / 7),
      months: Math.floor((diffInDays - 1) / 30)
    };
  }

  private translationKey(diffInDays: number): string {
    if (diffInDays === 0) {
      return 'time.todayLastSeen';
    }
    if (diffInDays === 1) {
      return 'time.yesterdayLastSeen';
    }
    if (diffInDays <= 7) {
      return 'time.thisWeekLastSeen';
    }
    if (diffInDays < 31) {
      return 'time.weeksLastSeen';
    }
    return 'time.monthsLastSeen';
  }
}
