import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Configuration, Customer, CustomerLocation } from '../../rest-types';

// initial set, before app is fully loaded
export const setCurrentLocationId = createAction(
  '[plume] set current location',
  props<{ currentLocationId: string }>()
);

// change location; after the app is fully loaded, and cache has to be cleared
export const changeCurrentLocationId = createAction(
  '[plume] change current location',
  props<{ newLocationId: string }>()
);

export const loginUser = createAction(
  '[plume] login user',
  props<{ customerId: string; token: string; expireAt: string; cloudId: string; cloudUrl: string }>()
);

export const customerLoaded = createAction(
  '[plume] login user done',
  props<{ customer: Customer; locations: CustomerLocation[]; configuration: Configuration }>()
);

export const setLocationName = createAction(
  '[plume] set location name',
  props<{ locationId: string; newName: string }>()
);

export const getLocations = createAction('[plume] get locations');

export const getLocationsSuccess = createAction(
  '[plume] get locations success',
  props<{ locations: CustomerLocation[] }>()
);

export const getLocationsFail = createAction('[People] get locations fail', props<{ error: HttpErrorResponse }>());

export const locationNameLoaded = createAction(
  '[plume] set location name loaded',
  props<{ location: CustomerLocation }>()
);

export const initBraze = createAction('[braze] start init braze');

export const showChat = createAction('[plume] show chat');
export const hideChat = createAction('[plume] hide chat');
