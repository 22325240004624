import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';
import { AlertMsg, AlertType } from './alert-pop.interface';

@UntilDestroy()
@Component({
  selector: 'plm-alert-pop-bar',
  templateUrl: './alert-pop-bar.component.html',
  styleUrls: ['./alert-pop-bar.component.scss'],
  animations: [
    trigger('fromBottom', [
      transition(':enter', [style({ opacity: 0, bottom: 0 }), animate('100ms', style({ opacity: 1, bottom: '*' }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0, bottom: 0 }))])
    ]),
    trigger('fromTop', [
      transition(':enter', [style({ opacity: 0, top: 0 }), animate('100ms', style({ opacity: 1, top: '*' }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0, top: 0 }))])
    ])
  ]
})
export class AlertPopBarComponent {
  @HostBinding('@.disabled') @HostBinding('class.noAnimation') @Input() animationsDisabled = false;
  @Input() direction: 'up' | 'down' = 'up';
  @Input() offset = '55px';
  @Input() set alert$(val$: Observable<AlertMsg>) {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.subs = val$.pipe(untilDestroyed(this)).subscribe((alert) => {
      this.alert = alert;
      this.startAnimation();
    });
  }
  AlertType = AlertType;
  alert: AlertMsg;
  animate = false;
  subs: Subscription;
  animationTimerId = 0;

  close(): void {
    this.animate = false;
  }

  private startAnimation(): void {
    this.animate = false;
    clearTimeout(this.animationTimerId);
    setTimeout(() => {
      this.animate = true;
    }, 0);
    this.animationTimerId = setTimeout(() => {
      this.animate = false;
    }, 3000) as unknown as number;
  }
}
