import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../../../../app/lib/directives/icon/icon.module';
import { AccordionItemComponent } from './accordion-item.component';
import { AccordionComponent } from './accordion.component';

@NgModule({
  imports: [CommonModule, IconModule],
  exports: [AccordionComponent, AccordionItemComponent],
  declarations: [AccordionComponent, AccordionItemComponent],
  providers: []
})
export class AccordionModule {}
