import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 *  example usage:
 * ```
 * <div [plmFocusOnClick]="searchInput">
 *   <input #searchInput />
 * </div>
 *  ```
 *  on click on element with plmFocusOnClick directive input is focused
 */
@Directive({
  selector: '[plmFocusOnClick]'
})
export class FocusOnClickDirective {
  @Input('plmFocusOnClick') element: ElementRef | HTMLElement;

  @HostListener('click') click(): void {
    if (this.element) {
      if ('focus' in this.element) {
        this.element.focus();
        return;
      }
      if ('nativeElement' in this.element) {
        (this.element as ElementRef<HTMLElement>).nativeElement.focus();
        return;
      }
      if ('elmRef' in this.element) {
        (this.element as { elmRef: ElementRef<HTMLElement> }).elmRef.nativeElement.focus();
        return;
      }
      if ('elementRef' in this.element) {
        (this.element as { elementRef: ElementRef<HTMLElement> }).elementRef.nativeElement.focus();
        return;
      }
    }
  }
}
