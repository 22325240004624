import { TileTypes } from 'src/app/components/guard-tile/guard-tile.component';
import { IPlumeState } from '../..';
import { DayVisualElement } from 'src/app/lib/d3/models/charts/security.chart';
import { createSelector } from '@ngrx/store';

export const EventType = (state: IPlumeState): TileTypes => state.localState.aiSecurity.eventType;
export const SelectedDeviceMac = (state: IPlumeState): string => state.localState.aiSecurity.deviceMac;
export const SelectedDay = (state: IPlumeState): DayVisualElement => state.localState.aiSecurity.day;
export const selectEventsFilter = createSelector(
  EventType,
  SelectedDeviceMac,
  SelectedDay,
  (eventType, deviceMac, day) => ({ eventType, deviceMac, day })
);
