import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AppFacade, Consumption } from 'src/app/lib/rest-types';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { Device } from 'src/app/lib/store/models/device.model';

interface MappedDevice {
  name: string;
  icon: string;
  iconV2: string;
  consumption: number;
  unit: string;
  percent: number;
}

type Granularity = 'day' | 'week' | 'month';
type Mode = 'download' | 'upload';

@Component({
  selector: 'plm-active-devices',
  templateUrl: './active-devices.component.html',
  styleUrls: ['./active-devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveDevicesComponent implements OnChanges {
  @Input()
  summary: AppFacade;
  @Input()
  devices: Device[];

  granularity: Granularity = 'day';
  stats = {
    upload: {
      mappedDevices: [] as MappedDevice[],
      total: {} as { consumption?: number; unit?: string }
    },
    download: {
      mappedDevices: [] as MappedDevice[],
      total: {} as { consumption?: number; unit?: string }
    }
  };

  constructor(private plume: PlumeService) {}

  ngOnChanges(): void {
    this.populate();
  }

  changeGranularity(granularity: Granularity): void {
    this.granularity = granularity;
    this.populate();
  }

  consumptionTo(index: number, devices: MappedDevice[]): number {
    return devices.slice(0, index).reduce((acc, val) => acc + val.percent, 0);
  }

  private populate(): void {
    this.stats = {
      upload: {
        mappedDevices: this.summary ? this.mostActiveDevices('upload') : [],
        total: this.summary
          ? this.formatConsumption(this.summary[this.granularity].devices.totalConsumption, 'upload')
          : {}
      },
      download: {
        mappedDevices: this.summary ? this.mostActiveDevices('download') : [],
        total: this.summary
          ? this.formatConsumption(this.summary[this.granularity].devices.totalConsumption, 'download')
          : {}
      }
    };
  }

  private mostActiveDevices(mode: Mode): MappedDevice[] {
    return (
      [...this.summary[this.granularity].devices.mostActive]
        .sort((a, b) => b[mode] - a[mode])
        // .splice(0, 5)
        .map(device => this.mapDevice(device, mode))
        .filter(device => device.percent >= 1)
    );
  }

  private mapDevice(device: Device, mode: Mode): MappedDevice {
    const percent = (device[mode] * 100) / this.summary[this.granularity].devices.totalConsumption[mode];
    const consumption = this.formatConsumption(device, mode);

    return {
      ...consumption,
      name: device.name,
      icon: device.icon,
      iconV2: this.lookupDeviceIcon(device),
      percent
    };
  }

  private lookupDeviceIcon(device: Device): string {
    if (this.devices) {
      return this.devices.find(dev => dev.mac === device.mac)?.iconV2;
    } else {
      return null;
    }
  }

  private formatConsumption(data: Consumption, mode: Mode): { consumption: number; unit: string } {
    const formatted = this.plume.formatBytes(
      data[mode],
      data[(mode + 'Units') as 'downloadUnits' | 'uploadUnits'].toUpperCase(),
      2
    );
    return {
      consumption: formatted.value,
      unit: formatted.unit
    };
  }
}
