import { Component, Input } from '@angular/core';

@Component({
  selector: 'plm-info-overlay',
  templateUrl: 'info-overlay.component.html',
  styleUrls: ['info-overlay.component.scss']
})
export class InfoOverlayComponent {
  @Input() height: number;
  @Input() width: number;

  public isOpen = false;

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }

  public close(): void {
    this.isOpen = false;
  }
}
