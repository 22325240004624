import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'plm-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputFieldComponent implements AfterViewInit {
  @Input() type: 'password' | undefined;
  passwordDisplayed = false;
  constructor(private elmRef: ElementRef<HTMLElement>) {}

  @HostBinding('class.hasValue')
  get hasValue(): boolean {
    return !!this.inputElm?.value;
  }

  // to be sure on focusout is hasValue checked
  @HostListener('focusout')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  focusout(): void {}

  @HostListener('click', ['$event.target'])
  onClick(elm: HTMLElement): void {
    if (elm.tagName.toLowerCase() === 'input') {
      return;
    }
    this.inputElm?.focus();
  }

  ngAfterViewInit(): void {
    if (this.type === 'password' && this.inputElm) {
      this.inputElm.type = 'password';
    }
  }

  showPassword(): void {
    this.passwordDisplayed = true;
    if (this.inputElm) {
      this.inputElm.type = 'text';
    }
  }

  hidePassword(): void {
    this.passwordDisplayed = false;
    if (this.inputElm) {
      this.inputElm.type = 'password';
    }
  }

  private get inputElm(): HTMLInputElement {
    return this.elmRef.nativeElement.getElementsByTagName('input')?.[0];
  }
}
