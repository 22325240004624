import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from 'src/app/lib/services/auth.service';
import { MainComponent } from 'src/app/views/home/main.component';
import { LoginComponent, HandlerComponent } from 'src/app/views/login/login.component';
import { InsightsComponent } from 'src/app/views/home/insights/insights.component';
import { PodsComponent } from 'src/app/views/home/pods/pods.component';
import { NewsComponent } from 'src/app/views/home/news/news.component';
import { LoginGuardService } from './services/login-guard/login-guard.service';
import { InsightsGuardService } from './services/insights-guard/insights-guard.service';
import { EmptyComponent } from '../components/empty/empty.component';
import { EventsComponent } from '../views/home/insights/events/events.component';
import { InvalidPartnerComponent } from '../views/invalid-partner/invalid-partner.component';
import { PartnerValidatorService } from '../services/partner-validator/partner-validator.service';
import { TopologyComponent } from '../views/topology/topology.component';
import { SettingsAdaptComponent } from '../views/settings/settings-adapt/settings-adapt.component';
import { SettingsAdvancedComponent } from '../views/settings/settings-advanced/settings.advanced.component';
import { SettingsGuardComponent } from '../views/settings/settings-guard/settings-guard.component';
import { SettingsAccessComponent } from '../views/settings/settings-access/settings-access.component';
import { AccessEditZoneAndKeyComponent } from '../views/settings/settings-access/access-edit-zone-and-key/access-edit-zone-and-key.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginGuardService] },
  { path: 'login/handler', component: HandlerComponent },
  { path: 'invalid-partner', component: InvalidPartnerComponent },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthService, PartnerValidatorService],
    canActivateChild: [AuthService],
    children: [
      {
        path: '',
        canActivate: [InsightsGuardService],
        children: [
          { path: '', redirectTo: 'insights', pathMatch: 'full' },
          {
            path: 'insights',
            component: InsightsComponent
          },
          {
            path: 'pods/:id',
            component: PodsComponent
          },
          { path: 'insights/events', component: EventsComponent, data: { panelRightHide: true } }
        ]
      },
      { path: 'empty', component: EmptyComponent },
      { path: 'news', component: NewsComponent },
      { path: 'news/tips', data: { name: 'tips' }, component: NewsComponent },
      { path: 'news/blog', data: { name: 'blog' }, component: NewsComponent },
      { path: 'news/releasenotes', data: { name: 'releasenotes' }, component: NewsComponent },
      { path: 'news/notifications', data: { name: 'notifications' }, component: NewsComponent },
      { path: 'settings/adapt', component: SettingsAdaptComponent },
      { path: 'settings/advanced', component: SettingsAdvancedComponent },
      { path: 'settings/guard', component: SettingsGuardComponent },
      { path: 'settings/adapt', component: SettingsAdaptComponent },
      { path: 'settings/access', component: SettingsAccessComponent },
      { path: 'settings/access/new-home-password', component: AccessEditZoneAndKeyComponent, data: { type: 'home' } },
      {
        path: 'settings/access/edit-home-password',
        component: AccessEditZoneAndKeyComponent,
        data: { type: 'home', edit: true }
      },
      {
        path: 'settings/access/new-internet-only-password',
        component: AccessEditZoneAndKeyComponent,
        data: { type: 'internetAccessOnly' }
      },
      {
        path: 'settings/access/edit-internet-only-password',
        component: AccessEditZoneAndKeyComponent,
        data: { type: 'internetAccessOnly', edit: true }
      },
      {
        path: 'settings/access/new-guest-password',
        component: AccessEditZoneAndKeyComponent,
        data: { type: 'guests' }
      },
      {
        path: 'settings/access/edit-guest-password',
        component: AccessEditZoneAndKeyComponent,
        data: { type: 'guests', edit: true }
      },
      {
        path: 'settings/access/edit-access-zone',
        component: AccessEditZoneAndKeyComponent,
        data: { type: 'guests', accessZoneOnly: true }
      }
    ]
  },
  {
    path: 'topology',
    component: TopologyComponent,
    canActivate: [AuthService, PartnerValidatorService]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule {}
