import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { DomPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[plmToolTipText]'
})
export class ToolTipTextDirective implements OnDestroy {
  overlayRef: OverlayRef;
  textElement: HTMLElement;
  @Input('plmToolTipText') text = '';

  constructor(private overlay: Overlay, elm: ElementRef) {
    this.overlayRef = this.overlay.create({
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      panelClass: 'tool-tip-basic-box',
      positionStrategy: overlay
        .position()
        .flexibleConnectedTo(elm)
        .withPositions([
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: 4
          }
        ])
    });
  }

  @HostListener('mouseenter')
  enter(): void {
    if (this.text) {
      this.textElement = document.createElement('span');
      document.body.appendChild(this.textElement);
      this.textElement.innerText = this.text;
      this.overlayRef.attach(new DomPortal(this.textElement));
    }
  }

  @HostListener('mouseleave')
  mouseleave(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      document.body.removeChild(this.textElement);
      this.textElement = null;
    }
  }

  ngOnDestroy(): void {
    if (this.textElement) {
      document.body.removeChild(this.textElement);
    }

    this.overlayRef.dispose();
  }
}
