import { Component, forwardRef, HostBinding, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'plm-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    }
  ]
})
export class ToggleComponent implements ControlValueAccessor {
  private onVal = false;
  private isDisabled = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onChange = (_: boolean): void => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  public get on(): boolean {
    return this.onVal;
  }
  public set on(v: boolean) {
    if (v !== this.onVal) {
      this.onVal = v;
      this.onChange(v);
    }
  }

  @HostBinding('class.on')
  get classOn(): boolean {
    return this.on;
  }

  @HostListener('click')
  click(): void {
    if (!this.isDisabled) {
      this.writeValue(!this.on);
    }
  }

  writeValue(val: boolean): void {
    this.on = val;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
