import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SecurityPolicyModel } from '../../store/models/security-policy.model';
import { TileTypes } from 'src/app/components/guard-tile/guard-tile.component';
import { DayVisualElement } from '../../d3/models/charts/security.chart';
import {
  AccessZoneWifi,
  AccessZoneWifiType,
  SecurityPolicy,
  SecurityPolicyEvents,
  SecurityPolicyEventTypes,
  securityTypeConversion,
  WifiNetwork,
  WifiNetworkKeys
} from '../../rest-types';
import { SharedUrlsService } from '../shared-urls';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SecurityPolicyService {
  constructor(private http: HttpClient, private sharedUrls: SharedUrlsService) {}

  getSecurityPolicy$(): Observable<SecurityPolicyModel> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(switchMap(locationUrl => this.http.get<SecurityPolicyModel>(`${locationUrl}/securityPolicy`)));
  }

  setSecurityPolicy$(data: Partial<SecurityPolicy>): Observable<SecurityPolicy> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(switchMap(locationUrl => this.http.patch<SecurityPolicy>(`${locationUrl}/securityPolicy`, data)));
  }

  getWifiNetwork$(): Observable<WifiNetwork> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(switchMap(locationUrl => this.http.get<{ wifiNetwork: WifiNetwork }>(`${locationUrl}/wifiNetwork`)))
      .pipe(map(w => w.wifiNetwork));
  }

  setWifiNetwork$(data: Partial<WifiNetwork>, locationId?: string, ignoreErrors?: string[]): Observable<WifiNetwork> {
    const headers = ignoreErrors ? { headers: { 'errors-white-list': ignoreErrors } } : {};
    return this.sharedUrls
      .locationUrl$(locationId)
      .pipe(switchMap(locationUrl => this.http.patch<WifiNetwork>(`${locationUrl}/wifiNetwork`, data, headers)));
  }

  getSecurityEvents$(
    params: {
      eventType?: TileTypes;
      deviceMac: string;
      day?: DayVisualElement;
    },
    limit = 10
  ): Observable<SecurityPolicyEvents[]> {
    const categories = this.tileTypeToEventType(params.eventType);
    const selectedDay = moment().startOf('day');
    selectedDay.add(-(params.day?.dayIndex ?? 0) + 1, 'day');
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap(locationUrl =>
          this.http.get<SecurityPolicyEvents[]>(
            `${locationUrl}${
              params.deviceMac ? '/devices/' + params.deviceMac : ''
            }/securityPolicy/events?includes=${categories.join(
              ','
            )}&startTime=${selectedDay.toISOString()}&limit=${limit}&direction=before`
          )
        )
      );
  }

  tileTypeToEventType(eventType: TileTypes = 'all'): string[] {
    if (eventType === 'all') {
      return Object.values(SecurityPolicyEventTypes);
    }
    const secType = Object.values(securityTypeConversion).find(t => t === eventType);
    if (!secType) {
      return [eventType];
    }
    return Object.keys(securityTypeConversion).filter(key => securityTypeConversion[key] === eventType);
  }

  deleteSecurityEvents$(
    category: (
      | SecurityPolicyEventTypes.dnsBlocked
      | SecurityPolicyEventTypes.ipThreat
      | SecurityPolicyEventTypes.anomaly
    )[] = [SecurityPolicyEventTypes.dnsBlocked, SecurityPolicyEventTypes.ipThreat, SecurityPolicyEventTypes.anomaly]
  ): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap(locationUrl =>
          this.http.delete(`${locationUrl}/securityPolicy/events?categories=${category.join(',')}`)
        )
      );
  }

  addSecurityPolicyDeviceWhitelist$(mac: string, url: string, dnsType: string): Observable<unknown> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.http.post<unknown>(
          `${locationUrl}/devices/${mac}/securityPolicy/websites/whitelist`,
          { value: url, type: dnsType },
          {
            headers: { 'errors-white-list': ['422'] }
          }
        )
      )
    );
  }

  addSecurityPolicyPersonWhitelist$(personId: string, url: string, dnsType: string): Observable<unknown> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.http.post<unknown>(
          `${locationUrl}/persons/${personId}/securityPolicy/websites/whitelist`,
          { value: url, type: dnsType },
          {
            headers: { 'errors-white-list': ['422'] }
          }
        )
      )
    );
  }

  removeSecurityPolicyPersonWhitelist$(personId: string, dns: string): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap(locationUrl =>
          this.http.delete<unknown>(`${locationUrl}/persons/${personId}/securityPolicy/websites/whitelist/${dns}`)
        )
      );
  }

  removeSecurityPolicyDeviceWhitelist$(mac: string, dns: string): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap(locationUrl =>
          this.http.delete<unknown>(`${locationUrl}/devices/${mac}/securityPolicy/websites/whitelist/${dns}`)
        )
      );
  }

  addSecurityPolicyDeviceBlacklist$(mac: string, url: string, dnsType: string): Observable<unknown> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.http.post<unknown>(`${locationUrl}/devices/${mac}/securityPolicy/websites/blacklist`, {
          value: url,
          type: dnsType
        })
      )
    );
  }

  addSecurityPolicyPersonBlacklist$(personId: string, url: string, dnsType: string): Observable<unknown> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.http.post<unknown>(`${locationUrl}/persons/${personId}/securityPolicy/websites/blacklist`, {
          value: url,
          type: dnsType
        })
      )
    );
  }

  removeSecurityPolicyPersonBlacklist$(personId: string, dns: string): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap(locationUrl =>
          this.http.delete<unknown>(`${locationUrl}/persons/${personId}/securityPolicy/websites/blacklist/${dns}`)
        )
      );
  }

  removeSecurityPolicyDeviceBlacklist$(mac: string, dns: string): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap(locationUrl =>
          this.http.delete<unknown>(`${locationUrl}/devices/${mac}/securityPolicy/websites/blacklist/${dns}`)
        )
      );
  }

  addSecurityPolicyWhitelist$(url: string, dnsType: string): Observable<unknown> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.http.post<unknown>(
          `${locationUrl}/securityPolicy/websites/whitelist`,
          { value: url, type: dnsType },
          {
            headers: { 'errors-white-list': ['422'] }
          }
        )
      )
    );
  }

  removeSecurityPolicyWhitelist$(dns: string): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap(locationUrl => this.http.delete<unknown>(`${locationUrl}/securityPolicy/websites/whitelist/${dns}`))
      );
  }

  addSecurityPolicyBlacklist$(url: string, dnsType: string): Observable<unknown> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.http.post<unknown>(
          `${locationUrl}/securityPolicy/websites/blacklist`,
          { value: url, type: dnsType },
          {
            headers: { 'errors-white-list': ['422'] }
          }
        )
      )
    );
  }

  removeSecurityPolicyBlacklist$(dns: string): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap(locationUrl => this.http.delete<unknown>(`${locationUrl}/securityPolicy/websites/blacklist/${dns}`))
      );
  }

  addWifiPassword$(
    accessZone: AccessZoneWifiType | number,
    password: string,
    expiresAt: string | undefined,
    locationId?: string
  ): Observable<WifiNetworkKeys[]> {
    return this.sharedUrls.locationUrl$(locationId).pipe(
      switchMap(locationUrl =>
        this.http.post<WifiNetworkKeys[]>(
          `${locationUrl}/wifiNetwork/accessZones/${accessZone}/keys`,
          {
            encryptionKey: password,
            format: 'encryptionKey',
            enable: true,
            expiresAt
          },
          {
            headers: { 'errors-white-list': ['400', '422'] }
          }
        )
      )
    );
  }

  addWifiAccessZoneWithPassword$(
    password: string,
    accessZoneType: AccessZoneWifiType,
    guestName: string,
    accessibleDevices: string[],
    expiresAt: string | undefined,
    locationId?: string
  ): Observable<WifiNetworkKeys[]> {
    return this.createAccessZone$(accessZoneType, guestName, accessibleDevices, locationId).pipe(
      switchMap(accessZone =>
        this.addWifiPassword$(accessZone.id, password, expiresAt, locationId).pipe(
          catchError((error: WifiNetworkKeys[]) =>
            this.deleteAccessZone$(accessZone.id, locationId).pipe(switchMap(() => throwError(error)))
          )
        )
      )
    );
  }

  createAccessZone$(
    type: AccessZoneWifiType,
    guestName: string,
    accessibleDevices: string[],
    locationId?: string
  ): Observable<AccessZoneWifi> {
    return this.sharedUrls.locationUrl$(locationId).pipe(
      switchMap(locationUrl =>
        this.http.post<AccessZoneWifi>(
          `${locationUrl}/wifiNetwork/accessZones`,
          {
            description: guestName,
            type,
            accessibleDevices: accessibleDevices
          },
          {
            headers: { 'errors-white-list': ['400', '422'] }
          }
        )
      )
    );
  }

  deleteAccessZone$(id: number, locationId: string): Observable<AccessZoneWifi[]> {
    return this.sharedUrls
      .locationUrl$(locationId)
      .pipe(
        switchMap(locationUrl => this.http.delete<AccessZoneWifi[]>(`${locationUrl}/wifiNetwork/accessZones/${id}`))
      );
  }

  editWifiPassword$(key: Partial<WifiNetworkKeys>, locationId?: string): Observable<WifiNetworkKeys[]> {
    return this.sharedUrls.locationUrl$(locationId).pipe(
      switchMap(locationUrl =>
        this.http.put<WifiNetworkKeys[]>(
          `${locationUrl}/wifiNetwork/accessZones/${key.accessZoneId}/keys/${key.id}`,
          {
            encryptionKey: key.encryptionKey,
            format: key.format,
            enable: key.enable,
            expiresAt: key.expiresAt
          },
          {
            headers: { 'errors-white-list': ['400', '422'] }
          }
        )
      )
    );
  }

  deleteWifiPassword$(key: WifiNetworkKeys, locationId?: string): Observable<WifiNetworkKeys[]> {
    return this.sharedUrls
      .locationUrl$(locationId)
      .pipe(
        switchMap(locationUrl =>
          this.http.delete<WifiNetworkKeys[]>(
            `${locationUrl}/wifiNetwork/accessZones/${key.accessZoneId}/keys/${key.id}`
          )
        )
      );
  }

  editWifiAccessZone$(accessZone: Partial<AccessZoneWifi>, locationId?: string): Observable<AccessZoneWifi> {
    return this.sharedUrls.locationUrl$(locationId).pipe(
      switchMap(locationUrl =>
        this.http.patch<AccessZoneWifi>(
          `${locationUrl}/wifiNetwork/accessZones/${accessZone.id}`,
          {
            accessibleDevices: accessZone.accessibleDevices,
            description: accessZone.description
          },
          {
            headers: { 'errors-white-list': ['400', '422'] }
          }
        )
      )
    );
  }
}
