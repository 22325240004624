<div class="main-header">
  <button class="icon back" [routerLink]="'..'" *ngIf="backButton">
    <div class="icon" icon="left-arrow-small"></div>
    <h3>{{ 'aisecurity.guard' | translate }}</h3>
  </button>
  <h3 *ngIf="!backButton">{{ 'aisecurity.guard' | translate }}</h3>
  <plm-overlay-button
    *ngIf="(error$ | async) === false"
    [buttonIcon]="'more'"
    [iconOnly]="true"
    [overlayHeight]="152"
    [overlayWidth]="180"
    [offSetY]="170"
    offSetX="0"
    [border]="false"
    originX="end"
    originY="top"
    overlayX="end"
    overlayY="bottom"
  >
    <plm-space size="xxs"></plm-space>
    <plm-list class="ai-security__menu">
      <li (click)="openApproveBlock(securityAccess.APPROVE)">
        <span class="icon" icon="check"></span>{{ 'aisecurity.menu.approve' | translate }}
      </li>
      <li (click)="openApproveBlock(securityAccess.BLOCKED)">
        <span class="icon" icon="close"></span>{{ 'aisecurity.menu.block' | translate }}
      </li>
      <li (click)="openSettings()">
        <span class="icon" icon="gear"></span>{{ 'aisecurity.menu.settings' | translate }}
      </li>
    </plm-list>
  </plm-overlay-button>
</div>
<div class="granulation">
  <plm-chip
    [chipIcon]="'close'"
    position="left"
    (click)="removeSelectedDay()"
    *ngIf="selectedDay$ | async as selectedDay; else noSelectedDay"
    >{{ selectedDay.text }}</plm-chip
  >
  <ng-template #noSelectedDay>
    <plm-button [disabled]="true">{{ 'aisecurity.last30days' | translate }}</plm-button>
  </ng-template>

  <plm-chip
    [chipIcon]="'close'"
    (click)="removeSelectedDevice()"
    *ngIf="selectedDevice$ | async as device; else noSelectedDevice"
    >{{ device.nickname || device.name || '' }}
  </plm-chip>
  <ng-template #noSelectedDevice>
    <plm-overlay-button
      [label]="'aisecurity.allDevice' | translate"
      [buttonIcon]="'arrow'"
      [overlayHeight]="370"
      [overlayWidth]="310"
      [offSetY]="402"
      offSetX="0"
      [border]="true"
      originX="end"
      originY="top"
      overlayX="end"
      overlayY="bottom"
      [closeOnClick]="false"
    >
      <plm-space size="xs"></plm-space>
      <div class="ai-security__lookup">
        <plm-form-input [field]="lookUpOptions" (changed)="filterDevices($event)"></plm-form-input>
      </div>
      <plm-space size="xxs"></plm-space>
      <perfect-scrollbar class="scrollbar">
        <plm-list [ruled]="true" class="ai-security__list">
          <li *ngFor="let item of devices | deviceFilter: searchTerm" (click)="onDeviceSelected(item)">
            <span class="icon" [icon]="item.iconV2" [device]="true" height="128" width="128"></span
            >{{ (item.nickname ? item.nickname : item.name || '') | ellipsis: 20 }}
          </li>
        </plm-list>
      </perfect-scrollbar>
    </plm-overlay-button>
  </ng-template>
</div>
<plm-securitychart
  [events]="chartData$ | async"
  (daySelected)="onDaySelected($event)"
  [selectedDay]="selectedDay$ | async"
></plm-securitychart>

<div class="loading" *ngIf="loading$ | async; else guardTilesBox">
  <div class="icon" icon="loading"></div>
</div>
<ng-template #guardTilesBox
  ><plm-guard-tiles-box
    [securityDailyCounts]="tileCounts$ | async"
    [activeTile]="activeTile$ | async"
    (tileClicked)="tileClicked($event)"
  >
  </plm-guard-tiles-box
></ng-template>
