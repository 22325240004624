import { Directive, OnChanges, ElementRef, Input } from '@angular/core';
import { IconService } from '../../../../app/lib/services/icon-service/icon.service';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[icon]'
})
export class IconDirective implements OnChanges {
  @Input()
  icon = '';
  @Input()
  device = false;

  @Input()
  width = '0';

  @Input()
  height = '0';

  constructor(private el: ElementRef<HTMLElement>, private iconService: IconService) {}

  ngOnChanges(): void {
    this.embedIcon();
  }

  embedIcon(): void {
    if (this.device) {
      this.iconService.loadIconV2$(this.icon).subscribe(response => {
        this.inlineIcon(response);
      });
    } else if (this.icon) {
      if (this.iconService.get(this.icon)) {
        (this.el.nativeElement as Element).innerHTML = this.iconService.get(this.icon);
      } else {
        this.iconService.loadIcon$(this.icon).subscribe((response: string) => {
          (this.el.nativeElement as Element).innerHTML = response;
        });
      }
    } else {
      (this.el.nativeElement as Element).innerHTML = '';
    }
  }

  inlineIcon(data: string): void {
    const parser = new DOMParser();
    const icon = parser.parseFromString(data, 'image/svg+xml').documentElement;
    icon.setAttribute('width', this.width);
    icon.setAttribute('height', this.height);
    this.el.nativeElement.innerHTML = '';
    this.el.nativeElement.appendChild(icon);
  }
}
