import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IPlumeState } from '../../store';
import { onboarded } from '../../store/selectors/plume.selectors';

@Injectable({
  providedIn: 'root'
})
export class InsightsGuardService implements CanActivate {
  constructor(private store$: Store<IPlumeState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store$.pipe(
      select(onboarded),
      tap(isOnBoarded => {
        if (!isOnBoarded) {
          void this.router.navigate(['news']);
        }
      })
    );
  }
}
