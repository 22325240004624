import { Component, EventEmitter, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Configuration } from 'src/app/lib/rest-types';
import { AuthService } from 'src/app/lib/services/auth.service';
import { IPlumeState } from 'src/app/lib/store';
import { configuration } from 'src/app/lib/store/selectors/plume.selectors';
@Component({
  selector: 'plm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() toggleMenu = new EventEmitter<void>();
  public showMenu = false;
  public config$ = this.store$.pipe(select(configuration));

  constructor(private auth: AuthService, private store$: Store<IPlumeState>) {}

  navigate(property: string, conf: Configuration): void {
    window.open(conf[property]);
  }

  logout(): void {
    this.auth.logout();
  }

  doToggleMenu(): void {
    this.toggleMenu.emit();
  }
}
