import { trigger, state, animate, style, transition } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'plm-accordion-item',
  templateUrl: 'accordion-item.component.html',
  styleUrls: ['accordion-item.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state(
        'initial',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
          visibility: 'hidden'
        })
      ),
      state(
        'final',
        style({
          overflow: 'hidden'
        })
      ),
      transition('initial<=>final', animate('250ms'))
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('default <=> rotated', animate('250ms'))
    ])
  ]
})
export class AccordionItemComponent {
  @Input() opened = false;
  @Input() id = '';

  @Input()
  noBorder = false;

  @Input()
  borderRadius = false;

  @Input()
  alert = false;

  @Output() toggle = new EventEmitter<void>();
}
