import { Component, Input } from '@angular/core';
import { Device } from 'src/app/lib/store/models/device.model';

@Component({
  selector: 'plm-device-item',
  templateUrl: './device-item.component.html',
  styleUrls: ['./device-item.component.scss']
})
export class DeviceItemComponent {
  @Input() device: Device;
}
