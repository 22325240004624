import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo, take, withLatestFrom } from 'rxjs/operators';
import { IPlumeState } from '..';
import { AiSecurityService } from '../../services/ai-security/ai-security.service';
import { AppFacadeService } from '../../services/app-facade/app-facade.service';
import { SecurityPolicyService } from '../../services/security-policy/security-policy.service';
import {
  loadFacade,
  loadFacadeDone,
  loadMoreSecurityEvents,
  loadSecurityByMac,
  loadSecurityDailyCounts,
  loadSecurityDailyCountsDone,
  loadSecurityEvents,
  loadSecurityEventsDone,
  reloadSecurityCounts,
  reLoadSecurityEvents,
  speedTestStart
} from '../actions/insights.actions';
import { securityDailyCountsMac, securityEventsLimit } from '../selectors/insights.selectors';
import { selectEventsFilter } from '../selectors/local-state/ai-security.selectors';

@Injectable()
export class InsightsEffects {
  loadSecurityDailyCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSecurityDailyCounts),
      switchMapTo(
        this.aiSecurityService.getAiSecurityDailyCounts$().pipe(catchError((error: HttpErrorResponse) => of({ error })))
      ),
      map(data => loadSecurityDailyCountsDone({ data, macAddress: null }))
    )
  );

  loadFacade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFacade),
      switchMapTo(this.appFacadeService.appFacade$()),
      map(data => loadFacadeDone({ data }))
    )
  );

  loadSecurityDailyCountsByMacAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSecurityByMac),
      switchMap(action =>
        this.aiSecurityService.getAiSecurityDailyCountsByMac$(action.macAddress).pipe(
          catchError((error: HttpErrorResponse) => of({ error })),
          map(data => loadSecurityDailyCountsDone({ data, macAddress: action.macAddress }))
        )
      )
    )
  );

  reloadSecurityCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadSecurityCounts),
      switchMapTo(this.store$.select(securityDailyCountsMac).pipe(take(1))),
      map(macAddress => {
        if (macAddress) {
          return loadSecurityByMac({ macAddress });
        } else {
          return loadSecurityDailyCounts();
        }
      })
    )
  );

  securityEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSecurityEvents),
      switchMapTo(
        this.store$
          .select(selectEventsFilter)
          .pipe(withLatestFrom(this.store$.select(securityEventsLimit)))
          .pipe(take(1))
      ),
      switchMap(([filter, limit]) =>
        this.securityPolicyService
          .getSecurityEvents$(filter, limit)
          .pipe(catchError((error: HttpErrorResponse) => of({ error })))
      ),
      map(data => loadSecurityEventsDone({ data }))
    )
  );

  reLoadSecurityEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reLoadSecurityEvents),
      switchMapTo(
        this.store$
          .select(selectEventsFilter)
          .pipe(withLatestFrom(this.store$.select(securityEventsLimit)))
          .pipe(take(1))
      ),
      switchMap(([filter, limit]) =>
        this.securityPolicyService
          .getSecurityEvents$(filter, limit)
          .pipe(catchError((error: HttpErrorResponse) => of({ error })))
      ),
      map(data => loadSecurityEventsDone({ data }))
    )
  );

  loadMoreSecurityEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreSecurityEvents),
      switchMapTo(
        this.store$
          .select(selectEventsFilter)
          .pipe(withLatestFrom(this.store$.select(securityEventsLimit)))
          .pipe(take(1))
      ),
      switchMap(([filter, limit]) =>
        this.securityPolicyService
          .getSecurityEvents$(filter, limit)
          .pipe(catchError((error: HttpErrorResponse) => of({ error })))
      ),
      map(data => loadSecurityEventsDone({ data }))
    )
  );

  startSpeedTest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(speedTestStart),
        switchMap(() => this.appFacadeService.startSpeedTest$())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private aiSecurityService: AiSecurityService,
    private appFacadeService: AppFacadeService,
    private securityPolicyService: SecurityPolicyService,
    private store$: Store<IPlumeState>
  ) {}
}
