import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'plm-button',
    templateUrl: 'button.component.html',
    styleUrls: ['button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ButtonComponent {
    @Input()
    public buttonType = 'button';

    @Input()
    public isActive = false;

    @Input()
    public disabled = false;

    @Input()
    public iconOnly = false;

    @Input()
    public size: 'small' | 'medium' | 'large' = 'medium';

    @Output()
    public clicked = new EventEmitter<void>();

    public onClick(): void {
        this.clicked.emit();
    }

}
