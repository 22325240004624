import { createReducer, Action, on } from '@ngrx/store';
import {
  setDay,
  setDevice,
  setEventType
} from '../../actions/local-state/ai-security.actions';
import {
  IAiSecurity,
  initialAiSecurityState
} from '../../interfaces/local-state/ai-security.interface';

export const reducer = createReducer(
  initialAiSecurityState,
  on(setDevice, (state, { deviceMac }) => ({
    ...state,
    deviceMac
  })),
  on(setEventType, (state, { eventType }) => ({
    ...state,
    eventType
  })),
  on(setDay, (state, { day }) => ({
    ...state,
    day
  }))
);

export const AiSecurityReducer = (state: IAiSecurity, action: Action): IAiSecurity => reducer(state, action);
