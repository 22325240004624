<div class="network-restart">
  <span class="icon" [icon]="'gfx'"></span>
  <plm-space size="xl"></plm-space>
  <div class="network-restart__title">{{ 'networkingMode.restartingTitle' | translate }}</div>
  <plm-space size="s"></plm-space>
  <div class="network-restart__content">{{ 'networkingMode.restartingMessage' | translate }}</div>
  <div class="network-restart__support">
    {{ 'networkingMode.plumeSupport' | translate }} <span class="network-restart__email">support@plume.com</span>
    {{ 'networkingMode.plumeSupportOr' | translate }}
    <span class="network-restart__phone">{{ (config$ | async)?.supportPhone }}</span>
  </div>
  <div class="network-restart__dismiss bottom-buttons">
    <button class="highlight" (click)="data.close()">{{ 'buttons.dismiss' | translate }}</button>
  </div>
</div>
