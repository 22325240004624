import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AccessZoneWifi, WifiNetworkKeys } from 'src/app/lib/rest-types';
import { Device } from 'src/app/lib/store/models/device.model';
import {
  AccessPasswordDropDownClicked,
  AccessPasswordDropDownType
} from '../access-password/access-password.interface';

type MappedZones = { zoneId: number; zoneName: string; devices: Device[]; passwords: WifiNetworkKeys[] }[];

@Component({
  selector: 'plm-access-guest-password-list',
  templateUrl: './access-guest-password-list.component.html',
  styleUrls: ['./access-guest-password-list.component.scss']
})
export class AccessGuestPasswordListComponent implements OnChanges {
  @Input() passwords: WifiNetworkKeys[] = [];
  @Input() devices: Device[];
  @Input() accessZone: AccessZoneWifi[];
  @Input() keysInProgress: number[] = [];
  @Output() dropDownSelected = new EventEmitter<{ key: WifiNetworkKeys; type: AccessPasswordDropDownClicked }>();
  @Output() editAccessZone = new EventEmitter<number>();
  mappedZones: MappedZones = [];
  noDeviceIcons = [
    'tv',
    'printer',
    'voiceassistant-amazon-echo',
    'speaker-apple-homepod',
    'gameconsole',
    'plug-belkin-wemo'
  ];

  ngOnChanges(): void {
    this.mappedZones =
      this.accessZone
        ?.filter(zone => zone.type === 'guests')
        .reduce(
          (acc, zone) => [
            ...acc,
            {
              zoneId: zone.id,
              zoneName: zone.description,
              devices: this.devices?.filter(device => zone.accessibleDevices.includes(device.mac)) || [],
              passwords: this.passwords?.filter(password => password.accessZoneId === zone.id) || []
            }
          ],
          [] as MappedZones
        ) || [];
  }

  dropDownType(key: WifiNetworkKeys): AccessPasswordDropDownType {
    if (this.keysInProgress.includes(key.id)) {
      return AccessPasswordDropDownType.none;
    }
    if (!key.enable) {
      return AccessPasswordDropDownType.enableDelete;
    }
    return AccessPasswordDropDownType.all;
  }
}
