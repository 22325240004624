import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Configuration } from 'src/app/lib/rest-types';
import { IPlumeState } from 'src/app/lib/store';
import { showChat } from 'src/app/lib/store/actions/plume.actions';
import { configuration } from 'src/app/lib/store/selectors/plume.selectors';

@Component({
  selector: 'plm-support-menu',
  templateUrl: './support-menu.component.html',
  styleUrls: ['./support-menu.component.scss']
})
export class SupportMenuComponent {
  config$ = this.store$.pipe(select(configuration));
  constructor(private store$: Store<IPlumeState>) {}

  navigate(property: string, conf: Configuration): void {
    window.open(conf[property]);
  }

  openChat(): void {
    this.store$.dispatch(showChat());
  }
}
