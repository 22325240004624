import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'plm-grid-item',
  styleUrls: ['./grid-item.component.scss'],
  template: '<ng-content></ng-content>'
})
export class GridItemComponent implements OnChanges {
  /**
   * Vertical alignment of the GridItem.
   */
  @Input() align: 'center' | 'end' | 'start';

  /**
   * Number of columns the GridItem should span.
   */
  @Input() colSpan: string;

  /**
   * Column number the GridItem should start at.
   */
  @Input() colStart: string;

  /**
   * Horizontal alignment of the GridItem.
   */
  @Input() justify: 'center' | 'end' | 'start';

  /**
   * Number of rows the GridItem should span.
   */
  @Input() rowSpan: string;

  /**
   * Row number the GridItem should start at.
   */
  @Input() rowStart: string;

  @HostBinding('style') style: SafeStyle | string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    const { align, colSpan, colStart, justify, rowSpan, rowStart } = this;
    const alignSelf = `${align ? `align-self:${align};` : ''}`;
    const gridColumnEnd = `${colSpan ? `grid-column-end:span ${colSpan};` : ''}`;
    const gridColumnStart = `${colStart ? `grid-column-start:${colStart};` : ''}`;
    const gridRowEnd = `${rowSpan ? `grid-row-end:span ${rowSpan};` : ''}`;
    const gridRowStart = `${rowStart ? `grid-row-start:${rowStart};` : ''}`;
    const justifySelf = `${justify ? `justify-self:${justify};` : ''}`;

    this.style = this.sanitizer.bypassSecurityTrustStyle(
      `${alignSelf}${gridColumnEnd}${gridColumnStart}${gridRowEnd}${gridRowStart}${justifySelf}`
    );
  }
}
