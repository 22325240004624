<plm-box>
  <plm-ai-security
    [securityDailyCounts]="aiSecurityDailyCounts$ | async"
    [devices]="devices$ | async"
    [backButton]="true"
    (device)="onDeviceSelected($event)"
    (reloadSecurity)="reloadSecurity()"
    (approveDialogOpened)="approveDialogOpened($event)"
  ></plm-ai-security>
  <plm-guard-event-list
    [events]="(events$ | async).data"
    [devices]="devices$ | async"
    [people]="people$ | async"
    (approve)="whitelistEvent($event)"
  ></plm-guard-event-list>
  <div class="loading" *ngIf="eventLoading$ | async; else noLoading">
    <div class="icon" icon="loading"></div>
  </div>
  <ng-template #noLoading>
    <button *ngIf="(allLoaded$ | async) === false" class="loadMore plm" (click)="loadMore()">
      {{ 'aisecurity.eventList.loadMore' | translate }}
    </button>
  </ng-template>
</plm-box>
