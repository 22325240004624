import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { filter, Observable, Subject } from 'rxjs';
import { ValidationService } from 'src/app/lib/services/validation/validation.service';
import { addPortForwardingError } from 'src/app/lib/store/actions/settings.actions';
import { PortForwards } from 'src/app/lib/store/models/dhcp-reservation.model';
import { AlertMsg, AlertType } from '../alert-pop-bar/alert-pop.interface';

@UntilDestroy()
@Component({
  selector: 'plm-ip-reservation-port',
  templateUrl: './ip-reservation-port.component.html',
  styleUrls: ['./ip-reservation-port.component.scss']
})
export class IpReservationPortComponent {
  @Input() editMode = false;
  @Input() saving = true;
  @Input() mac = '';
  @Input() public set data(value: PortForwards) {
    this.initData = value;
    this.resetEditData();
  }
  @Input() public set cancelEdit$(value$: Observable<void>) {
    value$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.resetEditData();
    });
  }
  @Output() save = new EventEmitter<PortForwards>();
  @Output() cancel = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  private initData: PortForwards;
  portEditData = { name: '', internalPort: '', externalPort: '', protocol: 'tcp+udp' as 'tcp+udp' | 'udp' | 'tcp' };
  alert$ = new Subject<AlertMsg>();

  constructor(private validationService: ValidationService, private actions$: Actions) {
    this.startComponentEffects();
  }

  doCancel(): void {
    this.resetEditData();
    this.cancel.emit();
  }

  doDelete(): void {
    this.delete.emit();
  }

  doSave(): void {
    if (this.validateForm()) {
      this.save.emit({
        externalPort: +this.portEditData.externalPort,
        internalPort: +this.portEditData.internalPort,
        name: this.portEditData.name,
        protocol: this.portEditData.protocol
      });
    }
  }

  private resetEditData(): void {
    this.portEditData = {
      name: this.initData?.name,
      internalPort: this.initData?.internalPort.toString(),
      externalPort: this.initData?.externalPort.toString(),
      protocol: this.initData?.protocol || 'tcp+udp'
    };
  }

  private validateForm(): boolean {
    if (!this.validationService.portRange(this.portEditData.externalPort)) {
      this.alert$.next({
        message: 'portForwarding.errorMsg.portRangeExternal',
        type: AlertType.fail,
        needsTranslation: true
      });
      return false;
    }
    if (!this.validationService.portRange(this.portEditData.internalPort)) {
      this.alert$.next({
        message: 'portForwarding.errorMsg.portRangeInternal',
        type: AlertType.fail,
        needsTranslation: true
      });
      return false;
    }

    return true;
  }

  private startComponentEffects() {
    this.actions$
      .pipe(
        ofType(addPortForwardingError),
        filter(() => this.editMode === false),
        filter((error) => this.mac === error.mac),
        untilDestroyed(this)
      )
      .subscribe((action) => {
        this.alert$.next({
          type: AlertType.fail,
          message: (action.error.error as { error: { message: string } }).error.message
        });
      });
  }
}
