import { Component, Inject } from '@angular/core';
import { PlmDialogToken, PlmDialogInject } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  templateUrl: './update-location.component.html',
  styleUrls: ['./update-location.component.scss']
})
export class UpdateLocationComponent {
  constructor(@Inject(PlmDialogToken) public data: PlmDialogInject<void, boolean>) {}

  get transPth(): string {
    return 'aisecurity.settings.updateLocationDialog.';
  }
}
