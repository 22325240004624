/* eslint-disable @typescript-eslint/no-shadow */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { SecurityPolicyService } from '../../services/security-policy/security-policy.service';
import { SettingsService } from '../../services/setting.service';
import {
  addNewWifiPassword,
  addNewWifiPasswordError,
  addNewWifiPasswordSuccess,
  changeNetworkMode,
  addNewWifiPasswordWithAccessZone,
  deleteWifiAccessZone,
  deleteWifiAccessZoneError,
  deleteWifiAccessZoneSuccess,
  deleteWifiPassword,
  deleteWifiPasswordError,
  deleteWifiPasswordSuccess,
  editWifiAccessZone,
  editWifiAccessZoneError,
  editWifiAccessZoneSuccess,
  editWifiPassword,
  editWifiPasswordError,
  editWifiPasswordSuccess,
  setSsidName,
  setWifiNetwork,
  setWifiNetworkLoaded,
  ssidLoaded,
  ssidLoadedError,
  getUpnp,
  getUpnpSuccess,
  getUpnpFail,
  setUpnp,
  setUpnpSuccess,
  getDhcpReservations,
  getDhcpReservationsSuccess,
  getDhcpReservationsFail,
  newIpReservation,
  newIpReservationSuccess,
  newIpReservationFailed,
  deleteIpReservation,
  deleteIpReservationSuccess,
  deleteIpReservationFailed,
  editIpReservation,
  editIpReservationSuccess,
  editIpReservationFailed,
  getDns,
  getDnsSuccess,
  setDns,
  setDnsSuccess,
  getDnsFail,
  addPortForwarding,
  addPortForwardingSuccess,
  addPortForwardingError,
  deletePortForwarding,
  deletePortForwardingSuccess,
  deletePortForwardingError,
  editPortForwarding,
  editPortForwardingSuccess,
  editPortForwardingError,
  getDhcp,
  getDhcpSuccess,
  setDhcp,
  setDhcpSuccess,
  getDhcpFail
} from '../actions/settings.actions';

@Injectable()
export class SettingsEffects {
  setSsidName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSsidName),
      switchMap(action =>
        this.policy.setWifiNetwork$({ ssid: action.ssid }, action.locationId, ['422']).pipe(
          map(wifiNetwork => ssidLoaded({ wifiNetwork })),
          catchError((error: HttpErrorResponse) =>
            of(
              ssidLoadedError({
                errorMessage: (error.error as { error: { message: string } }).error.message,
                locationId: action.locationId
              })
            )
          )
        )
      )
    )
  );

  setWifiNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setWifiNetwork),
      switchMap(({ wifiNetwork, locationId }) => this.policy.setWifiNetwork$(wifiNetwork, locationId)),
      map(wifiNetwork => setWifiNetworkLoaded({ wifiNetwork }))
    )
  );

  addNewWifiPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewWifiPassword),
      switchMap(({ accessZone, password, expiresAt, locationId }) =>
        this.policy.addWifiPassword$(accessZone, password, expiresAt, locationId).pipe(
          map(keys => addNewWifiPasswordSuccess({ keys })),
          catchError((error: HttpErrorResponse) => of(addNewWifiPasswordError({ error })))
        )
      )
    )
  );

  editWifiPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editWifiPassword),
      switchMap(({ key, locationId }) =>
        this.policy.editWifiPassword$(key, locationId).pipe(
          map(keys => editWifiPasswordSuccess({ keyId: key.id, keys })),
          catchError((error: HttpErrorResponse) => of(editWifiPasswordError({ keyId: key.id, error })))
        )
      )
    )
  );

  editWifiAccessZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editWifiAccessZone),
      switchMap(({ accessZone, locationId }) =>
        this.policy.editWifiAccessZone$(accessZone, locationId).pipe(
          map(accessZone => editWifiAccessZoneSuccess({ accessZone })),
          catchError((error: HttpErrorResponse) => of(editWifiAccessZoneError({ error })))
        )
      )
    )
  );

  deleteWifiPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteWifiPassword),
      switchMap(({ key, locationId }) =>
        this.policy.deleteWifiPassword$(key, locationId).pipe(
          map(keys => deleteWifiPasswordSuccess({ keyId: key.id, keys })),
          catchError((error: HttpErrorResponse) => of(deleteWifiPasswordError({ keyId: key.id, error })))
        )
      )
    )
  );

  deleteWifiAccessZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteWifiAccessZone),
      switchMap(({ key, locationId }) =>
        this.policy.deleteAccessZone$(key.accessZoneId, locationId).pipe(
          map(zones => deleteWifiAccessZoneSuccess({ accessZones: zones, keyId: key.id })),
          catchError((error: HttpErrorResponse) => of(deleteWifiAccessZoneError({ keyId: key.id, error })))
        )
      )
    )
  );

  addNewWifiPasswordWithAccessZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewWifiPasswordWithAccessZone),
      switchMap(({ accessZone, password, expiresAt, locationId, guestName, accessibleDevices }) =>
        this.policy
          .addWifiAccessZoneWithPassword$(password, accessZone, guestName, accessibleDevices, expiresAt, locationId)
          .pipe(
            map(keys => addNewWifiPasswordSuccess({ keys })),
            catchError((error: HttpErrorResponse) => of(addNewWifiPasswordError({ error })))
          )
      )
    )
  );

  changeNetworkMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeNetworkMode),
        switchMap(action => this.settings.switchNetworkMode$(action.mode))
      ),
    { dispatch: false }
  );

  upnp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUpnp),
      switchMap(() =>
        this.settings.getUpnp$().pipe(
          map(upnp => getUpnpSuccess({ upnp })),
          catchError(error => of(getUpnpFail(error)))
        )
      )
    )
  );

  getDhcpReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDhcpReservations),
      switchMap(() =>
        this.settings.getDhcpReservations$().pipe(
          map(dhcpReservations => getDhcpReservationsSuccess({ dhcpReservations })),
          catchError(error => of(getDhcpReservationsFail(error)))
        )
      )
    )
  );

  dns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDns),
      switchMap(() =>
        this.settings.getDns$().pipe(
          map(dnsServers => getDnsSuccess({ dnsServers })),
          catchError(error => of(getUpnpFail(error)))
        )
      )
    )
  );

  setUpnp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setUpnp),
      switchMap(data =>
        this.settings.setUpnp$(data.mode).pipe(
          map(upnp => setUpnpSuccess({ upnp })),
          catchError(error => of(getUpnpFail(error)))
        )
      )
    )
  );

  newIpReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newIpReservation),
      switchMap(data =>
        this.settings.editIpReservation$(data).pipe(
          map(() => newIpReservationSuccess({ ip: data.ip, mac: data.mac, name: data.name, hostname: data.hostName })),
          catchError(error => of(newIpReservationFailed(error)))
        )
      )
    )
  );

  editIpReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editIpReservation),
      switchMap(data =>
        this.settings.editIpReservation$(data).pipe(
          map(() => editIpReservationSuccess({ ip: data.ip, mac: data.mac })),
          catchError((error: HttpErrorResponse) => of(editIpReservationFailed({ error, mac: data.mac })))
        )
      )
    )
  );

  deleteIpReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteIpReservation),
      switchMap(data =>
        this.settings.deleteIpReservation$(data.mac).pipe(
          map(() => deleteIpReservationSuccess({ mac: data.mac })),
          catchError((error: HttpErrorResponse) => of(deleteIpReservationFailed({ mac: data.mac, error })))
        )
      )
    )
  );

  setDns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDns),
      switchMap(data =>
        this.settings.setDns$(data.dns).pipe(
          switchMap(dns => [setDnsSuccess({ dns })]),
          catchError(error => of(getDnsFail(error)))
        )
      )
    )
  );

  addPortForwarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPortForwarding),
      switchMap(data =>
        this.settings.addPortForwarding$(data.mac, data.port).pipe(
          map(res => addPortForwardingSuccess({ mac: data.mac, port: res })),
          catchError((error: HttpErrorResponse) => of(addPortForwardingError({ mac: data.mac, error })))
        )
      )
    )
  );

  dhcp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDhcp),
      switchMap(() =>
        this.settings.getDhcp$().pipe(
          map(dhcp => getDhcpSuccess({ dhcp: dhcp })),
          catchError(error => of(getUpnpFail(error)))
        )
      )
    )
  );

  editPortForwarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editPortForwarding),
      switchMap(data =>
        this.settings.editPortForwarding$(data.mac, data.port).pipe(
          map(res => editPortForwardingSuccess({ mac: data.mac, port: res })),
          catchError((error: HttpErrorResponse) =>
            of(editPortForwardingError({ mac: data.mac, error, externalPort: data.port.externalPort }))
          )
        )
      )
    )
  );

  deletePortForwarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePortForwarding),
      switchMap(({ mac, externalPort }) =>
        this.settings.deletePortForwarding$(mac, externalPort).pipe(
          map(ports => deletePortForwardingSuccess({ mac: mac, ports, externalPort })),
          catchError((error: HttpErrorResponse) => of(deletePortForwardingError({ mac: mac, error, externalPort })))
        )
      )
    )
  );

  setDhcp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDhcp),
      switchMap(data =>
        this.settings.setDhcp$(data.dhcp).pipe(
          map(dhcp => setDhcpSuccess({ dhcp })),
          catchError(error => of(getDhcpFail(error)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private policy: SecurityPolicyService, private settings: SettingsService) {}
}
