<div
  class="arrow left icon"
  *ngIf="scrollArrowVisible === 'left' && canBeScrolled(guardScroller)"
  (click)="scrollTo(guardScroller, 'left')"
  icon="arrow-right"
></div>
<div
  class="arrow right icon"
  *ngIf="scrollArrowVisible === 'right' && canBeScrolled(guardScroller)"
  (click)="scrollTo(guardScroller, 'right')"
  icon="arrow-right"
></div>

<div class="guardTileBox" #guardScroller>
  <ng-container *ngFor="let type of tiles">
    <plm-guard-tile
      #tileElm
      [type]="type"
      [count]="summary[type]"
      [class.selected]="activeTile === type"
      (click)="tileClickedAction(type, guardScroller, tileElm)"
    ></plm-guard-tile>
  </ng-container>
</div>
