import { Component, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';
import { SpeedForChart } from 'src/app/lib/rest-types';
import { CalculationsService } from '../calculations/calculations.service';

@Component({
  selector: 'plm-speed-test-graph',
  templateUrl: './speed-test-graph.component.html',
  styleUrls: ['./speed-test-graph.component.scss'],
  providers: [CalculationsService]
})
export class SpeedTestGraphComponent {
  @Input() public set chartData(chartData: SpeedForChart[]) {
    this.chartDataIsSet = chartData && chartData.length > 0;
    this.setChartData(chartData);
  }
  @Input() mode: 'upload' | 'download' = 'download';
  @Input() testInProgress = true;
  @Input() public set xAxis(axis: 'day' | 'week' | 'month') {
    this.valXAxis = axis;
    this.setChartData(this.valChartData);
  }
  public get xAxis(): 'day' | 'week' | 'month' {
    return this.valXAxis;
  }

  valXAxis: 'day' | 'week' | 'month' = 'day';
  valChartData: SpeedForChart[];
  toolTipIndex = -1;
  private chartDataIsSet = true;

  constructor(private elm: ElementRef<HTMLElement>, public calculations: CalculationsService) {}

  render(): void {
    if (this.mode !== 'download' && this.mode !== 'upload') {
      return;
    }
    const { width, height } = this.elmContentSize();
    this.calculations.recalculate(
      width,
      height,
      this.valChartData.map(sample => ({ x: sample.timestamp, y: sample[this.mode] }))
    );
  }

  setToolTipIndex(index: number): void {
    this.toolTipIndex = index;
  }

  round(value: number): number {
    return Math.round(value);
  }

  dayTimeFormat(timestamp: number): string {
    return `${moment(timestamp).format('MMM D')}, ${moment(timestamp).format('dddd')}`;
  }

  weekTimeFormat(timestamp: number): string {
    return `${moment(timestamp).format('MMM D')}, ${moment(timestamp)
      .format('LT')
      .toLowerCase()}`;
  }

  private elmContentSize(): { width: number; height: number } {
    const computedStyles = getComputedStyle(this.elm.nativeElement);

    const paddingX = (parseFloat(computedStyles.paddingLeft) || 0) + (parseFloat(computedStyles.paddingRight) || 0);
    const paddingY = (parseFloat(computedStyles.paddingTop) || 0) + (parseFloat(computedStyles.paddingBottom) || 0);

    const borderX =
      (parseFloat(computedStyles.borderLeftWidth) || 0) + (parseFloat(computedStyles.borderRightWidth) || 0);
    const borderY =
      (parseFloat(computedStyles.borderTopWidth) || 0) + (parseFloat(computedStyles.borderBottomWidth) || 0);
    const rect = this.elm.nativeElement.getBoundingClientRect();
    return {
      width: rect.width - paddingX - borderX,
      height: rect.height - paddingY - borderY
    };
  }

  private setChartData(chartData: SpeedForChart[]): void {
    if (this.chartDataIsSet) {
      this.valChartData = chartData;
    } else {
      if (this.xAxis === 'week') {
        this.valChartData = this.calculations.emptyWeekChart();
      } else if (this.xAxis === 'month') {
        this.valChartData = this.calculations.emptyMonthChart();
      } else {
        this.valChartData = this.calculations.emptyDayChart();
      }
    }
    this.render();
  }
}
