import { TileTypes } from 'src/app/components/guard-tile/guard-tile.component';
import { DayVisualElement } from 'src/app/lib/d3/models/charts/security.chart';

export interface IAiSecurity {
  deviceMac: string;
  day: DayVisualElement;
  eventType: TileTypes;
}

export const initialAiSecurityState: IAiSecurity = {
  deviceMac: null,
  day: null,
  eventType: 'all'
};
