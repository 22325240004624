export enum AccessPasswordDropDownType {
  copyEdit,
  enableDelete,
  all,
  none
}

export enum AccessPasswordDropDownClicked {
  toggleEnable,
  copy,
  delete,
  edit
}
