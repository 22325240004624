import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as elementResizeDetectorMaker from 'element-resize-detector';

@Directive({
  selector: '[plmResized]'
})
export class ResizedDirective implements OnInit, OnDestroy {
  @Output() plmResized = new EventEmitter<{ width: number; height: number }>();
  constructor(private readonly element: ElementRef) {}
  erdUltraFast: elementResizeDetectorMaker.Erd;
  ngOnInit(): void {
    this.erdUltraFast = elementResizeDetectorMaker({
      strategy: 'scroll'
    });

    this.erdUltraFast.listenTo(this.element.nativeElement, element => {
      const width = element.offsetWidth;
      const height = element.offsetHeight;
      this.plmResized.emit({ width, height });
    });
  }

  ngOnDestroy(): void {
    this.erdUltraFast.uninstall(this.element.nativeElement);
  }
}
