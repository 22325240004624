import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpaceComponent } from './space.component';

@NgModule({
  imports: [CommonModule],
  exports: [SpaceComponent],
  declarations: [SpaceComponent],
  providers: []
})
export class SpaceModule {}
