import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'plm-rename-pod',
  templateUrl: './rename-pod.component.html',
  styleUrls: ['./rename-pod.component.scss']
})
export class RenamePodComponent {
  @Input() public set podName(name: string) {
    this.privPodName = name;
    this.inputModel = name;
  }
  public get podName(): string {
    return this.privPodName;
  }

  @Output() nameChange = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();
  private privPodName = '';
  inputModel = '';

  clearModel(): void {
    this.inputModel = '';
  }

  close(): void {
    this.cancel.emit();
  }

  save(): void {
    if (this.inputModel) {
      this.nameChange.emit(this.inputModel);
    }
  }
}
