import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DayVisualElement } from 'src/app/lib/d3/models/charts/security.chart';
import { SecurityPolicyEvents } from 'src/app/lib/rest-types';
import { IPlumeState } from 'src/app/lib/store';
import {
  loadSecurityDailyCounts,
  loadSecurityByMac,
  reloadSecurityCounts,
  loadSecurityEvents,
  loadMoreSecurityEvents,
  reLoadSecurityEvents
} from 'src/app/lib/store/actions/insights.actions';
import { HttpState, LoadingState } from 'src/app/lib/store/interfaces/http-state.interface';
import {
  policyOngoingChanges,
  securityDailyCounts,
  securityEvents,
  securityEventsLimit
} from 'src/app/lib/store/selectors/insights.selectors';
import { SelectedDay, selectEventsFilter } from 'src/app/lib/store/selectors/local-state/ai-security.selectors';
import * as moment from 'moment';
import { Device } from 'src/app/lib/store/models/device.model';
import { PolicyOngoingChanges } from 'src/app/lib/store/interfaces/insights.interface';
import { SecurityPolicyActions } from 'src/app/lib/store/actions/security-policy.actions';
import { Actions, ofType } from '@ngrx/effects';
import { SecurityPolicyEventsLoading } from 'src/app/components/guard-event-list/guard-event-list.component';
import { selectDevices, selectPeople } from 'src/app/lib/store/selectors/home.selectors';

@UntilDestroy()
@Component({
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  aiSecurityDailyCounts$ = this.store$.pipe(select(securityDailyCounts));
  devices$ = this.store$.select(selectDevices);
  people$ = this.store$.select(selectPeople);
  events$ = this.store$.select(securityEvents).pipe(
    tap(() => this.collectGarbage()),
    switchMap(events => this.policyOngoingChanges$.pipe(map(progress => this.mapEventsProgress(events, progress)))),
    withLatestFrom(this.store$.select(SelectedDay)),
    map(([events, day]) => this.filterDay(events, day))
  );
  eventLoading$ = this.store$.select(securityEvents).pipe(map(e => e.state !== LoadingState.loaded));
  selectEventsFilter$ = this.store$.select(selectEventsFilter);
  policyOngoingChanges$ = this.store$.select(policyOngoingChanges);
  allLoaded$ = this.events$.pipe(
    withLatestFrom(this.store$.select(securityEventsLimit)),
    map(([events, eventsLimit]) => events.data?.length < eventsLimit)
  );
  approveDialogOpenedVal = false;

  constructor(private store$: Store<IPlumeState>, private actions$: Actions) {}

  ngOnInit(): void {
    this.store$.dispatch(reloadSecurityCounts());
    this.startComponentEffects();
  }

  onDeviceSelected(device: Device): void {
    if (device) {
      this.store$.dispatch(loadSecurityByMac({ macAddress: device.mac }));
    } else {
      this.store$.dispatch(loadSecurityDailyCounts());
    }
  }

  loadMore(): void {
    this.store$.dispatch(loadMoreSecurityEvents());
  }

  whitelistEvent(data: {
    event: SecurityPolicyEvents & { device: Device };
    type: 'everyone' | 'device' | 'person';
  }): void {
    this.store$.dispatch(
      SecurityPolicyActions.addSecurityPolicyWhiteList({
        url: data.event.eventData.topLevelDomain ?? data.event.eventData.ipaddress,
        dnsType: data.event.eventData.fqdn ? 'fqdn' : 'ip',
        for: data.type,
        personId: data.event.device.personId,
        mac: data.event.eventData.mac
      })
    );
  }

  reloadSecurity(): void {
    this.store$.dispatch(reloadSecurityCounts());
    this.store$.dispatch(reLoadSecurityEvents());
  }

  approveDialogOpened(val: boolean): void {
    this.approveDialogOpenedVal = val;
  }

  private collectGarbage(): void {
    if (!this.approveDialogOpenedVal) {
      this.store$.dispatch(SecurityPolicyActions.garbageCollectPolicyChangeList());
    }
  }

  private filterDay(
    data: HttpState<SecurityPolicyEventsLoading[]>,
    day?: DayVisualElement
  ): HttpState<SecurityPolicyEventsLoading[]> {
    if (day) {
      const selectedDay = moment(day.timestamp).startOf('day');
      data = {
        ...data,
        data: data.data?.filter(x =>
          moment(x.createdAt)
            .startOf('day')
            .isSame(selectedDay)
        )
      };
    }

    return data;
  }

  private startComponentEffects(): void {
    this.selectEventsFilter$.pipe(untilDestroyed(this)).subscribe(() => this.store$.dispatch(loadSecurityEvents()));
    this.actions$
      .pipe(
        ofType(
          SecurityPolicyActions.addSecurityPolicyWhiteListSuccess,
          SecurityPolicyActions.removeSecurityPolicyWhiteListSuccess
        ),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.reloadSecurity();
      });
  }

  private mapEventsProgress(
    events: HttpState<SecurityPolicyEvents[]>,
    progress: { [key: string]: PolicyOngoingChanges }
  ): HttpState<SecurityPolicyEventsLoading[]> {
    return {
      ...events,
      data: events?.data?.map(event => ({
        ...event,
        eventData: {
          ...event.eventData,
          loading: !!progress[event.eventData.topLevelDomain] || !!progress[event.eventData.ipaddress]
        }
      }))
    };
  }
}
