import { FormControl } from '@angular/forms';

export enum FormInputType {
  SEARCH = 'search',
  PASSWORD = 'password',
  TEXT = 'text'
}

export interface FormInputBaseField {
  name: string;
  label?: string;
  control: FormControl;
  type: FormInputType;
  placeholder?: string;
}
