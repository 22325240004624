<ng-container [ngSwitch]="state">
  <ng-container *ngSwitchCase="ProgressState.initial">
    <span *ngIf="!offline">{{ 'boxSpeedTestProgress.checkSpeedNow' | translate }}</span>
    <span *ngIf="offline">{{ 'boxSpeedTestProgress.offline' | translate }}</span>
    <span class="icon" icon="speedtest-powered"></span>
  </ng-container>
  <ng-container *ngSwitchCase="ProgressState.preparing">
    <span>{{ 'boxSpeedTestProgress.preparing' | translate }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="ProgressState.checkingDownload">
    <span>{{ 'boxSpeedTestProgress.checkingDownload' | translate }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="ProgressState.checkingUpload">
    <span>{{ 'boxSpeedTestProgress.checkingUpload' | translate }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="ProgressState.stillCalculating">
    <span>{{ 'boxSpeedTestProgress.stillCalculating' | translate }}</span>
    <span class="description">{{ 'boxSpeedTestProgress.stillCalculatingDesc' | translate }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="ProgressState.failed">
    <span>{{ 'boxSpeedTestProgress.failed' | translate }}</span>
  </ng-container>
</ng-container>

<div #progress class="progress"></div>
