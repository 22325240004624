<div class="carousel-content">
  <div class="item flex-center">
    <div class="title">
      {{ 'troubleshooting.obstructions.title' | translate }}
    </div>
    <div class="description">
      <p>{{ 'troubleshooting.obstructions.description' | translate }}</p>
    </div>
  </div>
  <div class="item image"><img src="/assets/images/obstructions.svg" width="240" /></div>
</div>
