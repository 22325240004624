import { NgModule } from '@angular/core';

import { RuleComponent } from './rule.component';

@NgModule({
    imports: [],
    exports: [RuleComponent],
    declarations: [RuleComponent],
    providers: [],
})
export class RuleModule { }
