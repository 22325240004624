import { Component, Inject } from '@angular/core';
import { PlmDialogToken, PlmDialogInject } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  selector: 'plm-delete-wifi-password',
  templateUrl: './delete-wifi-password.component.html',
  styleUrls: ['./delete-wifi-password.component.scss']
})
export class DeleteWifiPasswordComponent {
  constructor(@Inject(PlmDialogToken) public dialog: PlmDialogInject<string, boolean>) {}

  get transPth(): string {
    return 'settings.access.dialogs.deleteWifiPassword.';
  }
}
