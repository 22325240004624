import { ChangeDetectorRef } from '@angular/core';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CalculationsService } from '../calculations/calculations.service';

@Component({
  selector: 'plm-column-graph-tool-tip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.scss']
})
export class ToolTipComponent implements AfterViewInit {
  @ViewChild('borderBox') borderBox: ElementRef<HTMLElement>;
  @Input() index = 0;

  overflowOnLeft = false;
  overflowOnRight = false;

  constructor(private cdRef: ChangeDetectorRef, public calculations: CalculationsService) {}

  ngAfterViewInit(): void {
    const width = this.borderBox?.nativeElement.getBoundingClientRect().width;
    this.overflowOnLeft = width / 2 > this.calculations.columnXCenter(this.index);
    this.overflowOnRight = this.calculations.columnXCenter(this.index) + width / 2 > this.calculations.width;
    this.cdRef.detectChanges();
  }
}
