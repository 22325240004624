import { Component, Input } from '@angular/core';

@Component({
  selector: 'plm-channel-connection',
  templateUrl: './channel-connection.component.html',
  styleUrls: ['./channel-connection.component.scss']
})
export class ChannelConnectionComponent {
  @Input() podBandwidth: string;
  @Input() channelNumber: number;
}
