import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { hideChat, initBraze } from 'src/app/lib/store/actions/plume.actions';
import { IPlumeState } from 'src/app/lib/store';
import { MenuItem } from 'src/app/components/ui/menu/menu.model';
import { TranslateService } from '@ngx-translate/core';
import { nodes } from 'src/app/lib/store/selectors/home.selectors';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { onboarded } from 'src/app/lib/store/selectors/plume.selectors';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';

@UntilDestroy()
@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  menuItems: MenuItem[];
  settingsMenu: MenuItem[];
  showBanner = false;
  nodesSubscription$: Subscription;
  nodeMenuLinks: { title: string; link: string }[] = [];
  onboardedSubscription$: Subscription;
  isOnboarded: boolean;
  panelRightHide = false;
  mainColSpan = '6';
  data: Data;
  rightColStart = null;
  rightColSpan = '3';
  parentRoute: string;
  showMobileSideMenu = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth > 720 && window.innerWidth < 1023) {
      this.mainColSpan = '8';
      this.rightColStart = '4';
      this.rightColSpan = '8';
    } else {
      this.mainColSpan = '6';
      this.rightColStart = null;
      this.rightColSpan = '3';
    }
    this.showMobileSideMenu = this.showMobileSideMenu && window.innerWidth <= 720;
  }

  constructor(
    private store$: Store<IPlumeState>,
    private translate: TranslateService,
    activatedRoute: ActivatedRoute,
    router: Router
  ) {
    // Controls if right hand panel should be shown or not based on route data.
    router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects.split('?')[0];
        const data = this.getUrlData(activatedRoute.snapshot, url);
        if (data && data['panelRightHide']) {
          this.mainColSpan = '9';
          this.panelRightHide = true;
        } else {
          this.mainColSpan = '6';
          this.panelRightHide = false;
        }

        this.showMobileSideMenu = false;
        this.parentRoute = url.split('/')[1];
        this.buildMenu();
      }
    });
  }

  ngOnInit(): void {
    this.store$.dispatch(initBraze());
    this.store$.dispatch(hideChat());
    this.onboardedSubscription$ = this.store$
      .select(onboarded)
      .pipe(untilDestroyed(this))
      .subscribe((isOnboarded) => (this.isOnboarded = isOnboarded));

    this.nodesSubscription$ = this.store$
      .select(nodes)
      .pipe(untilDestroyed(this))
      .subscribe((pods) => {
        if (pods) {
          this.nodeMenuLinks = [];
          pods.forEach((node) => {
            this.nodeMenuLinks.push({ title: node.nickname ?? node.defaultName, link: `/pods/${node.id}` });
          });
        }

        this.buildMenu();
      });
  }

  private buildMenu() {
    this.menuItems = this.commonMenu();
    if (this.isOnboarded) {
      this.menuItems = this.onboardedMenu(this.menuItems);
    }
  }

  // eslint-disable-next-line max-lines-per-function
  commonMenu(): MenuItem[] {
    return [
      {
        icon: 'newsfeed',
        title: this.translate.instant('mainMenu.newsfeed') as string,
        expandable: true,
        isHeading: false,
        link: '#',
        opened: this.parentRoute === 'news' ? true : false,
        children: [
          {
            title: this.translate.instant('mainMenu.newsAll') as string,
            link: '/news'
          },
          {
            title: this.translate.instant('mainMenu.tips') as string,
            link: '/news/tips'
          },
          {
            title: this.translate.instant('mainMenu.plumeBlog') as string,
            link: '/news/blog'
          },
          {
            title: this.translate.instant('mainMenu.releaseNotes') as string,
            link: '/news/releasenotes'
          }
        ]
      },
      {
        title: this.translate.instant('settingsMenu.settings') as string,
        expandable: true,
        isHeading: true,
        seperator: true,
        link: '#',
        opened: this.parentRoute === 'settings' ? true : false,
        children: [
          {
            icon: 'wifi',
            title: this.translate.instant('settingsMenu.adapt') as string,
            link: '/settings/adapt'
          },
          {
            icon: 'key',
            title: this.translate.instant('settingsMenu.access') as string,
            link: '/settings/access',
            activeOnChild: true
          },
          {
            icon: 'guard',
            title: this.translate.instant('settingsMenu.guard') as string,
            link: '/settings/guard'
          },
          {
            icon: 'advanced-wifi-settings',
            title: this.translate.instant('settingsMenu.advanceWifi') as string,
            subTitle: this.translate.instant('settingsMenu.advanceWifiSub') as string,
            link: '/settings/advanced',
            rule: true
          }
        ]
      }
    ];
  }

  onboardedMenu(commonMenu: MenuItem[]): MenuItem[] {
    return [
      {
        icon: 'house',
        title: this.translate.instant('mainMenu.home') as string,
        expandable: false,
        isHeading: false,
        link: '/insights'
      },
      ...this.onboardedAndPodsMenu(),
      ...commonMenu
    ];
  }

  onboardedAndPodsMenu(): MenuItem[] {
    return this.nodeMenuLinks.length > 0
      ? [
          {
            icon: 'pod',
            title: this.translate.instant('mainMenu.pods') as string,
            expandable: true,
            isHeading: false,
            link: '#',
            more: true,
            moreLimit: 10,
            opened: this.parentRoute === 'pods' ? true : false,
            children: [...this.nodeMenuLinks]
          }
        ]
      : [];
  }

  private getUrlData(snap: ActivatedRouteSnapshot, fullUrl: string): Data {
    const snapUrl = '/' + snap.url.map((segment) => segment.path).join('/');
    if (snapUrl === fullUrl && snap.data) {
      return snap.data;
    }
    for (const child of snap.children) {
      const res = this.getUrlData(child, fullUrl);
      if (res) {
        return res;
      }
    }
    return undefined;
  }
}
