import { Component, Inject } from '@angular/core';
import { PlmDialogToken, PlmDialogInject } from '../../../lib/services/dialog/dialog.service';

@Component({
    selector: 'plm-approve-block-error-modal',
    templateUrl: 'approve-block-error-modal.component.html',
    styleUrls: ['approve-block-error-modal.component.scss']
})

export class ApproveBlockErrorComponent  {

    public mode = this.data.data.mode;
    public message = this.data.data.message;

    constructor(@Inject(PlmDialogToken) public data: PlmDialogInject<{ mode: string, message: string }, boolean>) {}
}
