import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { SpeedForChart } from 'src/app/lib/rest-types';
import { CalculationsService } from '../calculations/calculations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[plm-x-axis-weeks]',
  templateUrl: './x-axis-weeks.component.html',
  styleUrls: ['./x-axis-weeks.component.scss']
})
export class XAxisWeeksComponent {
  @Input()
  public set chartData(value: SpeedForChart[]) {
    this.privateChartData = value;
    this.calcWeekIndexes();
  }
  public get chartData(): SpeedForChart[] {
    return this.privateChartData;
  }

  weekIndexes: number[] = [];

  private privateChartData: SpeedForChart[] = [];

  constructor(public calculations: CalculationsService) {}

  calcWeekIndexes(): void {
    const todayStart = moment().startOf('day');
    const chartMaxDate = moment(this.privateChartData[this.privateChartData.length - 1].timestamp);
    const containsToday = todayStart < chartMaxDate;
    this.weekIndexes = [];
    let index = this.calculations.axisSizeInUnits.maxX - (containsToday ? 1 : 0);
    while (index >= 0) {
      this.weekIndexes.push(index);
      index -= 7;
    }
  }

  xAxisBaseText(arrIndex: number): string {
    if (arrIndex === 0) {
      return 'time.today';
    }
    if (arrIndex === 1) {
      return 'time.sevenDaysNoAgo';
    }
    if (arrIndex % 4 === 0) {
      return 'time.monthNoAgo';
    }
    return 'time.weeksNoAgo';
  }
}
