import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'plm-space',
  template: '',
  styleUrls: ['./space.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Use `Space` to create vertical space between components.
 */
export class SpaceComponent {
  @Input() public size: 'xxxl' | 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs' | 'base' = 'm';

  @HostBinding('class')
  public get sizingCssClass(): string {
    return this.size;
  }
}
