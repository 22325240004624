import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { D3Service } from 'src/app/lib/d3/service';
import { SecurityChart, DayVisualElement, EventVisualElement } from 'src/app/lib/d3/models/charts/security.chart';
import * as erdm from 'element-resize-detector';
import { Margins } from 'src/app/lib/helper-types';

export type DayTooltip = {
  show: boolean;
  style: { [key: string]: string | number };
  count: number;
  day_text: string;
  text: string;
  day: DayVisualElement;
};

@Component({
  selector: 'plm-securitychart',
  templateUrl: './securitychart.component.html',
  styleUrls: ['./securitychart.component.scss']
})
export class SecurityChartVisualComponent implements AfterViewInit, OnChanges {
  @Input()
  events = [];

  @Input()
  dayView = null;

  @Input() selectedDay: DayVisualElement;

  @Output()
  public daySelected = new EventEmitter<DayVisualElement>();

  @ViewChild('canvas')
  canvas: ElementRef<HTMLCanvasElement>;

  chart: SecurityChart;

  data: DayVisualElement[] = [];
  plot = {
    days: [],
    hours: []
  };
  margins: Margins = {
    top: 15,
    right: 10,
    bottom: 55,
    left: 30
  };

  xAxis = [];
  yAxis = [];

  tooltipDay: DayTooltip = {
    show: false,
    style: { 'left.px': 0 },
    count: 0,
    day_text: '',
    text: '',
    day: null
  };

  initialized = false;

  erd = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service, private cdr: ChangeDetectorRef) {
    this.chart = this.d3.generate('securitychart');
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.canvas.nativeElement, () => {
      this.render();
      this.cdr.detectChanges();
    });
    // svg can be visible only after initialization because safari
    // don't accept gradient when second time loading svg (page change)
    this.initialized = true;
  }

  ngOnChanges(): void {
    if (this.canvas) {
      this.render();
    }
  }

  render(): void {
    this.chart.update(
      this.events,
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight,
      this.margins
    );

    this.xAxis = this.chart.xAxis();
    this.yAxis = this.chart.yAxis();

    this.data = this.chart.calculateData();
  }
  positionTooltipDay(day: DayVisualElement, mode: boolean): void {
    this.tooltipDay.style['left.px'] = day.center + this.margins.left;
    this.tooltipDay.text = day.text;
    this.tooltipDay.day_text = day.day_text;
    this.tooltipDay.show = mode;
    this.tooltipDay.count = day.events.reduce((accumulator, hourEvent) => accumulator + hourEvent.count, 0);
    this.tooltipDay.day = day;
  }

  showSelectedDay(day: DayVisualElement): void {
    this.daySelected.emit(day);
  }

  trackByDay(index: number, item: DayVisualElement): string | number {
    return item.dayIndex;
  }

  trackByDayEvents(index: number, item: EventVisualElement): string | number {
    return item.timestamp;
  }
}
