import { createSelector } from '@ngrx/store';
import { Configuration, Customer, CustomerLocation } from '../../rest-types';
import { IPlumeState } from '..';

export const currentLocationId = (state: IPlumeState): string => state.plumeState.currentLocationId;
export const locations = (state: IPlumeState): CustomerLocation[] => state.plumeState.locations;
export const cloudUrl = (state: IPlumeState): string => state.plumeState.cloudUrl;
export const cloudId = (state: IPlumeState): string => state.plumeState.cloudId;
export const configuration = (state: IPlumeState): Configuration => state.plumeState.configuration;
export const authToken = (state: IPlumeState): { token: string; expireAt: string } => state.plumeState.authToken;
export const onboarded = (state: IPlumeState): boolean =>
  !!state.plumeState.locations.find(loc => loc.id === state.plumeState.currentLocationId)?.onboardedAt;
export const customer = (state: IPlumeState): Customer => state.plumeState.customer;
export const customerFirstName = (state: IPlumeState): string =>
  state.plumeState.customer?.name.split(' ')[0][0].toUpperCase() +
  state.plumeState.customer?.name.split(' ')[0].substr(1);
export const customerIdWithLocationId = createSelector(
  currentLocationId,
  customer,
  (locationId: string, c: Customer) => ({ customerId: c?.id, locationId })
);
export const currentLocation = createSelector(currentLocationId, locations, (locationId, loc) =>
  loc.find(l => l.id === locationId)
);
export const showChat = (state: IPlumeState): boolean => state.plumeState.showChat;
