<plm-box [padding]="false">
  <div class="pod__header">
    <div class="pod__title">
      <span>{{ 'pod.title' | translate }}</span>
    </div>
    <plm-overlay-button
      [buttonIcon]="'more'"
      [iconOnly]="true"
      [overlayHeight]="152"
      [overlayWidth]="225"
      [offSetY]="180"
      offSetX="0"
      [border]="false"
      originX="end"
      originY="top"
      overlayX="end"
      overlayY="bottom"
    >
      <plm-space size="xxs"></plm-space>
      <plm-list class="pod__menu">
        <li (click)="renamePod()"><span class="icon" icon="check"></span>{{ 'pod.renamePod' | translate }}</li>
        <li (click)="deletePod()"><span class="icon" icon="close"></span>{{ 'pod.deletePod' | translate }}</li>
        <li (click)="accordion.openItem(accordionItemHwInfo)">
          <span class="icon" icon="info"></span>{{ 'pod.hardwareInfo' | translate }}
        </li>
      </plm-list>
    </plm-overlay-button>
  </div>
  <div class="pod__summary">
    <div class="pod__icon">
      <div class="icon" icon="superPod"></div>
      <div *ngIf="isGateway" class="icon centerIcon" icon="gfx-globe"></div>
    </div>
    <plm-space size="xxs"></plm-space>
    <div class="roomname" *ngIf="!renamingPod">{{ (node$ | async)?.nickname || (node$ | async)?.defaultName }}</div>
    <plm-rename-pod
      [class.disabled]="renameInProgress"
      [podName]="(node$ | async)?.nickname || (node$ | async)?.defaultName"
      *ngIf="renamingPod"
      (cancel)="cancelRenamePod()"
      (nameChange)="applyRenamePod($event)"
    ></plm-rename-pod>
    <plm-space size="xxs"></plm-space>
    <div *ngIf="!isGateway && !renamingPod" class="">
      <plm-signal-pill (click)="openSignalHealthDesc()" [signalHealth]="healthStatus(node$ | async)"></plm-signal-pill>
      <plm-channel-connection
        *ngIf="(node$ | async)?.connectionState === 'connected'"
        [podBandwidth]="podBandwidth"
        [channelNumber]="channelNumber"
      ></plm-channel-connection>
      <plm-parent-node
        *ngIf="(node$ | async)?.connectionState === 'connected'"
        [node]="parentNode"
        [routerLink]="['..', parentNode.id]"
      ></plm-parent-node>
    </div>
  </div>
  <plm-space size="l"></plm-space>
  <plm-no-device
    *ngIf="(node$ | async)?.connectedDeviceCount === 0"
    [podName]="(node$ | async)?.nickname || (node$ | async)?.defaultName"
  ></plm-no-device>
  <plm-accordion #accordion>
    <plm-accordion-item *ngIf="(node$ | async)?.health?.status === 'poor'" [noBorder]="true" [alert]="true">
      <div slot-heading>
        {{ 'troubleshooting.titles.poorHealthPod' | translate }}
      </div>
      <div slot-body>
        <plm-troubleshooting-carousel></plm-troubleshooting-carousel>
      </div>
    </plm-accordion-item>

    <plm-accordion-item *ngIf="(node$ | async)?.connectionState === 'disconnected'">
      <div class="offlineHeader" slot-heading>
        {{ 'troubleshooting.whyOffline.title' | translate }}
      </div>
      <div slot-body>
        <plm-pod-why-offline></plm-pod-why-offline>
      </div>
    </plm-accordion-item>

    <plm-accordion-item #accordionItemDevices *ngIf="(node$ | async)?.connectedDeviceCount > 0">
      <div slot-heading>
        {{ (node$ | async)?.connectedDeviceCount }} {{ 'pod.devices.connectedDevices' | translate }}
      </div>
      <div slot-body>
        <plm-device-item *ngFor="let device of connectedDevices$ | async" [device]="device"></plm-device-item>
      </div>
    </plm-accordion-item>

    <plm-accordion-item #accordionItemHwInfo [noBorder]="true">
      <div slot-heading>
        {{ 'troubleshooting.titles.viewHardware' | translate }}
      </div>
      <div slot-body>
        <plm-hardware-info [hardware]="hardwareInfo"></plm-hardware-info>
      </div>
    </plm-accordion-item>
  </plm-accordion>
  <plm-alert-pop-bar [alert$]="alert$"></plm-alert-pop-bar>
</plm-box>
