import { Component, Inject } from '@angular/core';
import { PlmDialogToken, PlmDialogInject } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  templateUrl: './enable-online-protection.component.html',
  styleUrls: ['./enable-online-protection.component.scss']
})
export class EnableOnlineProtectionComponent {
  constructor(@Inject(PlmDialogToken) public data: PlmDialogInject<void, boolean>) {}

  get transPth(): string {
    return 'aisecurity.settings.enableOnlineProtectionDialog.';
  }
}
