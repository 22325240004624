import { Component, Input } from '@angular/core';

@Component({
  selector: 'plm-no-device',
  templateUrl: 'no-device.component.html',
  styleUrls: ['no-device.component.scss']
})
export class NoDeviceComponent {
  @Input() podName: string;
}
