import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MobileBannerComponent } from './mobile-banner.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [MobileBannerComponent],
  declarations: [MobileBannerComponent],
  providers: []
})
export class MobileBannerModule {}
