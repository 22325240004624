import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { WifiNetwork } from '../../rest-types';
import { SecurityPolicyModel } from '../models/security-policy.model';

const addSecurityPolicyWhiteList = createAction(
  '[Security] add security policy white list',
  props<
    { url: string; dnsType: 'fqdn' | 'ip' } & (
      | { personId: string; for: 'person' }
      | { mac: string; for: 'device' }
      | { for: 'everyone' }
    )
  >()
);

const addSecurityPolicyBlackList = createAction(
  '[Security] add security policy black list',
  props<
    { url: string; dnsType: 'fqdn' | 'ip' } & (
      | { personId: string; for: 'person' }
      | { mac: string; for: 'device' }
      | { for: 'everyone' }
    )
  >()
);

const removeSecurityPolicyWhiteList = createAction(
  '[Security] remove security policy white list',
  props<
    { url: string } & ({ personId: string; for: 'person' } | { mac: string; for: 'device' } | { for: 'everyone' })
  >()
);

const removeSecurityPolicyBlackList = createAction(
  '[Security] remove security policy black list',
  props<
    { url: string } & ({ personId: string; for: 'person' } | { mac: string; for: 'device' } | { for: 'everyone' })
  >()
);

const addSecurityPolicyWhiteListFail = createAction(
  '[Security] add security policy white list fail',
  props<{ error: HttpErrorResponse; url: string }>()
);

const addSecurityPolicyBlackListFail = createAction(
  '[Security] add security policy black list fail',
  props<{ error: HttpErrorResponse; url: string }>()
);

const removeSecurityPolicyWhiteListFail = createAction(
  '[Security] remove security policy white list fail',
  props<{ error: HttpErrorResponse; url: string }>()
);

const removeSecurityPolicyBlackListFail = createAction(
  '[Security] remove security policy black list fail',
  props<{ error: HttpErrorResponse; url: string }>()
);

const addSecurityPolicyWhiteListSuccess = createAction(
  '[Security] add security policy white list success',
  props<{ url: string }>()
);

const addSecurityPolicyBlackListSuccess = createAction(
  '[Security] add security policy black list success',
  props<{ url: string }>()
);

const removeSecurityPolicyWhiteListSuccess = createAction(
  '[Security] remove security policy white list success',
  props<{ url: string }>()
);

const removeSecurityPolicyBlackListSuccess = createAction(
  '[Security] remove security policy black list success',
  props<{ url: string }>()
);

const garbageCollectPolicyChangeList = createAction('[Security] garbage collect policy change list');

const getSecurityPolicyByLocation = createAction('[Security] get security by location');

const getSecurityPolicyByLocationSuccess = createAction(
  '[Security] get security by location success',
  props<{ securityPolicy: SecurityPolicyModel }>()
);

const getSecurityPolicyByLocationFail = createAction(
  '[Security] get security by location fail',
  props<{ error: HttpErrorResponse }>()
);

const toggleSecurityPolicy = createAction(
  '[Security] toggle security policy',
  props<
    { action: string; url: string; dnsType: 'fqdn' | 'ip' } & (
      | { personId: string; for: 'person' }
      | { mac: string; for: 'device' }
      | { for: 'everyone' }
    )
  >()
);

const toggleSecurityPolicyFail = createAction(
  '[Security] toggle security policy fail',
  props<{ error: HttpErrorResponse; url: string }>()
);

const loadWifiNetwork = createAction('[Security] load wifi network');
const loadWifiNetworkDone = createAction(
  '[Security] load wifi network done',
  props<{ data: WifiNetwork | { error: HttpErrorResponse } }>()
);

export const SecurityPolicyActions = {
  getSecurityPolicyByLocation,
  getSecurityPolicyByLocationSuccess,
  getSecurityPolicyByLocationFail,
  addSecurityPolicyWhiteList,
  addSecurityPolicyBlackList,
  removeSecurityPolicyWhiteList,
  removeSecurityPolicyBlackList,
  addSecurityPolicyWhiteListFail,
  addSecurityPolicyBlackListFail,
  removeSecurityPolicyWhiteListFail,
  removeSecurityPolicyBlackListFail,
  addSecurityPolicyWhiteListSuccess,
  addSecurityPolicyBlackListSuccess,
  removeSecurityPolicyWhiteListSuccess,
  removeSecurityPolicyBlackListSuccess,
  garbageCollectPolicyChangeList,
  toggleSecurityPolicy,
  toggleSecurityPolicyFail,
  loadWifiNetwork,
  loadWifiNetworkDone
};
