import { Component, Input, OnInit } from '@angular/core';
import { PolygonInCircle } from 'src/app/lib/functions/polygon-in-circle';

@Component({
  selector: 'plm-pod-graphics',
  templateUrl: './pod-graphics.component.html',
  styleUrls: ['./pod-graphics.component.scss']
})
export class PodGraphicsComponent implements OnInit {
  @Input() status: 'connected' | 'disconnected' = 'disconnected';
  @Input() gateway = false;
  @Input() childCount = 0;

  width = 57;
  strokeWidth = 2;
  path = '';

  ngOnInit(): void {
    if (this.gateway) {
      this.width = 57;
    } else {
      this.width = 37;
    }
    this.path = PolygonInCircle.get(this.width / 2, 6, this.strokeWidth, 0, 6);
  }
}
