<plm-dropdown-menu class="people" #peopleMenu [position]="position">
  <plm-list dropdown>
    <li class="drop-down-item peopleDropDownRowWithPersonIcon" (click)="selectPerson(null)">
      <ng-container *ngTemplateOutlet="personIconTemplate"> </ng-container>
      <div>
        {{ 'peopleDropDown.everyone' | translate }}
      </div>
    </li>
    <li
      class="drop-down-item peopleDropDownRowWithPersonIcon"
      *ngFor="let person of people$ | async"
      (click)="selectPerson(person)"
    >
      <ng-container *ngTemplateOutlet="personIconTemplate"></ng-container>
      <div plmOverflowToolTip>{{ person.nickname }}</div>
    </li>
  </plm-list>
  <button class="pill-icon-box down peopleButton highlighted medium-left" [class.opened]="peopleMenu.menuOpened">
    <div icon="person-hovered" class="big peopleDropDownButtonWithPersonIcon"></div>
    <div icon="person-hovered-dark-theme" class="big peopleDropDownButtonWithPersonIcon"></div>
    <div class="ellipsis-overflow">{{ person?.nickname || 'peopleDropDown.everyone' | translate }}</div>
    <div icon="arrow" class="arrow big"></div>
  </button>
</plm-dropdown-menu>

<ng-template #personIconTemplate>
  <div icon="person" class="icon"></div>
  <div icon="person-hovered" class="icon"></div>
  <div icon="person-dark-theme" class="icon"></div>
  <div icon="person-hovered-dark-theme" class="icon"></div>
</ng-template>
