import * as server from './shared/server';
import * as braze from './shared/braze';
import * as chat from './shared/chat';
import packageInfo from '../../package.json';

export const environment = {
  production: false,
  ENV: 'Development',
  version: packageInfo?.version,
  SENTRY_DSN: 'https://d6d5d1224460472ba73d650d597e106e@o228599.ingest.sentry.io/5660112',
  ...chat,
  ...server,
  ...braze
};
