/* eslint-disable max-lines-per-function */
import { scaleLinear } from 'd3';
import * as moment from 'moment';
import { Margins } from 'src/app/lib/helper-types';
import { AiSecurityHourlyEventCount } from 'src/app/lib/services/ai-security/ai-security.service';

export type DayVisualElement = {
  x: number;
  y: number;
  width: number;
  gap: number;
  dayIndex: number;
  height: number;
  center: number;
  text: string;
  day_text: string;
  timestamp: number;
  events: EventVisualElement[];
  hover: boolean;
};
export type EventVisualElement = {
  type: string;
  Time: string;
  y: number;
  timestamp: number;
  count: number;
  date: number;
  newtime: number;
  day: Date;
  hover?: boolean;
};

export class SecurityChart {
  private data: AiSecurityHourlyEventCount[];
  private events: { [key: string]: EventVisualElement[] };
  private width: number;
  private height: number;
  private xScale: (_: number) => number;
  private yScale: (_: number) => number;
  private day: DayVisualElement;

  private margins: Margins = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };
  private verticalAxisLocation = { y: 155, dx: 0 };

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return Math.max(this.height - this.margins.top - this.margins.bottom, 0);
  }

  prepare(): void {
    if (this.day) {
      const date = moment(this.day.timestamp);

      const oneDay = date.clone().add(24, 'hours');

      const hour = 60 * 60 * 1000;
      this.xScale = scaleLinear()
        .domain([date.valueOf() - hour / 2, oneDay.valueOf() + hour / 2])
        .range([0, this.calculateWidth()]);
      this.yScale = scaleLinear().domain([hour, 0]).range([this.calculateHeight(), 0]);
    } else {
      const today = moment();
      today.startOf('day');

      const monthAgo = today.clone().subtract(29, 'days');

      const day = 24 * 60 * 60 * 1000;
      this.xScale = scaleLinear()
        .domain([monthAgo.valueOf() - day / 2, today.valueOf() + day / 2])
        .range([0, this.calculateWidth()]);
      this.yScale = scaleLinear().domain([day, 0]).range([this.calculateHeight(), 0]);
    }
  }

  calculatehour(): void {
    const hourEvents: { [key: string]: EventVisualElement[] } = {};
    if (this.data) {
      this.data.forEach((event: AiSecurityHourlyEventCount) => {
        const d: Date = new Date();
        d.setDate(d.getDate() - event.day);
        d.setHours(event.hour, 0, 0, 0);
        const timestamp = new Date(d).getTime();
        const date = moment(timestamp)
          .startOf(this.day ? 'hour' : 'day')
          .valueOf();
        const newtime: number = timestamp - date;
        const newEvent: EventVisualElement = {
          type: event.type,
          Time: moment(event.hour, 'hh').format('LT'),
          y: this.yScale(event.hour * 60 * 60 * 1000),
          timestamp: timestamp,
          count: event.count,
          date: date,
          newtime: newtime,
          day: d
        };
        if (hourEvents[event.day]) {
          const oldEvent = hourEvents[event.day].find((arrEvent) => arrEvent.timestamp === newEvent.timestamp);
          hourEvents[event.day].push(this.upperPoint(newEvent, oldEvent));
        } else {
          hourEvents[event.day] = [newEvent];
        }
      });
      this.events = hourEvents;
    }
  }

  calculateData(): DayVisualElement[] {
    const monthOfDaysVisuals: DayVisualElement[] = [];

    const now = moment().startOf('day');
    for (let i = 0; i < 30; i++) {
      const val = now.clone().subtract(i, 'days');
      const half = val.clone().subtract(12, 'hours');
      const day: DayVisualElement = {
        x: this.xScale(half.valueOf()),
        y: 0,
        width: (this.xScale(val.valueOf()) - this.xScale(half.valueOf())) * 1.5,
        gap: this.xScale(val.valueOf()) - this.xScale(half.valueOf()),
        dayIndex: i,
        height: this.calculateHeight(),
        center: 0,
        text: val.format('MMM D'),
        day_text: val.format('dddd'),
        timestamp: val.valueOf(),
        events: this.events[i] ? this.events[i] : [],
        hover: false
      };
      day.center = day.x + day.width / 2;

      monthOfDaysVisuals.push(day);
    }

    return monthOfDaysVisuals;
  }

  xAxis(): {
    dx: number;
    anchor: string;
    y: number;
    text: string;
    x: number;
  }[] {
    const now = moment().startOf('day');
    return [
      {
        text: 'aisecurity.chart.today',
        x: this.xScale(now.valueOf()),
        ...this.verticalAxisLocation,
        dx: 0,
        anchor: 'end'
      },
      {
        text: 'aisecurity.chart.daysAgo',
        x: this.xScale(now.subtract(7, 'days').valueOf()),
        ...this.verticalAxisLocation,
        anchor: 'middle'
      },
      {
        text: 'aisecurity.chart.twoWeeks',
        x: this.xScale(now.subtract(7, 'days').valueOf()),
        ...this.verticalAxisLocation,
        anchor: 'middle'
      },
      {
        text: 'aisecurity.chart.threeWeeks',
        x: this.xScale(now.subtract(7, 'days').valueOf()),
        ...this.verticalAxisLocation,
        anchor: 'middle'
      },
      {
        text: 'aisecurity.chart.monthAgo',
        x: this.xScale(now.subtract(7, 'days').valueOf()),
        ...this.verticalAxisLocation,
        anchor: 'middle'
      }
    ];
  }

  yAxis(): {
    text: string;
    x: number;
    y: number;
    dy: number;
    anchor: string;
  }[] {
    return [{ text: 'aisecurity.chart.12am', x: -10, y: this.yScale(0), dy: 6, anchor: 'end' }];
  }

  update(data: AiSecurityHourlyEventCount[], width?: number, height?: number, margins?: Margins): void {
    this.data = data ? data : [];
    this.width = width ? width : 0;
    this.height = height ? height : 0;
    this.margins = margins ? margins : this.margins;
    this.prepare();
    this.calculatehour();
  }

  private upperPoint(point1: EventVisualElement, point2?: EventVisualElement): EventVisualElement {
    if (!point2) {
      return point1;
    }
    const point1ZIndex = this.pointZIndex(point1);
    const point2ZIndex = this.pointZIndex(point2);
    if (point1ZIndex > point2ZIndex) {
      return point1;
    }
    return point2;
  }

  private pointZIndex(point: EventVisualElement): number {
    switch (point.type) {
      case 'anomaly':
        return 4;
      case 'contentAccess':
        return 3;
      case 'onlineProtection':
        return 2;
      case 'adBlocking':
        return 1;
      default:
        return 0;
    }
  }
}
