<ng-container *ngIf="(isOnboarded$ | async) === false">
  <div class="welcome">
    <div class="customer">
      <plm-mood></plm-mood>, <span class="firstName">{{ firstName$ | async }}</span>
    </div>
    <div class="title">{{ 'news.welcome' | translate }}</div>
    <div class="description">{{ 'news.membershipDescription1' | translate }}</div>
    <div class="description">{{ 'news.membershipDescription2' | translate }}</div>
  </div>
  <div class="bubble full">
    <iframe [src]="video$ | async" frameborder="0" allow="clipboard-write; encrypted-media;" allowfullscreen></iframe>
  </div>
  <plm-personalize-network *ngIf="false"></plm-personalize-network>
</ng-container>
<div #newsfeed class="braze"></div>
<div class="alertContainer">
  <plm-alert-pop-bar [alert$]="braze.alertNotification$"></plm-alert-pop-bar>
</div>
