<div class="carousel-content">
  <div class="item flex-center">
    <div class="title">
      {{ 'troubleshooting.assistance.title' | translate }}
    </div>
    <div class="description">
      <p>{{ 'troubleshooting.assistance.description' | translate }}</p>
      <p class="support" *ngIf="config$ | async as config" (click)="navigate('supportUrl', config)">
        {{ 'troubleshooting.assistance.contact' | translate }}<span [icon]="'arrow-right'" class="icon"></span>
      </p>
    </div>
  </div>
  <div class="item image"><img src="/assets/images/armchair-lamp.svg" width="200" /></div>
</div>
