import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';

type stylableElement = Element & {
  style: { display: string };
};

// add `resized` directive to this component for instant component resize detection
@UntilDestroy()
@Component({
  selector: 'plm-overflow-element-hider',
  templateUrl: './overflow-element-hider.component.html',
  styleUrls: ['./overflow-element-hider.component.scss']
})
export class OverflowElementHiderComponent implements AfterViewInit, OnChanges {
  @ViewChild('overlayOrigin') oiOverlayOrigin: CdkOverlayOrigin;
  hiddenItems = 0;
  subscriber: Subscription;
  @Input() overflowIndicator?: TemplateRef<unknown>;
  @Input() refresh$: Observable<unknown>;

  constructor(private elm: ElementRef<HTMLElement>) {}

  @HostListener('window:resize') windowResized(): void {
    this.hideOverflowItems();
  }

  @HostListener('resized') resized(): void {
    this.hideOverflowItems();
  }

  ngAfterViewInit(): void {
    // timeout needed to initialize component before doing computation
    setTimeout(() => {
      this.hideOverflowItems();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refresh$) {
      if (this.subscriber) {
        this.subscriber.unsubscribe();
      }
      this.subscriber = this.refresh$.pipe(untilDestroyed(this)).subscribe(() => {
        // so ngFor have time to paint
        setTimeout(() => {
          this.hideOverflowItems();
        }, 0);
      });
    }
  }

  private hideOverflowItems(): void {
    this.showAllItems();
    if (!this.overflowed()) {
      this.hiddenItems = 0;
    } else {
      this.hiddenItems = 1;
      this.showOverflowIndicator(true);
      this.hideItem(this.hiddenItems);
      while (this.overflowed() && this.childCount() > this.hiddenItems) {
        this.hiddenItems++;
        this.hideItem(this.hiddenItems);
      }
    }
  }

  private overflowed(): boolean {
    return this.elm.nativeElement.scrollWidth > this.elm.nativeElement.clientWidth;
  }

  private showAllItems(): void {
    for (let i = 0; i < this.elm.nativeElement.childElementCount - 1; i++) {
      (this.elm.nativeElement.children.item(i) as stylableElement).style.display = '';
    }
    this.showOverflowIndicator(false);
  }

  private hideItem(endIndex: number): void {
    const resSensorIncluded = this.resizeSensorIncluded();
    const elm = this.elm.nativeElement.children.item(
      this.elm.nativeElement.children.length - (resSensorIncluded ? 2 : 1) - endIndex
    ) as stylableElement;
    if (elm) {
      elm.style.display = 'none';
    }
  }

  private childCount(): number {
    const resSensorIncluded = this.resizeSensorIncluded();
    return this.elm.nativeElement.children.length - (resSensorIncluded ? 2 : 1);
  }

  private showOverflowIndicator(val: boolean): void {
    if (this.elm.nativeElement.children.length === 0) {
      return;
    }
    const resSensorIncluded = this.resizeSensorIncluded();

    if (val) {
      (this.elm.nativeElement.children.item(
        this.elm.nativeElement.childElementCount - (resSensorIncluded ? 2 : 1)
      ) as stylableElement).style.display = '';
    } else {
      (this.elm.nativeElement.children.item(
        this.elm.nativeElement.childElementCount - (resSensorIncluded ? 2 : 1)
      ) as stylableElement).style.display = 'none';
    }
  }

  private resizeSensorIncluded(): boolean {
    return this.elm.nativeElement.children
      .item(this.elm.nativeElement.children.length - 1)
      .classList.contains('resize-sensor');
  }
}
