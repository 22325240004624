<div class="bubble">
  <div class="header">
    <div class="home">
      <div class="circle">
        <div class="icon" icon="home"></div>
      </div>
    </div>
    <div class="icon" icon="plus" *ngIf="false"></div>
  </div>
  <div
    *ngFor="let location of locations$ | async"
    class="location"
    [class.selected]="location.id === (currentLocationId$ | async)"
    (click)="changeLocation(location.id)"
  >
    <div class="info">
      <div class="name">{{ location.name }}</div>
      <div class="member">
        <span>{{ 'accountMenu.accountCreated' | translate }} </span>
        <span class="date">{{ location.createdAt | date }}</span>
      </div>
    </div>
    <div
      class="icon"
      icon="edit"
      *ngIf="location.id === (currentLocationId$ | async)"
      (click)="changeLocationName($event)"
    ></div>
    <div class="icon" *ngIf="location.id !== (currentLocationId$ | async)"></div>
  </div>
</div>
