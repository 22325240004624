<plm-box>
  <h3>{{ 'settings.adaptView.title' | translate }}</h3>
  <div class="homeWifi">
    <div class="block-header">{{ 'settings.adaptView.homeWifi' | translate }}</div>
    <div class="ssidRow">
      <ng-container *ngIf="currentLocation$ | async as currentLocation">
        <ng-container *ngIf="currentLocation.ssid; else noSsid">
          <div class="icon firstColumn" icon="wifi"></div>
          <div class="ssid">
            <div class="readOnly" *ngIf="!editMode">
              <div class="name">{{ currentLocation.ssid }}</div>
              <div class="icon" icon="edit" (click)="startEditing(currentLocation.ssid)"></div>
            </div>
            <div class="edit" *ngIf="editMode">
              <plm-input-field>
                <input [(ngModel)]="ssidValue" maxlength="32" />
              </plm-input-field>
              <div class="icon" icon="close" (click)="cancelEditing()" [class.disabled]="ssidSaving$ | async"></div>
              <div class="icon" icon="stroke-check" (click)="setSsid()" [class.disabled]="ssidSaving$ | async"></div>
            </div>
          </div>
        </ng-container>
        <ng-template #noSsid>{{ 'settings.adaptView.noSSID' | translate }}</ng-template>
      </ng-container>
    </div>
  </div>
  <plm-alert-pop-bar [alert$]="alert$"></plm-alert-pop-bar>
  <div class="pods">
    <div class="block-header">{{ 'settings.adaptView.plumePods' | translate }}</div>
    <div class="podLoading" *ngIf="(loading$ | async) === true"></div>
    <div class="podRow" [class.lastRow]="last" *ngFor="let pod of pods$ | async; let last = last">
      <div class="icon firstColumn" icon="adapt-pod-online" *ngIf="pod.connectionState === 'connected'"></div>
      <div class="icon firstColumn" icon="adapt-pod-offline" *ngIf="pod.connectionState !== 'connected'"></div>
      <div class="name" [class.offline]="pod.connectionState !== 'connected'">
        {{ pod.nickname || pod.defaultName }}
      </div>
      <div class="signal">
        <div *ngIf="pod.connectionState !== 'connected'">Offline</div>
        <div *ngIf="pod.connectionState === 'connected'">
          {{ 'podsOverview.healthStatus.' + pod.health?.status | translate }}
        </div>
      </div>

      <plm-dropdown-menu panelClass="light-grey" position="topRight">
        <plm-list dropdown>
          <li class="drop-down-item big" [routerLink]="['/', 'pods', pod.id]" [queryParams]="{ action: 'rename' }">
            <div icon="selected-iem" class="icon"></div>
            <div>{{ 'settings.adaptView.podMenu.rename' | translate }}</div>
          </li>
          <li class="drop-down-item big" [routerLink]="['/', 'pods', pod.id]" [queryParams]="{ action: 'delete' }">
            <div icon="close-small" class="icon"></div>
            <div>{{ 'settings.adaptView.podMenu.delete' | translate }}</div>
          </li>
          <li class="drop-down-item big" [routerLink]="['/', 'pods', pod.id]" [queryParams]="{ action: 'viewInfo' }">
            <div icon="info2" class="icon"></div>
            <div>{{ 'settings.adaptView.podMenu.viewInfo' | translate }}</div>
          </li>
        </plm-list>
        <div class="icon" icon="more"></div>
      </plm-dropdown-menu>
    </div>
    <div class="noPods" *ngIf="(loading$ | async) === false && (pods$ | async).length === 0">
      <div class="icon" icon="lighter-pod"></div>
      <div class="text">{{ 'settings.adaptView.noPods' | translate }}</div>
    </div>
  </div>
  <div class="extendCoverage">
    <div class="block-header">{{ 'settings.adaptView.extendCoverage' | translate }}</div>
  </div>
  <plm-box-footer
    linkIcon="cart"
    [text]="'settings.adaptView.buyPod' | translate"
    [itsLink]="false"
    (click)="buyPod()"
  ></plm-box-footer>
</plm-box>
