import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlmDialogInject, PlmDialogToken } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  selector: 'plm-networking-mode-dialog',
  templateUrl: 'networking-mode-dialog.component.html',
  styleUrls: ['networking-mode-dialog.component.scss']
})
export class NetworkModeDialogComponent {
  public mode = this.data.data.mode;

  constructor(
    @Inject(PlmDialogToken) public data: PlmDialogInject<{ mode: string }, boolean>,
    public translate: TranslateService
  ) {}
}
