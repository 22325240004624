<plm-accordion #accordion>
  <plm-accordion-item>
    <div slot-heading class="subnetItem">
      <span class="ip" *ngIf="ipClass !== 'A'">10.0.0.x</span>
      <span class="ip" *ngIf="ipClass === 'A'">{{ maskedSubNet() }}</span>
      <span class="icon" icon="check" *ngIf="ipClass === 'A'"></span>
    </div>
    <div slot-body>
      <plm-lan-subnet-edit
        [networkClass]="'A'"
        (save)="onSave($event)"
        (cancel)="onCancel()"
        [dhcp]="ipClass === 'A' ? dhcp : undefined"
      ></plm-lan-subnet-edit>
    </div>
  </plm-accordion-item>
  <plm-accordion-item>
    <div slot-heading class="subnetItem">
      <span class="ip" *ngIf="ipClass !== 'B'">172.16.0.x</span>
      <span class="ip" *ngIf="ipClass === 'B'">{{ maskedSubNet() }}</span>
      <span class="icon" icon="check" *ngIf="ipClass === 'B'"></span>
    </div>
    <div slot-body>
      <plm-lan-subnet-edit
        [networkClass]="'B'"
        (save)="onSave($event)"
        (cancel)="onCancel()"
        [dhcp]="ipClass === 'B' ? dhcp : undefined"
      ></plm-lan-subnet-edit>
    </div>
  </plm-accordion-item>
  <plm-accordion-item>
    <div slot-heading class="subnetItem">
      <span class="ip" *ngIf="ipClass !== 'C'">192.168.40.x</span>
      <span class="ip" *ngIf="ipClass === 'C'">{{ maskedSubNet() }}</span>
      <span class="icon" icon="check" *ngIf="ipClass === 'C'"></span>
    </div>
    <div slot-body>
      <plm-lan-subnet-edit
        [networkClass]="'C'"
        (save)="onSave($event)"
        (cancel)="onCancel()"
        [dhcp]="ipClass === 'C' ? dhcp : undefined"
      ></plm-lan-subnet-edit>
    </div>
  </plm-accordion-item>
</plm-accordion>
