import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Person } from 'src/app/lib/store/models/person.model';
import { MappedDeviceCategory } from './top-bar.interface';

@Component({
  selector: 'plm-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
  @Input() people$: Observable<Person[]>;
  @Input() deviceCategories$: Observable<MappedDeviceCategory[]>;
  @Output() filter = new EventEmitter<{
    searchText: string;
    person: Person | null;
    deviceCategory: MappedDeviceCategory;
    advanced: boolean;
  }>();

  advanced = false;
  searchText = '';
  person: Person = null;
  selectedDeviceCategory: MappedDeviceCategory = { name: '', allDevices: true, count: -1 };

  categoryTranslation(category: MappedDeviceCategory): string {
    return category.allDevices ? 'topology.allDevices' : 'deviceCategory.' + (category.name || 'Unknown');
  }

  selectPerson(person: Person | null): void {
    this.person = person;
    this.emitFilterChange();
  }

  selectDeviceCategory(deviceCategory: MappedDeviceCategory): void {
    this.selectedDeviceCategory = deviceCategory;
    this.emitFilterChange();
  }

  advancedChanged(): void {
    this.emitFilterChange();
  }

  searchTextChanges(): void {
    this.emitFilterChange();
  }

  emitFilterChange(): void {
    this.filter.emit({
      deviceCategory: this.selectedDeviceCategory,
      searchText: this.searchText,
      advanced: this.advanced,
      person: this.person
    });
  }
}
