import { Component, Input } from '@angular/core';
import { CalculationsService } from '../calculations/calculations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[plm-column]',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss']
})
export class ColumnComponent {
  @Input() index: number;
  @Input() value: number;

  constructor(public calculations: CalculationsService) {}

  public get radius(): number {
    return this.calculations.columnDrawingWith / 2;
  }
}
