import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPlumeState } from '../../../../app/lib/store';
import { Device } from '../../../../app/lib/store/models/device.model';
import { selectDevices } from '../../../../app/lib/store/selectors/home.selectors';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'plm-devices-drop-down',
  templateUrl: './devices-drop-down.component.html',
  styleUrls: ['./devices-drop-down.component.scss']
})
export class DevicesDropDownComponent {
  @Input() position: ConnectedPosition[] | 'bottomRight' | 'topRight' | 'bottomLeft' | 'topLeft' = 'topLeft';
  @Input() device: Device;
  @Output() deviceChange = new EventEmitter<Device>();
  @ViewChild('dropdownMenu') dropDown: DropdownMenuComponent;
  @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef<HTMLInputElement>;
  filterValue$ = new BehaviorSubject<string>('');
  devices$ = combineLatest([this.store$.select(selectDevices), this.filterValue$]).pipe(
    map(([devices, filterText]) =>
      devices
        .map(device => ({ ...device, name: device?.nickname || device?.name || device?.mac }))
        .filter(device => device.name.toLowerCase().includes(filterText.toLowerCase()))
    )
  );
  searchText = '';

  constructor(private store$: Store<IPlumeState>) {}

  selectDevice(device: Device | null): void {
    this.deviceChange.emit(device);
    this.dropDown.closeMenu();
  }

  menuOpened(value: boolean): void {
    if (value) {
      this.searchInput.nativeElement.focus();
      this.searchText = '';
      this.filterValue$.next(this.searchText);
    }
  }

  filterDevices(value: string): void {
    this.filterValue$.next(value);
  }
}
