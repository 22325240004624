<svg:line
  class="line"
  x1="0"
  [attr.y1]="this.calculations.columnY(0)"
  [attr.x2]="calculations.width"
  [attr.y2]="this.calculations.columnY(0)"
/>
<ng-container *ngIf="this.chartData?.length > 0">
  <ng-container *ngFor="let _ of [].constructor(calculations.axisSizeInUnits.maxX + 1); let index = index">
    <svg:line
      class="line"
      [attr.x1]="calculations.columnXStart(index)"
      [attr.y1]="this.calculations.columnY(0)"
      [attr.x2]="calculations.columnXStart(index)"
      [attr.y2]="this.calculations.columnY(0) + 3"
    />
    <ng-container>
      <svg:text
        class="text"
        *ngIf="labels[index].timeVisible"
        [attr.x]="calculations.columnXStart(index)"
        [attr.y]="this.calculations.columnY(0) + 13"
      >
        {{ labels[index].time }}
      </svg:text>
      <svg:text
        class="text"
        *ngIf="labels[index].amPmVisible"
        [attr.x]="calculations.columnXStart(index)"
        [attr.y]="this.calculations.columnY(0) + 23"
      >
        {{ labels[index].amPm }}
      </svg:text>
    </ng-container>
  </ng-container>
</ng-container>
