import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CssVariablesService {
  get(variable: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(variable);
  }
}
