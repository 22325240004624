import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { PlmDialogToken, PlmDialogInject } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  templateUrl: './network-error.component.html',
  styleUrls: ['./network-error.component.scss']
})
export class NetworkErrorComponent {
  constructor(@Inject(PlmDialogToken) public dialog: PlmDialogInject<HttpErrorResponse, boolean>) {}

  get transPth(): string {
    return 'networkErrorDialog.';
  }
}
