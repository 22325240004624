import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { loginUser } from 'src/app/lib/store/actions/plume.actions';
import { Store } from '@ngrx/store';
import { IPlumeState } from 'src/app/lib/store';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
}

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class HandlerComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private store$: Store<IPlumeState>
  ) {}

  ngOnInit(): void {
    const { userid, token, expireAt, cloudId, cloud } = this.route.snapshot.queryParams as {
      userid: string, token: string, expireAt: string, cloudId: string, cloud: string
    };
    this.store$.dispatch(loginUser({
      cloudId,
      cloudUrl: cloud,
      customerId: userid,
      expireAt,
      token
    }));
  }
}
