import { Component } from '@angular/core';

@Component({
    selector: 'plm-rule',
    templateUrl: 'rule.component.html',
    styleUrls: ['rule.component.scss']
})

export class RuleComponent {

}
