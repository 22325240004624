import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../../../../app/lib/directives/icon/icon.module';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuComponent } from './menu.component';

@NgModule({
  imports: [CommonModule, IconModule, RouterModule, TranslateModule],
  exports: [MenuComponent],
  declarations: [MenuComponent, MenuItemComponent],
  providers: []
})
export class MenuModule {}
