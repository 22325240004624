import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth.isAuthenticated$().pipe(
      tap(isAuth => {
        if (isAuth) {
          void this.router.navigate(['']);
        } else {
          this.auth.redirect('login', route.queryParams.cloud);
        }
      })
    );
  }
}
