import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'plm-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent {

  @HostBinding('style.height.px')
  @Input() height = 370;

  @HostBinding('style.width.px')
  @Input() width = 310;

  @Input() border = false;
  @HostBinding('class.no-border')
  public get noBorder(): boolean {
      return !this.border;
  }

}
