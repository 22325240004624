<plm-accordion #accordion>
  <plm-accordion-item
    [id]="reservation.mac"
    *ngFor="let reservation of dhcpReservations; trackBy: trackByReservation"
    [noBorder]="true"
  >
    <div slot-heading class="reservationItem">
      <span>{{ reservation.hostname || reservation.name || reservation.mac }}</span>
      <span class="ip">{{ reservation.ip }}</span>
    </div>
    <div slot-body>
      <plm-ip-reservation-edit
        [data]="{
          name: reservation.hostname || reservation.name || reservation.mac,
          ip: reservation.ip,
          mac: reservation.mac,
          portForwards: reservation.portForwards
        }"
        [editMode]="true"
        [saving]="reservationSavingInProgress[reservation.mac]"
        (delete)="deleteReservation(reservation.mac)"
        (cancel)="cancelReservation()"
        (save)="saveReservation($event)"
        (saveNewPort)="saveNewPort(reservation.mac, $event)"
        (saveEditPort)="saveEditPort(reservation.mac, $event)"
        (deletePort)="deletePort(reservation.mac, $event)"
        [closeNewPort$]="closePortForMac$(reservation.mac)"
        [portSaving]="portSaving[reservation.mac] || {}"
        [newPortSaving]="newPortSaving[reservation.mac]"
      ></plm-ip-reservation-edit>
    </div>
  </plm-accordion-item>
  <plm-accordion-item [noBorder]="true">
    <div slot-heading class="reservationItem new">
      <span>{{ 'portForwarding.newIpReservation' | translate }}</span>
    </div>
    <div slot-body>
      <plm-ip-reservation-edit
        [data]="newReservationData"
        [manualMode]="newReservationManual"
        [saving]="newReservationSaving"
        *ngIf="!newReservationDeviceSelector"
        (cancel)="cancelNewReservation()"
        (save)="saveNewReservation($event)"
      ></plm-ip-reservation-edit>
      <div class="reservationVariants" *ngIf="newReservationDeviceSelector">
        <plm-devices-drop-down (deviceChange)="deviceSelected($event)"></plm-devices-drop-down>
        <span class="or">{{ 'portForwarding.or' | translate }}</span>
        <a href="javascript:void(0)" (click)="deviceSelected(undefined)">{{
          'portForwarding.addManually' | translate
        }}</a>
      </div>
    </div>
  </plm-accordion-item>
</plm-accordion>
<plm-alert-pop-bar [alert$]="alert$"></plm-alert-pop-bar>
