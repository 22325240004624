<div class="menu-item__container" [class.separator]="item.seperator">
  <div
    class="menu-item__heading"
    (click)="toggle()"
    [class.open]="item.opened"
    [routerLink]="item.link === '#' ? [] : item.link"
    [routerLinkActive]="item.link === '#' ? '' : 'active activeFill'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <div *ngIf="item.icon" class="icon" [icon]="item.icon"></div>
    <div>{{ item.title }}</div>
    <div *ngIf="item.subTitle">{{ item.subTitle }}</div>
    <div *ngIf="item.expandable" class="menu-item__collapsable" [@rotatedState]="!item.opened ? 'default' : 'rotated'">
      <span class="icon" icon="arrow-right"></span>
    </div>
  </div>
  <div [@smoothCollapse]="!item.opened ? 'initial' : 'final'">
    <div class="menu-item__body">
      <div class="menu-item__children_box" *ngFor="let child of item.children; let i = index" [class.rule]="child.rule">
        <div
          *ngIf="!item.moreLimit || i < item.moreLimit || more"
          class="menu-item__children"
          [class.hasIcon]="child.icon"
          routerLink="{{ child.link }}"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: !child.activeOnChild }"
        >
          <div *ngIf="child.icon" class="icon" [icon]="child.icon"></div>
          <div class="ellipsis-overflow">{{ child.title }}</div>
          <div *ngIf="child.subTitle" class="subTitle">{{ child.subTitle }}</div>
        </div>
      </div>
      <ng-container *ngIf="item.children && item.moreLimit < item.children.length">
        <div class="menu-item__children" (click)="more = !more" *ngIf="!more">
          <span class="icon" [icon]="'plus'"></span>
          <span>{{ 'mainMenu.viewMore' | translate }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
