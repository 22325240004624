import { Component, Input } from '@angular/core';

/* top-left
originX: 'start', // left corner of the button
originY: 'bottom', // bottom corner of the button
overlayX: 'start', // left corner of the overlay to the origin
overlayY: 'top', // top corner of the overlay to the origin

// top-right
originX: 'end', // right corner of the button
originY: 'bottom', // bottom corner of the button
overlayX: 'end', // right corner of the overlay to the origin
overlayY: 'top', // top corner of the overlay to the origin

// bottom-left
originX: 'start', // left corner of the button
originY: 'top', // top corner of the button
overlayX: 'start', // left corner of the overlay to the origin
overlayY: 'bottom', // top corner of the overlay to the origin

// bottom-right
originX: 'end', // right corner of the button
originY: 'top', // top corner of the button
overlayX: 'end', // right corner of the overlay to the origin
overlayY: 'bottom', // top corner of the overlay to the origin
*/

@Component({
  selector: 'plm-overlay-button',
  templateUrl: './overlay-button.component.html',
  styleUrls: ['./overlay-button.component.scss']
})
export class OverlayButtonComponent {
  @Input()
  public buttonIcon = 'arrow';

  @Input()
  public label: string;

  @Input()
  public size: 'large' | 'medium' | 'small' = 'medium';

  @Input()
  public state: 'positive' | 'negative' = 'positive';

  @Input()
  originX: 'start' | 'end' = 'start';

  @Input()
  originY: 'bottom' | 'top' = 'top';

  @Input()
  overlayX: 'start' | 'end' = 'start';

  @Input()
  overlayY: 'bottom' | 'top' = 'bottom';

  @Input()
  public offSetY = 170;

  @Input()
  public offsetX = 0;

  @Input()
  public iconOnly = false;

  @Input()
  public overlayHeight: number;

  @Input()
  public overlayWidth: number;

  @Input()
  public border: boolean;

  @Input() closeOnClick = true;
  @Input() disabled = false;

  public isOpen = false;
  public toggle(): void {
    this.isOpen = !this.isOpen;
  }

  public close(): void {
    this.isOpen = false;
  }

  overlayClicked(): void {
    if (this.closeOnClick) {
      this.close();
    }
  }
}
