import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'plm-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnChanges {
  @Input() date = moment();
  @Input() visibleMonth = moment();
  @Output() dateChange = new EventEmitter<moment.Moment>();
  readonly weekDays = moment().localeData().weekdaysShort();
  readonly amPm = moment().localeData().longDateFormat('LT').includes('h');
  monthText = '';
  days: { date: number; currentMonth: boolean; selectedDay: boolean; day: moment.Moment }[] = [];
  time: { minutes: number; hours: number; amPm: 'AM' | 'PM' } = { minutes: 0, hours: 0, amPm: 'AM' };

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.date?.currentValue as moment.Moment)?.isValid()) {
      this.date = this.date.clone();
      this.visibleMonth = this.date.clone();
      this.calcCalendar();
    }
    if ((changes.visibleMonth?.currentValue as moment.Moment)?.isValid()) {
      this.visibleMonth = this.visibleMonth.clone();
      this.calcCalendar();
    }
  }

  nextMonth(): void {
    this.visibleMonth.add(1, 'month');
    this.calcCalendar();
  }

  previousMonth(): void {
    this.visibleMonth.add(-1, 'month');
    this.calcCalendar();
  }

  setDay(day: moment.Moment): void {
    if (!this.date?.isValid()) {
      return;
    }
    this.visibleMonth = day.clone();
    this.date.year(day.year());
    this.date.month(day.month());
    this.date.date(day.date());

    this.calcCalendar();
    this.dateChange.emit(this.date);
  }

  setHours(hours: number): void {
    if (!this.date?.isValid()) {
      return;
    }
    if (this.amPm && this.time.amPm === 'PM' && hours < 12) {
      hours += 12;
    } else if (this.amPm && this.time.amPm === 'AM' && hours === 12) {
      hours = 0;
    }
    this.date.hours(hours);
    this.calcCalendar();
    this.dateChange.emit(this.date);
  }

  setMinutes(minutes: number): void {
    if (!this.date?.isValid()) {
      return;
    }
    this.date.minutes(minutes);
    this.calcCalendar();
    this.dateChange.emit(this.date);
  }

  setAmPm(val: 'AM' | 'PM'): void {
    if (!this.date?.isValid()) {
      return;
    }
    if (val === 'AM' && this.date.hours() >= 12) {
      this.date.hours(this.date.hours() - 12);
      this.calcCalendar();
      this.dateChange.emit(this.date);
    } else if (val === 'PM' && this.date.hours() < 12) {
      this.date.hours(this.date.hours() + 12);
      this.calcCalendar();
      this.dateChange.emit(this.date);
    }
  }

  private calcCalendar(): void {
    this.calcTime();
    if (!this.date?.isValid() || !this.visibleMonth?.isValid()) {
      return;
    }
    this.monthText = this.visibleMonth.format('YYYY MMMM');
    const firstDayOfMonth = this.visibleMonth.clone().startOf('month');
    const lastDayOfMonth = this.visibleMonth.clone().endOf('month');
    const lastDayOfCalendar = lastDayOfMonth.clone().add(7 - lastDayOfMonth.weekday() - 1, 'day');
    this.days = [];
    const incrementDate = firstDayOfMonth.clone().add(-firstDayOfMonth.weekday(), 'day');
    const currentMonth = this.visibleMonth.month();
    while (!incrementDate.isAfter(lastDayOfCalendar, 'days')) {
      this.days.push({
        date: incrementDate.date(),
        currentMonth: incrementDate.month() === currentMonth,
        selectedDay: incrementDate.isSame(this.date, 'day'),
        day: incrementDate.clone().startOf('day')
      });
      incrementDate.add(1, 'day');
    }
  }

  private calcTime(): void {
    if (!this.date?.isValid()) {
      return;
    }
    this.time.hours = Number.parseInt(this.amPm ? this.date.format('h') : this.date.format('H'), 10);
    this.time.minutes = this.date.minutes();
    this.time.amPm = this.date.format('A') as 'AM' | 'PM';
  }
}
