import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from '../ui/menu/menu.model';

@Component({
  selector: 'plm-side-menu-mobile',
  templateUrl: './side-menu-mobile.component.html',
  styleUrls: ['./side-menu-mobile.component.scss']
})
export class SideMenuMobileComponent implements OnInit, OnDestroy {
  @Input() config: MenuItem[];

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
  }

  ngOnDestroy(): void {
    document.body.style.overflow = '';
  }
}
