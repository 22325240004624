import { createReducer, on, Action } from '@ngrx/store';
import {
  deleteNode,
  deleteNodeDone,
  loadHome,
  loadHomeDone,
  setPubnubForceGraph,
  renameNodeDone
} from '../actions/home.actions';
import { IHome, initialHomeState } from '../interfaces/home.interface';

export const reducer = createReducer(
  initialHomeState,
  on(loadHome, state => ({ ...state, loading: true })),

  on(loadHomeDone, (state, { data }) => ({
    ...state,
    ...data,
    loading: false
  })),
  on(setPubnubForceGraph, (state, { forceGraph }) => ({
    ...state,
    forceGraph
  })),
  on(deleteNode, (state, { nodeId }) => ({
    ...state,
    deletingNodesId: [...state.deletingNodesId, nodeId]
  })),
  on(deleteNodeDone, (state, { nodeId }) => ({
    ...state,
    deletingNodesId: state.deletingNodesId.filter(nodeItemId => nodeItemId !== nodeId)
  })),
  on(renameNodeDone, (state, { node }) => ({
    ...state,
    nodes: state.nodes.map(nodeItem => ({
      ...nodeItem,
      nickname: nodeItem.id === node.id ? node.nickname : nodeItem.nickname
    }))
  }))
);

export const HomeReducer = (state: IHome, action: Action): IHome => reducer(state, action);
