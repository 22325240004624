import { Component } from '@angular/core';

@Component({
  selector: 'plm-mood',
  templateUrl: './mood.component.html',
  styleUrls: ['./mood.component.scss']
})
export class MoodComponent {
  hour = new Date().getHours(); // TODO use moment as it have set location time zone
}
