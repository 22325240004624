import { EventEmitter } from '@angular/core';
import { Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AiSecurityHourlyEventCount } from 'src/app/lib/services/ai-security/ai-security.service';
import { HttpState } from 'src/app/lib/store/interfaces/http-state.interface';
import { GuardTileComponent, TileTypes } from '../guard-tile/guard-tile.component';

@Component({
  selector: 'plm-guard-tiles-box',
  templateUrl: './guard-tiles-box.component.html',
  styleUrls: ['./guard-tiles-box.component.scss']
})
export class GuardTilesBoxComponent implements OnChanges {
  summary = this.countSummary();
  @Input() securityDailyCounts: HttpState<AiSecurityHourlyEventCount[]>;
  @Input() activeTile: TileTypes;
  @Output() tileClicked = new EventEmitter<TileTypes>();

  tiles: TileTypes[] = ['all', 'onlineProtection', 'adBlocking', 'contentAccess', 'anomaly'];
  scrollArrowVisible: 'left' | 'right' = 'right';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.securityDailyCounts) {
      this.summary = this.countSummary();
    }
  }

  scrollTo(elm: HTMLElement, orientation: 'left' | 'right'): void {
    if (orientation === 'left') {
      elm.scrollTo({ left: 0, behavior: 'smooth' });
      this.scrollArrowVisible = 'right';
    } else {
      elm.scrollTo({ left: elm.clientWidth + elm.scrollLeft + 50, behavior: 'smooth' });
      this.scrollArrowVisible = 'left';
    }
  }

  tileClickedAction(tile: TileTypes, container: HTMLElement, tileComponent: GuardTileComponent): void {
    this.tileClicked.emit(tile);
    if (container.getBoundingClientRect().right < tileComponent.elmRef.nativeElement.getBoundingClientRect().right) {
      this.scrollTo(container, 'right');
    } else if (
      container.getBoundingClientRect().left > tileComponent.elmRef.nativeElement.getBoundingClientRect().left
    ) {
      this.scrollTo(container, 'left');
    }
  }

  private countSummary(): Partial<{ [key in TileTypes]: number }> {
    if (this.securityDailyCounts?.data) {
      return {
        anomaly: this.securityDailyCounts.data
          .filter(event => event.type === 'anomaly')
          .reduce((accumulator, event) => accumulator + event.count, 0),
        onlineProtection: this.securityDailyCounts.data
          .filter(event => event.type === 'onlineProtection')
          .reduce((accumulator, event) => accumulator + event.count, 0),
        adBlocking: this.securityDailyCounts.data
          .filter(event => event.type === 'adBlocking')
          .reduce((accumulator, event) => accumulator + event.count, 0),
        all: this.securityDailyCounts.data.reduce((accumulator, event) => accumulator + event.count, 0),
        contentAccess: this.securityDailyCounts.data
          .filter(event => event.type === 'contentAccess')
          .reduce((accumulator, event) => accumulator + event.count, 0)
      };
    } else {
      // disable tiles as they are not loaded
      return {};
    }
  }

  canBeScrolled(elm: HTMLElement): boolean {
    return elm.scrollWidth > elm.clientWidth;
  }
}
