<div class="header">
  <span>{{ 'portForwarding.portAssignment' | translate }}</span>
  <a href="javascript:void(0)" *ngIf="!newPortOpened" (click)="openNewPort()">{{
    'portForwarding.openPort' | translate
  }}</a>
  <span class="inActiveLink" *ngIf="newPortOpened">{{ 'portForwarding.openPort' | translate }}</span>
</div>
<plm-ip-reservation-port
  *ngIf="newPortOpened"
  (save)="saveNewPort.emit($event)"
  (cancel)="cancelNewPort()"
  [saving]="newPortSaving"
  [mac]="mac"
></plm-ip-reservation-port>
<plm-accordion #accordion>
  <plm-accordion-item [noBorder]="true" *ngFor="let portDef of ports" [class.topBorder]="newPortOpened">
    <div slot-heading class="portItem">
      <div>{{ 'portForwarding.portName' | translate }}</div>
      <div class="value">{{ portDef.name }}</div>
    </div>
    <div slot-body>
      <plm-ip-reservation-port
        [saving]="portSaving[portDef.externalPort]"
        [mac]="mac"
        [editMode]="true"
        [data]="portDef"
        [cancelEdit$]="valCancelEdit$"
        (save)="saveEditPort.emit($event)"
        (cancel)="cancelEditPort()"
        (delete)="deletePort.emit(portDef.externalPort)"
      ></plm-ip-reservation-port>
    </div>
  </plm-accordion-item>
</plm-accordion>
