<div container #canvas>
  <svg *ngIf="initialized">
    <defs>
      <linearGradient id="gradient" x1="0" x2="0" y1="1" y2="0">
        <stop class="stop1" offset="0%" />
        <stop class="stop2" offset="100%" />
      </linearGradient>
      <filter id="shadow" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.121569 0 0 0 0 0.141176 0 0 0 0 0.172549 0 0 0 0.22 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>

    <svg:g
      [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'"
      [attr.width]="100"
      [attr.height]="100"
    >
      <svg:line x1="-20" y1="140" x2="100%" y2="140" class="axis" />
      <svg:text
        [attr.x]="axis.x"
        [attr.y]="axis.y"
        [attr.dx]="axis.dx"
        [attr.text-anchor]="axis.anchor"
        [class.todayText]="first"
        *ngFor="let axis of xAxis; let first = first"
      >
        {{ axis.text | translate }}
      </svg:text>

      <svg:line
        [attr.x1]="axis.x - 2"
        y1="145"
        [attr.x2]="axis.x - 2"
        y2="140"
        class="axis"
        *ngFor="let axis of xAxis"
      />
      <svg:text
        [attr.x]="axis.x"
        [attr.y]="axis.y"
        [attr.dy]="axis.dy"
        [attr.text-anchor]="axis.anchor"
        class="small"
        *ngFor="let axis of yAxis"
      >
        <tspan [attr.x]="axis.x" dy="0">{{ (axis.text | translate).split(' ')[0] }}</tspan>
        <tspan [attr.x]="axis.x" dy="1.2em">{{ (axis.text | translate).split(' ')[1] }}</tspan>
      </svg:text>
      <image href="/assets/icons/sunrise.svg" x="-23" y="30" height="14px" width="14px" />
      <image href="/assets/icons/sunset.svg" x="-23" y="90" height="14px" width="14px" />
      <svg:rect
        class="border"
        x="0"
        y="-5"
        [attr.width]="chart.calculateWidth() || 0"
        [attr.height]="chart.calculateHeight() + 10 || 0"
      ></svg:rect>
      <svg:g
        *ngFor="let day of data; trackBy: trackByDay"
        class="day"
        [ngClass]="{ active: day.dayIndex === selectedDay?.dayIndex }"
        [class.noclick]="dayView"
        (mouseenter)="positionTooltipDay(day, true)"
        (mouseleave)="positionTooltipDay(day, false)"
        (click)="showSelectedDay(day)"
        [class.hovered]="tooltipDay.day === day && tooltipDay.show"
      >
        <svg:path
          class="region"
          attr.d="
          M{{ day.x }},{{ day.y + 5 }}
          q0,-10 {{ day.width / 2 }},-10
          q{{ day.width / 2 }},0 {{ day.width / 2 }}, 10
          v{{ day.height }}
          h{{ -day.width }}
          "
        ></svg:path>
        <svg:rect
          class="regionLight"
          [attr.x]="day.x - 1"
          [attr.y]="chart.calculateHeight() / 4 || 0"
          [attr.width]="day.width + 2"
          [attr.height]="chart.calculateHeight() / 2 + 5 || 0"
        ></svg:rect>

        <svg:circle
          *ngFor="let event of day.events; trackBy: trackByDayEvents"
          class="event"
          [ngClass]="event.type"
          [class.bigger]="event.hover"
          [attr.cx]="day.center"
          [attr.cy]="event.y"
          [attr.r]="event.hover ? 4 : 2"
        />
      </svg:g>
      <svg:circle
        *ngFor="let day of data; trackBy: trackByDay"
        class="hoverCircle"
        [class.hovered]="tooltipDay.day === day && tooltipDay.show"
        [attr.cx]="day.center"
        [attr.cy]="day.height / 2"
        [attr.r]="16"
        (mouseenter)="positionTooltipDay(day, true)"
        (mouseleave)="positionTooltipDay(day, false)"
        (click)="showSelectedDay(day)"
      />
    </svg:g>
  </svg>
  <div
    class="tooltip-day"
    *ngIf="tooltipDay.show"
    [ngStyle]="tooltipDay.style"
    [class.last]="tooltipDay?.day?.dayIndex === 0"
  >
    {{ this.tooltipDay.text }}, {{ this.tooltipDay.day_text }}
    <div class="eventCountText" *ngIf="tooltipDay.count === 1">
      {{ this.tooltipDay.count }} {{ 'aisecurity.chart.event' | translate }}
    </div>
    <div class="eventCountText" *ngIf="tooltipDay.count > 1">
      {{ this.tooltipDay.count }} {{ 'aisecurity.chart.events' | translate }}
    </div>
  </div>
</div>
