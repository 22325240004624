/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import { NetworkErrorComponent } from 'src/app/components/network-error/network-error.component';
import { IPlumeState } from '../store';
import { authToken, cloudUrl } from '../store/selectors/plume.selectors';
import { AuthService } from './auth.service';
import { DialogService } from './dialog/dialog.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  errorDialogShowed = false;
  errorDialog401Showed = false;
  constructor(private store$: Store<IPlumeState>, private dialog: DialogService, private auth: AuthService) {}

  intercept(originalReq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.setAuthToken$(originalReq).pipe(
      switchMap(req => this.setCloudUrl$(req)),
      map(req => this.removeInternalHeaders(req)),
      switchMap(req => next.handle(req)),
      catchError((error: HttpErrorResponse) => {
        this.showError(error, originalReq);
        throw error;
      })
    );
  }

  private setCloudUrl$(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    if (!req.url.startsWith('assets') && !req.url.startsWith('/assets')) {
      return this.store$.select(cloudUrl).pipe(
        first(),
        map(clUrl =>
          req.clone({
            url: this.makeUrl(clUrl, req.url, req.headers.get('api-name'))
          })
        )
      );
    } else {
      return of(req);
    }
  }
  private removeInternalHeaders(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({ headers: req.headers.delete('errors-white-list').delete('api-name') });
  }

  private setAuthToken$(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    if (!req.url.startsWith('assets') && !req.url.startsWith('/assets')) {
      return this.store$.select(authToken).pipe(
        first(),
        map(({ token }) => token),
        map(token =>
          req.clone({
            setHeaders: { Authorization: token }
          })
        )
      );
    } else {
      return of(req);
    }
  }

  private makeUrl(clUrl: string, path: string, apiName?: string): string {
    apiName = apiName ?? 'api';
    if (!clUrl.endsWith('/')) {
      apiName = '/' + apiName;
    }
    if (path == '') {
      return `${clUrl}${apiName}`;
    } else if (!path.startsWith('/')) {
      apiName = apiName + '/';
    }
    return `${clUrl}${apiName}${path}`;
  }

  private showError(error: HttpErrorResponse, originalReq: HttpRequest<any>): void {
    if (
      originalReq.url.startsWith('assets') ||
      originalReq.url.startsWith('/assets') ||
      originalReq.headers.getAll('errors-white-list')?.includes(error.status.toString())
    ) {
      return;
    }

    if (error.status === 401) {
      this.showTokenExpireError(error);
      return;
    }

    if (!this.errorDialogShowed) {
      this.errorDialogShowed = true;
      this.dialog
        .openDialog$(NetworkErrorComponent, error)
        .pipe(take(1))
        .subscribe(() => (this.errorDialogShowed = false));
    }
  }

  private showTokenExpireError(error: HttpErrorResponse): void {
    this.errorDialogShowed = true;
    if (!this.errorDialog401Showed) {
      this.errorDialog401Showed = true;
      this.dialog
        .openDialog$(NetworkErrorComponent, error)
        .pipe(take(1))
        .subscribe(() => {
          this.auth.logout();
        });
    }
  }
}
