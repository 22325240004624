import { Component, OnInit, Input } from '@angular/core';
import { PolygonInCircle } from '../../../../app/lib/functions/polygon-in-circle';
import { ConnectionState } from '../../../../app/lib/rest-types';

@Component({
  selector: 'plm-parent-node',
  templateUrl: './parent-node.component.html',
  styleUrls: ['./parent-node.component.scss']
})
export class ParentNodeComponent implements OnInit {
  viewAll = false;
  width = 37;
  strokeWidth = 2;
  path = '';
  @Input() node: { id: string; nickname?: string; defaultName?: string; connectionState?: ConnectionState; isGateway };

  ngOnInit(): void {
    this.path = PolygonInCircle.get(this.width / 2, 6, this.strokeWidth, 0, 4);
  }
}
