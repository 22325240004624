import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { DomPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';

@Directive({
  selector: '[plmOverflowToolTip]'
})
export class OverflowToolTipDirective implements OnDestroy {
  overlayRef: OverlayRef;
  textElement: HTMLElement;
  observer: MutationObserver;
  constructor(private overlay: Overlay, private elm: ElementRef<HTMLElement>) {
    this.overlayRef = this.overlay.create({
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      panelClass: 'tool-tip-basic-box',
      positionStrategy: overlay
        .position()
        .flexibleConnectedTo(elm)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 4
          }
        ])
    });
    this.observeBody();
  }

  @HostListener('mouseenter', ['$event.target'])
  showToolTipIfRequired(elm: HTMLElement): void {
    if (elm.offsetWidth < elm.scrollWidth && !this.overlayRef.hasAttached()) {
      this.textElement = document.createElement('span');
      document.body.appendChild(this.textElement);
      this.textElement.innerHTML = elm.innerHTML;
      this.overlayRef.attach(new DomPortal(this.textElement));
    }
  }

  @HostListener('mouseleave')
  hideToolTip(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      document.body.removeChild(this.textElement);
      this.textElement = null;
    }
  }

  private observeBody(): void {
    // observe body removed items and if this directive was removed from body in any depth then hide tooltip
    this.observer = new MutationObserver(e => {
      e.forEach(mutationRecord => {
        mutationRecord.removedNodes.forEach(node => {
          if (node.contains(this.elm.nativeElement)) {
            this.hideToolTip();
          }
        });
      });
    });

    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    this.overlayRef.dispose();
    if (this.textElement && document.body.contains(this.textElement)) {
      document.body.removeChild(this.textElement);
    }
  }
}
