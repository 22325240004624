import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ListModule } from '../ui/list/list.module';
import { HardwareInfoComponent } from './hardware-info.component';

@NgModule({
  imports: [CommonModule, TranslateModule, ListModule],
  exports: [HardwareInfoComponent],
  declarations: [HardwareInfoComponent],
  providers: []
})
export class HardwareInfoModule {}
