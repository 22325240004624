<svg:line
  class="line"
  x1="0"
  [attr.y1]="this.calculations.columnY(0)"
  [attr.x2]="calculations.width"
  [attr.y2]="this.calculations.columnY(0)"
/>
<ng-container *ngIf="this.chartData?.length > 0">
  <ng-container *ngFor="let columnIndex of weekIndexes; let first = first; let arrIndex = index; let last = last">
    <svg:line
      class="line"
      [attr.x1]="calculations.columnXStart(columnIndex)"
      [attr.y1]="this.calculations.columnY(0)"
      [attr.x2]="calculations.columnXStart(columnIndex)"
      [attr.y2]="this.calculations.columnY(0) + 3"
    />
    <svg:text
      class="text"
      [class.today]="first"
      [attr.x]="calculations.columnXStart(columnIndex)"
      [attr.y]="this.calculations.columnY(0) + 13"
    >
      {{ xAxisBaseText(arrIndex) | translate: { weeks: arrIndex } }}
    </svg:text>
    <svg:text
      class="text ago"
      [class.last]="last"
      *ngIf="!first"
      [attr.x]="calculations.columnXStart(columnIndex)"
      [attr.y]="this.calculations.columnY(0) + 23"
    >
      {{ 'time.ago' | translate }}
    </svg:text>
  </ng-container>
</ng-container>
