import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AppFacade, SecurityPolicyEvents } from '../../rest-types';
import { AiSecurityHourlyEventCount } from '../../services/ai-security/ai-security.service';

export const loadSecurityDailyCounts = createAction('[insights] load security daily counts');

export const loadSecurityDailyCountsDone = createAction(
  '[insights] load security Daily Counts done',
  props<{ data: AiSecurityHourlyEventCount[] | { error: HttpErrorResponse }; macAddress: string }>()
);

export const loadSecurityByMac = createAction(
  '[insights] load security by mac address',
  props<{ macAddress: string }>()
);

export const reloadSecurityCounts = createAction('[insights] reload security');

export const loadSecurityEvents = createAction('[insights] load security events');
export const loadMoreSecurityEvents = createAction('[insights] load more security events');
export const reLoadSecurityEvents = createAction('[insights] reload security events');
export const loadSecurityEventsDone = createAction(
  '[insights] load security events done',
  props<{ data: SecurityPolicyEvents[] | { error: HttpErrorResponse } }>()
);
export const loadFacade = createAction('[insights] load facade');
export const loadFacadeDone = createAction('[insights] load facade done', props<{ data: AppFacade }>());
export const speedTestStart = createAction('[insights] speed test start');
export const speedTestFinished = createAction('[insights] speed test finished');
