import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'daysAgo'
})
export class DaysAgoPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(dateTime: string): string {
    const inputDay = moment(dateTime).startOf('day');
    const todayDay = moment().startOf('day');
    const diffInDays = moment(todayDay).diff(inputDay, 'days');
    if (diffInDays === 0) {
      return this.translate.instant('time.today') as string;
    }
    if (diffInDays === 1) {
      return this.translate.instant('time.yesterday') as string;
    }
    if (diffInDays > 7) {
      return moment(dateTime).format(this.translate.instant('aisecurity.eventList.dateFormat'));
    }
    return this.translate.instant('time.daysAgo', { days: diffInDays }) as string;
  }
}
