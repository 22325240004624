<ol>
  <li>
    <span [innerHtml]="'troubleshooting.whyOffline.a.text' | translate: urls"></span>
    <ul>
      <li [innerHtml]="'troubleshooting.whyOffline.a.point1' | translate: urls"></li>
      <li [innerHtml]="'troubleshooting.whyOffline.a.point2' | translate: urls"></li>
    </ul>
  </li>
  <li>
    <span [innerHtml]="'troubleshooting.whyOffline.b.text' | translate: urls"></span>
    <ul>
      <li [innerHtml]="'troubleshooting.whyOffline.b.point1' | translate: urls"></li>
    </ul>
  </li>
  <li>
    <span [innerHtml]="'troubleshooting.whyOffline.c.text' | translate: urls"></span>
  </li>
</ol>
<a [href]="urls.learnMore" class="learnMore">
  {{ 'troubleshooting.whyOffline.learnMore' | translate }}
  <div class="icon" icon="arrow-website-small"></div>
</a>
