import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from 'src/app/lib/directives/icon/icon.module';
import { InfoOverlayComponent } from './info-overlay.component';
import { OverlayModule as CDKOverlayModule } from '@angular/cdk/overlay';
import { OverlayModule } from '../overlay/overlay.module';

@NgModule({
  imports: [CommonModule, IconModule, OverlayModule, CDKOverlayModule, OverlayModule],
  exports: [InfoOverlayComponent],
  declarations: [InfoOverlayComponent],
  providers: []
})
export class InfoOverlayModule {}
