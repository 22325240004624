import { Component, Input } from '@angular/core';
import { MenuItem } from './menu.model';

@Component({
  selector: 'plm-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss']
})
export class MenuComponent {
  @Input() config: MenuItem[];
  @Input() inset = false;
}
