import { createSelector } from '@ngrx/store';
import { IPlumeState } from '..';
import { Optimization, PlumeNode, PlumeSummary, Topology } from '../../rest-types';
import { Device } from '../models/device.model';
import { Person } from '../models/person.model';

export const nodes = (state: IPlumeState): PlumeNode[] => state.homeState.nodes;
export const summary = (state: IPlumeState): PlumeSummary => state.homeState.summary;
export const optimization = (state: IPlumeState): Optimization => state.homeState.optimization;
export const forceGraph = (state: IPlumeState): Topology => state.homeState.forceGraph;
export const selectDevices = (state: IPlumeState): Device[] => state.homeState.devices;
export const selectPeople = (state: IPlumeState): Person[] => state.homeState.persons;
export const homeLoading = (state: IPlumeState): boolean => state.homeState.loading;
export const deletingNodesId = (state: IPlumeState): string[] => state.homeState.deletingNodesId;

export const offline = createSelector(
  homeLoading,
  summary,
  (homeLoadingVal, summaryVal) => homeLoadingVal === false && summaryVal?.gatewayConnectionState === 'disconnected'
);
