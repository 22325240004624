<div class="device-item-container">
  <div class="device-item-info">
    <span icon="person" class="icon person-icon"></span>
    <div class="device-icon-container">
      <span class="icon device-icon" [icon]="device?.iconV2" [device]="true" height="50" width="50"></span>
    </div>
    <div class="device-name-container">
      <span class="device-name">{{ device?.nickname || device?.name }}</span>
      <span class="device-connection"
        >{{ 'pod.device.onlineSince' | translate }} {{ device?.connectionStateChangeAt | time }},
        {{ device?.connectionStateChangeAt | daysAgo }}</span
      >
    </div>
  </div>
  <div class="side-menu">
    <plm-overlay-button
      [buttonIcon]="'more'"
      [iconOnly]="true"
      [overlayHeight]="110"
      [overlayWidth]="300"
      [offSetY]="125"
      offSetX="170"
      [border]="false"
      originX="end"
      originY="top"
      overlayX="end"
      overlayY="bottom"
    >
      <plm-space size="xxs"></plm-space>
      <plm-list class="device-side-info">
        <li>
          <span class="key">{{ 'pod.device.macAddress' | translate }} </span>
          <span class="value">{{ device?.mac }}</span>
        </li>
        <li>
          <span class="key">{{ 'pod.device.ipAddress' | translate }} </span>
          <span class="value">{{ device?.ip }}</span>
        </li>
      </plm-list>
    </plm-overlay-button>
  </div>
</div>
