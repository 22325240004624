<div class="dns" *ngIf="dns" (click)="disabled && openNetworkingModeDialog()">
  <plm-tabs class="dns__tabs" [disabled]="disabled">
    <plm-tab [active]="!dns.enabled">
      <ng-template plmTabLabel>
        <span>{{ 'dns.ispDns' | translate }}</span>
      </ng-template>
      <div class="dns__item line">
        {{ 'dns.primary' | translate }}<span>{{ dns.enabled === false ? dns.primaryDns : '' }}</span>
      </div>

      <div class="dns__item">
        {{ 'dns.secondary' | translate }}<span>{{ dns.enabled === false ? dns.secondaryDns : '' }}</span>
      </div>

      <div class="dns__bottom-buttons">
        <button class="highlight" *ngIf="dns.enabled" (click)="onReset()">{{ 'dns.reset' | translate }}</button>
      </div>
    </plm-tab>
    <plm-tab [active]="dns.enabled">
      <ng-template plmTabLabel>
        <span>{{ 'dns.customDns' | translate }}</span>
      </ng-template>
      <div class="dns__item line">
        {{ 'dns.primary' | translate
        }}<input class="dns__input" [(ngModel)]="customPrimaryDns" [ngClass]="{ valid: !isCustomPrimaryDnsValid }" />
      </div>

      <div class="dns__item">
        {{ 'dns.secondary' | translate
        }}<input
          class="dns__input"
          [(ngModel)]="customSecondaryDns"
          [ngClass]="{ valid: !isCustomSecondaryDnsValid }"
        />
      </div>

      <div class="dns__bottom-buttons" *ngIf="!disabled">
        <button class="highlight" (click)="onSave()">{{ 'buttons.save' | translate }}</button>
      </div>
    </plm-tab>
  </plm-tabs>
</div>
