import { AppFacade, SecurityPolicyEvents, WifiNetwork } from '../../rest-types';
import { AiSecurityHourlyEventCount } from '../../services/ai-security/ai-security.service';
import { Person } from '../models/person.model';
import { SecurityPolicyModel } from '../models/security-policy.model';
import { HttpState, LoadingState } from './http-state.interface';

export enum PolicyOngoingChanges {
  processing = 1, // waiting for backend
  waitForReload // backend answered, waiting for data reload
}

export interface IInsights {
  appFacade: AppFacade;
  queryMacAddress: string;
  dataMacAddress: string;
  securityDailyCounts: HttpState<AiSecurityHourlyEventCount[]>;
  securityEvents: HttpState<SecurityPolicyEvents[]>;
  securityEventsLimit: number;
  selectedPerson: Person;
  selectedPersonLoad: boolean;
  securityPolicy: SecurityPolicyModel;
  securityPolicyLoad: boolean;
  policyOngoingChanges: { [key: string]: PolicyOngoingChanges }; // key is URL/IPv4
  speedTestRunning: boolean;
  wifiNetwork: HttpState<WifiNetwork>;
}

export const initialInsightsState: IInsights = {
  appFacade: null,
  queryMacAddress: null,
  dataMacAddress: null,
  securityDailyCounts: { data: null, state: LoadingState.init },
  selectedPerson: null,
  selectedPersonLoad: false,
  securityPolicy: null,
  securityPolicyLoad: false,
  securityEvents: { data: null, state: LoadingState.init },
  securityEventsLimit: 0,
  policyOngoingChanges: {},
  speedTestRunning: false,
  wifiNetwork: { data: null, state: LoadingState.init }
};

export const securityEventPageSize = 10;
