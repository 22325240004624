import { Input } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'plm-chip',
  templateUrl: 'chip.component.html',
  styleUrls: ['chip.component.scss']
})
export class ChipComponent {
  @Input() chipIcon: string;
  @Input() position: 'left' | 'right' = 'right';
}
