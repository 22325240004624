<h3>{{ 'podsOverview.title' | translate }}</h3>
<div class="subHeader">
  {{ 'podsOverview.subHeader' | translate: { online: connectedDevices, offline: allDevices - connectedDevices } }}
</div>
<div class="separator" *ngIf="gatewayList?.length > 0">
  <span>{{ 'podsOverview.gateway' | translate }}</span>
  <span class="line"></span>
</div>

<ng-container *ngIf="gatewayList?.length > 0">
  <div class="gateway" *ngFor="let gateWay of gatewayList | sort: '-connectedDeviceCount':'name'">
    <plm-pod-graphics
      [status]="gateWay.connectionState"
      [gateway]="true"
      [childCount]="gateWay.connectedDeviceCount"
    ></plm-pod-graphics>
    <div>
      <div class="podName">{{ gateWay.name }}</div>
      <div class="connectedCount">
        {{ 'podsOverview.numConnectedDevices' | translate: { count: gateWay.connectedDeviceCount } }}
      </div>
    </div>
  </div>
</ng-container>

<div class="separator" *ngIf="nodeList?.length > 0">
  <span>{{ 'podsOverview.others' | translate }}</span>
  <span class="line"></span>
  <span class="viewAll" (click)="toggleAll()" *ngIf="nodeList.length > 5">
    <span>{{ 'podsOverview.viewAll' | translate: { count: nodeList.length } }}</span>
    <span class="icon" icon="arrow-right" [class.opened]="viewAll"></span>
  </span>
</div>

<div class="nodeGrid" *ngIf="nodeList?.length > 0">
  <div
    class="node"
    *ngFor="
      let node of nodeList
        | sort: 'connectionState':'name':'-connectedDeviceCount'
        | slice: 0:(viewAll ? nodeList.length : 5)
    "
  >
    <plm-pod-graphics [status]="node.connectionState" [childCount]="node.connectedDeviceCount"></plm-pod-graphics>
    <span class="podName" plmOverflowToolTip>{{ node.name }}</span>
    <span class="health" [class]="healthStatus(node)">{{
      'podsOverview.healthStatus.' + healthStatus(node) | translate
    }}</span>
  </div>
</div>
