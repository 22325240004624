import { Component, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { Dhcp } from 'src/app/lib/store/models/dhcp.model';
import { NetworkModeRouterDialogComponent } from '../network-mode-router-dialog/network-mode-router-dialog.component';
import { AccordionComponent } from '../ui/accordion/accordion.component';

@Component({
  selector: 'plm-lan-subnet',
  templateUrl: 'lan-subnet.component.html',
  styleUrls: ['lan-subnet.component.scss']
})
export class LanSubnetComponent {
  private valDhcp: Dhcp;
  public ipClass: string;

  @Input() @HostBinding('class.disabled') disabled = false;

  @Input() set dhcp(dhcp: Dhcp) {
    this.valDhcp = dhcp;
    if (this.valDhcp && this.valDhcp.subnet) {
      this.ipClass = this.getIpClass(this.valDhcp.subnet);
    }
  }

  get dhcp(): Dhcp {
    return this.valDhcp;
  }

  @Output() save = new EventEmitter<string>();

  @ViewChild('accordion') accordion: AccordionComponent;
  @HostListener('click') click(): void {
    if (this.disabled) {
      this.dialog.openDialog$<boolean>(NetworkModeRouterDialogComponent);
    }
  }

  constructor(private dialog: DialogService) {}

  onSave(ip: string): void {
    this.save.emit(ip);
    this.accordion.closeAll();
  }

  onCancel(): void {
    this.accordion.closeAll();
  }

  maskedSubNet(): string {
    const subNetParts = this.valDhcp.subnet?.split('.') || [];
    if (subNetParts[3]) {
      subNetParts[3] = 'x';
    }

    return subNetParts.join('.');
  }

  private getIpClass(ip: string): string {
    const ipOct = ip.split('.')[0];
    switch (ipOct) {
      case '10':
        return 'A';
        break;
      case '172':
        return 'B';
      case '192':
        return 'C';
    }
  }
}
