import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { currentLocation, currentLocationId, locations } from 'src/app/lib/store/selectors/plume.selectors';
import { changeCurrentLocationId, setLocationName } from 'src/app/lib/store/actions/plume.actions';
import { IPlumeState } from 'src/app/lib/store';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { switchMap, take } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { RenameLocationComponent } from '../account-menu/rename-location/rename-location.component';

@Component({
  selector: 'plm-account-menu-mobile',
  templateUrl: './account-menu-mobile.component.html',
  styleUrls: ['./account-menu-mobile.component.scss']
})
export class AccountMenuMobileComponent {
  currentLocationId$ = this.store$.pipe(select(currentLocationId));
  locations$ = this.store$.pipe(select(locations));
  currentLocation$ = this.store$.pipe(select(currentLocation));
  dropDownOpened = false;
  constructor(private store$: Store<IPlumeState>, private dialog: DialogService) {}

  changeLocation(locationId: string): void {
    this.store$.dispatch(changeCurrentLocationId({ newLocationId: locationId }));
    this.dropDownOpened = false;
  }

  changeLocationName(event: MouseEvent): void {
    event.stopPropagation();
    this.currentLocation$
      .pipe(
        switchMap((location) =>
          forkJoin([of(location), this.dialog.openDialog$<string>(RenameLocationComponent, location.name)])
        ),
        take(1)
      )
      .subscribe(([location, newName]) => {
        if (newName) {
          this.store$.dispatch(setLocationName({ locationId: location.id, newName }));
        }
      });
  }

  toggleDropDown(): void {
    this.dropDownOpened = !this.dropDownOpened;
  }
}
