<plm-button
  (clicked)="toggle()"
  [isActive]="isOpen"
  cdkOverlayOrigin
  [iconOnly]="iconOnly"
  #trigger="cdkOverlayOrigin"
  [size]="size"
  [disabled]="disabled"
  >{{ label }}
  <span
    *ngIf="buttonIcon"
    class="icon"
    [icon]="buttonIcon"
    [ngClass]="{ 'icon-only': iconOnly, active: isOpen }"
  ></span>
</plm-button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="[
    {
      originX: originX,
      originY: originY,
      overlayX: overlayX,
      overlayY: overlayY,
      offsetY: offSetY,
      offsetX: offsetX
    }
  ]"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="toggle()"
>
  <plm-overlay [height]="overlayHeight" [width]="overlayWidth" [border]="border" (click)="overlayClicked()">
    <ng-content></ng-content>
  </plm-overlay>
</ng-template>
