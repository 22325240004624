import { Component, Inject } from '@angular/core';
import { PlmDialogToken, PlmDialogInject } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  selector: 'plm-rename-location',
  templateUrl: './rename-location.component.html',
  styleUrls: ['./rename-location.component.scss']
})
export class RenameLocationComponent {
  constructor(@Inject(PlmDialogToken) public dialog: PlmDialogInject<string, string | undefined>) {}
  get transPth(): string {
    return 'accountMenu.renameLocation.';
  }
}
