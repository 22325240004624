import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, endWith, filter, takeUntil, tap } from 'rxjs/operators';
import { SecurityAccess } from 'src/app/lib/enums/plume.enums';
import { PlmDialogInject, PlmDialogToken } from 'src/app/lib/services/dialog/dialog.service';
import { ValidationService } from 'src/app/lib/services/validation/validation.service';
import { IPlumeState } from 'src/app/lib/store';
import { PersonActions } from 'src/app/lib/store/actions/person.actions';
import { SecurityPolicyActions } from 'src/app/lib/store/actions/security-policy.actions';
import { Person } from 'src/app/lib/store/models/person.model';
import { WhitelistedOrBlacklisted } from 'src/app/lib/store/models/security-policy.model';
import { policyOngoingChanges } from 'src/app/lib/store/selectors/insights.selectors';
import { selectWebsites, selectWebsitesLoading } from 'src/app/lib/store/selectors/security-policy.selectors';
import { AlertMsg, AlertType } from '../alert-pop-bar/alert-pop.interface';
@UntilDestroy()
@Component({
  selector: 'plm-approve-block',
  templateUrl: 'approve-block.component.html',
  styleUrls: ['approve-block.component.scss']
})
export class ApproveBlockComponent implements OnInit {
  public mode: SecurityAccess = this.data.data.mode;
  public securityAccess: typeof SecurityAccess = SecurityAccess;
  public policyOngoingChanges$ = this.store$.select(policyOngoingChanges);
  public person$: Observable<Person>;
  public person: Person;
  public websiteType: 'fqdn' | 'ip' = 'fqdn';
  public address = new FormControl('');
  public form: FormGroup;
  public websites$: Observable<WhitelistedOrBlacklisted[]>;
  public websitesLoading$: Observable<boolean>;
  public messageAlert$ = new Subject<AlertMsg>();

  // Regex to check valid domain name
  public domainValidatePattern = '^(?:(?!-)[A-Za-z0-9-]{1,63}\\.)+[A-Za-z]{2,63}$';
  addressInvalid: boolean;
  public loadingTemplateWidth = [240, 332, 185, 240, 280, 240, 240];

  constructor(
    @Inject(PlmDialogToken) public data: PlmDialogInject<{ mode: SecurityAccess }>,
    private store$: Store<IPlumeState>,
    private formBuilder: FormBuilder,
    private actions$: Actions,
    private validationService: ValidationService
  ) {
    this.buildForm();
    this.startComponentEffects();
  }

  ngOnInit(): void {
    this.store$.dispatch(SecurityPolicyActions.getSecurityPolicyByLocation());

    switch (this.mode) {
      case 'APPROVE':
        this.getWebsites('PolicyWhiteList');
        break;
      case 'BLOCKED':
        this.getWebsites('PolicyBlackList');
        break;
      default:
        break;
    }
  }

  getPerson(person: Person): void {
    if (!person) {
      this.store$.dispatch(SecurityPolicyActions.getSecurityPolicyByLocation());
    } else {
      this.store$.dispatch(PersonActions.getPersonById({ id: person.id }));
    }
    this.person = person;
    this.resetForm();

    switch (this.mode) {
      case 'APPROVE':
        this.getWebsites(person ? 'PersonWhiteList' : 'PolicyWhiteList');
        break;
      case 'BLOCKED':
        this.getWebsites(person ? 'PersonBlackList' : 'PolicyBlackList');
        break;
      default:
        break;
    }
  }

  guardAction(): void {
    if (this.websiteType === 'fqdn') {
      this.form.patchValue({
        ['address']: this.cleanDomainUrl((this.form.value as string)['address'])
      });
    }
    // Check that address is valid for url and Ip's
    if (!this.validateAddress((this.form.value as { address: string })['address'], this.websiteType)) {
      this.addressInvalid = true;
      return;
    }
    switch (this.mode) {
      case 'APPROVE':
        this.store$.dispatch(SecurityPolicyActions.addSecurityPolicyWhiteList(this.securityPolicyDispatchParams()));
        this.resetForm();

        break;
      case 'BLOCKED':
        this.store$.dispatch(SecurityPolicyActions.addSecurityPolicyBlackList(this.securityPolicyDispatchParams()));
        this.resetForm();
        break;
    }
  }

  validateAddress(address: string, websiteType: string): boolean {
    if (websiteType === 'ip' && this.validationService.ipAddress(address)) {
      return true;
    }

    if (websiteType === 'fqdn' && new RegExp(this.domainValidatePattern).exec(address)) {
      return true;
    }
    // Invalid Address or IP
    return false;
  }

  removeAction(website: string): void {
    switch (this.mode) {
      case 'APPROVE':
        this.store$.dispatch(
          SecurityPolicyActions.removeSecurityPolicyWhiteList({
            url: website,
            for: this.person ? 'person' : 'everyone',
            personId: this.person?.id
          })
        );
        break;

      case 'BLOCKED':
        this.store$.dispatch(
          SecurityPolicyActions.removeSecurityPolicyBlackList({
            url: website,
            for: this.person ? 'person' : 'everyone',
            personId: this.person?.id
          })
        );
        break;

      default:
        break;
    }
  }

  openWebsite(website: string): void {
    window.open(`https://${website}`, '_blank'); // Open new tab
  }

  setWebsiteType(type: 'fqdn' | 'ip'): void {
    if (type === 'fqdn') {
      this.websiteType = type;
      this.resetForm();
    }
    if (type === 'ip') {
      this.websiteType = type;
      this.resetForm();
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      address: ['']
    });
  }

  private getWebsites(type: string): void {
    // complete loading when it's turn to false and emit false - so loading is not visible when loading
    // items on add/remove, but change subscription on people change so loading is visible when changing people
    this.websitesLoading$ = this.store$
      .select(selectWebsitesLoading(type))
      .pipe(
        takeUntil(this.store$.select(selectWebsitesLoading(type)).pipe(filter(val => val === false))),
        endWith(false)
      );
    this.websites$ = this.store$.select(selectWebsites(type)).pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      tap(() => this.store$.dispatch(SecurityPolicyActions.garbageCollectPolicyChangeList()))
    );
  }

  private resetForm(): void {
    this.addressInvalid = false;
    this.form.controls['address'].patchValue('');
  }

  private securityPolicyDispatchParams(): { url: string; dnsType: 'fqdn' | 'ip' } & (
    | { personId: string; for: 'person' }
    | { mac: string; for: 'device' }
    | { for: 'everyone' }
  ) {
    return {
      url: (this.form.value as { address: string })['address'],
      dnsType: this.websiteType,
      for: this.person ? 'person' : 'everyone',
      personId: this.person?.id
    };
  }

  private startComponentEffects() {
    this.actions$
      .pipe(
        ofType(
          SecurityPolicyActions.addSecurityPolicyWhiteListSuccess,
          SecurityPolicyActions.addSecurityPolicyBlackListSuccess,
          SecurityPolicyActions.removeSecurityPolicyWhiteListSuccess,
          SecurityPolicyActions.removeSecurityPolicyBlackListSuccess
        ),
        untilDestroyed(this)
      )
      .subscribe(action => {
        this.effectMsgNotification(action);

        if (this.person) {
          this.store$.dispatch(PersonActions.getPersonById({ id: this.person?.id }));
        } else {
          this.store$.dispatch(SecurityPolicyActions.getSecurityPolicyByLocation());
        }
      });
  }

  private effectMsgNotification(action: { type: string }): void {
    if (action.type === SecurityPolicyActions.addSecurityPolicyWhiteListSuccess.type) {
      this.messageAlert$.next({
        type: AlertType.notification,
        message: 'approveBlocked.approved',
        needsTranslation: true
      });
    } else if (action.type === SecurityPolicyActions.addSecurityPolicyBlackListSuccess.type) {
      this.messageAlert$.next({
        type: AlertType.notification,
        message: 'approveBlocked.blocked',
        needsTranslation: true
      });
    } else {
      this.messageAlert$.next({
        type: AlertType.notification,
        message: 'approveBlocked.removed',
        needsTranslation: true
      });
    }
  }

  private cleanDomainUrl(url: string) {
    return url.replace(/(^www\.)(.+)/, '$2');
  }
}
