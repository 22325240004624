import { SecurityChart } from './models/charts/security.chart';
import { Injectable } from '@angular/core';

@Injectable()
export class D3Service {
  generate(type: string): SecurityChart {
    switch (type) {
      case 'securitychart':
        return new SecurityChart();
    }
  }
}
