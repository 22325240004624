<div (click)="!disabled && clicked()" cdkOverlayOrigin #menuTrigger="cdkOverlayOrigin" (plmResized)="onResized()">
  <ng-content></ng-content>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="menuTrigger"
  [cdkConnectedOverlayOpen]="menuOpened"
  [cdkConnectedOverlayPositions]="positionValue"
  [cdkConnectedOverlayPanelClass]="panelClass"
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayWidth]="
    computedTouchCorner === 'bottom' || computedTouchCorner === 'top' ? triggerWidth : undefined
  "
  (overlayOutsideClick)="closeOnClickOutside && closeMenu()"
>
  <perfect-scrollbar
    #overlay
    class="plm-drop-down-menu-component-overlay"
    [ngClass]="touchCorner || computedTouchCorner"
    [style.max-width]="width ? 'none' : ''"
    [style.width]="width"
    [style.min-width.px]="dropDownMinWidth"
    [class.minWidth]="menuOpened && dropDownMinWidth && dropDownCurrentWidth === dropDownMinWidth"
  >
    <div (click)="closeOnClick && closeMenu()">
      <ng-content select="[dropdown]"></ng-content>
    </div>
  </perfect-scrollbar>
</ng-template>
