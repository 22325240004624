<div class="header">{{ 'aisecurity.eventList.header' | translate }}</div>

<div *ngIf="mappedEvents?.length > 0">
  <div class="row" *ngFor="let event of mappedEvents">
    <div class="basicData">
      <div class="date">
        <div>{{ event.createdAt | daysAgo }}</div>
        <div>{{ event.createdAt | time }}</div>
      </div>
      <div class="type">
        <div class="verticalLine"></div>
        <div class="icon" [icon]="tileTypeIcon(event.eventType)"></div>
        <div class="circle" [ngClass]="tileType(event.eventType)"></div>
      </div>
      <div class="target">
        <div class="device">{{ event.device?.nickname || event.device?.name || event.eventData.mac }}</div>
        <div class="destination">{{ website(event) }}</div>
      </div>
      <div class="gap"></div>
      <div class="reason">{{ message(event) }}</div>
      <plm-dropdown-menu #menu position="bottomRight" [offsetY]="-6">
        <plm-list dropdown>
          <li class="drop-down-item" (click)="doApprove(event, 'person')" *ngIf="event?.device?.personId">
            {{
              'aisecurity.eventList.approveForUser' | translate: { user: peopleHash[event?.device?.personId]?.nickname }
            }}
          </li>
          <li class="drop-down-item" (click)="doApprove(event, 'device')" *ngIf="!event?.device?.personId">
            {{
              'aisecurity.eventList.approveForDevice'
                | translate: { device: event?.device?.nickname || event?.device?.name || event?.eventData?.mac }
            }}
          </li>
          <li class="drop-down-item" (click)="doApprove(event, 'everyone')">
            {{ 'aisecurity.eventList.approveForEveryone' | translate }}
          </li>
        </plm-list>
        <div
          [class.loading]="event.eventData.loading"
          [class.selected]="menu.menuOpened"
          cdkOverlayOrigin
          class="icon more"
          icon="more"
          #trigger="cdkOverlayOrigin"
        ></div>
      </plm-dropdown-menu>
    </div>
    <div *ngIf="showMap(event)">
      <div class="mapRow">
        <div class="date"></div>
        <div class="type"><div class="verticalLine"></div></div>
        <plm-map class="map" [pos]="{ lat: +event.eventData.latitude, long: +event.eventData.longitude }"> </plm-map>
      </div>
    </div>
  </div>
</div>
