import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayButtonComponent } from './overlay-button.component';
import { OverlayModule as CDKOverlayModule } from '@angular/cdk/overlay';
import { OverlayModule } from '../overlay/overlay.module';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../../../../app/lib/directives/icon/icon.module';

@NgModule({
  declarations: [OverlayButtonComponent],
  exports: [OverlayButtonComponent],
  imports: [CommonModule, ButtonModule, OverlayModule, CDKOverlayModule, OverlayModule, IconModule]
})
export class OverlayButtonModule {}
