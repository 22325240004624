import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IPlumeState } from 'src/app/lib/store';
import { showChat } from 'src/app/lib/store/selectors/plume.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'plm-zend-chat',
  templateUrl: 'zend-chat.component.html',
  styleUrls: ['zend-chat.component.scss']
})
export class ZendChatComponent implements OnInit {
  public zendChatUrl: string = environment.zend.CHAT_URL;
  urlSafe: SafeResourceUrl;
  showChat$: Observable<boolean>;
  constructor(public sanitizer: DomSanitizer, private store$: Store<IPlumeState>) {}

  ngOnInit(): void {
    this.showChat$ = this.store$.select(showChat);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.zendChatUrl);
  }
}
