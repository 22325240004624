import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppFacade, CustomerLocation, PlumeHome } from '../../rest-types';
import { IPlumeState } from '../../store';
import { customer } from '../../store/selectors/plume.selectors';
import { SharedUrlsService } from '../shared-urls';

@Injectable({
  providedIn: 'root'
})
export class AppFacadeService {
  constructor(private http: HttpClient, private sharedUrls: SharedUrlsService, private store$: Store<IPlumeState>) {}

  appFacade$(): Observable<AppFacade> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(switchMap(locationUrl => this.http.get<AppFacade>(`${locationUrl}/appFacade/dashboard`)));
  }

  appFacadeHome$(): Observable<PlumeHome> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap(locationUrl =>
          this.http.get<PlumeHome>(
            `${locationUrl}/appFacade/home?filters=devices,nodes,persons,optimization,summary,forceGraph`
          )
        )
      );
  }

  startSpeedTest$(): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(switchMap(locationUrl => this.http.post<unknown>(`${locationUrl}/ispSpeedTest`, {})));
  }

  setLocationName$(locationId: string, newName: string): Observable<CustomerLocation> {
    return this.store$.select(customer).pipe(
      switchMap(customerData =>
        this.http.put<CustomerLocation>(`/Customers/${customerData.id}/locations/${locationId}`, { name: newName })
      )
    );
  }
}
