import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'plm-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownMenuComponent implements OnDestroy {
  @Input() menuOpened = false;
  @Input() closeOnClick = true;
  @Input() closeOnClickOutside = true;
  @Input() disabled = false;
  @Input() panelClass = '';
  @Input() width = '';
  @Input() offsetY = 0;
  @Input() touchCorner: 'bottomRight' | 'topRight' | 'bottomLeft' | 'topLeft' | 'none' | 'bottom' | 'top' | null = null;
  @Input() set position(
    pos: ConnectedPosition[] | 'bottomRight' | 'topRight' | 'bottomLeft' | 'topLeft' | 'bottom' | 'top'
  ) {
    if (typeof pos === 'string') {
      this.positionValue = this.predefinedPositions[pos];
      this.computedTouchCorner = pos;
    } else {
      this.positionValue = pos;
      this.computedTouchCorner = '';
    }
  }
  @Output() opened = new EventEmitter<boolean>();
  @ViewChild('overlay', { read: ElementRef }) dropDownElm: ElementRef<HTMLElement> | undefined;
  @ViewChild('menuTrigger', { read: ElementRef }) menuTrigger: ElementRef<HTMLElement> | undefined;
  computedTouchCorner = 'topLeft';
  triggerWidth = 0;
  positionValue: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top'
    }
  ];

  predefinedPositions: { [key: string]: ConnectedPosition[] } = {
    topRight: [
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top'
      }
    ],
    bottomRight: [
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom'
      }
    ],
    bottomLeft: [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
      }
    ],
    topLeft: [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      }
    ],
    top: [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      }
    ],
    bottom: [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
      }
    ]
  };
  observer: MutationObserver;
  dropDownMinWidth: number = null;
  dropDownCurrentWidth = 0;

  constructor(private elm: ElementRef<HTMLElement>) {
    this.observer = new MutationObserver(() => {
      this.calcDropDownMinWidth(document.body.classList.contains('dark-theme'));
    });

    this.observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
  }

  clicked(): void {
    this.menuOpened = !this.menuOpened;
    if (this.menuOpened) {
      this.calcDropDownMinWidth(document.body.classList.contains('dark-theme'));
      this.calcCurrentDropDownWidth();
    }
    setTimeout(() => {
      this.opened.emit(this.menuOpened);
    }, 0);
  }

  closeMenu(): void {
    // overlayOutsideClick events fires before click even on button,
    // so if we don't delay closing the menu then clicking on the button will always open menu
    setTimeout(() => {
      this.menuOpened = false;
      this.opened.emit(this.menuOpened);
    }, 0);
  }

  onResized(): void {
    if (this.dropDownMinWidth) {
      this.calcDropDownMinWidth(true);
      this.calcCurrentDropDownWidth();
    }
  }

  private calcDropDownMinWidth(setMinWidth: boolean): void {
    if (setMinWidth) {
      this.dropDownMinWidth = this.elm.nativeElement.clientWidth;
    } else {
      this.dropDownMinWidth = null;
    }
  }

  private calcCurrentDropDownWidth(): void {
    this.triggerWidth = this.menuTrigger?.nativeElement.getBoundingClientRect().width;
    // wait unlit is displayed before measure width
    setTimeout(() => {
      this.dropDownCurrentWidth = this.dropDownElm?.nativeElement.clientWidth;
    }, 0);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
