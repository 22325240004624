import { NgModule } from '@angular/core';
import { EllipsisPipe } from './ellipsis.pipe';

@NgModule({
    imports: [],
    exports: [EllipsisPipe],
    declarations: [EllipsisPipe],
    providers: [],
})
export class EllipsisPipeModule { }
