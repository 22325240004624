import { ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { IPlumeState } from '..';

export const sessionStorageSyncReducer = (reducer: ActionReducer<IPlumeState>): ActionReducer<IPlumeState> =>
  localStorageSync({
    keys: ['plumeState'],
    rehydrate: true,
    storage: sessionStorage
  })(reducer);
