<div class="parent-node-container">
  <div class="pod-network">
    <svg [attr.width]="width" [attr.height]="width" attr.viewBox="0 0 {{ width }} {{ width }}">
      <path
        class="polygon"
        [attr.d]="path"
        [attr.stroke-width]="strokeWidth"
        [class.offline]="node?.connectionState === 'disconnected'"
      />
    </svg>
    <div *ngIf="node?.isGateway" class="centerIcon" icon="gfx-globe"></div>
  </div>
  <div class="pod-name">{{ node?.nickname || node?.defaultName }}</div>
</div>
