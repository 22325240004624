import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { IPlumeState } from 'src/app/lib/store';
import { getLocations } from 'src/app/lib/store/actions/plume.actions';
import { changeNetworkMode } from 'src/app/lib/store/actions/settings.actions';
import { NetworkRestartComponent } from './network-restart-dialog/network-restart.component';
import { NetworkModeDialogComponent } from './networking-mode-dialog/networking-mode-dialog.component';

@Component({
  selector: 'plm-networking-mode',
  templateUrl: 'networking-mode.component.html',
  styleUrls: ['networking-mode.component.scss']
})
export class NetworkingModeComponent {
  @Input() networkMode: 'auto' | 'bridge' | 'router';
  @Input() networkModeRealized: 'bridge' | 'router';

  constructor(private dialog: DialogService, private translate: TranslateService, private store$: Store<IPlumeState>) {}

  changeMode(mode: 'auto' | 'router'): void {
    this.dialog
      .openDialog$<boolean>(NetworkModeDialogComponent, { mode: mode })
      .pipe(take(1))
      .subscribe(switchMode => {
        if (switchMode) {
          this.restartNetwork(mode);
        }
      });
  }

  private restartNetwork(mode: 'auto' | 'router') {
    this.store$.dispatch(changeNetworkMode({ mode: mode }));
    return this.dialog
      .openDialog$<boolean>(NetworkRestartComponent)
      .pipe(take(1))
      .subscribe(() => {
        this.store$.dispatch(getLocations());
      });
  }
}
