import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { SaveSsidDialogComponent } from 'src/app/components/adapt/save-ssid-dialog/save-ssid-dialog.component';
import { AlertType } from 'src/app/components/alert-pop-bar/alert-pop.interface';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { IPlumeState } from 'src/app/lib/store';
import { setSsidName, ssidLoaded, ssidLoadedError } from 'src/app/lib/store/actions/settings.actions';
import { homeLoading, nodes } from 'src/app/lib/store/selectors/home.selectors';
import { configuration, currentLocation, currentLocationId } from 'src/app/lib/store/selectors/plume.selectors';
import { ssidSaving } from 'src/app/lib/store/selectors/settings.selectors';

@UntilDestroy()
@Component({
  selector: 'plm-adapt',
  templateUrl: './settings-adapt.component.html',
  styleUrls: ['./settings-adapt.component.scss']
})
export class SettingsAdaptComponent {
  editMode = false;
  ssidValue = '';
  ssidSaving$ = this.store$.pipe(select(ssidSaving));
  currentLocation$ = this.store$.pipe(select(currentLocation));
  pods$ = this.store$.pipe(select(nodes));
  config$ = this.store$.pipe(select(configuration));
  loading$ = this.store$.pipe(select(homeLoading));
  alert$ = new Subject<{ type: AlertType; message: string }>();

  constructor(private store$: Store<IPlumeState>, private dialog: DialogService, actions$: Actions) {
    actions$
      .pipe(
        ofType(ssidLoaded),
        withLatestFrom(this.store$.select(currentLocationId)),
        filter(([wifiAction, locationId]) => wifiAction.wifiNetwork.locationId === locationId),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.editMode = false;
      });
    actions$.pipe(ofType(ssidLoadedError), untilDestroyed(this)).subscribe(msg => {
      this.alert$.next({ message: msg.errorMessage, type: AlertType.fail });
    });
  }

  startEditing(ssid: string): void {
    this.editMode = true;
    this.ssidValue = ssid;
  }

  cancelEditing(): void {
    this.editMode = false;
  }

  setSsid(): void {
    this.dialog
      .openDialog$<boolean>(SaveSsidDialogComponent)
      .pipe(take(1), withLatestFrom(this.store$.select(currentLocationId)))
      .subscribe(([doSave, locationId]) => {
        if (doSave) {
          this.store$.dispatch(setSsidName({ ssid: this.ssidValue, locationId }));
        } else {
          this.editMode = false;
        }
      });
  }

  buyPod(): void {
    this.config$.pipe(take(1)).subscribe(conf => window.open(conf['plumeShop']));
  }
}
