<span class="info-overlay__icon" icon="info2" (click)="toggle()" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></span>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetY: height + 25,
      offsetX: 0
    }
  ]"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="toggle()"
>
  <plm-overlay [height]="height" [width]="width" (click)="toggle()">
    <ng-content></ng-content>
  </plm-overlay>
</ng-template>
