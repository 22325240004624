import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ApproveBlockErrorComponent } from './approve-block-error-modal.component';


@NgModule({
    imports: [CommonModule, TranslateModule,],
    exports: [ApproveBlockErrorComponent],
    declarations: [ApproveBlockErrorComponent],
    providers: [],
})
export class ApproveBlockErrorModule { }
