import { HttpErrorResponse } from '@angular/common/http';

export enum LoadingState {
  init = 0,
  loading,
  loaded
}

export interface HttpState<T> {
  data: T;
  state: { error: HttpErrorResponse } | LoadingState;
}
