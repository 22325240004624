import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild, ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Configuration, Customer, CustomerLocation } from '../rest-types';
import { IPlumeState } from '../store';
import { authToken, cloudId } from '../store/selectors/plume.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate, CanActivateChild {
  constructor(private router: Router, private store$: Store<IPlumeState>, private http: HttpClient) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.activateCheck$(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.activateCheck$(route);
  }

  activateCheck$(route: ActivatedRouteSnapshot = null): Observable<boolean> {
    return this.isAuthenticated$().pipe(
      tap(isAuth => {
        if (isAuth) {
          return;
        } else {
          if (route.queryParams.cloud) {
            void this.router.navigate(['login'], { queryParams: { cloud: route.queryParams.cloud as string } });
          } else {
            void this.router.navigate(['login']);
          }
        }
      })
    );
  }

  isAuthenticated$(): Observable<boolean> {
    return this.store$.pipe(
      select(authToken),
      map(tokenObj => {
        const time = new Date(tokenObj.expireAt);
        return !!(tokenObj.token && tokenObj.expireAt && time.getTime() > Date.now());
      })
    );
  }

  redirect(mode: string, cloud?: string): void {
    this.store$.pipe(select(cloudId), take(1)).subscribe(cloudFromStore => {
      cloud = cloud ?? cloudFromStore ?? 'gamma';
      const clientId = 'plumeHomepass';
      const redirectUri = window.location.origin + '/login/handler';
      const queryParams = '?cloud=' + cloud + '&client_id=' + clientId + '&redirect_uri=' + redirectUri;
      let suffix = '-dev';
      environment.ENV = this.getEnvironment();
      if (environment.ENV === 'Production') {
        suffix = '';
      }

      if (environment.ENV === 'Staging') {
        suffix = '-stg';
      }
      sessionStorage.clear();
      window.location.href = 'https://account' + suffix + '.plume.com/' + mode + queryParams;
    });
  }

  getEnvironment(): string {
    let env: string;
    const splitUrl = window.location.origin.split('.');
    const splitAlias = splitUrl[0].split('-');
    const finalEnv = splitAlias[1];
    if (finalEnv === 'stg') {
      env = 'Staging';
    } else if (finalEnv === 'dev') {
      env = 'Development';
    } else {
      env = 'Production';
    }
    return env;
  }

  logout(): void {
    this.redirect('logout');
  }

  getCustomer$(customerId: string): Observable<Customer> {
    return this.http.get<Customer>(`/Customers/${customerId}`);
  }

  getLocation$(customerId: string): Observable<CustomerLocation[]> {
    return this.http.get<CustomerLocation[]>(`/Customers/${customerId}/locations`);
  }

  configuration$(cloud: string, partnerId: string = null): Observable<Configuration> {
    return (partnerId
      ? this.http.get<Configuration>('/assets/configurations/' + partnerId + '.json')
      : throwError(undefined)
    ).pipe(catchError(() => this.http.get<Configuration>('/assets/configurations/' + cloud + '.json')));
  }
}
