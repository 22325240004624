import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { Upnp } from 'src/app/lib/store/models/upnp.model';
import { NetworkModeRouterDialogComponent } from '../network-mode-router-dialog/network-mode-router-dialog.component';
import { ToggleComponent } from '../ui/toggle/toggle.component';

@Component({
  selector: 'plm-upnp',
  templateUrl: 'upnp.component.html',
  styleUrls: ['upnp.component.scss']
})
export class UpnpComponent {
  private valUpnp: Upnp;
  public hasUpnpChanged: boolean;

  @Input() set upnp(upnp: Upnp) {
    if (upnp) {
      this.valUpnp = JSON.parse(JSON.stringify(upnp)) as Upnp;
      this.hasUpnpChanged = this.upnp.enabled;
    }
  }

  get upnp(): Upnp {
    return this.valUpnp;
  }

  @Input() networkModeRealized: 'router' | 'bridge';

  @Output() upnpEnabled = new EventEmitter<string>();

  @ViewChild('toggle') toggleComponent: ToggleComponent;

  constructor(private dialog: DialogService) {}

  openNetworkingModeDialog(): void {
    this.dialog.openDialog$<boolean>(NetworkModeRouterDialogComponent);
  }

  upnpChanged(): void {
    if (this.networkModeRealized !== 'router') {
      this.toggleComponent.setDisabledState(true);
      return;
    }
    if (this.hasUpnpChanged !== this.upnp.enabled) {
      this.hasUpnpChanged = !this.hasUpnpChanged;
      this.upnpEnabled.emit(this.upnp.enabled ? 'enable' : 'disable');
    }
  }
}
