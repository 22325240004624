<div class="approve-block">
  <div class="approve-block__header header">
    {{
      this.mode === this.securityAccess.APPROVE
        ? ('approveBlocked.approve' | translate)
        : ('approveBlocked.blocked' | translate)
    }}
    <plm-people-drop-down
      [person]="person"
      (personChange)="getPerson($event)"
      position="topRight"
    ></plm-people-drop-down>
  </div>
  <div class="approve-block__selection">
    <plm-dropdown-menu #websiteTypeDropDownMenu position="top">
      <plm-list dropdown>
        <li class="drop-down-item" (click)="setWebsiteType('fqdn')">{{ 'aisecurity.websiteurl' | translate }}</li>
        <li class="drop-down-item" (click)="setWebsiteType('ip')">{{ 'aisecurity.ipaddress' | translate }}</li>
      </plm-list>
      <button class="pill-icon-box down website-button" [class.opened]="websiteTypeDropDownMenu.menuOpened">
        <div>
          {{ websiteType === 'fqdn' ? ('aisecurity.websiteurl' | translate) : ('aisecurity.ipaddress' | translate) }}
        </div>
        <div icon="arrow" class="arrow big"></div>
      </button>
    </plm-dropdown-menu>

    <form [formGroup]="form" class="approve-block__form">
      <div class="approve-block__input" [ngClass]="{ invalid: addressInvalid }" [plmFocusOnClick]="addressInput">
        <span class="textPrefix" *ngIf="websiteType === 'fqdn'">www.</span>
        <input type="text" formControlName="address" #addressInput />
      </div>
      <button class="approve-block__button" (click)="guardAction()">
        {{
          this.mode === this.securityAccess.APPROVE ? ('buttons.approve' | translate) : ('buttons.block' | translate)
        }}
      </button>
    </form>
  </div>

  <plm-space size="s"></plm-space>
  <plm-rule></plm-rule>
  <plm-space size="s"></plm-space>

  <div class="approve-block__subtitle">
    {{
      this.mode === this.securityAccess.APPROVE
        ? ('aisecurity.approvedListing' | translate)
        : ('aisecurity.blockedListing' | translate)
    }}
  </div>

  <plm-space size="xs"></plm-space>

  <perfect-scrollbar class="scrollbar">
    <ng-container *ngIf="(websitesLoading$ | async) === false; else loading">
      <ng-container *ngIf="(websites$ | async)?.length > 0; else emptyList">
        <plm-list class="approve-block__addresses">
          <li *ngFor="let website of websites$ | async; trackBy: trackByIndex">
            {{ website.value }}
            <plm-dropdown-menu position="topRight" [class.disabled]="(policyOngoingChanges$ | async)[website.value]">
              <plm-list dropdown>
                <li
                  class="drop-down-item website-visit"
                  (click)="openWebsite(website.value)"
                  *ngIf="website.type === 'fqdn'"
                >
                  {{ 'approveBlocked.openWebsite' | translate }}
                  <span icon="arrow-website" class="icon"></span>
                </li>
                <li class="drop-down-item" (click)="removeAction(website.value)">
                  {{
                    this.mode === this.securityAccess.APPROVE
                      ? ('approveBlocked.removeFromApprovedList' | translate)
                      : ('approveBlocked.removeFromBlockedList' | translate)
                  }}
                </li>
              </plm-list>
              <div class="icon more" icon="more"></div>
            </plm-dropdown-menu>
          </li>
        </plm-list>
      </ng-container>
    </ng-container>
  </perfect-scrollbar>

  <div class="bottom-buttons">
    <button class="highlight" (click)="data.close()">{{ 'buttons.done' | translate }}</button>
  </div>

  <plm-alert-pop-bar [alert$]="messageAlert$"></plm-alert-pop-bar>
</div>

<ng-template #emptyList>
  <div class="emptyList" *ngIf="this.mode === this.securityAccess.BLOCKED">
    <div icon="empty-list-blocked"></div>
    <div>{{ 'approveBlocked.emptyBlockedList' | translate }}</div>
  </div>
  <div class="emptyList" *ngIf="this.mode === this.securityAccess.APPROVE">
    <div icon="empty-list-approved"></div>
    <div>{{ 'approveBlocked.emptyApprovedList' | translate }}</div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loadingRow" *ngFor="let width of loadingTemplateWidth">
    <div class="line" [style.width.px]="width"></div>
    <div class="circle"></div>
  </div>
</ng-template>
