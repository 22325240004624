import { DhcpReservation } from '../models/dhcp-reservation.model';
import { Dhcp } from '../models/dhcp.model';
import { DnsServers } from '../models/dns.model';
import { Upnp } from '../models/upnp.model';

export interface ISettings {
  locationIdSsidSaving: string[]; // locationId with ssid saving in progress,
  upnp: Upnp;
  dhcpReservations: DhcpReservation[];
  dnsServers: DnsServers;
  dhcp: Dhcp;
}

export const initialSettingsState: ISettings = {
  locationIdSsidSaving: [],
  upnp: undefined,
  dhcpReservations: [],
  dnsServers: undefined,
  dhcp: undefined
};
