import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormInputComponent } from './form-input.component';
import { IconModule } from 'src/app/lib/directives/icon/icon.module';

@NgModule({
    imports: [ReactiveFormsModule, FormsModule, CommonModule, IconModule],
    exports: [FormInputComponent],
    declarations: [FormInputComponent],
    providers: [],
})
export class FormInputModule { }
