import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'plm-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent {
  @Input() date = moment();
  @Output() dateChange = new EventEmitter<moment.Moment>();
  @Output() valid = new EventEmitter<boolean>();
  calendarVisible = false;

  dateChanged(date: moment.Moment): void {
    this.date = date;
    this.dateChange.emit(date);
  }
}
