import { NgModule } from '@angular/core';
import { IconDirective } from './icon.directive';
import { IconService } from '../../../../app/lib/services/icon-service/icon.service';


@NgModule({
    imports: [],
    exports: [IconDirective],
    declarations: [IconDirective],
    providers: [IconService],
})
export class IconModule { }
