import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from 'src/app/lib/directives/icon/icon.module';
import { ChipComponent } from './chip.component';

@NgModule({
    imports: [CommonModule, IconModule],
    exports: [ChipComponent],
    declarations: [ChipComponent],
    providers: [],
})
export class ChipModule { }
