import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'plm-box-footer',
  templateUrl: './box-footer.component.html',
  styleUrls: ['./box-footer.component.scss']
})
export class BoxFooterComponent {
  /**
   * Left side icon
   */
  @Input() linkIcon: string;
  /**
   * Main text
   */
  @Input() text: string;
  /**
   * Optional description under main text, footer will gain extra height
   */
  @Input() description?: string;
  /**
   * Adds right arrow and cursor pinter if true
   */
  @Input() itsLink = true;
  /**
   * blue centered text with pointer
   */
  @Input() itsLocalLink = false;

  @HostBinding('class.withDescription')
  get classWithDescription(): boolean {
    return !!this.description;
  }

  @HostBinding('class.itsLink')
  get classItsLink(): boolean {
    return this.itsLink;
  }

  @HostBinding('class.itsLocalLink')
  get classItsLocalLink(): boolean {
    return this.itsLocalLink;
  }
}
