import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'momentFormat'
})
export class MomentFormatPipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    return moment(value).format(args[0] ?? 'lll');
  }
}
