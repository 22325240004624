import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlumeService {
  formatBytes(value: number, unit: string, decimals = 2): { value: number; unit: string } {
    if (value === 0) {
      return { value: 0, unit };
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const bytes = value * Math.pow(k, sizes.indexOf(unit));

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return {
      value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
      unit: sizes[i]
    };
  }

  isASCII(value: string, extended = false): boolean {
    return (extended ? /^[\x00-\xFF]*$/ : /^[\x00-\x7F]*$/).test(value);
  }
}
