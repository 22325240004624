/* eslint-disable @typescript-eslint/no-shadow */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mapTo, switchMap, switchMapTo } from 'rxjs/operators';
import { AppFacadeService } from '../../services/app-facade/app-facade.service';
import { NodesService } from '../../services/nodes/nodes.service';
import {
  deleteNode,
  deleteNodeDone,
  loadHome,
  loadHomeDone,
  loadHomeSilent,
  renameNode,
  renameNodeDone,
  renameNodeError,
  setNodeLedMode
} from '../actions/home.actions';

@Injectable()
export class HomeEffects {
  loadFacadeHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadHome, loadHomeSilent),
      switchMapTo(this.appFacadeService.appFacadeHome$()),
      map(data => loadHomeDone({ data }))
    )
  );
  deleteNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteNode),
      switchMap(({ nodeId }) =>
        this.nodesService.delete$(nodeId).pipe(
          mapTo({ nodeId, success: true }),
          catchError(() => of({ nodeId, success: false }))
        )
      ),
      map(({ nodeId, success }) => deleteNodeDone({ nodeId, success }))
    )
  );

  setNodeLedMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setNodeLedMode),
        switchMap(({ nodeId, mode, locationId }) => this.nodesService.setLedMode$(nodeId, mode, locationId))
      ),
    { dispatch: false }
  );

  renameNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(renameNode),
      switchMap(({ nodeId, newName }) =>
        this.nodesService.rename$(nodeId, newName).pipe(
          map(node => renameNodeDone({ node })),
          catchError((error: HttpErrorResponse) =>
            of(renameNodeError({ nodeId, errorMessage: (error.error as { error: { message: string } }).error.message }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private appFacadeService: AppFacadeService,
    private nodesService: NodesService
  ) {}
}
