import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { AccessPasswordDropDownClicked } from 'src/app/components/access-settings/access-password/access-password.interface';
import { DeleteWifiPasswordComponent } from 'src/app/components/access-settings/dialogs/delete-wifi-password/delete-wifi-password.component';
import { DisableWifiPasswordComponent } from 'src/app/components/access-settings/dialogs/disable-wifi-password/disable-wifi-password.component';
import { TabComponent } from 'src/app/components/ui/tab/tab/tab.component';
import { TabsComponent } from 'src/app/components/ui/tab/tabs/tabs.component';
import { AccessZoneWifiType, WifiNetworkKeys } from 'src/app/lib/rest-types';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { IPlumeState } from 'src/app/lib/store';
import { SecurityPolicyActions } from 'src/app/lib/store/actions/security-policy.actions';
import {
  deleteWifiAccessZone,
  deleteWifiAccessZoneError,
  deleteWifiPassword,
  deleteWifiPasswordError,
  deleteWifiPasswordSuccess,
  editWifiPassword,
  editWifiPasswordError,
  editWifiPasswordSuccess
} from 'src/app/lib/store/actions/settings.actions';
import { selectDevices } from 'src/app/lib/store/selectors/home.selectors';
import { wifiNetwork } from 'src/app/lib/store/selectors/insights.selectors';
import { EditWifiPasswordComponent } from '../../../components/access-settings/dialogs/edit-wifi-password/edit-wifi-password.component';

@UntilDestroy()
@Component({
  selector: 'plm-settings-access',
  templateUrl: './settings-access.component.html',
  styleUrls: ['./settings-access.component.scss']
})
export class SettingsAccessComponent implements AfterViewInit {
  @ViewChild('tabs') tabs: TabsComponent;
  homePasswords$ = this.store$.pipe(
    select(wifiNetwork),
    map(wifi => wifi?.data?.keys || []),
    map(keys => keys.filter(key => key.accessZone === 'home'))
  );
  guestsPasswords$ = this.store$.pipe(
    select(wifiNetwork),
    map(wifi => wifi?.data?.keys || []),
    map(keys => keys.filter(key => key.accessZone === 'guests'))
  );
  internetOnlyPasswords$ = this.store$.pipe(
    select(wifiNetwork),
    map(wifi => wifi?.data?.keys || []),
    map(keys => keys.filter(key => key.accessZone === 'internetAccessOnly'))
  );
  devices$ = this.store$.pipe(select(selectDevices));
  accessZones$ = this.store$.pipe(
    select(wifiNetwork),
    map(wifi => wifi?.data?.accessZones || [])
  );
  tabsNames: AccessZoneWifiType[] = ['home', 'guests', 'internetAccessOnly'];
  keysInProgress: number[] = [];

  constructor(
    private store$: Store<IPlumeState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private actions$: Actions,
    private dialog: DialogService
  ) {
    store$.dispatch(SecurityPolicyActions.loadWifiNetwork());
    this.startComponentEffects();
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      if (params.tab) {
        const tabName = params.tab as string;
        const index = this.tabsNames.findIndex(loopTabName => loopTabName === tabName);
        this.tabs.selectTabIndex(index);
        this.cdr.detectChanges();
      }
    });
  }

  tabSelected(tabDefinition: { tab: TabComponent; index: number; initial: boolean }): void {
    if (tabDefinition.initial) {
      return;
    }
    void this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.tabsNames[tabDefinition.index] }
    });
  }

  dropDownSelected(
    event: { key: WifiNetworkKeys; type: AccessPasswordDropDownClicked },
    pwdType: 'home' | 'internet-only' | 'guest'
  ): void {
    switch (event.type) {
      case AccessPasswordDropDownClicked.edit:
        this.copyPassword(event, pwdType);
        break;
      case AccessPasswordDropDownClicked.toggleEnable:
        this.toggleEnablePassword(event);
        break;
      case AccessPasswordDropDownClicked.delete:
        this.deletePassword(event, pwdType);
        break;
    }
  }

  editAccessZone(zoneId: number): void {
    void this.router.navigate(['edit-access-zone'], {
      relativeTo: this.activatedRoute,
      queryParams: { id: zoneId }
    });
  }

  private copyPassword(
    event: { key: WifiNetworkKeys; type: AccessPasswordDropDownClicked },
    pwdType: 'home' | 'internet-only' | 'guest'
  ): void {
    this.dialog
      .openDialog$(EditWifiPasswordComponent, event.key.encryptionKey)
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          void this.router.navigate([`edit-${pwdType}-password`], {
            relativeTo: this.activatedRoute,
            queryParams: { id: event.key.id }
          });
        }
      });
  }

  private toggleEnablePassword(event: { key: WifiNetworkKeys; type: AccessPasswordDropDownClicked }): void {
    if (event.key.enable) {
      this.dialog
        .openDialog$(DisableWifiPasswordComponent, event.key.encryptionKey)
        .pipe(take(1))
        .subscribe(res => {
          if (res) {
            this.enablePassword(false, event.key);
          }
        });
    } else {
      this.enablePassword(true, event.key);
    }
  }

  private enablePassword(enable: boolean, key: WifiNetworkKeys): void {
    this.keysInProgress.push(key.id);
    this.store$.dispatch(
      editWifiPassword({
        key: {
          ...key,
          enable: enable,
          expiresAt: undefined
        }
      })
    );
  }

  private deletePassword(
    event: { key: WifiNetworkKeys; type: AccessPasswordDropDownClicked },
    pwdType: 'home' | 'internet-only' | 'guest'
  ): void {
    this.dialog
      .openDialog$(DeleteWifiPasswordComponent, event.key.encryptionKey)
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.keysInProgress.push(event.key.id);
          if (pwdType === 'guest') {
            this.store$.dispatch(
              deleteWifiAccessZone({
                key: event.key
              })
            );
          } else {
            this.store$.dispatch(
              deleteWifiPassword({
                key: event.key
              })
            );
          }
        }
      });
  }

  private startComponentEffects() {
    this.actions$
      .pipe(ofType(editWifiPasswordSuccess, deleteWifiPasswordSuccess), untilDestroyed(this))
      .subscribe(val => {
        this.keysInProgress = this.keysInProgress.filter(keyId => keyId !== val.keyId);
      });
    this.actions$
      .pipe(ofType(editWifiPasswordError, deleteWifiPasswordError, deleteWifiAccessZoneError), untilDestroyed(this))
      .subscribe(val => {
        this.keysInProgress = this.keysInProgress.filter(keyId => keyId !== val.keyId);
      });
  }
}
