<plm-box>
  <h3>{{ 'boxSpeedTest.title' | translate }}</h3>
  <div class="granulation">
    <button class="pill-icon-box" [class.focus]="granularity === 'day'" (click)="changeGranularity('day')">
      {{ 'activeDevices.last24hrs' | translate }}
    </button>
    <button class="pill-icon-box" [class.focus]="granularity === 'week'" (click)="changeGranularity('week')">
      {{ 'activeDevices.last7days' | translate }}
    </button>
    <button class="pill-icon-box" [class.focus]="granularity === 'month'" (click)="changeGranularity('month')">
      {{ 'activeDevices.last30days' | translate }}
    </button>
  </div>

  <plm-tabs>
    <plm-tab (plmResized)="downloadChart.render()">
      <ng-template plmTabLabel>
        <span class="icon" icon="download"></span>
        <span>{{ 'boxSpeedTest.download' | translate }}</span>
      </ng-template>
      <plm-speed-test-graph
        #downloadChart
        [chartData]="facade && facade[granularity]?.chart"
        [testInProgress]="granularity === 'day' && speedTestRunning$ | async"
        [xAxis]="granularity"
        [mode]="'download'"
      ></plm-speed-test-graph>
    </plm-tab>
    <plm-tab (plmResized)="uploadChart.render()">
      <ng-template plmTabLabel>
        <span class="icon" icon="upload"></span>
        <span>{{ 'boxSpeedTest.upload' | translate }}</span>
      </ng-template>
      <plm-speed-test-graph
        #uploadChart
        [chartData]="facade && facade[granularity]?.chart"
        [testInProgress]="granularity === 'day' && speedTestRunning$ | async"
        [xAxis]="granularity"
        [mode]="'upload'"
      ></plm-speed-test-graph>
    </plm-tab>
  </plm-tabs>

  <plm-box-speed-test-progress
    [processFinished$]="speedTestFinished$"
    [offline]="offline$ | async"
    (started)="startSpeedTest.emit()"
  ></plm-box-speed-test-progress>
</plm-box>
