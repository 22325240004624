import { Component, OnInit, Input } from '@angular/core';
import { timer } from 'rxjs';
import { latLng, icon, tileLayer, marker, polyline, Layer, Marker, Polyline, MapOptions } from 'leaflet';

import { GeoLoc, MapSetup } from './map.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CssVariablesService } from 'src/app/lib/services/css-variables/css-variables.service';

@Component({
  selector: 'plm-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})


@UntilDestroy()
export class MapComponent implements OnInit {
  @Input() pos: GeoLoc;
  @Input() mapSetup: MapSetup = {};

  private dashpos = 0;
  private rangeUnits: 'mi'|'km' = 'mi';
  layers: Layer[];
  options: MapOptions;

  constructor(private cssVariablesService: CssVariablesService){}

  ngOnInit(): void {
    if (this.mapSetup.animConnect) {
      this.observableTimer();
    }
    let rangeStr = '';
    if (this.mapSetup.showDistance && this.mapSetup.destination ) {
      const range = this.distance(this.pos, this.mapSetup.destination, this.rangeUnits);
      rangeStr = 'Distance ' + this.formatRange(range) + ' ' + this.rangeUnits;
    }

    this.options = {
      layers: [
        tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
          { maxZoom: 20,
            attribution: rangeStr })
      ],
      zoom: this.mapSetup.zoom || 10,
      zoomControl: false,
      scrollWheelZoom: !this.mapSetup.noZoom,
      center: latLng(this.pos.lat, this.pos.long)
    };
    this.layers = this.getLayers( );
  }

  formatRange(range: number): string {
    let radix = 1;
    if (range < 1) { radix = 2; }
    return range.toFixed(radix).toString();
  }

  getLayers(): Layer[] {
    const layerStack: Layer[] =  [ this.getMarker( this.pos ) ];
    if (this.mapSetup.destination ) {
      layerStack.push(this.getMarker( this.mapSetup.destination ));
    }
    if (this.mapSetup.showConnect ) {
      layerStack.push(this.getConnect(  this.pos, this.mapSetup.destination ));
    }
    return layerStack;
  }

  getMarker( pos: GeoLoc ): Marker {
    return marker([ pos.lat, pos.long ], {
      icon: icon({
        iconSize: [ 24, 26],
        iconAnchor: [ 12, 16 ],
        iconUrl: 'assets/pin.png',
        shadowUrl: ''
      })
    });
  }

  getConnect( origin: GeoLoc, destination: GeoLoc): Polyline {
    return  polyline( [[origin.lat, origin.long ], [ destination.lat, destination.long ]], {
      dashArray: '5 5',
      dashOffset: this.dashpos.toString(),
      color: this.cssVariablesService.get('--color--sore'),
      weight: 2
    } );
  }

  observableTimer(): void {
    timer(100, 50).pipe(untilDestroyed(this)).subscribe(val => {
      this.dashpos = val;
      this.layers[2] = this.getConnect( this.pos, this.mapSetup.destination);

    });
  }

  distance( origin: GeoLoc, destination: GeoLoc, unit: string): number {

    if ((origin.lat == destination.lat) && (origin.long == destination.long)) {
      return 0;
    }
    else {
      const radlat1 = Math.PI * origin.lat / 180;
      const radlat2 = Math.PI * destination.lat / 180;
      const theta = origin.long - destination.long;
      const radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'km') { dist = dist * 1.609344; }
      if (unit === 'nm') { dist = dist * 0.8684; }
      return dist;
    }
  }
}
