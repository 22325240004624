import { Configuration, Customer, CustomerLocation } from '../../rest-types';

export interface IPlume {
  customer: Customer;
  currentLocationId: string;
  locations: CustomerLocation[];
  authToken: { token: string; expireAt: string };
  cloudUrl: string;
  cloudId: string;
  configuration: Configuration;
  showChat: boolean;
}

export const initialPlumeState: IPlume = {
  customer: null,
  currentLocationId: '',
  locations: [],
  authToken: { token: '', expireAt: '' },
  cloudUrl: '',
  cloudId: '',
  configuration: null,
  showChat: false
};
