<plm-box>
  <button class="back" [routerLink]="['..']" [disabled]="saving" [queryParams]="{ tab: type }">
    <div icon="left-arrow-small" class="icon"></div>
    <h3 *ngIf="editMode === 'NEW'">{{ 'settings.access.editPassword.' + type + '.titleNewPassword' | translate }}</h3>
    <h3 *ngIf="editMode === 'PASSWORD'">
      {{ 'settings.access.editPassword.' + type + '.titleEditPassword' | translate }}
    </h3>
    <h3 *ngIf="editMode === 'ACCESS_ZONE'">
      {{ 'settings.access.editPassword.' + type + '.titleEditAccessZone' | translate }}
    </h3>
  </button>
  <div class="inputRow" *ngIf="guestName.visible">
    <div class="icon" icon="user-smaller"></div>
    <div class="title">{{ 'settings.access.editPassword.enterNewGuestName' | translate }}</div>
    <input [(ngModel)]="guestName.value" maxlength="63" />
  </div>
  <div class="separator" *ngIf="guestName.visible && password.visible"></div>
  <div class="inputRow" *ngIf="password.visible">
    <div class="icon" icon="key"></div>
    <div class="title" *ngIf="editMode === 'NEW'">
      {{ 'settings.access.editPassword.enterNewPassword' | translate }}
    </div>
    <div class="title" *ngIf="editMode !== 'NEW'">
      {{ 'settings.access.editPassword.enterWifiPassword' | translate }}
    </div>
    <input [(ngModel)]="password.value" maxlength="63" />
  </div>
  <div class="tip" *ngIf="password.visible">
    <span class="highlighted">{{ 'settings.access.editPassword.tip' | translate }}</span>
    {{ 'settings.access.editPassword.tipText' | translate }}
  </div>
  <div class="separator" *ngIf="expiresAt.visible"></div>
  <div class="expires" *ngIf="expiresAt.visible">
    <div class="inputRow">
      <div class="icon" icon="expire-time"></div>
      <div class="title">{{ 'settings.access.editPassword.autoExpirePassword' | translate }}</div>
      <plm-dropdown-menu #expirationSelect position="top">
        <plm-list dropdown>
          <li class="drop-down-item" *ngFor="let value of expiresAt.values" (click)="selectExpiredValue(value)">
            <div>{{ 'settings.access.editPassword.expireValues.' + value | translate }}</div>
          </li>
        </plm-list>
        <button class="select" [class.opened]="expirationSelect.menuOpened">
          <div>{{ 'settings.access.editPassword.expireValues.' + expiresAt.selectedValue | translate }}</div>
          <div icon="arrow" class="arrow"></div>
        </button>
      </plm-dropdown-menu>
    </div>
    <plm-date-time
      *ngIf="expiresAt.selectedValue === 'custom'"
      [(date)]="expiresAt.customDate"
      (valid)="expiresAt.customDateValid = $event"
    ></plm-date-time>
    <div
      class="calendarPlaceHolder"
      [class.medium]="expiresAt.selectedValue === 'custom'"
      [class.small]="sharedHomeDevices.visible"
    ></div>
  </div>
  <div class="devices" *ngIf="sharedHomeDevices.visible">
    <div class="separator"></div>
    <div class="tapRow" (click)="sharedHomeDevices.opened = !sharedHomeDevices.opened">
      <div class="icon" icon="house2"></div>
      <div class="title">
        <div>{{ 'settings.access.editPassword.sharedHomeDevices' | translate }}</div>
        <div class="description">{{ 'settings.access.editPassword.sharedHomeDevicesDescription' | translate }}</div>
      </div>
    </div>
    <plm-device-list-chooser
      *ngIf="sharedHomeDevices.opened"
      [devices]="homeDevices$ | async"
      [people]="people$ | async"
      [(selectedMacs)]="sharedHomeDevices.selectedMacs"
    ></plm-device-list-chooser>
  </div>
  <plm-alert-pop-bar [alert$]="alert$"></plm-alert-pop-bar>
  <div class="bottom-buttons">
    <button class="flat" [routerLink]="['..']" [disabled]="saving" [queryParams]="{ tab: type }">
      {{ 'buttons.cancel' | translate }}
    </button>
    <button class="flat highlight" (click)="save()" [disabled]="saving">{{ 'buttons.save' | translate }}</button>
  </div>
</plm-box>
