import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DhcpReservation, PortForwards } from '../store/models/dhcp-reservation.model';
import { Dhcp } from '../store/models/dhcp.model';
import { DnsServers } from '../store/models/dns.model';
import { Upnp } from '../store/models/upnp.model';
import { SharedUrlsService } from './shared-urls';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  constructor(private httpClient: HttpClient, private sharedUrls: SharedUrlsService) {}

  switchNetworkMode$(mode: 'router' | 'auto'): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(switchMap((locationUrl) => this.httpClient.put(`${locationUrl}/networkMode`, { networkMode: mode })));
  }

  getUpnp$(): Observable<Upnp> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(switchMap((locationUrl) => this.httpClient.get<Upnp>(`${locationUrl}/networkConfiguration/upnp`)));
  }

  setUpnp$(mode: string): Observable<Upnp> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap((locationUrl) =>
          this.httpClient.put<Upnp>(`${locationUrl}/networkConfiguration/upnp`, { mode: mode })
        )
      );
  }

  getDhcpReservations$(): Observable<DhcpReservation[]> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap((locationUrl) =>
          this.httpClient.get<DhcpReservation[]>(`${locationUrl}/networkConfiguration/dhcpReservations`)
        )
      );
  }

  editIpReservation$(data: {
    ip: string;
    mac: string;
    hostName?: string;
  }): Observable<{ ip: string; mac: string; name: string; hostname: string }> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap((locationUrl) =>
        this.httpClient.put<{ ip: string; mac: string; name: string; hostname: string }>(
          `${locationUrl}/networkConfiguration/dhcpReservations/${data.mac}`,
          data,
          {
            headers: { 'errors-white-list': ['400', '422'] }
          }
        )
      )
    );
  }

  deleteIpReservation$(mac: string): Observable<unknown> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap((locationUrl) =>
          this.httpClient.delete<unknown>(`${locationUrl}/networkConfiguration/dhcpReservations/${mac}`)
        )
      );
  }

  getDns$(): Observable<DnsServers> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap((locationUrl) => this.httpClient.get<DnsServers>(`${locationUrl}/networkConfiguration/dnsServers`))
      );
  }

  setDns$(dns: DnsServers): Observable<DnsServers> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap((locationUrl) =>
          this.httpClient.put<DnsServers>(`${locationUrl}/networkConfiguration/dnsServers`, { ...dns })
        )
      );
  }

  addPortForwarding$(mac: string, port: PortForwards): Observable<PortForwards> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap((locationUrl) =>
        this.httpClient.post<PortForwards>(
          `${locationUrl}/networkConfiguration/dhcpReservations/${mac}/portForward`,
          port,
          {
            headers: { 'errors-white-list': ['422'] }
          }
        )
      )
    );
  }

  editPortForwarding$(mac: string, port: PortForwards): Observable<PortForwards> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap((locationUrl) =>
          this.httpClient.put<PortForwards>(
            `${locationUrl}/networkConfiguration/dhcpReservations/${mac}/portForward/${port.externalPort}`,
            port
          )
        )
      );
  }

  deletePortForwarding$(mac: string, portNumber: number): Observable<PortForwards[]> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap((locationUrl) =>
          this.httpClient.delete<PortForwards[]>(
            `${locationUrl}/networkConfiguration/dhcpReservations/${mac}/portForward/${portNumber}`
          )
        )
      );
  }

  getDhcp$(): Observable<Dhcp> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(switchMap((locationUrl) => this.httpClient.get<Dhcp>(`${locationUrl}/networkConfiguration/dhcp`)));
  }

  setDhcp$(dhcp: Dhcp): Observable<Dhcp> {
    return this.sharedUrls
      .locationUrl$()
      .pipe(
        switchMap((locationUrl) => this.httpClient.put<Dhcp>(`${locationUrl}/networkConfiguration/dhcp`, { ...dhcp }))
      );
  }
}
