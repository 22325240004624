export enum AlertType {
  notification = 'notification',
  loading = 'loading',
  success = 'success',
  fail = 'fail'
}

export interface AlertMsg {
  type: AlertType;
  message: string;
  needsTranslation?: boolean;
}
