import { Component, Inject } from '@angular/core';
import { PlmDialogInject, PlmDialogToken } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  selector: 'plm-edit-wifi-password',
  templateUrl: './edit-wifi-password.component.html',
  styleUrls: ['./edit-wifi-password.component.scss']
})
export class EditWifiPasswordComponent {
  constructor(@Inject(PlmDialogToken) public dialog: PlmDialogInject<string, boolean>) {}

  get transPth(): string {
    return 'settings.access.dialogs.editWifiPassword.';
  }
}
