import { createSelector } from '@ngrx/store';
import { IPlumeState } from '..';
import { AppFacade, SecurityPolicyEvents, WifiNetwork } from '../../rest-types';
import { AiSecurityHourlyEventCount } from '../../services/ai-security/ai-security.service';
import { HttpState } from '../interfaces/http-state.interface';
import { PolicyOngoingChanges } from '../interfaces/insights.interface';

export const appFacade = (state: IPlumeState): AppFacade => state.insightsState.appFacade;
export const securityEvents = (state: IPlumeState): HttpState<SecurityPolicyEvents[]> =>
  state.insightsState.securityEvents;
export const securityEventsLimit = (state: IPlumeState): number => state.insightsState.securityEventsLimit;
export const securityDailyCounts = (state: IPlumeState): HttpState<AiSecurityHourlyEventCount[]> =>
  state.insightsState.securityDailyCounts;
export const securityDailyCountsMac = (state: IPlumeState): string => state.insightsState.queryMacAddress;
export const securityDailyCountsDataMac = (state: IPlumeState): string => state.insightsState.dataMacAddress;
export const macQueryDataSame = createSelector(
  securityDailyCountsMac,
  securityDailyCountsDataMac,
  (mac1, mac2) => mac1 === mac2
);
export const policyOngoingChanges = (
  state: IPlumeState
): {
  [key: string]: PolicyOngoingChanges;
} => state.insightsState.policyOngoingChanges;
export const speedTestRunning = (state: IPlumeState): boolean => state.insightsState.speedTestRunning;
export const wifiNetwork = (state: IPlumeState): HttpState<WifiNetwork> => state.insightsState.wifiNetwork;
