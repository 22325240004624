import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'plm-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss']
})
export class DateTimeInputComponent {
  @Input() public set date(value: moment.Moment) {
    let time = value?.format('LT');
    if (time.split(':')[0].length === 1) {
      time = '0' + time;
    }
    this.model = value?.format('L') + ', ' + time;
  }
  @Output() dateChange = new EventEmitter<moment.Moment>();
  @Output() valid = new EventEmitter<boolean>();
  mask = '';
  localeMask = '';
  localeMaskNoSymbols = '';
  model = '';

  constructor() {
    this.setMask();
  }

  modelChanged(value: string): void {
    if (value.length !== this.localeMaskNoSymbols.length) {
      this.valid.emit(false);
      return;
    }

    try {
      const dateTime = moment(this.getIsoDate(value));
      if (dateTime.isValid()) {
        this.dateChange.emit(dateTime);
        this.valid.emit(true);
      } else {
        this.valid.emit(false);
      }
    } catch (error) {
      this.valid.emit(false);
    }
  }

  private getIsoDate(value: string): string {
    const year = this.getValueForSymbol(value, 'Y');
    const month = this.getValueForSymbol(value, 'M');
    const date = this.getValueForSymbol(value, 'D');
    const hours =
      this.getValueForSymbol(value, 'H') ||
      this.amPmHoursToIso(this.getValueForSymbol(value, 'A').toLowerCase(), this.getValueForSymbol(value, 'h'));
    const minutes = this.getValueForSymbol(value, 'm');
    return `${year}-${month}-${date}T${hours}:${minutes}`;
  }

  private amPmHoursToIso(amPm: string, hours: string): string {
    if (amPm !== 'am' && amPm !== 'pm') {
      throw new Error('invalid AM/PM string' + amPm);
    }
    if ((hours !== '12' && amPm === 'am') || (hours === '12' && amPm === 'pm')) {
      return hours;
    } else {
      return `0${(+hours + 12) % 24}`.slice(-2);
    }
  }

  private getValueForSymbol(value: string, symbol: string): string {
    return this.getIndexes(symbol)
      .map(index => value.charAt(index))
      .join('');
  }

  private getIndexes(symbol: string): number[] {
    const indexes = [this.localeMaskNoSymbols.indexOf(symbol, 0)];
    while (indexes[indexes.length - 1] > -1) {
      indexes.push(this.localeMaskNoSymbols.indexOf(symbol, indexes[indexes.length - 1] + 1));
    }
    indexes.pop(); // delete -1
    return indexes;
  }

  private setMask(): void {
    this.localeMask =
      moment()
        .localeData()
        .longDateFormat('L') +
      ', ' +
      moment()
        .localeData()
        .longDateFormat('LT');
    this.mask = this.localeMask
      .replace(/[DMYms]/g, '0')
      .replace(/[Hh]/g, '00')
      .replace(/A/g, 'SS');
    this.localeMaskNoSymbols = this.localeMask
      .replace(/[/ :,\\.]/g, '')
      .replace(/[A]/g, 'AA')
      .replace(/[H]/g, 'HH')
      .replace(/[h]/g, 'hh');
  }
}
