import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../../../app/lib/directives/icon/icon.module';
import { AccordionModule } from '../ui/accordion/accordion.module';
import { BoxModule } from '../ui/box/box.component.module';
import { CarouselModule } from '../ui/carousel/carousel.module';
import { ListModule } from '../ui/list/list.module';
import { SpaceModule } from '../ui/space/space.module';
import { TroubleshootingCarouselComponent } from './troubleshooting-carousel.component';
import { AssistanceComponent } from './troubleshooting/assistance/assistance.component';
import { CentralizePodsComponent } from './troubleshooting/centralize-pods/centralize-pods.component';
import { DeviceHealthComponent } from './troubleshooting/device-health/device-health.component';
import { MetalsComponent } from './troubleshooting/metals/metals.component';
import { ObstructionsComponent } from './troubleshooting/obstructions/obstructions.component';
import { OfflineComponent } from './troubleshooting/offline/offline.component';
import { PlugsComponent } from './troubleshooting/plugs/plugs.component';

@NgModule({
  imports: [
    CommonModule,
    AccordionModule,
    ListModule,
    IconModule,
    TranslateModule,
    CarouselModule,
    BoxModule,
    SpaceModule
  ],
  exports: [TroubleshootingCarouselComponent],
  declarations: [
    TroubleshootingCarouselComponent,
    AssistanceComponent,
    CentralizePodsComponent,
    ObstructionsComponent,
    MetalsComponent,
    PlugsComponent,
    OfflineComponent,
    DeviceHealthComponent
  ],
  providers: []
})
export class TroubleshootingCarouselModule {}
