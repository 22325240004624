import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IPlumeState } from 'src/app/lib/store';
import { currentLocation, customerFirstName } from 'src/app/lib/store/selectors/plume.selectors';
import { OnInit } from '@angular/core';
import { Device } from 'src/app/lib/store/models/device.model';
import {
  loadFacade,
  loadSecurityByMac,
  loadSecurityDailyCounts,
  reloadSecurityCounts,
  speedTestFinished,
  speedTestStart
} from 'src/app/lib/store/actions/insights.actions';
import { appFacade, securityDailyCounts, speedTestRunning } from 'src/app/lib/store/selectors/insights.selectors';
import {
  selectDevices,
  nodes,
  summary,
  optimization,
  homeLoading,
  offline
} from 'src/app/lib/store/selectors/home.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';

@UntilDestroy()
@Component({
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {
  appFacade$ = this.store$.pipe(select(appFacade));
  firstName$ = this.store$.pipe(select(customerFirstName));
  aiSecurityDailyCounts$ = this.store$.pipe(select(securityDailyCounts));
  devices$ = this.store$.select(selectDevices);
  nodes$ = this.store$.pipe(select(nodes));
  summary$ = this.store$.pipe(select(summary));
  optimization$ = this.store$.pipe(select(optimization));
  homeLoading$ = this.store$.pipe(select(homeLoading));
  offline$ = this.store$.pipe(select(offline));
  speedTestRunning$ = this.store$.pipe(select(speedTestRunning));
  currentLocation$ = this.store$.pipe(select(currentLocation));

  constructor(private store$: Store<IPlumeState>, private actions$: Actions) {
    this.startComponentEffects();
  }

  ngOnInit(): void {
    this.store$.dispatch(reloadSecurityCounts());
    this.store$.dispatch(loadFacade());
  }

  onDeviceSelected(device: Device): void {
    if (device) {
      this.store$.dispatch(loadSecurityByMac({ macAddress: device.mac }));
    } else {
      this.store$.dispatch(loadSecurityDailyCounts());
    }
  }

  reloadSecurity(): void {
    this.store$.dispatch(reloadSecurityCounts());
  }

  startSpeedTest(): void {
    this.store$.dispatch(speedTestStart());
  }

  private startComponentEffects() {
    this.actions$.pipe(ofType(speedTestFinished), untilDestroyed(this)).subscribe(() => {
      this.store$.dispatch(loadFacade());
    });
  }
}
