<div class="carousel-content">
  <div class="item flex-center">
    <div class="title">
      {{ 'troubleshooting.metal.title' | translate }}
    </div>
    <div class="description">
      <p>
        {{ 'troubleshooting.metal.description' | translate }}
      </p>
    </div>
  </div>
  <div class="item image"><img src="/assets/images/gfx-avoid-metals.svg" width="240" /></div>
</div>
