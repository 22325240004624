<plm-dropdown-menu
  #dropdownMenu
  [position]="position"
  [closeOnClick]="false"
  width="306px"
  (opened)="menuOpened($event)"
>
  <div dropdown>
    <div class="searchRow">
      <div class="icon search" icon="search"></div>
      <input
        #searchInput
        [placeholder]="'deviceDropDown.selectDevicePlaceholder' | translate"
        [(ngModel)]="searchText"
        (ngModelChange)="filterDevices($event)"
      />
    </div>
    <perfect-scrollbar>
      <plm-list>
        <li class="deviceRow" *ngFor="let device of devices$ | async | sort: 'name'" (click)="selectDevice(device)">
          <div class="icon person medium" icon=""></div>
          <div class="deviceIcon" [icon]="device.iconV2" [device]="true" height="40" width="80"></div>
          <div class="ellipsis-overflow" plmOverflowToolTip>{{ device.nickname || device.name || device.mac }}</div>
        </li>
      </plm-list>
    </perfect-scrollbar>
  </div>

  <button class="pill-icon-box down" [class.opened]="dropdownMenu.menuOpened" [class.focus]="dropdownMenu.menuOpened">
    <div class="ellipsis-overflow">
      {{ device?.nickname || device?.name || device?.mac || 'deviceDropDown.selectDevice' | translate }}
    </div>
    <div icon="arrow" class="arrow big"></div>
  </button>
</plm-dropdown-menu>
