<div class="positioner" @fromBottom *ngIf="animate && direction === 'up'" [style.bottom]="offset">
  <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
</div>

<div class="positioner" @fromTop *ngIf="animate && direction === 'down'" [style.top]="offset">
  <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
</div>

<ng-template #contentTemplate>
  <div class="box" [ngClass]="alert.type" (click)="close()">
    <ng-container [ngSwitch]="alert.type">
      <div *ngSwitchCase="AlertType.success"><div class="icon" icon="alert-check"></div></div>
      <div *ngSwitchCase="AlertType.fail"><div class="icon" icon="fail"></div></div>
      <div *ngSwitchCase="AlertType.loading"><div class="icon" icon="loading"></div></div>
      {{ alert.needsTranslation ? (alert.message | translate) : alert.message }}
    </ng-container>
  </div>
</ng-template>
