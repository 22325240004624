import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../../../app/lib/directives/icon/icon.module';
import { AlertPopBarComponent } from './alert-pop-bar.component';

@NgModule({
  imports: [CommonModule, IconModule, TranslateModule],
  exports: [AlertPopBarComponent],
  declarations: [AlertPopBarComponent],
  providers: []
})
export class AlertPopBarModule {}
