import { HttpClient, HttpBackend } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

type RecursiveStringObject = { [key: string]: string | RecursiveStringObject };

export class CustomLoader implements TranslateLoader {
  constructor(private handler: HttpBackend, private url: string, public token: string) {}

  // eslint-disable-next-line rxjs/finnish
  getTranslation(lang: string): Observable<RecursiveStringObject> {
    lang = lang.split('_').join('-');
    const http = new HttpClient(this.handler);
    return http.get<RecursiveStringObject>(`${this.url}${lang}.json?token=${this.token}`).pipe(
      catchError(() => {
        if (lang.includes('-')) {
          lang = lang.split('-')[0];
          return http.get<RecursiveStringObject>(`${this.url}${lang}.json?token=${this.token}`);
        } else {
          throwError(undefined);
        }
      }),
      catchError(() => http.get<RecursiveStringObject>(`${this.url}en.json?token=${this.token}`))
    );
  }
}

export const CustomLoaderFactory = (handler: HttpBackend): TranslateLoader =>
  new CustomLoader(handler, '/assets/i18n/', environment.production ? environment.version : Date.now().toString());
