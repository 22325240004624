import { createReducer, on, Action } from '@ngrx/store';
import { initialPlumeState, IPlume } from '../interfaces/plume.interface';
import {
  customerLoaded,
  getLocationsSuccess,
  hideChat,
  locationNameLoaded,
  loginUser,
  setCurrentLocationId,
  showChat
} from '../actions/plume.actions';
import { ssidLoaded } from '../actions/settings.actions';

export const reducer = createReducer(
  initialPlumeState,
  on(setCurrentLocationId, (state, { currentLocationId }) => ({
    ...state,
    currentLocationId
  })),
  on(customerLoaded, (state, { customer, locations, configuration }) => ({
    ...state,
    locations,
    currentLocationId: '',
    customer,
    configuration
  })),
  on(loginUser, (state, { customerId, token, expireAt, cloudId, cloudUrl }) => ({
    ...state,
    cloudId,
    cloudUrl,
    authToken: { token, expireAt },
    customer: { id: customerId }
  })),
  on(showChat, state => ({
    ...state,
    showChat: true
  })),
  on(hideChat, state => ({
    ...state,
    showChat: false
  })),
  on(locationNameLoaded, (state, { location }) => ({
    ...state,
    locations: state.locations.map(loc => (loc.id === location.id ? location : loc))
  })),
  on(getLocationsSuccess, (state, { locations }) => ({
    ...state,
    locations: locations
  })),
  on(ssidLoaded, (state, { wifiNetwork }) => ({
    ...state,
    locations: state.locations.map(loc =>
      loc.id === wifiNetwork.locationId ? { ...loc, ssid: wifiNetwork.ssid } : loc
    )
  }))
);

export const PlumeReducer = (state: IPlume, action: Action): IPlume => reducer(state, action);
