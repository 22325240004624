import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Device } from 'src/app/lib/store/models/device.model';
import { Person } from 'src/app/lib/store/models/person.model';

@Component({
  selector: 'plm-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent {
  @Input() set offlineDevices(devices: Device[]) {
    this.devicesWithPerson = devices;
    this.mapPersonToDevice();
  }

  @Input() set people(people: Person[]) {
    this.cachedPeople = people;
    this.mapPersonToDevice();
  }

  @Output() zoomOut = new EventEmitter<void>();
  @Output() zoomIn = new EventEmitter<void>();
  @Output() deviceSelected = new EventEmitter<Device>();
  devicesWithPerson: (Device & { person?: Person })[] = [];
  private cachedPeople: Person[] = [];

  selectDevice(device: Device): void {
    this.deviceSelected.emit(device);
  }

  private mapPersonToDevice(): void {
    this.devicesWithPerson = this.devicesWithPerson.map(device => ({
      ...device,
      person: this.cachedPeople.find(person => person.id === device.personId)
    }));
  }
}
