<ng-container *ngIf="chartData.length > 0">
  <svg:line
    class="line vertical"
    x1="0"
    [attr.y1]="this.calculations.columnY(0)"
    [attr.x2]="calculations.width"
    [attr.y2]="this.calculations.columnY(0)"
  />
  <svg:line
    class="line horizontal first"
    [attr.x1]="calculations.columnXStart(0)"
    [attr.y1]="this.calculations.columnY(0)"
    [attr.x2]="calculations.columnXStart(0)"
    [attr.y2]="this.calculations.columnY(0) + 3"
  />
  <svg:line
    class="line horizontal last"
    [attr.x1]="calculations.columnXStart(chartData.length)"
    [attr.y1]="this.calculations.columnY(0)"
    [attr.x2]="calculations.columnXStart(chartData.length)"
    [attr.y2]="this.calculations.columnY(0) + 3"
  />
  <ng-container *ngFor="let startDayIndex of newDayIndexes; let newDayArrPosition = index">
    <svg:line
      class="line horizontal value"
      [attr.x1]="calculations.columnXStart(startDayIndex)"
      [attr.y1]="this.calculations.columnY(0)"
      [attr.x2]="calculations.columnXStart(startDayIndex)"
      [attr.y2]="this.calculations.columnY(0) + 3"
    />
    <svg:text class="text" [attr.x]="textCenter(newDayArrPosition)" [attr.y]="this.calculations.columnY(0) + 13">
      {{ dayText(startDayIndex) }}
    </svg:text>
  </ng-container>
  <svg:text class="text today" [attr.x]="textCenterToday()" [attr.y]="this.calculations.columnY(0) + 13">
    {{ 'time.today' | translate }}
  </svg:text>
</ng-container>
