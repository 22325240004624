import { Component, Inject } from '@angular/core';
import { PlmDialogInject, PlmDialogToken } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  selector: 'plm-network-mode-router-dialog',
  templateUrl: 'network-mode-router-dialog.component.html',
  styleUrls: ['network-mode-router-dialog.component.scss']
})
export class NetworkModeRouterDialogComponent {
  constructor(@Inject(PlmDialogToken) public data: PlmDialogInject) {}
}
