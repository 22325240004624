import { Component, HostBinding, HostListener, Input, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { IPlumeState } from 'src/app/lib/store';
import {
  addPortForwarding,
  addPortForwardingError,
  addPortForwardingSuccess,
  deleteIpReservation,
  deleteIpReservationFailed,
  deleteIpReservationSuccess,
  deletePortForwarding,
  deletePortForwardingError,
  deletePortForwardingSuccess,
  editIpReservation,
  editIpReservationFailed,
  editIpReservationSuccess,
  editPortForwarding,
  editPortForwardingError,
  editPortForwardingSuccess,
  newIpReservation,
  newIpReservationFailed,
  newIpReservationSuccess
} from 'src/app/lib/store/actions/settings.actions';
import { Device } from 'src/app/lib/store/models/device.model';
import { DhcpReservation, PortForwards } from 'src/app/lib/store/models/dhcp-reservation.model';
import { AlertMsg, AlertType } from '../alert-pop-bar/alert-pop.interface';
import { NetworkModeRouterDialogComponent } from '../network-mode-router-dialog/network-mode-router-dialog.component';
import { AccordionComponent } from '../ui/accordion/accordion.component';

@UntilDestroy()
@Component({
  selector: 'plm-ip-reservation-list',
  templateUrl: './ip-reservation-list.component.html',
  styleUrls: ['./ip-reservation-list.component.scss']
})
export class IpReservationListComponent {
  @Input() dhcpReservations: DhcpReservation[];
  @Input() @HostBinding('class.disabled') disabled = false;
  @ViewChild('accordion') accordion: AccordionComponent;
  alert$ = new Subject<AlertMsg>();
  newReservationDeviceSelector = true;
  newReservationManual = false;
  newReservationData: DhcpReservation;
  newReservationSaving = false;
  reservationSavingInProgress: { [key: string]: true } = {}; // {[mac address]: true}
  newPortSaving: { [key: string]: true } = {}; // {[mac address]: true}
  portSaving: { [key: string]: { [key: number]: true } } = {}; // mac - external port - true
  closeNewPort$ = new Subject<string>(); // mac
  @HostListener('click') click(): void {
    if (this.disabled) {
      this.dialog.openDialog$<boolean>(NetworkModeRouterDialogComponent);
    }
  }

  constructor(private dialog: DialogService, private actions$: Actions, private store$: Store<IPlumeState>) {
    this.startComponentEffects();
  }

  deviceSelected(device: Device | null): void {
    this.newReservationDeviceSelector = false;
    this.newReservationManual = !device;
    if (device) {
      this.newReservationData = {
        ip: device.ip,
        mac: device.mac,
        name: device.nickname || device.name || device.mac,
        portForwards: []
      };
    } else {
      this.newReservationData = {
        ip: '',
        mac: '',
        name: '',
        portForwards: []
      };
    }
  }

  cancelReservation(): void {
    this.accordion.closeAll();
  }

  saveReservation(data: DhcpReservation): void {
    this.store$.dispatch(editIpReservation({ ip: data.ip, mac: data.mac }));
    this.reservationSavingInProgress[data.mac] = true;
  }

  cancelNewReservation(): void {
    this.newReservationDeviceSelector = true;
  }

  saveNewReservation(data: DhcpReservation): void {
    this.newReservationSaving = true;
    this.store$.dispatch(newIpReservation({ ip: data.ip, name: data.name, mac: data.mac, hostName: data.hostname }));
  }

  deleteReservation(mac: string): void {
    this.store$.dispatch(deleteIpReservation({ mac }));
    this.reservationSavingInProgress[mac] = true;
  }

  saveNewPort(mac: string, port: PortForwards): void {
    this.store$.dispatch(addPortForwarding({ mac, port: port }));
    this.newPortSaving[mac] = true;
  }

  saveEditPort(mac: string, port: PortForwards): void {
    this.store$.dispatch(editPortForwarding({ mac, port: port }));
    this.portSaving[mac] = this.portSaving[mac] || {};
    this.portSaving[mac][port.externalPort] = true;
  }

  deletePort(mac: string, externalPort: number): void {
    this.store$.dispatch(deletePortForwarding({ mac, externalPort }));
    this.portSaving[mac] = this.portSaving[mac] || {};
    this.portSaving[mac][externalPort] = true;
  }

  closePortForMac$(mac: string): Observable<string> {
    return this.closeNewPort$.pipe(filter((obsVal) => obsVal === mac));
  }

  trackByReservation(index: number, item: DhcpReservation): string {
    return item.mac;
  }

  // eslint-disable-next-line max-lines-per-function
  private startComponentEffects() {
    this.actions$.pipe(ofType(newIpReservationSuccess), untilDestroyed(this)).subscribe((action) => {
      this.newReservationDeviceSelector = true;
      this.newReservationSaving = false;
      setTimeout(() => {
        this.accordion.openItemById(action.mac);
      }, 0);
    });
    this.actions$.pipe(ofType(newIpReservationFailed), untilDestroyed(this)).subscribe((action) => {
      this.alert$.next({ type: AlertType.fail, message: (action.error.error as { message: string }).message });
      this.newReservationSaving = false;
    });
    this.actions$
      .pipe(ofType(editIpReservationFailed, deleteIpReservationFailed), untilDestroyed(this))
      .subscribe((action) => {
        this.alert$.next({
          type: AlertType.fail,
          message: (action.error.error as { error: { message: string } }).error.message
        });
        delete this.reservationSavingInProgress[action.mac];
      });
    this.actions$
      .pipe(ofType(editIpReservationSuccess, deleteIpReservationSuccess), untilDestroyed(this))
      .subscribe((action) => {
        delete this.reservationSavingInProgress[action.mac];
      });
    this.actions$.pipe(ofType(addPortForwardingSuccess), untilDestroyed(this)).subscribe((action) => {
      this.closeNewPort$.next(action.mac);
      delete this.newPortSaving[action.mac];
    });
    this.actions$.pipe(ofType(addPortForwardingError), untilDestroyed(this)).subscribe((action) => {
      delete this.newPortSaving[action.mac];
    });
    this.actions$
      .pipe(
        ofType(deletePortForwardingSuccess, deletePortForwardingError, editPortForwardingError),
        untilDestroyed(this)
      )
      .subscribe((action) => {
        delete this.portSaving[action.mac][action.externalPort];
      });
    this.actions$.pipe(ofType(editPortForwardingSuccess), untilDestroyed(this)).subscribe((action) => {
      delete this.portSaving[action.mac][action.port.externalPort];
    });
  }
}
