<button class="button__default"
    [attr.type]="buttonType" 
    (click)="onClick()" 
    [disabled]="disabled" 
    [ngClass]="{
        'disabled': disabled,
        'active': isActive,
        'button__icon-only': iconOnly,
        'small': size === 'small',
        'medium': size === 'medium',
        'large': size === 'large' }"
    >
    <ng-content></ng-content>
</button>
