import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPlumeState } from '../../../../app/lib/store';
import { Person } from '../../../../app/lib/store/models/person.model';
import { selectPeople } from '../../../../app/lib/store/selectors/home.selectors';

@Component({
  selector: 'plm-people-drop-down',
  templateUrl: './people-drop-down.component.html',
  styleUrls: ['./people-drop-down.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PeopleDropDownComponent {
  @Input() position: ConnectedPosition[] | 'bottomRight' | 'topRight' | 'bottomLeft' | 'topLeft' = 'topLeft';
  @Input() person: Person;
  @Output() personChange = new EventEmitter<Person>();
  people$ = this.store$.select(selectPeople);

  constructor(private store$: Store<IPlumeState>) {}

  selectPerson(person: Person | null): void {
    this.personChange.emit(person);
  }
}
