import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const PlmDialogToken = 'PlmDialogToken';
export interface PlmDialogInject<TI = void, TO = void> {
  data: TI;
  close: (data: TO) => void;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private overlay: Overlay) {}

  openDialog$<TO>(component: ComponentType<unknown>, inputData?: unknown): Observable<TO> {
    const output$ = new Subject<TO>();
    const overlayRef = this.createOverlay();

    const portal = new ComponentPortal(
      component,
      undefined,
      Injector.create({
        providers: [this.createProvider(inputData, overlayRef, output$)]
      })
    );
    overlayRef.attach(portal);
    return output$.asObservable();
  }

  private createProvider<TI, TO>(
    inputData: TI,
    overlayRef: OverlayRef,
    output$: Subject<TO>
  ): { provide: string; useValue: PlmDialogInject<TI, TO> } {
    return {
      provide: PlmDialogToken,
      useValue: {
        data: inputData,
        close: (data: TO) => {
          overlayRef.detach();
          output$.next(data);
          output$.complete();
        }
      } as PlmDialogInject<TI, TO>
    };
  }

  private createOverlay(): OverlayRef {
    return this.overlay.create({
      maxWidth: (594 / 16).toString() + 'rem',
      width: 'calc(100% - 48px)', // 24px margins on low resolution
      hasBackdrop: true,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      panelClass: 'plm-dialog',
      positionStrategy: new GlobalPositionStrategy().centerHorizontally().centerVertically()
    });
  }
}
