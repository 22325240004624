import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { SecurityPolicy, SecurityPolicyContent, SecurityPolicyEventTypes } from 'src/app/lib/rest-types';
import { DialogService } from 'src/app/lib/services/dialog/dialog.service';
import { SecurityPolicyService } from 'src/app/lib/services/security-policy/security-policy.service';
import { IPlumeState } from 'src/app/lib/store';
import { SecurityPolicyActions } from 'src/app/lib/store/actions/security-policy.actions';
import { setWifiNetwork } from 'src/app/lib/store/actions/settings.actions';
import { LoadingState } from 'src/app/lib/store/interfaces/http-state.interface';
import { wifiNetwork } from 'src/app/lib/store/selectors/insights.selectors';
import { DeleteSecEventsComponent } from './delete-sec-events/delete-sec-events.component';
import { EnableOnlineProtectionComponent } from './enable-online-protection/enable-online-protection.component';
import { RuleState, SecurityRuleItem } from './security-settings.interfaces';
import { UpdateLocationComponent } from './update-location/update-location.component';

@Component({
  selector: 'plm-security-settings',
  templateUrl: './security-settings.component.html',
  styleUrls: ['./security-settings.component.scss']
})
export class SecuritySettingsComponent {
  privacyMode = false;
  securityRules: SecurityRuleItem[] = [
    {
      type: 'secureAndProtect',
      icon: 'online-protection',
      state: 'disabled'
    },
    {
      type: 'iotProtect',
      icon: 'ion-protection',
      state: 'disabled'
    },
    {
      type: 'adBlocking',
      icon: 'adblock-protection',
      state: 'disabled',
      chips: ['labs']
    }
  ];

  loading = true;
  saveLoading = false;
  originalContent: SecurityPolicyContent[];

  constructor(
    private dialog: DialogService,
    private policyService: SecurityPolicyService,
    private store$: Store<IPlumeState>
  ) {
    store$.dispatch(SecurityPolicyActions.loadWifiNetwork());
    forkJoin([
      this.policyService.getSecurityPolicy$(),
      store$.pipe(select(wifiNetwork)).pipe(
        filter(wifi => wifi.state === LoadingState.loaded),
        take(1)
      )
    ])
      .pipe(take(1))
      .subscribe(([policy, wifi]) => {
        this.loading = false;
        this.privacyMode = wifi.data.privateMode;
        this.originalContent = policy.content;
        this.parseSecurityPolice(policy as SecurityPolicy);
      });
  }

  ruleMenuSelected(selectedSecurityRule: SecurityRuleItem, state: RuleState): void {
    if (selectedSecurityRule.state === 'custom') {
      this.dialog
        .openDialog$(UpdateLocationComponent)
        .pipe(
          take(1),
          filter(res => !!res)
        )
        .subscribe(() => this.setRuleState(selectedSecurityRule, state));
    } else {
      this.setRuleState(selectedSecurityRule, state);
    }
  }

  save(): void {
    this.saveLoading = true;
    this.store$.dispatch(setWifiNetwork({ wifiNetwork: { privateMode: this.privacyMode } }));
    forkJoin([
      this.store$.pipe(select(wifiNetwork)).pipe(
        filter(wifi => wifi.state === LoadingState.loaded),
        take(1)
      ),
      this.policyService.setSecurityPolicy$(this.createPatchSecurityPolice())
    ])
      .pipe(take(1))
      .subscribe(() => {
        this.saveLoading = false;
      });
  }

  deleteSecurityEvent(): void {
    this.dialog
      .openDialog$(DeleteSecEventsComponent)
      .pipe(
        filter(res => !!res),
        switchMapTo(this.policyService.deleteSecurityEvents$()),
        take(1)
      )
      .subscribe();
  }

  private setRuleState(selectedSecurityRule: SecurityRuleItem, state: RuleState): void {
    if (
      selectedSecurityRule.type === 'iotProtect' &&
      state === 'enabled' &&
      this.securityRules.find(sr => sr.type === 'secureAndProtect').state !== 'enabled'
    ) {
      this.dialog
        .openDialog$(EnableOnlineProtectionComponent)
        .pipe(
          take(1),
          filter(res => !!res)
        )
        .subscribe(() => {
          selectedSecurityRule.state = state;
          this.securityRules.find(securityRule => securityRule.type === 'secureAndProtect').state = 'enabled';
        });
    } else {
      selectedSecurityRule.state = state;
      if (selectedSecurityRule.type === 'secureAndProtect' && state === 'disabled') {
        this.securityRules.find(securityRule => securityRule.type === 'iotProtect').state = 'disabled';
      }
    }
  }

  private state(policy: SecurityPolicy, section: SecurityPolicyEventTypes, enabled: boolean): RuleState {
    return !policy.appliesToAllDevices[section] ? 'custom' : enabled ? 'enabled' : 'disabled';
  }

  private parseSecurityPolice(policy: SecurityPolicy): void {
    this.securityRules.find(securityRule => securityRule.type === 'adBlocking').state = this.state(
      policy,
      SecurityPolicyEventTypes.adBlocking,
      policy.content.includes('adBlocking')
    );
    this.securityRules.find(securityRule => securityRule.type === 'secureAndProtect').state = this.state(
      policy,
      SecurityPolicyEventTypes.secureAndProtect,
      policy.secureAndProtect
    );
    this.securityRules.find(securityRule => securityRule.type === 'iotProtect').state = this.state(
      policy,
      SecurityPolicyEventTypes.iotProtect,
      policy.iotProtect
    );
  }

  private createPatchSecurityPolice(): Partial<SecurityPolicy> {
    const res: Partial<SecurityPolicy> = { appliesToAllDevices: {} };
    if (this.securityRules.find(securityRule => securityRule.type === 'iotProtect').state !== 'custom') {
      res.iotProtect = this.securityRules.find(securityRule => securityRule.type === 'iotProtect').state === 'enabled';
      res.appliesToAllDevices = { ...res.appliesToAllDevices, iotProtect: true };
    }
    if (this.securityRules.find(securityRule => securityRule.type === 'secureAndProtect').state !== 'custom') {
      res.secureAndProtect =
        this.securityRules.find(securityRule => securityRule.type === 'secureAndProtect').state === 'enabled';
      res.appliesToAllDevices = { ...res.appliesToAllDevices, secureAndProtect: true };
    }
    if (this.securityRules.find(securityRule => securityRule.type === 'adBlocking').state !== 'custom') {
      (res.content as string[]) = [...this.originalContent].filter(
        securityPolicyContent => securityPolicyContent !== 'adBlocking'
      );
      res.appliesToAllDevices = { ...res.appliesToAllDevices, adBlocking: true };
      if (this.securityRules.find(securityRule => securityRule.type === 'adBlocking').state === 'enabled') {
        res.content.push('adBlocking');
      }
    }
    return res;
  }
}
