import { Component, Input } from '@angular/core';

@Component({
  selector: 'plm-view-password',
  templateUrl: './view-password.component.html',
  styleUrls: ['./view-password.component.scss']
})
export class ViewPasswordComponent {
  @Input() password = '';
  visible = false;
}
