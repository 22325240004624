import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SecurityPolicyEvents, SecurityPolicyEventTypes, securityTypeConversion } from 'src/app/lib/rest-types';
import { Device } from 'src/app/lib/store/models/device.model';
import { Person } from 'src/app/lib/store/models/person.model';
import { TileTypes } from '../guard-tile/guard-tile.component';

export type SecurityPolicyEventsLoading = SecurityPolicyEvents & { eventData: { loading: boolean } };

@Component({
  selector: 'plm-guard-event-list',
  templateUrl: './guard-event-list.component.html',
  styleUrls: ['./guard-event-list.component.scss']
})
export class GuardEventListComponent implements OnChanges {
  @Input() events: SecurityPolicyEventsLoading[] = [];
  @Input() devices: Device[] = [];
  @Input() set people(people: Person[]) {
    this.peopleHash =
      people?.reduce((acc, person) => {
        acc[person.id] = person;
        return acc;
      }, {} as { [key: string]: Person }) ?? {};
  }
  @Output() approve = new EventEmitter<{
    event: SecurityPolicyEventsLoading & { device: Device };
    type: 'everyone' | 'device' | 'person';
  }>();
  peopleHash: { [key: string]: Person };
  mappedEvents: (SecurityPolicyEventsLoading & { device: Device })[] = [];

  constructor(private translate: TranslateService) {}

  ngOnChanges(): void {
    if (this.devices && this.events) {
      this.mapDevices();
    } else {
      this.mappedEvents = [];
    }
  }

  mapDevices(): void {
    this.mappedEvents = this.events.map(event => ({
      ...event,
      device: this.devices.find(d => d.mac === event.eventData.mac)
    }));
  }

  tileType(type: SecurityPolicyEventTypes): TileTypes {
    return (securityTypeConversion[type] || type) as TileTypes;
  }

  tileTypeIcon(type: SecurityPolicyEventTypes): string {
    const tileType = this.tileType(type);
    switch (tileType) {
      case 'adBlocking':
        return 'adblock-little';
      case 'anomaly':
        return 'advanced-iot-prot-little';
      case 'contentAccess':
        return 'content-access-little';
      case 'onlineProtection':
        return 'online-protection-little';
      default:
        break;
    }
  }

  message(event: SecurityPolicyEventsLoading & { device: Device }): string {
    const trKey = this.messageKey(event);
    const trValue = this.translate.instant(trKey, {
      reason: this.blockedReason(event.eventData.categoryId),
      fqdn: event.eventData.fqdn,
      deviceName: event.device?.nickname || event.device?.name || event.eventData.mac,
      ipaddress: event.eventData.ipaddress,
      city: event.eventData.city,
      country: event.eventData.country
    }) as string;

    return trKey === trValue ? '' : trValue;
  }

  website(event: SecurityPolicyEventsLoading & { device: Device }): string {
    const trKey = 'aisecurity.eventList.websiteText.' + event.eventType;
    const trValue = this.translate.instant(trKey) as string;
    return trKey === trValue ? event.eventData.topLevelDomain : trValue;
  }

  blockedReason(type: number): string {
    const trPrefix = 'aisecurity.eventList.blockedReasonCategory';
    const trKey = `${trPrefix}.${type}`;
    const trValue = this.translate.instant(trKey) as string;
    if (trKey === trValue) {
      return this.translate.instant(`${trPrefix}.default`) as string;
    }
    return trValue;
  }

  showMap(event: SecurityPolicyEventsLoading): boolean {
    return (
      (event.eventType === 'SecureAndProtect.outboundIpBlocked' ||
        event.eventType === 'SecureAndProtect.inboundIpBlocked') &&
      !Number.isNaN(+event.eventData.latitude) &&
      !Number.isNaN(+event.eventData.longitude)
    );
  }

  doApprove(event: SecurityPolicyEventsLoading & { device: Device }, type: 'everyone' | 'device' | 'person'): void {
    this.approve.emit({ event, type });
  }

  private messageKey(event: SecurityPolicyEventsLoading): string {
    if (event.eventType === 'SecureAndProtect.outboundIpBlocked') {
      if (event.eventData.city && event.eventData.country) {
        return 'aisecurity.eventList.messageByType.' + event.eventType + 'CountryCity';
      }
      if (event.eventData.country) {
        return 'aisecurity.eventList.messageByType.' + event.eventType + 'Country';
      }
      if (event.eventData.city) {
        return 'aisecurity.eventList.messageByType.' + event.eventType + 'City';
      }
    }
    return 'aisecurity.eventList.messageByType.' + event.eventType;
  }
}
