<a class="left" [routerLink]="['/', 'insights']">
  <div class="icon" icon="arrow-right"></div>
  <div class="networkText">{{ 'topology.network' | translate }}</div>
</a>
<div class="middle">
  <div class="pill-icon-box search" [plmFocusOnClick]="searchInput">
    <div icon="search"></div>
    <input
      #searchInput
      type="text"
      [placeholder]="'topology.search' | translate"
      [(ngModel)]="searchText"
      (ngModelChange)="searchTextChanges()"
    />
  </div>
  <plm-people-drop-down class="people" [(person)]="person" (personChange)="selectPerson($event)"></plm-people-drop-down>

  <plm-overflow-element-hider resized [refresh$]="deviceCategories$" #deviceCategoryElmHider>
    <plm-pill-with-counter
      class="deviceCategory"
      *ngFor="let deviceCategory of deviceCategories$ | async"
      (click)="selectDeviceCategory(deviceCategory)"
      [text]="categoryTranslation(deviceCategory) | translate"
      [count]="deviceCategory.count"
      [selected]="
        deviceCategory.name === selectedDeviceCategory.name &&
        deviceCategory.allDevices === selectedDeviceCategory.allDevices
      "
    ></plm-pill-with-counter>
    <plm-dropdown-menu hiddenIndicator #deviceCategoryDropdown>
      <button class="pill-icon-box down ellipsis-overflow" [class.opened]="deviceCategoryDropdown.menuOpened">
        <div>{{ 'topology.itemsMore' | translate: { count: deviceCategoryElmHider.hiddenItems } }}</div>
        <div icon="arrow" class="arrow big"></div>
      </button>
      <plm-list dropdown>
        <ng-container *ngIf="deviceCategories$ | async as deviceCategories">
          <li
            class="drop-down-item ellipsis-overflow"
            *ngFor="
              let deviceCategory of deviceCategories
                | slice: deviceCategories.length - deviceCategoryElmHider.hiddenItems
            "
            (click)="selectDeviceCategory(deviceCategory)"
          >
            {{ categoryTranslation(deviceCategory) | translate }} {{ deviceCategory.count }}
          </li>
        </ng-container>
      </plm-list>
    </plm-dropdown-menu>
  </plm-overflow-element-hider>
</div>
<div class="right">
  <div class="text">
    <div class="firstLine">{{ 'topology.advanced' | translate }}</div>
    <div class="secLine">{{ 'topology.advancedDesc' | translate }}</div>
  </div>
  <plm-toggle [(ngModel)]="advanced" (ngModelChange)="advancedChanged()"></plm-toggle>
</div>
