<ng-container *ngIf="!loading">
  <ng-container *ngIf="!dashboard?.mostRecent?.speedLevel">
    <div class="description" *ngIf="devices.length === 0">
      <span>{{ 'insights.speed.noDevices' | translate }}</span>
      <span *ngIf="ssid"
        >, <span class="marked">{{ ssid }}</span> {{ 'insights.speed.noDevicesSSID' | translate }}</span
      >
      <span>.</span>
    </div>
    <div
      class="description"
      *ngIf="devices.length !== 0"
      [innerHtml]="'insights.speed.preparingFirstOptimization' | translate"
    ></div>
  </ng-container>

  <ng-container *ngIf="dashboard?.mostRecent?.speedLevel">
    <div class="description">
      {{ 'insights.at' | translate }}
      <span class="bold">{{ dashboard.mostRecent.download | number: '1.0-0' }} {{ 'insights.mbps' | translate }}</span
      >, <span class="marked">{{ dashboard.isp }}</span> {{ 'insights.description' | translate }}
      <span class="marked link" (click)="openSpeedLegend()">{{
        'insights.speed.name.' + dashboard.mostRecent.speedLevel | translate
      }}</span
      >.
      <span>{{ 'insights.speed.descriptions.' + dashboard.mostRecent.speedLevel | translate }}</span>
    </div>
  </ng-container>
</ng-container>
