import { createReducer, on, Action } from '@ngrx/store';
import {
  loadFacadeDone,
  loadMoreSecurityEvents,
  loadSecurityByMac,
  loadSecurityDailyCounts,
  loadSecurityDailyCountsDone,
  loadSecurityEvents,
  loadSecurityEventsDone,
  speedTestStart,
  speedTestFinished
} from '../actions/insights.actions';
import { getPersonById, getPersonByIdSuccess, getPersonByIdFail } from '../actions/person.actions';
import { SecurityPolicyActions } from '../actions/security-policy.actions';
import { LoadingState } from '../interfaces/http-state.interface';
import {
  IInsights,
  initialInsightsState,
  PolicyOngoingChanges,
  securityEventPageSize
} from '../interfaces/insights.interface';

import {
  setWifiNetwork,
  setWifiNetworkLoaded,
  addNewWifiPasswordSuccess,
  editWifiPasswordSuccess,
  editWifiAccessZoneSuccess,
  deleteWifiPasswordSuccess,
  deleteWifiAccessZoneSuccess
} from '../actions/settings.actions';

export const reducer = createReducer(
  initialInsightsState,
  on(loadSecurityDailyCountsDone, (state, { data, macAddress }) => ({
    ...state,
    dataMacAddress: macAddress,
    securityDailyCounts: {
      data: 'error' in data ? state.securityDailyCounts.data : data,
      state: 'error' in data ? data : LoadingState.loaded
    }
  })),
  on(loadSecurityDailyCounts, state => ({
    ...state,
    queryMacAddress: null,
    securityDailyCounts: { ...state.securityDailyCounts, state: LoadingState.loading }
  })),
  on(loadSecurityByMac, (state, { macAddress }) => ({
    ...state,
    queryMacAddress: macAddress,
    securityDailyCounts: { ...state.securityDailyCounts, state: LoadingState.loading }
  })),
  on(loadFacadeDone, (state, { data }) => ({
    ...state,
    appFacade: data
  })),
  on(getPersonById, state => ({
    ...state,
    selectedPersonLoad: true
  })),
  on(getPersonByIdSuccess, (state, { person }) => ({
    ...state,
    selectedPerson: person,
    selectedPersonLoad: false
  })),
  on(getPersonByIdFail, state => ({
    ...state,
    selectedPersonLoad: false
  })),
  on(SecurityPolicyActions.getSecurityPolicyByLocation, state => ({
    ...state,
    securityPolicyLoad: true
  })),
  on(SecurityPolicyActions.getSecurityPolicyByLocationSuccess, (state, { securityPolicy }) => ({
    ...state,
    securityPolicy: securityPolicy,
    securityPolicyLoad: false
  })),
  on(SecurityPolicyActions.getSecurityPolicyByLocationFail, state => ({
    ...state,
    securityPolicyLoad: false
  })),
  on(loadSecurityEventsDone, (state, { data }) => ({
    ...state,
    securityEvents: {
      data: 'error' in data ? state.securityEvents.data : data,
      state: 'error' in data ? data : LoadingState.loaded
    }
  })),
  on(loadSecurityEvents, state => ({
    ...state,
    securityEventsLimit: securityEventPageSize,
    securityEvents: { data: null, state: LoadingState.loading }
  })),
  on(loadMoreSecurityEvents, state => ({
    ...state,
    securityEventsLimit: state.securityEventsLimit + securityEventPageSize,
    securityEvents: { ...state.securityEvents, state: LoadingState.loading }
  })),
  on(
    SecurityPolicyActions.addSecurityPolicyWhiteList,
    SecurityPolicyActions.addSecurityPolicyBlackList,
    SecurityPolicyActions.removeSecurityPolicyWhiteList,
    SecurityPolicyActions.removeSecurityPolicyBlackList,
    (state, { url }) => ({
      ...state,
      policyOngoingChanges: {
        ...state.policyOngoingChanges,
        [url]: PolicyOngoingChanges.processing
      }
    })
  ),

  on(
    SecurityPolicyActions.addSecurityPolicyWhiteListSuccess,
    SecurityPolicyActions.addSecurityPolicyBlackListSuccess,
    SecurityPolicyActions.removeSecurityPolicyWhiteListSuccess,
    SecurityPolicyActions.removeSecurityPolicyBlackListSuccess,
    (state, { url }) => ({
      ...state,
      policyOngoingChanges: {
        ...state.policyOngoingChanges,
        [url]: PolicyOngoingChanges.waitForReload
      }
    })
  ),
  on(
    SecurityPolicyActions.addSecurityPolicyWhiteListFail,
    SecurityPolicyActions.addSecurityPolicyBlackListFail,
    SecurityPolicyActions.removeSecurityPolicyWhiteListFail,
    SecurityPolicyActions.removeSecurityPolicyBlackListFail,
    SecurityPolicyActions.toggleSecurityPolicyFail,
    (state, { url }) => {
      const changes = { ...state.policyOngoingChanges };
      delete changes[url];
      return {
        ...state,
        policyOngoingChanges: changes
      };
    }
  ),
  on(SecurityPolicyActions.garbageCollectPolicyChangeList, state => {
    const obj = state.policyOngoingChanges;
    const allowedKeys = Object.keys(obj).filter(k => obj[k] !== PolicyOngoingChanges.waitForReload);
    const filtered = {};
    allowedKeys.forEach(key => (filtered[key] = obj[key]));
    return {
      ...state,
      policyOngoingChanges: filtered
    };
  }),
  on(speedTestStart, state => ({
    ...state,
    speedTestRunning: true
  })),
  on(speedTestFinished, state => ({
    ...state,
    speedTestRunning: false
  })),
  on(SecurityPolicyActions.loadWifiNetwork, state => ({
    ...state,
    wifiNetwork: { data: state.wifiNetwork.data, state: LoadingState.loading }
  })),
  on(SecurityPolicyActions.loadWifiNetworkDone, (state, { data }) => ({
    ...state,
    wifiNetwork: {
      data: 'error' in data ? state.wifiNetwork.data : data,
      state: 'error' in data ? data : LoadingState.loaded
    }
  })),
  on(setWifiNetworkLoaded, (state, { wifiNetwork }) => ({
    ...state,
    wifiNetwork: {
      data: wifiNetwork,
      state: LoadingState.loaded
    }
  })),
  on(setWifiNetwork, state => ({
    ...state,
    wifiNetwork: {
      data: state.wifiNetwork.data,
      state: LoadingState.loading
    }
  })),
  on(addNewWifiPasswordSuccess, (state, { keys }) => ({
    ...state,
    wifiNetwork: {
      data: { ...state.wifiNetwork.data, keys },
      state: state.wifiNetwork.state
    }
  })),
  on(editWifiPasswordSuccess, deleteWifiPasswordSuccess, (state, { keys }) => ({
    ...state,
    wifiNetwork: {
      data: { ...state.wifiNetwork.data, keys },
      state: state.wifiNetwork.state
    }
  })),
  on(editWifiAccessZoneSuccess, (state, { accessZone }) => ({
    ...state,
    wifiNetwork: {
      data: {
        ...state.wifiNetwork.data,
        accessZones: state.wifiNetwork.data.accessZones.map(zone => (zone.id === accessZone.id ? accessZone : zone))
      },
      state: state.wifiNetwork.state
    }
  })),
  on(deleteWifiAccessZoneSuccess, (state, { accessZones }) => ({
    ...state,
    wifiNetwork: {
      data: {
        ...state.wifiNetwork.data,
        accessZones
      },
      state: state.wifiNetwork.state
    }
  }))
);

export const InsightReducer = (state: IInsights, action: Action): IInsights => reducer(state, action);
