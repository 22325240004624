<div class="carousel-content">
  <div class="item flex-center">
    <div class="title">
      {{ 'troubleshooting.plug.title' | translate }}
    </div>
    <div class="description">
      <p>{{ 'troubleshooting.plug.description' | translate }}</p>
    </div>
  </div>
  <div class="item image"><img src="/assets/images/gfx-plug-lower.svg" width="152" /></div>
</div>
