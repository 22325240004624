import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPlumeState } from 'src/app/lib/store';
import { customer as selectCustomer } from 'src/app/lib/store/selectors/plume.selectors';

@Injectable({
  providedIn: 'root'
})
export class PartnerValidatorService implements CanActivate {
  readonly permittedPartnerIds = [
    '5d2e22b126ad033b05956af4',
    '5c916631e811936365036331',
    '5b1ecb5983fca72c41d55733',
    '5d8e3b032906fe05f464eb0a',
    '5cfa96ac4268dc1dcade42f5',
    '596d43baf95c211513aab7a9',
    '5bad6a805169ff0a3a5e6c49',
    '58126694fbd0c68312d32f77',
    '5f9a29c73be66f6387fa51b9',
    'mobile_qa_test',
    'mobile_qa_test_2'
  ];
  constructor(private router: Router, private store$: Store<IPlumeState>) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store$.select(selectCustomer).pipe(
      map(customer => customer.partnerId),
      map(partnerId => {
        if (this.permittedPartnerIds.includes(partnerId)) {
          return true;
        }
        return this.router.createUrlTree(['/invalid-partner']);
      })
    );
  }
}
