<plm-box>
  <h3>{{ 'settings.access.title' | translate }}</h3>

  <plm-tabs #tabs (tabSelected)="tabSelected($event)">
    <plm-tab>
      <ng-template plmTabLabel>
        <span>{{ 'settings.access.home' | translate }}</span>
      </ng-template>
      <plm-access-password-list
        [passwords]="homePasswords$ | async"
        [type]="'home'"
        [devices]="devices$ | async"
        [keysInProgress]="keysInProgress"
        (dropDownSelected)="dropDownSelected($event, 'home')"
      ></plm-access-password-list>
      <plm-box-footer
        [text]="'settings.access.newHomePassword' | translate"
        [itsLink]="false"
        [itsLocalLink]="true"
        [routerLink]="['new-home-password']"
      ></plm-box-footer>
    </plm-tab>
    <plm-tab>
      <ng-template plmTabLabel>
        <span>{{ 'settings.access.guests' | translate }}</span>
      </ng-template>
      <plm-access-guest-password-list
        [passwords]="guestsPasswords$ | async"
        [devices]="devices$ | async"
        [accessZone]="accessZones$ | async"
        [keysInProgress]="keysInProgress"
        (dropDownSelected)="dropDownSelected($event, 'guest')"
        (editAccessZone)="editAccessZone($event)"
      ></plm-access-guest-password-list>
      <plm-box-footer
        [text]="'settings.access.newGuestPassword' | translate"
        [itsLink]="false"
        [itsLocalLink]="true"
        [routerLink]="['new-guest-password']"
      ></plm-box-footer>
    </plm-tab>
    <plm-tab>
      <ng-template plmTabLabel>
        <span>{{ 'settings.access.internetOnly' | translate }}</span>
      </ng-template>
      <plm-access-password-list
        [passwords]="internetOnlyPasswords$ | async"
        [type]="'internetOnly'"
        [devices]="devices$ | async"
        [keysInProgress]="keysInProgress"
        (dropDownSelected)="dropDownSelected($event, 'internet-only')"
      ></plm-access-password-list>
      <plm-box-footer
        [text]="'settings.access.newInternetOnlyPassword' | translate"
        [itsLink]="false"
        [itsLocalLink]="true"
        [routerLink]="['new-internet-only-password']"
      ></plm-box-footer>
    </plm-tab>
  </plm-tabs>
</plm-box>
