<div class="currentLocation" [class.opened]="dropDownOpened">
  <div class="dropDownTitle" (click)="toggleDropDown()" role="button">
    <div>
      {{ (currentLocation$ | async)?.name }}
      <div class="icon medium" icon="arrow" *ngIf="(locations$ | async)?.length > 1"></div>
    </div>
    <div class="date">
      {{ 'accountMenu.accountCreated' | translate }} {{ (currentLocation$ | async)?.createdAt | date }}
    </div>
  </div>
  <div
    *ngIf="!dropDownOpened && (locations$ | async)?.length === 1"
    class="icon medium"
    icon="edit"
    (click)="changeLocationName($event)"
    role="button"
  ></div>
</div>
<div class="dropDown" *ngIf="dropDownOpened">
  <div
    class="dropDownItem"
    [class.selected]="location.id === (currentLocationId$ | async)"
    *ngFor="let location of locations$ | async"
  >
    <div class="description" role="button" (click)="changeLocation(location.id)">
      <div>
        {{ location.name }}
      </div>
      <div class="date">{{ 'accountMenu.accountCreated' | translate }} {{ location.createdAt | date }}</div>
    </div>
    <div
      *ngIf="location.id === (currentLocationId$ | async)"
      class="icon medium"
      icon="edit"
      (click)="changeLocationName($event)"
      role="button"
    ></div>
  </div>
</div>
