import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { IPlumeState } from '../store';
import { customer, customerIdWithLocationId } from '../store/selectors/plume.selectors';

@Injectable({
  providedIn: 'root'
})
export class SharedUrlsService {
  constructor(private store$: Store<IPlumeState>) {}

  locationUrl$(locationId?: string): Observable<string> {
    return this.store$.pipe(
      select(customerIdWithLocationId),
      filter(data => !!data.locationId),
      first(),
      map(data => `/Customers/${data.customerId}/locations/${locationId ?? data.locationId}`)
    );
  }
  customerUrl$(): Observable<string> {
    return this.store$.pipe(
      select(customer),
      first(),
      map(customerInst => `/Customers/${customerInst.id}`)
    );
  }
}
