<ng-container *ngIf="toggle; then correctSetup; else incorrectSetup"></ng-container>

<ng-template #correctSetup>
  <div class="carousel-content">
    <div class="item">
      <div class="title">
        {{ 'troubleshooting.centralizePods.title' | translate }}
      </div>
      <div class="description">
        <p>{{ 'troubleshooting.centralizePods.description' | translate }}</p>
        <span [ngSwitch]="podType">
          <p *ngSwitchCase="'SuperPod'">
            {{ 'troubleshooting.centralizePods.superPod' | translate }}
          </p>
          <p *ngSwitchCase="'Pod'">
            {{ 'troubleshooting.centralizePods.pod' | translate }}
          </p>
          <p *ngSwitchCase="'PowerPod'">
            {{ 'troubleshooting.centralizePods.powerPod' | translate }}
          </p>
          <p *ngSwitchDefault></p>
        </span>
        <p></p>
      </div>
    </div>
    <div class="item image"><img src="/assets/images/Do.svg" width="240" (click)="onToggle()" /></div>
  </div>
</ng-template>

<ng-template #incorrectSetup>
  <div class="carousel-content">
    <div class="item flex-center">
      <div class="title">
        {{ 'troubleshooting.centralizePods.incorrectSetupTitle' | translate }}
      </div>
      <div class="description">
        <p>{{ 'troubleshooting.centralizePods.incorrectSetupDescription' | translate }}</p>
      </div>
    </div>
    <div class="item"><img src="../../../../../assets/images/Dont.svg" width="240" (click)="onToggle()" /></div>
  </div>
</ng-template>
