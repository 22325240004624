import { Component, Inject } from '@angular/core';
import { PlmDialogInject, PlmDialogToken } from 'src/app/lib/services/dialog/dialog.service';

@Component({
  templateUrl: './pod-health-desc.component.html',
  styleUrls: ['./pod-health-desc.component.scss']
})
export class PodHealthDescComponent {
  constructor(@Inject(PlmDialogToken) public dialog: PlmDialogInject<void, boolean>) {}
}
