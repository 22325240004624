import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Person } from '../models/person.model';

export const getPersonById = createAction(
    '[People] get person by id',
    props<{id: string}>()
);

export const getPersonByIdSuccess = createAction(
    '[People] get person by id success',
    props<{ person: Person }>()
);

export const getPersonByIdFail = createAction(
    '[People] get person by id fail',
    props<{ error: HttpErrorResponse }>()
);

export const PersonActions = {
    getPersonById,
    getPersonByIdSuccess,
    getPersonByIdFail
};
