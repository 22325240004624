import { Component, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PlmDialogToken, PlmDialogInject } from 'src/app/lib/services/dialog/dialog.service';
import { IPlumeState } from 'src/app/lib/store';
import { configuration } from 'src/app/lib/store/selectors/plume.selectors';

@Component({
  selector: 'plm-network-restart',
  templateUrl: 'network-restart.component.html',
  styleUrls: ['network-restart.component.scss']
})
export class NetworkRestartComponent {
  config$ = this.store$.pipe(select(configuration));
  constructor(
    @Inject(PlmDialogToken) public data: PlmDialogInject,
    public translate: TranslateService,
    private store$: Store<IPlumeState>
  ) {
    const closeAfter = 300000; // 5 mins
    setTimeout(this.data.close, closeAfter);
  }
}
