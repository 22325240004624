import { Component, Input } from '@angular/core';

@Component({
  selector: 'plm-centralize-pods',
  templateUrl: 'centralize-pods.component.html',
  styleUrls: ['centralize-pods.component.scss']
})
export class CentralizePodsComponent {
  @Input() podType: 'SuperPod' | 'Pod' | 'PowerPod' = 'SuperPod';

  public toggle = true;

  onToggle(): void {
    this.toggle = !this.toggle;
  }
}
