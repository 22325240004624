import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';
import { mapTo, startWith, switchMap } from 'rxjs/operators';
import { AccordionItemComponent } from './accordion-item.component';

@UntilDestroy()
@Component({
  selector: 'plm-accordion',
  templateUrl: 'accordion.component.html',
  styleUrls: ['accordion.component.scss']
})
export class AccordionComponent implements AfterContentInit, OnChanges {
  @ContentChildren(AccordionItemComponent)
  accordionItems: QueryList<AccordionItemComponent>;

  @Input() openAccordionItem: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openAccordionItem) {
      this.openTabByIndex(this.openAccordionItem);
    }
  }

  ngAfterContentInit(): void {
    this.openTabByIndex(this.openAccordionItem);

    this.accordionItems.changes
      .pipe(
        startWith({}),
        switchMap(() =>
          merge(...this.accordionItems.map(accordionItem => accordionItem.toggle.pipe(mapTo(accordionItem))))
        ),
        untilDestroyed(this)
      )
      .subscribe(accordionItem => this.toggleItem(accordionItem));
  }

  openTabByIndex(index: number): void {
    if (index && index <= this.accordionItems.length) {
      const accordionItem = this.accordionItems.toArray()[index - 1];
      this.openItem(accordionItem);
    }
  }

  toggleItem(item: AccordionItemComponent): void {
    if (item.opened) {
      item.opened = false;
    } else {
      this.accordionItems.toArray().forEach(accordionItem => (accordionItem.opened = false));
      item.opened = true;
    }
  }
  openItem(item: AccordionItemComponent): void {
    if (item.opened) {
      return;
    } else {
      this.accordionItems.toArray().forEach(accordionItem => (accordionItem.opened = false));
      item.opened = true;
    }
  }

  openItemById(id: string): void {
    this.accordionItems.toArray().forEach(accordionItem => (accordionItem.opened = accordionItem.id === id));
  }

  closeAll(): void {
    this.accordionItems.toArray().forEach(accordionItem => (accordionItem.opened = false));
  }
}
