<div class="header" *ngIf="config$ | async as config">
  <div class="icon medium hamburger" icon="hamburger" (click)="doToggleMenu()"></div>
  <div class="home">
    <div class="title">
      HomePass<span class="reg">&reg;</span
      ><span class="pill" *ngIf="'header.beta' | translate">{{ 'header.beta' | translate }}</span>
    </div>
    <svg class="logo">
      <use href="/assets/logo.svg#Layer_1" />
    </svg>
  </div>
  <div class="user" [class.open]="showMenu" (click)="showMenu = !showMenu">
    <div class="icon" icon="user"></div>
    <div class="icon arrow" icon="arrow"></div>
    <ul class="menu">
      <li class="item" (click)="navigate('plumeShop', config)">{{ 'header.pods' | translate }}</li>
      <li class="item" (click)="navigate('plumeMembership', config)">
        {{ 'header.membership' | translate }}
        <plm-space size="base"></plm-space>
        <span class="text">{{ 'header.membershipText' | translate }}</span>
      </li>
      <li class="item" (click)="navigate('myAccount', config)">{{ 'header.myAccount' | translate }}</li>
      <li class="item" (click)="logout()">{{ 'header.logout' | translate }}</li>
    </ul>
  </div>
</div>
