import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormInputBaseField, FormInputType } from './form-input-field.interface';

@UntilDestroy()
@Component({
  selector: 'plm-form-input',
  templateUrl: 'form-input.component.html',
  styleUrls: ['form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInputComponent implements OnInit {
  @Input()
  public field: FormInputBaseField;

  @Input()
  public id: string = 'id_' + performance.now().toString();

  @Output()
  public changed = new EventEmitter<string>();

  @HostBinding('class.hasValue')
  public get hasValue(): boolean {
    if (this.field) {
      return !!this.field.control.value;
    }
    return false;
  }

  public inputType = FormInputType;
  public showPassword = false;

  ngOnInit(): void {
    this.field.control.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.changed.emit(value);
    });
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
