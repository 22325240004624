import { PlumeHome } from '../../rest-types';

export interface IHome extends PlumeHome {
  loading: boolean;
  deletingNodesId: string[];
}

export const initialHomeState: IHome = {
  customer: null,
  optimization: null,
  devices: [],
  nodes: [],
  summary: null,
  persons: [],
  loading: true,
  forceGraph: null,
  deletingNodesId: []
};
