import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import appboy from '@braze/web-sdk';
import { Subject } from 'rxjs';
import { AlertMsg, AlertType } from '../../components/alert-pop-bar/alert-pop.interface';

@Injectable({
  providedIn: 'root'
})
export class BrazeService {
  latestCards: (appboy.Card & { url: string; instruction?: string })[];
  initialized = false;
  alertNotification$ = new Subject<AlertMsg>();

  init(cloudId: string, currentLocationId: string): void {
    if (this.initialized) {
      return;
    }
    const apiKey = (environment.braze.cloud[cloudId] as string) || environment.braze.apiKey;

    appboy.initialize(apiKey, {
      baseUrl: environment.braze.baseUrl,
      enableLogging: false
    });

    appboy.openSession();
    this.initialized = true;
    this.setUser(currentLocationId);
    this.instructionClickSubscription();
  }

  setUser(userid: string): void {
    if (!this.initialized) {
      return;
    }
    appboy.changeUser(userid);
    appboy.requestFeedRefresh();
  }

  showFeed(el: Element, feedFilter: string[] = null): void {
    appboy.display.showFeed(el, this.latestCards, feedFilter);
  }

  destroyFeed(): void {
    appboy.display.destroyFeed();
  }

  private instructionClickSubscription(): void {
    appboy.subscribeToFeedUpdates(feed => {
      this.latestCards = feed.cards as (appboy.Card & { url: string })[];
      feed.cards.forEach((card: appboy.Card & { url: string; instruction?: string }) => {
        if (card.url?.startsWith('instruction/')) {
          card.subscribeToClickedEvent(() => {
            if (!card.instruction) {
              card.instruction = card.url.split('instruction/')[1];
            }
            card.url = 'javascript:void(0)';
            this.doInstruction(card.instruction);
          });
        }
      });
    });
  }

  private doInstruction(instruction: string): void {
    switch (instruction) {
      case 'requestFeedRefresh':
        this.alertNotification$.next({
          type: AlertType.notification,
          message: 'news.requestFeedRefreshNotification',
          needsTranslation: true
        });
        setTimeout(() => {
          appboy.requestFeedRefresh();
        }, 3000);
        break;
      default:
        break;
    }
  }
}
