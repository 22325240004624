<div class="header">{{ transPth + 'title' | translate }}</div>
<div class="content-text">{{ transPth + 'subTitle' | translate }}</div>
<div *ngFor="let speedLevel of speedLevels">
  <div class="speedLevelHeader">{{ transPth + speedLevel + '.title' | translate }}</div>
  <div class="speedValue">{{ transPth + speedLevel + '.speed' | translate }}</div>
  <div class="content-text">{{ transPth + speedLevel + '.description' | translate }}</div>
</div>
<div class="bottom-buttons">
  <button class="highlight" (click)="dialog.close()">{{ 'buttons.close' | translate }}</button>
</div>
