import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { SpeedForChart } from 'src/app/lib/rest-types';
import { CalculationsService } from '../calculations/calculations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[plm-x-axis-hours]',
  templateUrl: './x-axis-hours.component.html',
  styleUrls: ['./x-axis-hours.component.scss']
})
export class XAxisHoursComponent {
  @Input()
  public set chartData(chartData: SpeedForChart[]) {
    this.valChartData = chartData;
    this.labels = [];
    for (let i = 0; i < this.calculations.axisSizeInUnits.maxX + 1; i++) {
      this.labels.push(this.label(i));
    }
  }

  public get chartData(): SpeedForChart[] {
    return this.valChartData;
  }

  valChartData: SpeedForChart[] = [];
  labels: { time: string; amPm: string; timeVisible: boolean; amPmVisible: boolean }[] = [];
  constructor(public calculations: CalculationsService) {}

  private label(index: number): { time: string; amPm: string; timeVisible: boolean; amPmVisible: boolean } {
    let timeStamp = this.chartData[index]?.timestamp;
    if (!timeStamp && timeStamp !== 0) {
      timeStamp = this.chartData[index - 1].timestamp + 1000 * 60 * 60; // add 1 hour
    }
    const time = moment(timeStamp).format('h');
    const amPm = moment(timeStamp).format('a');
    const amPmVisible = time === '12';
    const timeVisible = !!(amPmVisible || this.chartData[index]?.download || this.chartData[index - 1]?.download);
    return {
      time,
      amPm,
      amPmVisible,
      timeVisible
    };
  }
}
