import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { IPlumeState } from '../../store';
import { loadHomeSilent } from '../../store/actions/home.actions';
import { currentLocationId } from '../../store/selectors/plume.selectors';
import { SharedUrlsService } from '../shared-urls';
import * as PubNub from 'pubnub';
import { speedTestFinished } from '../../store/actions/insights.actions';
import { getLocations } from '../../store/actions/plume.actions';

@Injectable({
  providedIn: 'root'
})
export class PubnubService {
  pubnubInstance: PubNub;
  constructor(private sharedUrls: SharedUrlsService, private http: HttpClient, private store$: Store<IPlumeState>) {}

  init(): void {
    this.store$
      .select(currentLocationId)
      .pipe(switchMap(locationId => forkJoin([this.getPubnubKeys$(locationId), of(locationId)])))
      .pipe(take(1))
      .subscribe(([pubnubCredentials, locationId]) => {
        if (this.pubnubInstance) {
          this.pubnubInstance.unsubscribeAll();
        }
        this.pubnubInstance = new PubNub({ ...pubnubCredentials, ssl: true });
        this.pubnubInstance.addListener({
          message: data => {
            this.processMessage(data, locationId);
          }
        });
        this.pubnubInstance.subscribe({
          channels: [locationId + '.topologyAlert', locationId + '.dashboard']
        });
      });
  }

  private processMessage(data: PubNub.MessageEvent, locationId: string): void {
    switch (data.channel) {
      case `${locationId}.topologyAlert`:
        this.store$.dispatch(loadHomeSilent());
        this.store$.dispatch(getLocations());
        break;
      case `${locationId}.dashboard`:
        this.store$.dispatch(speedTestFinished());
        break;
      default:
        break;
    }
  }

  private getPubnubKeys$(locationId: string): Observable<{ authKey: string; subscribeKey: string }> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.http.post<{ authKey: string; subscribeKey: string }>(
          `${locationUrl}/subscribe`,
          { channels: [`${locationId}.topologyAlert`] },
          {
            headers: { 'api-name': 'notifications' }
          }
        )
      )
    );
  }
}
