import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { WifiNetworkKeys } from 'src/app/lib/rest-types';
import { Device } from 'src/app/lib/store/models/device.model';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AlertType } from '../../alert-pop-bar/alert-pop.interface';
import { AccessPasswordDropDownClicked, AccessPasswordDropDownType } from './access-password.interface';

@Component({
  selector: 'plm-access-password',
  templateUrl: './access-password.component.html',
  styleUrls: ['./access-password.component.scss']
})
export class AccessPasswordComponent implements OnChanges {
  @Input() dropDownType: AccessPasswordDropDownType = AccessPasswordDropDownType.all;
  @Input() data: WifiNetworkKeys;
  @Input() devices: Device[];
  @Output() dropDownSelected = new EventEmitter<AccessPasswordDropDownClicked>();
  AccessPasswordDropDownClicked = AccessPasswordDropDownClicked;
  AccessPasswordDropDownType = AccessPasswordDropDownType;
  alert$ = new Subject<{ type: AlertType; message: string }>();
  deviceCount = 0;
  ngOnChanges(): void {
    this.deviceCount = this.devices?.filter(device => device.keyId === this.data.id)?.length ?? 0;
  }

  constructor(private translate: TranslateService) {}

  copyPasswordToClipboard(): void {
    void navigator.clipboard.writeText(this.data.encryptionKey).then(() =>
      this.alert$.next({
        message: this.translate.instant('settings.accessPassword.copied') as string,
        type: AlertType.notification
      })
    );
  }
}
