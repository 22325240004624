import { Component, Input } from '@angular/core';
import { trigger, state, animate, style, transition } from '@angular/animations';
import { MenuItem } from '../../menu.model';

@Component({
  selector: 'plm-menu-item',
  templateUrl: 'menu-item.component.html',
  styleUrls: ['menu-item.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state(
        'initial',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
          visibility: 'hidden'
        })
      ),
      state(
        'final',
        style({
          overflow: 'hidden'
        })
      ),
      transition('initial<=>final', animate('250ms'))
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('default <=> rotated', animate('250ms'))
    ])
  ]
})
export class MenuItemComponent {
  @Input() item: MenuItem;

  public more = false;

  toggle(): void {
    if (this.item.expandable) {
      this.item.opened = !this.item.opened;
    }
  }
}
