import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';

enum ProgressState {
  initial = 'initial',
  preparing = 'preparing',
  checkingDownload = 'checkingDownload',
  checkingUpload = 'checkingUpload',
  stillCalculating = 'stillCalculating',
  failed = 'failed'
}

@UntilDestroy()
@Component({
  selector: 'plm-box-speed-test-progress',
  templateUrl: './box-speed-test-progress.component.html',
  styleUrls: ['./box-speed-test-progress.component.scss']
})
export class BoxSpeedTestProgressComponent {
  ProgressState = ProgressState;
  @ViewChild('progress') progressElm: ElementRef<HTMLElement>;
  @HostBinding('class') state = ProgressState.initial;
  @Input()
  public set processFinished$(processObs$: Observable<unknown>) {
    if (!processObs$) {
      return;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = processObs$.pipe(untilDestroyed(this)).subscribe(() => {
      this.processFinished = true;
      this.initFinishedMode();
    });
  }
  @Input() @HostBinding('class.offline') offline = false;
  @Output() started = new EventEmitter<void>();

  private subscription: Subscription;
  private processFinished = false;
  private timeOutTimer: number;
  private endState = [ProgressState.initial, ProgressState.failed];
  /*
    timeout - step duration in ms
    processFinishedTimeOut - step duration in ms if testing is finished
    nextState - after this state is done then what state should be next
    if process finishes in middle of step then this step continue with new speed.
      Timeout is calculated as remaining %, so if step is in state of 80%, then this step will
      continue from 80% and will take processFinishedTimeOut * 0.2 (100% - 80% = 20%)
  */
  private stateDefinition = {
    [ProgressState.initial]: { timeOut: 0, processFinishedTimeOut: 0, nextState: () => ProgressState.preparing },
    [ProgressState.failed]: { timeOut: 0, processFinishedTimeOut: 0, nextState: () => ProgressState.preparing },
    [ProgressState.preparing]: {
      timeOut: 5000,
      processFinishedTimeOut: 500,
      nextState: () => ProgressState.checkingDownload
    },
    [ProgressState.checkingDownload]: {
      timeOut: 15000,
      processFinishedTimeOut: 500,
      nextState: () => ProgressState.checkingUpload
    },
    [ProgressState.checkingUpload]: {
      timeOut: 15000,
      processFinishedTimeOut: 500,
      nextState: () => ProgressState.stillCalculating
    },
    [ProgressState.stillCalculating]: {
      timeOut: 25000,
      processFinishedTimeOut: 0,
      nextState: () => (this.processFinished ? ProgressState.initial : ProgressState.failed)
    }
  };

  @HostListener('click')
  private clicked(): void {
    this.startProgress();
  }

  private startProgress(): void {
    if (this.endState.includes(this.state) && !this.offline) {
      this.processFinished = false;
      this.started.emit();
      this.delayedSetNextState();
    }
  }

  private delayedSetNextState(finished = false, continueProgress = false): void {
    this.resetAnimation(finished, continueProgress);
    this.timeOutTimer = (setTimeout(() => {
      this.state = this.stateDefinition[this.state].nextState();
      if (!this.endState.includes(this.state)) {
        this.delayedSetNextState(finished);
      }
    }, this.timeoutTime(finished, continueProgress)) as unknown) as number;
  }

  private resetAnimation(finished: boolean, continueProgress: boolean): void {
    if (continueProgress) {
      this.progressElm.nativeElement.style.right = window.getComputedStyle(this.progressElm.nativeElement).right;
    } else {
      this.progressElm.nativeElement.style.right = null;
    }
    this.progressElm.nativeElement.style.animation = 'none';
    this.doNothing(this.progressElm.nativeElement.offsetHeight); // trigger reflow
    this.progressElm.nativeElement.style.animation = null;
    this.progressElm.nativeElement.style['animation-duration'] = `${this.timeoutTime(finished, continueProgress)}ms`;
  }

  // this is needed because without it tree shaking will remove reflow triggering
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private doNothing(param: number): void {}

  private initFinishedMode(): void {
    if (this.endState.includes(this.state)) {
      return;
    }
    clearTimeout(this.timeOutTimer);

    this.delayedSetNextState(true, true);
  }

  private timeoutTime(finished: boolean, continuingProgress: boolean): number {
    let timeShortageRate = 1;
    if (continuingProgress) {
      const right = Number.parseInt(this.progressElm.nativeElement.style.right, 10);
      const left = Number.parseInt(window.getComputedStyle(this.progressElm.nativeElement).width, 10);
      timeShortageRate = right / (right + left);
    }
    return (
      timeShortageRate *
      (finished ? this.stateDefinition[this.state].processFinishedTimeOut : this.stateDefinition[this.state].timeOut)
    );
  }
}
