import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HourlyBlockedCounts, SecurityPolicyEventTypes, securityTypeConversion } from '../../rest-types';
import { SharedUrlsService } from '../shared-urls';

export interface AiSecurityHourlyEventCount {
  type: string;
  count: number;
  hour: number;
  day: number;
}

@Injectable({
  providedIn: 'root'
})
export class AiSecurityService {
  constructor(private http: HttpClient, private sharedUrls: SharedUrlsService) {}



  getAiSecurityDailyCounts$(): Observable<AiSecurityHourlyEventCount[]> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.http.get<HourlyBlockedCounts>(`${locationUrl}/securityPolicy/hourlyBlockedCounts`, {
          headers: { 'errors-white-list': ['404'] }
        })
      ),
      map(hourlyCounts => this.mapToHourlyCounts(hourlyCounts))
    );
  }

  getAiSecurityDailyCountsByMac$(mac: string): Observable<AiSecurityHourlyEventCount[]> {
    return this.sharedUrls.locationUrl$().pipe(
      switchMap(locationUrl =>
        this.http.get<HourlyBlockedCounts>(`${locationUrl}/devices/${mac}/securityPolicy/hourlyBlockedCounts`, {
          headers: { 'errors-white-list': ['404'] }
        })
      ),
      map(hourlyCounts => this.mapToHourlyCounts(hourlyCounts))
    );
  }

  private mapToHourlyCounts(sections: HourlyBlockedCounts): AiSecurityHourlyEventCount[] {
    return (Object.keys(sections) as SecurityPolicyEventTypes[])
      .map(key => [
        ...sections[key].map((daily, dayIndex) =>
          daily
            .map((hourDatapoint, hourIndex) => ({
              type: (securityTypeConversion[key]) || key,
              count: hourDatapoint,
              hour: hourIndex,
              day: dayIndex
            }))
            .filter(hour => hour.count > 0)
        )
      ])
      .reduce((flatten, array) => [...flatten, ...array], [])
      .reduce((flatten, array) => [...flatten, ...array], []);
  }
}
