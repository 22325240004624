<div class="row">
  <div>{{ 'portForwarding.portName' | translate }}</div>
  <plm-input-field><input [(ngModel)]="portEditData.name" maxlength="63"/></plm-input-field>
</div>
<div class="row">
  <div>{{ 'portForwarding.externalPort' | translate }}</div>
  <plm-input-field><input [disabled]="editMode" [(ngModel)]="portEditData.externalPort"/></plm-input-field>
</div>
<div class="row">
  <div>{{ 'portForwarding.internalPort' | translate }}</div>
  <plm-input-field><input [(ngModel)]="portEditData.internalPort"/></plm-input-field>
</div>
<div class="row">
  <div>{{ 'portForwarding.protocol' | translate }}</div>
  <div class="protocolList">
    <button
      class="pill-icon-box"
      [class.focus]="portEditData.protocol === 'tcp+udp'"
      (click)="portEditData.protocol = 'tcp+udp'"
    >
      {{ 'portForwarding.tcpUdp' | translate }}
    </button>
    <button
      class="pill-icon-box"
      [class.focus]="portEditData.protocol === 'tcp'"
      (click)="portEditData.protocol = 'tcp'"
    >
      {{ 'portForwarding.tcp' | translate }}
    </button>
    <button
      class="pill-icon-box"
      [class.focus]="portEditData.protocol === 'udp'"
      (click)="portEditData.protocol = 'udp'"
    >
      {{ 'portForwarding.udp' | translate }}
    </button>
  </div>
</div>
<div class="buttonList">
  <button [disabled]="saving" class="flat alert delete" (click)="doDelete()" *ngIf="editMode">
    {{ 'portForwarding.deletePort' | translate }}
  </button>
  <button [disabled]="saving" class="flat" (click)="doCancel()">{{ 'buttons.cancel' | translate }}</button>
  <button [disabled]="saving" class="flat highlight" (click)="doSave()">{{ 'buttons.save' | translate }}</button>
</div>
<plm-alert-pop-bar [alert$]="alert$"></plm-alert-pop-bar>
