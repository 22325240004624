import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { MappedDeviceCategory } from 'src/app/components/topology/top-bar/top-bar.interface';
import { IPlumeState } from 'src/app/lib/store';
import { Device } from 'src/app/lib/store/models/device.model';
import { Person } from 'src/app/lib/store/models/person.model';
import { selectDevices, selectPeople } from 'src/app/lib/store/selectors/home.selectors';

@Component({
  selector: 'plm-topology',
  templateUrl: './topology.component.html',
  styleUrls: ['./topology.component.scss']
})
export class TopologyComponent implements OnDestroy {
  deviceCategorySortPriority = [
    'Set Top Box',
    'Smart Phone',
    'Laptop',
    'Tablet',
    'Game Console',
    'Computer',
    'Voice Assistant',
    'Camera',
    'Speaker',
    'Smart Device',
    'Thermostat',
    'Watch'
  ];
  people$ = this.store$.select(selectPeople);
  offlineDevices$ = this.store$
    .select(selectDevices)
    .pipe(map(devices => devices.filter(device => device.connectionState === 'disconnected')));
  deviceCategories$ = this.store$.select(selectDevices).pipe(
    map(devices => this.deviceCategoryCounter(devices)),
    map(categoryCounter => this.deviceCategorySorter(categoryCounter)),
    map(sortedDeviceCounter =>
      sortedDeviceCounter.map(deviceCounterItem => ({ name: deviceCounterItem[0], ...deviceCounterItem[1] }))
    )
  );

  constructor(private store$: Store<IPlumeState>, private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'dark-theme');
  }

  filter(val: {
    searchText: string;
    person: Person | null;
    deviceCategory: MappedDeviceCategory;
    advanced: boolean;
  }): void {
    console.log(val);
  }

  zoomOut(): void {
    console.log('zoom out');
  }

  zoomIn(): void {
    console.log('zoom in');
  }

  deviceSelected(device: Device): void {
    console.log(device);
  }

  private deviceCategoryCounter(devices: Device[]): { [key: string]: { count: number; allDevices: boolean } } {
    return devices.reduce((acc, val) => {
      if (val.connectionState === 'connected') {
        acc[val.category] = {
          count: (acc[val.category]?.count ?? 0) + 1,
          allDevices: false
        };
      }
      return acc;
    }, {} as { [key: string]: { count: number; allDevices: boolean } });
  }

  private deviceCategorySorter(categoryCounter: {
    [key: string]: { count: number; allDevices: boolean };
  }): [string, { count: number; allDevices: boolean }][] {
    const sorted = Object.entries(categoryCounter).sort((entry1, entry2) => {
      let categoryPriority1 = this.deviceCategorySortPriority.indexOf(entry1[0]);
      let categoryPriority2 = this.deviceCategorySortPriority.indexOf(entry2[0]);
      categoryPriority1 = categoryPriority1 > -1 ? categoryPriority1 : this.deviceCategorySortPriority.length;
      categoryPriority2 = categoryPriority2 > -1 ? categoryPriority2 : this.deviceCategorySortPriority.length;
      return categoryPriority1 - categoryPriority2 || entry1[0].localeCompare(entry2[0]);
    });
    sorted.unshift(['', { count: sorted.reduce((a, b) => a + b[1].count, 0), allDevices: true }]);
    return sorted;
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'dark-theme');
  }
}
