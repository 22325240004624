import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[plmToolTipBox]'
})
export class ToolTipBoxDirective implements OnDestroy{
  @Input('plmToolTipBox') template: TemplateRef<unknown> = null;
  overlayRef: OverlayRef;

  constructor(private overlay: Overlay, elm: ElementRef, private viewContainerRef: ViewContainerRef) {
    this.overlayRef = this.overlay.create({
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      panelClass: 'tool-tip-box',
      positionStrategy: overlay
        .position()
        .flexibleConnectedTo(elm)
        .withPositions([
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -12
          }
        ])
    });
  }

  @HostListener('mouseenter')
  mouseenter(): void {
    if (this.template && !this.overlayRef.hasAttached()) {
      this.overlayRef.attach(new TemplatePortal(this.template, this.viewContainerRef));
    }
  }

  @HostListener('mouseleave')
  mouseleave(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  ngOnDestroy(): void {
    this.overlayRef.dispose();
  }
}
