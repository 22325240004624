<div class="header">
  <span class="highlighted">{{ 'settings.access.passwordList.guestTitle' | translate }}</span>
  <span> {{ 'settings.access.passwordList.guestDescription' | translate }}</span>
</div>

<div *ngFor="let zone of mappedZones; let first = first">
  <div class="block-empty-separator" *ngIf="!first"></div>
  <div class="zoneRow">
    <div class="icon" icon="zone"></div>
    <div class="text">
      <div class="title">{{ 'settings.access.passwordList.devicesAvailableTo' | translate }}</div>
      <div class="name">{{ zone.zoneName }}</div>
    </div>
    <div class="icon" icon="edit" (click)="editAccessZone.emit(zone.zoneId)"></div>
  </div>
  <div class="tapToSelectDevices" *ngIf="zone.devices.length === 0" (click)="editAccessZone.emit(zone.zoneId)">
    {{ 'settings.access.passwordList.tapToSelectDevices' | translate }}
  </div>
  <div class="devices" [class.empty]="zone.devices.length === 0">
    <div class="device" *ngFor="let device of zone.devices">
      <div class="deviceIcon" [icon]="device.iconV2" [device]="true" height="64" width="64"></div>
      <div plmOverflowToolTip class="ellipsis-overflow deviceName">{{ device.nickname || device.name || '' }}</div>
    </div>
    <ng-container *ngIf="zone.devices.length === 0">
      <div class="device" *ngFor="let icon of noDeviceIcons">
        <div class="deviceIcon empty" [icon]="icon" [device]="true" height="64" width="64"></div>
      </div>
    </ng-container>
  </div>
  <plm-access-password
    class="border-top"
    [data]="key"
    [devices]="devices"
    [dropDownType]="dropDownType(key)"
    *ngFor="let key of zone.passwords"
    (dropDownSelected)="dropDownSelected.emit({ key: key, type: $event })"
  ></plm-access-password>
</div>
