import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CustomerLocation } from 'src/app/lib/rest-types';
import { IPlumeState } from 'src/app/lib/store';
import {
  getDhcp,
  getDhcpReservations,
  getDns,
  getUpnp,
  setDhcp,
  setDns,
  setDnsSuccess,
  setUpnp
} from 'src/app/lib/store/actions/settings.actions';
import { Dhcp } from 'src/app/lib/store/models/dhcp.model';
import { DnsServers } from 'src/app/lib/store/models/dns.model';
import { Upnp } from 'src/app/lib/store/models/upnp.model';
import { offline } from 'src/app/lib/store/selectors/home.selectors';
import { currentLocation } from 'src/app/lib/store/selectors/plume.selectors';
import { dhcp, dhcpReservations, dnsServers, upnp } from 'src/app/lib/store/selectors/settings.selectors';

@UntilDestroy()
@Component({
  selector: 'plm-settings-advanced',
  templateUrl: 'settings-advanced.component.html',
  styleUrls: ['settings-advanced.component.scss']
})
export class SettingsAdvancedComponent implements OnInit {
  currentLocation$: Observable<CustomerLocation>;
  infoContent: string;
  upnp$: Observable<Upnp>;
  dhcpReservations$ = this.store$.select(dhcpReservations);
  offline$ = this.store$.select(offline);
  dnsServers$: Observable<DnsServers>;
  dhcp$: Observable<Dhcp>;
  constructor(private store$: Store<IPlumeState>, protected readonly actions$: Actions) {
    this.currentLocation$ = this.store$.select(currentLocation);
    this.upnp$ = this.store$.select(upnp);
    this.dnsServers$ = this.store$.select(dnsServers);
    this.dhcp$ = this.store$.select(dhcp);
  }

  ngOnInit(): void {
    this.store$.dispatch(getUpnp());
    this.store$.dispatch(getDhcpReservations());
    this.store$.dispatch(getDns());
    this.store$.dispatch(getDhcp());
    this.startComponentEffects();
  }

  onUpnEnabled(mode: string): void {
    this.store$.dispatch(setUpnp({ mode: mode }));
  }

  onCustomDns(dns: DnsServers): void {
    this.store$.dispatch(setDns({ dns }));
  }

  onDnsReset(): void {
    const dns = {
      primaryDns: '',
      secondaryDns: ''
    };

    this.store$.dispatch(setDns({ dns }));
  }

  onSaveLanSubnet(ip: string): void {
    this.store$.dispatch(
      setDhcp({
        dhcp: {
          subnet: ip
        }
      })
    );
  }

  private startComponentEffects() {
    this.actions$.pipe(ofType(setDnsSuccess), untilDestroyed(this)).subscribe(() => {
      // Ensure Database is in Sync with device state.
      setTimeout(() => {
        this.store$.dispatch(getDns());
      }, 2000);
    });
  }
}
