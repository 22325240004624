import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { PortForwards } from 'src/app/lib/store/models/dhcp-reservation.model';
import { AccordionComponent } from '../ui/accordion/accordion.component';

@UntilDestroy()
@Component({
  selector: 'plm-ip-reservation-port-list',
  templateUrl: './ip-reservation-port-list.component.html',
  styleUrls: ['./ip-reservation-port-list.component.scss']
})
export class IpReservationPortListComponent {
  @Input() ports: PortForwards[] = [];
  @Input() portSaving: { [key: string]: true } = {}; // [external port list]: true
  @Input() newPortSaving = false;
  @Input() mac = '';
  @Input() public set cancelEdit$(value$: Observable<void>) {
    this.valCancelEdit$ = value$;
    value$.pipe(untilDestroyed(this)).subscribe(() => {
      this.newPortOpened = false;
      this.accordion.closeAll();
    });
  }
  valCancelEdit$: Observable<void>;
  @Input() public set closeNewPort$(value$: Observable<string>) {
    value$.pipe(untilDestroyed(this)).subscribe(() => {
      this.newPortOpened = false;
    });
  }

  @Output() saveNewPort = new EventEmitter<PortForwards>();
  @Output() saveEditPort = new EventEmitter<PortForwards>();
  @Output() deletePort = new EventEmitter<number>();
  @ViewChild('accordion') accordion: AccordionComponent;
  newPortOpened = false;

  openNewPort(): void {
    this.newPortOpened = true;
  }

  cancelNewPort(): void {
    this.newPortOpened = false;
  }

  cancelEditPort(): void {
    this.accordion.closeAll();
  }
}
