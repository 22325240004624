export class PolygonInCircle {
  static get(
    radius: number,
    polygonSides: number,
    strokeWidth: number,
    rotate: number,
    cornerAngle: number
  ): string {
    const points = this.polygonInCirclePoints(radius, polygonSides, strokeWidth, rotate, cornerAngle);
    return points.reduce((acc, val) => {
      if (val[2] === 'A') {
        return acc + ` A${cornerAngle},${cornerAngle} 0 0 1 ${val[0]} ${val[1]}`;
      }
      return acc + ` ${val[2]}${val[0]} ${val[1]}`;
    }, '');
  }

  private static polygonInCircleStart(
    radius: number,
    rotate: number,
    cornerAngle: number,
    angle: number,
    strokeWidth: number
  ): [number, number, 'M' | 'L' | 'A'] {
    return [
      radius - Math.sin(angle / 2 - rotate) * (radius - strokeWidth / 1.5) + Math.cos(rotate) * (cornerAngle / 2),
      radius - Math.cos(angle / 2 - rotate) * (radius - strokeWidth / 1.5) + Math.sin(rotate) * (cornerAngle / 2),
      'M'
    ];
  }

  private static polygonInCirclePoints(
    radius: number,
    polygonSides: number,
    strokeWidth: number,
    rotate: number,
    cornerAngle: number
  ): [number, number, 'M' | 'L' | 'A'][] {
    const lineLength =
      Math.sqrt(2 * radius * radius - 2 * radius * radius * Math.cos((2 * Math.PI) / polygonSides)) - strokeWidth;
    const angle = (2 * Math.PI) / polygonSides;
    const points: [number, number, 'M' | 'L' | 'A'][] = [
      this.polygonInCircleStart(radius, rotate, cornerAngle, angle, strokeWidth)
    ];

    for (let i = 0; i < polygonSides; i++) {
      const currAngl = rotate + angle * i;
      points.push([
        Math.cos(currAngl) * (lineLength - cornerAngle) + points[points.length - 1][0],
        Math.sin(currAngl) * (lineLength - cornerAngle) + points[points.length - 1][1],
        'L'
      ]);
      points.push([
        Math.cos(currAngl + angle / 2) * cornerAngle + points[points.length - 1][0],
        Math.sin(currAngl + angle / 2) * cornerAngle + points[points.length - 1][1],
        'A'
      ]);
    }
    return points;
  }
}
