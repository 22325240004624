<plm-header></plm-header>
<plm-top-bar
  class="top"
  [people$]="people$"
  [deviceCategories$]="deviceCategories$"
  (filter)="filter($event)"
></plm-top-bar>
<div class="graph">graph</div>
<plm-bottom-bar
  class="bottom"
  (zoomOut)="zoomOut()"
  (zoomIn)="zoomIn()"
  (deviceSelected)="deviceSelected($event)"
  [offlineDevices]="offlineDevices$ | async"
  [people]="people$ | async"
></plm-bottom-bar>
