import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo, take } from 'rxjs/operators';
import { ApproveBlockErrorComponent } from '../../../../app/components/approve-block/approve-block-error-modal/approve-block-error-modal.component';
import { DialogService } from '../../services/dialog/dialog.service';
import { SecurityPolicyService } from '../../services/security-policy/security-policy.service';
import { SecurityPolicyActions } from '../actions/security-policy.actions';

interface ErrorWithMessage extends HttpErrorResponse {
  error: {
    error: {
      message: string;
    };
  };
}

@Injectable()
export class SecurityPolicyEffects {
  securityPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityPolicyActions.getSecurityPolicyByLocation),
      switchMap(() =>
        this.securityPolicyService.getSecurityPolicy$().pipe(
          map(response => SecurityPolicyActions.getSecurityPolicyByLocationSuccess({ securityPolicy: response })),
          catchError(error => of(SecurityPolicyActions.getSecurityPolicyByLocationFail(error)))
        )
      )
    )
  );

  addSecurityPolicyWhiteList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityPolicyActions.addSecurityPolicyWhiteList),
      switchMap(data =>
        of(true)
          .pipe(switchMap(() => this.whiteListPolicy$(data)))
          .pipe(
            map(() => SecurityPolicyActions.addSecurityPolicyWhiteListSuccess({ url: data.url })),
            catchError((error: ErrorWithMessage) => {
              if (error && error.status === 422) {
                return this.openErrorDialog$(error.error.error.message, 'Blocked').pipe(
                  map(result => {
                    if (result) {
                      return SecurityPolicyActions.toggleSecurityPolicy({
                        ...data,
                        action: SecurityPolicyActions.addSecurityPolicyWhiteList.type
                      });
                    } else {
                      return SecurityPolicyActions.addSecurityPolicyWhiteListFail({ error, url: data.url });
                    }
                  })
                );
              }
              return of(SecurityPolicyActions.addSecurityPolicyWhiteListFail({ error, url: data.url }));
            })
          )
      )
    )
  );

  addSecurityPolicyBlackList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityPolicyActions.addSecurityPolicyBlackList),
      switchMap(data =>
        of(true)
          .pipe(switchMap(() => this.blackListPolicy$(data)))
          .pipe(
            map(() => SecurityPolicyActions.addSecurityPolicyBlackListSuccess({ url: data.url })),
            catchError((error: ErrorWithMessage) => {
              if (error.status === 422) {
                return this.openErrorDialog$(error.error.error.message, 'Approve').pipe(
                  map(result => {
                    if (result) {
                      return SecurityPolicyActions.toggleSecurityPolicy({
                        ...data,
                        action: SecurityPolicyActions.addSecurityPolicyBlackList.type
                      });
                    } else {
                      return SecurityPolicyActions.addSecurityPolicyBlackListFail({ error, url: data.url });
                    }
                  })
                );
              }
              return of(SecurityPolicyActions.addSecurityPolicyBlackListFail({ error, url: data.url }));
            })
          )
      )
    )
  );

  loadWifiNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityPolicyActions.loadWifiNetwork),
      switchMapTo(
        this.securityPolicyService.getWifiNetwork$().pipe(catchError((error: HttpErrorResponse) => of({ error })))
      ),
      map(data => SecurityPolicyActions.loadWifiNetworkDone({ data }))
    )
  );

  removeSecurityPolicyWhiteList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityPolicyActions.removeSecurityPolicyWhiteList),
      switchMap(data =>
        of(true)
          .pipe(switchMap(() => this.removeWhiteListPolicy$(data)))
          .pipe(
            map(() => SecurityPolicyActions.removeSecurityPolicyWhiteListSuccess({ url: data.url })),
            catchError((error: HttpErrorResponse) =>
              of(SecurityPolicyActions.removeSecurityPolicyWhiteListFail({ error, url: data.url }))
            )
          )
      )
    )
  );

  removeSecurityPolicyBlackList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityPolicyActions.removeSecurityPolicyBlackList),
      switchMap(data =>
        of(true)
          .pipe(switchMap(() => this.removeBlackListPolicy$(data)))
          .pipe(
            map(() => SecurityPolicyActions.removeSecurityPolicyBlackListSuccess({ url: data.url })),
            catchError((error: HttpErrorResponse) =>
              of(SecurityPolicyActions.removeSecurityPolicyBlackListFail({ error, url: data.url }))
            )
          )
      )
    )
  );

  toggleSecurityPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityPolicyActions.toggleSecurityPolicy),
      switchMap(data =>
        of(true)
          .pipe(
            switchMap(() => {
              switch (data.action) {
                case SecurityPolicyActions.addSecurityPolicyWhiteList.type:
                  return this.removeBlackListPolicy$(data);
                case SecurityPolicyActions.addSecurityPolicyBlackList.type:
                  return this.removeWhiteListPolicy$(data);
              }
            })
          )
          .pipe(
            map(() => {
              switch (data.action) {
                case SecurityPolicyActions.addSecurityPolicyWhiteList.type:
                  return SecurityPolicyActions.addSecurityPolicyWhiteList(data);
                case SecurityPolicyActions.addSecurityPolicyBlackList.type:
                  return SecurityPolicyActions.addSecurityPolicyBlackList(data);
              }
            }),
            catchError((error: HttpErrorResponse) =>
              of(SecurityPolicyActions.toggleSecurityPolicyFail({ error: error, url: data.url }))
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private securityPolicyService: SecurityPolicyService,
    private dialog: DialogService
  ) {}

  private openErrorDialog$(message: string, mode: string): Observable<boolean> {
    return this.dialog
      .openDialog$<boolean>(ApproveBlockErrorComponent, { message: message, mode: mode })
      .pipe(take(1));
  }

  private addressType(url: string, dnsType: 'fqdn' | 'ip'): string {
    if (dnsType === 'fqdn') {
      return 'fqdn';
    }
    return url.includes(':') ? 'ipv6' : 'ipv4';
  }

  private whiteListPolicy$(
    data: { url: string; dnsType: 'fqdn' | 'ip' } & (
      | { personId: string; for: 'person' }
      | { mac: string; for: 'device' }
      | { for: 'everyone' }
    )
  ): Observable<unknown> {
    switch (data.for) {
      case 'person':
        return this.securityPolicyService.addSecurityPolicyPersonWhitelist$(
          data.personId,
          data.url,
          this.addressType(data.url, data.dnsType)
        );
      case 'device':
        return this.securityPolicyService.addSecurityPolicyDeviceWhitelist$(
          data.mac,
          data.url,
          this.addressType(data.url, data.dnsType)
        );
      default:
        return this.securityPolicyService.addSecurityPolicyWhitelist$(
          data.url,
          this.addressType(data.url, data.dnsType)
        );
    }
  }

  private blackListPolicy$(
    data: { url: string; dnsType: 'fqdn' | 'ip' } & (
      | { personId: string; for: 'person' }
      | { mac: string; for: 'device' }
      | { for: 'everyone' }
    )
  ): Observable<unknown> {
    switch (data.for) {
      case 'person':
        return this.securityPolicyService.addSecurityPolicyPersonBlacklist$(
          data.personId,
          data.url,
          this.addressType(data.url, data.dnsType)
        );
      case 'device':
        return this.securityPolicyService.addSecurityPolicyDeviceBlacklist$(
          data.mac,
          data.url,
          this.addressType(data.url, data.dnsType)
        );
      default:
        return this.securityPolicyService.addSecurityPolicyBlacklist$(
          data.url,
          this.addressType(data.url, data.dnsType)
        );
    }
  }

  private removeWhiteListPolicy$(
    data: { url: string } & ({ personId: string; for: 'person' } | { mac: string; for: 'device' } | { for: 'everyone' })
  ): Observable<unknown> {
    switch (data.for) {
      case 'person':
        return this.securityPolicyService.removeSecurityPolicyPersonWhitelist$(data.personId, data.url);
      case 'device':
        return this.securityPolicyService.removeSecurityPolicyDeviceWhitelist$(data.mac, data.url);
      default:
        return this.securityPolicyService.removeSecurityPolicyWhitelist$(data.url);
    }
  }

  private removeBlackListPolicy$(
    data: { url: string } & ({ personId: string; for: 'person' } | { mac: string; for: 'device' } | { for: 'everyone' })
  ): Observable<unknown> {
    switch (data.for) {
      case 'person':
        return this.securityPolicyService.removeSecurityPolicyPersonBlacklist$(data.personId, data.url);
      case 'device':
        return this.securityPolicyService.removeSecurityPolicyDeviceBlacklist$(data.mac, data.url);
      default:
        return this.securityPolicyService.removeSecurityPolicyBlacklist$(data.url);
    }
  }
}
